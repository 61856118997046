import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SwivoClient = {
  client: {
    clientId: null,
  },
};

export const clientSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clientDetails: (
      state: SwivoClient,
      action: PayloadAction<ClientEntities>
    ) => {
      state.client.clientId = action.payload.clientId;
    },
    removeClientDetails: () => initialState,
  },
});

export default clientSlice.reducer;
export const { clientDetails, removeClientDetails } = clientSlice.actions;
