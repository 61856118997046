import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SitesState = { sites: [] };

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    addSites: (state, action: PayloadAction<Site[]>) => {
      state.sites = action.payload;
    },
  },
});

export default sitesSlice.reducer;
export const { addSites } = sitesSlice.actions;
