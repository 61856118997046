import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { getAllSites } from "../../apis/site";
import AddSite from "./AddSite";
import ShowSiteDetails from "./ShowSiteDetails";
import ComponentLoading from "../loading/ComponentLoading";
import { useSelector } from "react-redux";

const ShowSites = () => {
  // useSubMenuURL("/show-sites");
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [showTable, setShowTable] = useState<boolean>();
  const [isAddSite, setIsAddSite] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const allSites: any = useSelector<SitesState>((state) => state.sites);
  const getSites = async () => {
    // const [response, error] = await getAllSites();
    // if (response) {
    setSites(
      allSites.sites.map((site: Site) => {
        return {
          ownerStaffName: `${site.ownerStaff?.title} ${site.ownerStaff.firstName} ${site.ownerStaff.lastName}`,
          email: site.ownerStaff.email,
          ...site,
        };
      })
    );
    setIsLoading(false);
    setShowTable(true);
    // } else {
    //   console.log(error);
    // }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Head Staff",
      key: "ownerStaffName",
      sortable: true,
    },
    {
      label: "Staff Email",
      key: "email",
    },
  ];

  const handleRowClick = (row: any) => {
    console.log(row);
    // row = combineArrays(
    //     patientAndPartnerFormInputs,
    //     getForm(row.type) || [],
    //     row.reportFields
    //   );
    setIsAddSite(false);
    setSelectedSite(row);
    // setShowTable(false);
  };
  useEffect(() => {
    getSites();
  }, [reload]);
  // useEffect(() => {
  //   getSites();
  // }, [isAddSite]);
  return (
    <>
      <Grid container width={"inherit"} spacing={2}>
        {isLoading ? (
          <ComponentLoading />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
            {sites && (
              <CustomTable
                data={sites}
                columns={columns}
                onRowClick={handleRowClick}
                searchable={true}
                optionButtonText={"Add Site"}
                setOptionButton={setIsAddSite}
                //   dateFormatter={formatToDateTime}
                defaultSort={{
                  key: columns[0].key,
                  direction: "desc",
                }}
                height={"50vh"}
              />
            )}
          </Grid>
        )}
        {(isAddSite || selectedSite) && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            minHeight={"50vh"}
            sx={{
              borderLeft: {
                md: "none",
                lg: "1px solid #dbd9d9",
              },
              borderBottom: {
                md: "none",
                lg: "none",
              },
              mt: 2,
            }}>
            {isAddSite ? (
              <AddSite setIsAddSite={setIsAddSite} setReload={setReload} />
            ) : (
              selectedSite && (
                <ShowSiteDetails
                  selectedSite={selectedSite}
                  setSelectedSite={setSelectedSite}
                  setReload={setReload}
                />
              )
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ShowSites;
