import React from "react";

interface CharCountProps {
  value: string;
  maxCount: number;
}

const CharacterCount: React.FC<CharCountProps> = ({ value, maxCount }) => {
  const characterCount = value.length;
  const remainingCount = maxCount - characterCount;
  const isExceeded = characterCount > maxCount;

  return (
    <div>
      <p>
        {characterCount}/{maxCount}
      </p>
      {isExceeded && (
        <p style={{ color: "red" }}>
          Exceeded the maximum character count by {remainingCount * -1}{" "}
          characters.
        </p>
      )}
    </div>
  );
};

export default CharacterCount;
