import { Box, Typography } from "@mui/material";

import HeaderElement from "./HeaderElement";
const ShowLetter: React.FC<ShowLetterProps> = ({
  letter,
  setShowTable,
  handleDownload,
}) => {
  return (
    <>
      <HeaderElement
        name={letter?.name}
        whenCreated={letter?.whenCreated}
        downloadFunction={() => handleDownload(letter)}
        backFunction={() => {
          setShowTable(true);
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 3,
        }}>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "20%" }}>
            <Typography variant="subtitle1">
              <b>Letter content</b>
            </Typography>
          </Box>{" "}
          <Box sx={{ width: "80%" }}>{letter.content}</Box>
        </Box>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "20%" }}>
            <Typography variant="subtitle1">
              <b>Signature</b>
            </Typography>
          </Box>{" "}
          <Box sx={{ width: "80%" }}>{letter.signature}</Box>
        </Box>
      </Box>
    </>
  );
};

export default ShowLetter;
