import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from "../../constants/Constants";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import PrimaryButton from "../buttons/PrimaryButton";
import TernaryButton from "../buttons/TernaryButton";
import SearchPathway from "../search/SearchPathway";
import SearchServiceMultiSelect from "../search/ServiceMultiSelect";
import { createService } from "../../apis/services";
import { SERVICE_CREATE_SUCCESS } from "../../constants/SnackbarMessages";
import { SERVICE_CREATE_FAILED } from "../../constants/SnackbarMessages";

interface AddServiceProps {
  setIsAddService: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}

const AddService: React.FC<AddServiceProps> = ({
  setIsAddService,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [values, setValues] = useState<MultiSelectOptionType[]>([]);
  const handleSelectedService = (service: Service | null) => {
    if (service) {
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        service,
      ]);
      console.log(selectedServices);
    }
  };

  const handleRemoveService = (index: number) => {
    setSelectedServices((prevSelectedServices) => {
      const updatedServices = [...prevSelectedServices];
      updatedServices.splice(index, 1);
      return updatedServices;
    });
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const suggestions = values.map((item: any, index) => {
      return {
        suggestedServicePublicId: item.publicId,
      };
    });
    if (suggestions.length > 0) {
      data.serviceSuggestions = suggestions;
    }

    const [response, error] = await createService(data as CreateServiceRequest);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: SERVICE_CREATE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setIsAddService(false);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || SERVICE_CREATE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container flex={1} spacing={2} mt={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Service Name"
            className="col-sm-4 p-2"
            id="name"
            placeholder="Give service name"
            required
            {...register("name", { required: true })}
          />
          <TextField
            label="External Id"
            className="col-sm-4 p-2"
            id="externalId"
            placeholder="Give external Id"
            {...register("externalId", { required: false })}
          />
          <TextField
            label="Type of service"
            className="col-sm-4 p-2"
            id="type"
            placeholder="Select type of service"
            required
            {...register("type", { required: true })}
            select>
            <MenuItem value="Treatment">Treatment</MenuItem>
            <MenuItem value="Consultation">Consultation</MenuItem>
            <MenuItem value="Test">Test</MenuItem>
          </TextField>
          <SearchPathway
            setValue={setValue}
            id={`pathwayPublicId`}
            label="Select pathway"
            className="col-sm-4 p-2"
          />
          <SearchServiceMultiSelect
            label={"Suggest services"}
            id={""}
            setValues={setValues}
            className="col-sm-12 p-2"
            values={values}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {/* Display selected services */}
          {selectedServices.map((service, index) => (
            <div key={index}>
              {service.name}
              <button onClick={() => handleRemoveService(index)}>Remove</button>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <TernaryButton
              onClick={() => {
                setIsAddService(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Save"} type={"submit"} className="m-2" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddService;
