import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import ActionDelete from "../actionIcons/ActionDelete";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import EditableBox from "../adminCenterComponents/EditableBox";
import {
  DELETE_FAILED,
  DELETE_SUCCESS,
} from "../../constants/SnackbarMessages";
import { CustomTable } from "../table/Table";
import ActionDownload from "../actionIcons/ActionDownload";
import { createPrescriptionPDF } from "../../util/prescription/prescription";
import { formatDate } from "../../util/data.util";
import { deletePrescription } from "../../apis/prescription";

const ShowPrescriptionDetails: React.FC<PrescriptionDetailsProps> = ({
  selectedPrescription,
  setSelectedPrescription,
  setReload,
}) => {
  const [editing, setEditing] = useState<boolean>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleDelete = async () => {
    const [response, error] = await deletePrescription(
      selectedPrescription.publicId
    );
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Prescription" + DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload((prev) => !prev);
      setSelectedPrescription(null);
      setEditing(false);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || DELETE_FAILED + "prescription.",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const columns = [
    {
      label: "Medicine Name",
      key: "medicine",
    },
    {
      label: "Direction",
      key: "directions",
    },
    {
      label: "Duration",
      key: "duration",
    },
  ];
  useEffect(() => {}, [selectedPrescription]);

  return (
    <>
      <Box
        padding={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box>
          {/* <ActionEdit handleClick={handleEdit} /> */}
          <ActionDownload
            handleClick={() => createPrescriptionPDF(selectedPrescription)}
          />
          <ActionDelete handleClick={openConfirmModal} />
        </Box>
      </Box>
      <Box
        padding={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box>
          Date: {formatDate(selectedPrescription.whenCreated.toString())}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
        }}
      >
        <EditableBox
          label={"Created By"}
          currentValue={selectedPrescription.staff.signature}
          name={""}
        />
      </Box>
      <CustomTable
        data={selectedPrescription.prescriptionRows}
        columns={columns}
        searchable={false}
        defaultSort={{
          key: columns[0].key,
          direction: "desc",
        }}
        height={"50vh"}
      />

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete the prescription?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ShowPrescriptionDetails;
