export const paymentStatusStyle = {
  Paid: {
    color: "green",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Pending: {
    color: "#5f72ff",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Failed: {
    color: "red",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
};

export const testResultStatus = {
  "Results Available": {
    color: "green",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Pending: {
    color: "#5f72ff",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
};
