import { PLACEHOLDER_VALUE } from "../../constants/Constants";

export const TDLRequestDetails = [
  {
    label: "Clinical Details",
    type: "text",
    id: "clinicalDetails",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Sample Date-Time",
    type: "datetime-local",
    id: "sampleDateTime",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  // {
  //   label: "Account To",
  //   type: "text",
  //   id: "accountTo",
  //   default: "Doctor",
  //   boxSize: 6,
  //   placeholder: "Enter here",
  //   required: true,
  // },
  {
    label: "Insurance Number",
    type: "text",
    id: "insuranceNum",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Insurance Company",
    type: "text",
    id: "insuranceCo",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
];

export const TDLRequestDetailCheckbox = [
  {
    label: "",
    type: "checkbox",
    id: "isGrey",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "Have you sent a grey tube",
        value: "Have you sent a grey tube?",
      },
    ],
  },
  {
    label: "",
    type: "checkbox",
    id: "isFasting",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "Is the Patient Fasting",
        value: "Is the Patient Fasting?",
      },
    ],
  },

  {
    label: "",
    type: "checkbox",
    id: "needsVenous",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "VENOUS blood kit",
        value: "VENOUS blood kit",
      },
    ],
  },
  {
    label: "",
    type: "checkbox",
    id: "needsKit",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "needsKitKey",
        value: "Self collect collection kit",
      },
    ],
  },
];
