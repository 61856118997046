import { Card, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getAllPatients, searchPatient } from "../../apis/patients";
import { formatDate, formatDateStr } from "../../util/data.util";
import { NewPatientFormInputs } from "../../util/patient/patient.form.helper";
import SecondaryButton from "../buttons/SecondaryButton";
import { FormDialogBox } from "../dialogBoxes/FormDialogBox";
import { CustomTable } from "../table/Table";
import { useDispatch } from "react-redux";
import { removePartnerInformation } from "../../redux/slice/partnerInformation";
import { removePatientInformation } from "../../redux/slice/patientInformation";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import { removeFemalePatientInformation } from "../../redux/slice/femalePatientSlice";
import PrimaryButton from "../buttons/PrimaryButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DateRange from "../fromComponents/DateRange";
import { NewPatientForm } from "./NewPatientForm";

const PatientsTable: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState<any>();
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const columns = [
    { label: "ID", key: "publicId", sortable: true },
    {
      label: "Name of Patient",
      key: "fullName",
      sortable: true,
    },
    {
      label: "Date of Birth",
      key: "dateOfBirth",
    },
    {
      label: "Phone Number",
      key: "phoneNo",
    },
    { label: "Email", key: "email" },
  ];

  const handleRowClick = (row: any) => {
    navigate(`/patients/information/${row.publicId}`);
  };

  const handleSearch = async () => {
    if (search.length < 3) {
      setValidationError("Search must be at least 3 characters long");
      return;
    }
    dispatch(loading());
    await searchPatient(search)
      // await getAllPatients()
      .then((res) => {
        let data: any[] = [];
        res.data.forEach((row: any) => {
          data.push({
            publicId: `${row.publicId}`,
            fullName: `${row.firstName ? row.firstName : ""} ${
              row.lastName ? row.lastName : ""
            }`,
            email: `${row.email ? row.email : ""}`,
            dateOfBirth: row.dateOfBirth
              ? row.dateOfBirth
              : row.dateOfBirthStr
              ? row.dateOfBirthStr
              : "",
            phoneNo: `${row.phoneNo ? row.phoneNo : ""}`,
          });
        });
        setTableData(data);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(doneLoading());
        }, 200);
      });
  };

  useEffect(() => {
    dispatch(removePartnerInformation());
    dispatch(removePatientInformation());
    dispatch(removeFemalePatientInformation());
  }, []);

  return (
    <>
      <Card sx={{ boxShadow: 1, borderRadius: 4, m: 2 }}>
        <Grid sx={{ m: 2 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            marginBottom={3}
            flex={1}
            paddingRight={2}>
            <>
              <Grid item md={4} lg={3}>
                <TextField
                  fullWidth
                  placeholder={"Search by name or email"}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setValidationError(
                        "Search must be at least 3 characters long"
                      );
                    } else {
                      setValidationError("");
                    }
                  }}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  error={!!validationError}
                />
              </Grid>

              <Grid item>
                <PrimaryButton
                  onClick={handleSearch}
                  label={"Search"}
                  className={""}
                  type={"button"}
                />
              </Grid>
            </>

            <Grid item xs sx={{ display: "flex", justifyContent: "flex-end" }}>
              <SecondaryButton
                onClick={() => setIsNewPatient(!isNewPatient)}
                label={"Add Patient"}
                className={""}
                type={"button"}
                startIcon={<AddIcon />}
              />
            </Grid>
          </Grid>
          {tableData && (
            <CustomTable
              data={tableData}
              columns={columns}
              defaultSort={{ key: "publicId", direction: "asc" }}
              searchable={true}
              dateFormatter={formatDate}
              searchPlaceholder="Search in table..."
              onRowClick={handleRowClick}
              isAddPatient={true}
              height={"50vh"}
            />
          )}
          {isNewPatient && (
            <NewPatientForm
              isOpen={isNewPatient}
              setIsOpen={setIsNewPatient}
              header={"Add New Patient"}
              width={"xl"}
            />
          )}
        </Grid>
      </Card>
      {/* {tableData && (
        <Card sx={{ boxShadow: 1, borderRadius: 4, m: 2, p: 2 }}>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              textAlign: "center",
            }}>
            <Grid item md={12}>
              <DateRange />
            </Grid>
          </Grid>
        </Card>
      )} */}
    </>
  );
});

export default PatientsTable;
