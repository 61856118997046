import React from "react";
import { CustomTable } from "../table/Table";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
interface ArchivedEventsProps {
  data: EventLite[];
}
const ArchivedEvents: React.FC<ArchivedEventsProps> = ({ data }) => {
  let tableData: any[] = [];
  data.forEach((element: EventLite) => {
    tableData.push({
      patientName: ` ${element.patient.firstName} ${element.patient.lastName}`,
      serviceName: element.service.name,
      staffName: `${element.staff.title} ${element.staff.firstName} ${element.staff.lastName}`,
      currentStage: element.currentStage.name,
      assigneeTeam: element.assigneeTeam.name,
    });
  });
  const columns = [
    {
      label: "Patient Name",
      key: "patientName",
      sortable: true,
    },
    {
      label: "Service Name",
      key: "serviceName",
      sortable: true,
    },
    {
      label: "Staff Name",
      key: "staffName",
      sortable: true,
    },
    {
      label: "Assignee Team",
      key: "assigneeTeam",
    },
    {
      label: "Current Stage",
      key: "currentStage",
      sortable: true,
    },
  ];
  return (
    <div>
      <CustomTable
        data={tableData}
        columns={columns}
        // actions={actions}
        // onRowClick={handleRowClick}
        searchable={true}
        //   optionButtonText={tableButtonLabel}
        //   setOptionButton={setIsUploadDocuments}
        // optionIcon={<AddIcon />}
        //   dateFormatter={formatToDateTime}
        defaultSort={{
          key: columns[0].key,
          direction: "desc",
        }}
        height={"50vh"}
      />
    </div>
  );
};

export default ArchivedEvents;
