import Alerts from "../../components/alerts/Alerts";
import { Box, Grid } from "@mui/material";
import Calender from "../../components/calendar/Calendar";
import Notifications from "../../components/notifications/Notifications";
import Communications from "../../components/comunications/Comunications";

const Dashboard = () => {
  return (
    <Box sx={{ height: "100%" }} padding={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Calender />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Alerts />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Notifications />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Communications />
        </Grid>
        {/* <KanbanBoard /> */}
      </Grid>
    </Box>
  );
};

export default Dashboard;
