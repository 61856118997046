import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

const ActionEdit = React.memo<ActionClickIconProps>(({ handleClick }) => {
  return (
    <Tooltip title="Edit" placement="top">
      <EditIcon
        style={{ margin: 3, cursor: "pointer" }}
        color="primary"
        onClick={handleClick}
      />
    </Tooltip>
  );
});

export default ActionEdit;
