import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid, TextField, Button, MenuItem, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getWixBookings } from "../../apis/wix";
import SearchSites from "../search/SearchSites";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import SecondaryButton from "../buttons/SecondaryButton";
import EditableBox from "../adminCenterComponents/EditableBox";
import { StripedTable } from "../table/StripedTable";
import ShowQueryResponse from "./ShowQueryResponse";

interface QueryData {
  status: string;
  createdDate: string;
  sitePublicId?: string;
  offset?: number;
  // Add more fields as needed
}

const QueryComponent: React.FC = () => {
  const { control, handleSubmit } = useForm<QueryData>();
  const [defaultStatus] = useState<string>("CONFIRMED");
  const [defaultSiteId] = useState<string>();
  const defaultCreatedDate = dayjs().format("YYYY-MM-DD");
  const [selectedSite, setSelectedSite] = useState<MultiSelectOptionType>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const dispatch = useDispatch();
  const [response, setResponse] = useState<WixQueryResponse | null>(null);

  const onSubmit = async (data: QueryData) => {
    if (selectedSite) {
      setSelectedDate(dayjs(data.createdDate).format("YYYY-MM-DD"));
      const formattedDate =
        dayjs(data.createdDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      setResponse(null);
      const query = {
        query: {
          filter: {
            status: data.status,
            createdDate: {
              $gte: formattedDate,
              // $lte: "2023-05-31T00:00:00.000Z",
            },
          },
          paging: {
            limit: 500,
            offset: data.offset || 0,
          },
          sort: [
            {
              fieldName: "createdDate",
              order: "DESC",
            },
          ],
        },
        sitePublicId: selectedSite?.publicId || defaultSiteId,
      };
      dispatch(loading());
      const [response, error] = await getWixBookings(query);
      if (response) {
        console.log(response);
        setResponse(response);
        dispatch(doneLoading());
      } else {
        console.log(error);
        dispatch(doneLoading());
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" mt={3} mb={3}>
        Get Wix Bookings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name="status"
            defaultValue={defaultStatus}
            render={({ field }) => (
              <TextField {...field} label="Status" select fullWidth>
                <MenuItem value="CONFIRMED">CONFIRMED</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name="createdDate"
            defaultValue={defaultCreatedDate}
            render={({ field }) => (
              <TextField
                {...field}
                label="Created Date"
                type="date"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SearchSites
            className="col-sm-12"
            label="Select website"
            setSelectedSite={setSelectedSite}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name="offset"
            defaultValue={0}
            render={({ field }) => (
              <TextField {...field} label="Offset" select fullWidth>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={300}>300</MenuItem>
                <MenuItem value={400}>400</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={600}>600</MenuItem>
                {/* Add more options as needed */}
              </TextField>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained" color="primary">
            Get Data
          </Button>{" "}
        </Grid>

        {response && selectedDate && (
          <Grid item xs={12} sm={12} md={12}>
            <hr />
            <Typography variant="h6" mt={3} mb={3}>
              Response
            </Typography>
            <ShowQueryResponse
              response={response}
              selectedDate={selectedDate}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default QueryComponent;
