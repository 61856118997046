import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { useDispatch } from "react-redux";
import AddLocation from "./AddLocation";
import ShowLocationDetails from "./ShowLocationDetails";
import { getAllLocations } from "../../apis/location";
import ComponentLoading from "../loading/ComponentLoading";
const ShowLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );

  const dispatch = useDispatch();
  const [isAddLocation, setIsAddLocation] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getLocations = async () => {
    const [response, error] = await getAllLocations();
    if (response) {
      console.log(response);

      setLocations(
        response.map((location: Location) => {
          return {
            siteName: location.site.name,
            headStaff: `${location.site.ownerStaff.title} ${location.site.ownerStaff.firstName} ${location.site.ownerStaff.lastName}`,
            ...location,
            fullAddress: `${location.address.street1}, ${location.address.city}, ${location.address.postalCode}`,
          };
        })
      );
      // setLocations([
      //   {
      //     ...response,
      //   },
      // ]);
      // setSelectedLocation(locations[0]);
      setIsLoading(false);
    } else {
      console.log(error);
    }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Address",
      key: "fullAddress",
    },
    {
      label: "Site",
      key: "siteName",
      sortable: true,
    },
    {
      label: "Head Staff",
      key: "headStaff",
    },
  ];

  const handleRowClick = (row: any) => {
    console.log(row);
    // row = combineArrays(
    //     patientAndPartnerFormInputs,
    //     getForm(row.type) || [],
    //     row.reportFields
    //   );
    setIsAddLocation(false);
    setSelectedLocation(row);
  };
  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getLocations();
  }, [reload]);
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {locations && (
            <CustomTable
              data={locations}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              optionButtonText="Add Location"
              setOptionButton={setIsAddLocation}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "desc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}

      {(isAddLocation || selectedLocation) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}>
          {isAddLocation ? (
            <AddLocation
              setIsAddLocation={setIsAddLocation}
              setReload={setReload}
              reload={reload}
            />
          ) : (
            selectedLocation && (
              <ShowLocationDetails
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setReload={setReload}
                reload={reload}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowLocations;
