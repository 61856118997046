import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { createStaff } from "../../apis/users";
import { createStaffForm } from "../../util/superAdmin/createForm.util";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import PrimaryButton from "../buttons/PrimaryButton";
import TernaryButton from "../buttons/TernaryButton";
import SearchSites from "../search/SearchSites";
import MultiSelectStaff from "../search/MultiSelectStaff";
import TextInputFull from "../fromComponents/TextInputFull";
import {
  STAFF_CREATE_FAILED,
  STAFF_CREATE_SUCCESS,
} from "../../constants/SnackbarMessages";

interface AddStaffProps {
  setIsAddStaff: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddStaff: React.FC<AddStaffProps> = ({ setIsAddStaff, setReload }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm<CreateStaff>();
  const [selectedSite, setSelectedSite] = useState<SelectedSite>();
  const [selectedStaff, setSelectedStaff] = useState<MultiSelectOptionType[]>(
    []
  );

  const onSubmit = async (data: CreateStaff) => {
    const options = selectedStaff?.map((value) => value.publicId) || [];
    if (options.length > 0) data.secretaryPublicIds = options;
    if (selectedSite?.publicId) data.siteExternalId = selectedSite?.publicId;
    console.log(data);
    const [response, error] = await createStaff(data);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: STAFF_CREATE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      reset();
      setReload((prev) => !prev);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: STAFF_CREATE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add New Staff
      </Typography>
      <Box sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            {createStaffForm.map((item: InputFieldType, index: number) => (
              <Grid item xs={4}>
                <TextInputFull
                  keyName={item.id}
                  register={register}
                  placeholder={item.placeholder}
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  required={item.required}
                  tag={item.tag}
                  options={item.options}
                />{" "}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={12}>
              <SearchSites
                className="col-sm-12"
                label="Select Staff Site"
                setSelectedSite={setSelectedSite}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MultiSelectStaff
                label="Select related staff"
                id="secretaryPublicIds"
                setValues={setSelectedStaff}
                values={selectedStaff}
                className="col-sm-12"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <TernaryButton
                  onClick={() => {
                    setIsAddStaff(false);
                  }}
                  label="Cancel"
                  className="m-2"
                />
              </Grid>
              <Grid item>
                <PrimaryButton label="Add" type="submit" className="m-2" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddStaff;
