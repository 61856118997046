import { AZURE_URL } from "../constants/env-constants";

export const createDocument = async (data: CreateDocumentRequest) => {
  try {
    const response = await AZURE_URL.post("/document/create", data);

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response || "Something went wrong"];
    }
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getDocuments = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/document/get?patientPublicId=${patientId}`
    );

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response || "Something went wrong"];
    }
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getDocumentsByType = async (type: string) => {
  try {
    const response = await AZURE_URL.get(`/document/get?type=${type}`);

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response || "Something went wrong"];
    }
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const deleteDocumentById = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/document/delete?publicId=${id}`);

    if (response.status === 200) {
      return ["Success", null];
    } else {
      return [null, response || "Something went wrong"];
    }
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};
