import React from "react";
import { CircularProgress } from "@mui/material";
import { SyncLoader } from "react-spinners";
import {
  BRAND_COLOR_DARK,
  BRAND_COLOR_LIGHT,
} from "../../constants/BrandConstants";

interface LoadingProps {
  size?: number;
  thickness?: number;
}

const Loading: React.FC<LoadingProps> = ({ size = 20, thickness = 4 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
      }}>
      <SyncLoader color={BRAND_COLOR_LIGHT} size={size} margin={5} />
    </div>
  );
};

export default Loading;
