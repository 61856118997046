import { AZURE_URL } from "../constants/env-constants";
export const createNewNote = async (note: CreateNote) => {
  try {
    const response = await AZURE_URL.post("/note/create", note);

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getPatientNotes = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/note/get?patientPublicId=${patientId}`
    );

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
