import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Stages from "./Stages";
import PrimaryButton from "../buttons/PrimaryButton";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { objectToArray } from "../../util/data.util";
import TernaryButton from "../buttons/TernaryButton";
import { createPathway } from "../../apis/pathway";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import {
  PATHWAY_CREATE_FAILED,
  PATHWAY_CREATE_SUCCESS,
} from "../../constants/SnackbarMessages";

interface AddPathwayProps {
  setIsAddPathway: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}
const AddPathway: React.FC<AddPathwayProps> = ({
  setIsAddPathway,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [pathwayName, setPathwayName] = useState<string>();
  // const [stages, setStages] = useState<AddStage[] | null>(null);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const stages = objectToArray(data.pathwayStages).map((item: any, index) => {
      return {
        name: item.name,
        stageIndex: index,
        defaultAssigneePublicId: item.defaultAssigneePublicId,
        defaultAssigneeTeamPublicId: item.defaultAssigneeTeamPublicId,
        finalStage: item.finalStage || false,
      };
    });
    data.pathwayStages = stages;
    console.log(data);
    if (data.pathwayStages.length) {
      const [response, error] = await createPathway(
        data as CreatePathwayRequest
      );
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: PATHWAY_CREATE_SUCCESS,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setIsAddPathway(false);
        setReload(!reload);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: error || PATHWAY_CREATE_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  // useEffect(() => {
  //   console.log(pathwayName);
  // }, [pathwayName]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container flex={1} spacing={2} mt={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Pathway Name"
            className="col-sm-4"
            id="followup-comments"
            placeholder="Give pathway name"
            required
            {...register("name", { required: true })}
            onChange={(e) => setPathwayName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Stages
            register={register}
            setValue={setValue}
            unregister={unregister}
            // setStages={setStages}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <TernaryButton
              onClick={() => {
                setIsAddPathway(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Save"} type={"submit"} className="m-2" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPathway;
