import React from "react";
import PatientsTable from "../../components/patientsModule/PatientsTable";

const Patients = () => {
  return (
    <div>
      <PatientsTable />
    </div>
  );
};

export default Patients;
