import { useState } from "react";
import { downloadFileByUrl } from "../apis/files.api";

type DownloadFileResponse = [object, any | null];
interface JsonFileUploaderHook {
  createJsonFile: (jsonData: object) => Blob;
  extractJson: (url: string) => Promise<[object | null, any | null]>;
  error: string | null;
}

function useCreateJsonFile(): JsonFileUploaderHook {
  const [error, setError] = useState<string | null>(null);

  const createJsonFile = (jsonData: object): Blob => {
    try {
      // Create a Blob object from the JSON data
      const blob = new Blob([JSON.stringify(jsonData)], {
        type: "application/json",
      });
      setError(null); // Clear any previous errors
      return blob;
    } catch (error) {
      setError("Error creating the JSON file.");
      return new Blob(); // Return an empty Blob in case of an error
    }
  };
  const extractJson = async (
    url: string
  ): Promise<[object | null, any | null]> => {
    try {
      // Create a URL for the Blob
      const [blob, error] = await downloadFileByUrl({ url });
      if (blob) {
        const blobUrl = URL.createObjectURL(blob);

        // Fetch the Blob URL and parse it as JSON
        const response = await fetch(blobUrl);
        const jsonData = await response.json();

        // Clean up the Blob URL
        URL.revokeObjectURL(blobUrl);

        return [jsonData, null];
      } else {
        return [null, error];
      }
    } catch (error) {
      setError("Error downloading and extracting JSON data.");
      return [null, error];
    }
  };

  return { createJsonFile, extractJson, error };
}

export default useCreateJsonFile;
