import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import TernaryButton from "../buttons/TernaryButton";
import SelectStaff from "../select/SelectStaff";
import SelectTeam from "../select/SelectTeam";
interface EditableStageFormProps {
  stages: PathwayStages[];
  setNewStages: React.Dispatch<React.SetStateAction<PathwayStages[]>>;
  setIsFormComplete: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditableStageForm: React.FC<EditableStageFormProps> = ({
  stages,
  setNewStages,
  setIsFormComplete,
}) => {
  const [allStages, setAllStages] = useState<PathwayStages[]>(stages);
  const [formCount, setFormCount] = useState(stages.length);
  const checkFormCompletion = () => {
    const isComplete = allStages.every((stage) => {
      if (!stage.maxWaitTimeDays) {
        stage.maxWaitTimeDays = 5;
      }
      return (
        stage.name !== "" &&
        (stage.defaultAssignee ||
          (stage.defaultAssigneePublicId !== "" &&
            stage.defaultAssigneePublicId !== undefined)) &&
        (stage.defaultAssigneeTeam ||
          (stage.defaultAssigneeTeamPublicId !== "" &&
            stage.defaultAssigneeTeamPublicId !== undefined))
      );
    });
    return isComplete;
  };
  const addForm = () => {
    setFormCount(formCount + 1);
    setAllStages((prevStages) => [
      ...prevStages,
      {
        name: "",
        stageIndex: formCount,
        maxWaitTimeDays: 5,
        finalStage: false,
      },
    ]);
  };
  const removeForm = (index: any) => {
    setFormCount((prevCount) => prevCount - 1);
    const updatedStages = [...allStages];
    updatedStages.pop();
    setAllStages(updatedStages);
  };
  const handleFieldChange = <T extends keyof PathwayStages>(
    index: number,
    value: string | boolean | number,
    field: T
  ) => {
    const updatedStages = [...allStages];

    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      updatedStages[index][field] = value as PathwayStages[T];
    }

    setAllStages(updatedStages);
  };

  const setAssigneeStaff = (index: string, value: string) => {
    const updatedStages = [...allStages];
    updatedStages[JSON.parse(index)].defaultAssigneePublicId = value;
    setAllStages(updatedStages);
  };
  const setAssigneeTeam = (index: string, value: string) => {
    const updatedStages = [...allStages];
    updatedStages[JSON.parse(index)].defaultAssigneeTeamPublicId = value;
    setAllStages(updatedStages);
  };

  useEffect(() => {
    const isComplete = checkFormCompletion();
    setNewStages(allStages);
    setIsFormComplete(isComplete);
  }, [allStages, setNewStages, setIsFormComplete]);

  return (
    <Box>
      <Grid container spacing={2}>
        {[...Array(formCount)].map((form, index) => {
          return (
            <Grid
              className="card"
              container
              key={index}
              xs={12}
              sm={12}
              md={12}
              p={3}
              m={2}
              spacing={2}
            >
              <Grid item key={index + "name"} xs={12} sm={6} md={4}>
                <TextField
                  key={`name-${index}`}
                  className="col-sm-12"
                  onChange={(e) =>
                    handleFieldChange(index, e.target.value, "name")
                  }
                  placeholder="Stage name"
                  defaultValue={allStages?.[index]?.name || ""}
                  id={`pathwayStages.${index}.name`}
                  label="Stage Name"
                  type="text"
                  required
                />
              </Grid>
              <Grid item key={index + "staff"} xs={12} sm={6} md={4}>
                {stages && (
                  <SelectStaff
                    id={JSON.stringify(index)}
                    className="col-sm-12"
                    setValue={setAssigneeStaff}
                    label="Default Assignee"
                    defaultValue={allStages?.[index]?.defaultAssignee}
                  />
                )}
              </Grid>
              <Grid item key={index + "team"} xs={12} sm={6} md={4}>
                {stages && (
                  <SelectTeam
                    id={JSON.stringify(index)}
                    className="col-sm-12"
                    setValue={setAssigneeTeam}
                    label="Default Assignee Team"
                    defaultValue={allStages?.[index]?.defaultAssigneeTeam}
                  />
                )}
              </Grid>
              <Grid item key={index + "maxWaitTimeDays"} xs={12} sm={6} md={4}>
                <TextField
                  key={`name-${index}`}
                  className="col-sm-12"
                  placeholder="Max Wait Time In Days"
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      JSON.parse(e.target.value),
                      "maxWaitTimeDays"
                    )
                  }
                  defaultValue={allStages?.[index]?.maxWaitTimeDays || 5}
                  id={`pathwayStages.${index}.maxWaitTimeDays`}
                  label="Max Wait Time In Days"
                  type="number"
                  required
                />
              </Grid>
              <Grid item key={index + "finalStage"} xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      //   onChange={(e) => handleChange(e, index)}
                      onChange={(e) =>
                        handleFieldChange(index, e.target.checked, "finalStage")
                      }
                      defaultChecked={allStages[index].finalStage}
                    />
                  }
                  label="Final Stage"
                />
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            {formCount > 1 && (
              <SecondaryButton
                className={"m-2"}
                type={"button"}
                label={"Remove"}
                onClick={() => removeForm(formCount - 1)}
              />
            )}
            <SecondaryButton
              className={"m-2"}
              type={"button"}
              label={"Add"}
              onClick={addForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditableStageForm;
