import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Tests from "../test/Tests";
import PreTreatmentPlan from "../preTreatment/PreTreatmentPlan";
import Treatments from "../treatment/Treatments";

const ManagementPlan: React.FC<ManagementPlanProps> = ({
  tests,
  setTests,
  preTreatmentPlan,
  setPreTreatmentPlan,
  treatments,
  setTreatments,
}) => {
  return (
    <Box>
      <Tests tests={tests} setTests={setTests} />
      <PreTreatmentPlan
        preTreatmentPlan={preTreatmentPlan}
        setPreTreatmentPlan={setPreTreatmentPlan}
      />
      <Treatments treatments={treatments} setTreatments={setTreatments} />
    </Box>
  );
};

export default ManagementPlan;
