import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Tooltip } from "@mui/material";

interface ActionCloseProps {
  handleClick: () => void;
}

const ActionClose: React.FC<ActionCloseProps> = ({ handleClick }) => {
  return (
    <Tooltip title="Close" placement="top">
      <CloseOutlinedIcon
        style={{ margin: 3, cursor: "pointer" }}
        className="icon-hover"
        onClick={handleClick}
        color="error"
      />
    </Tooltip>
  );
};

export default ActionClose;
