import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Tooltip } from "@mui/material";

const ActionBack: React.FC<ActionClickIconProps> = ({ handleClick }) => {
  return (
    <Tooltip title="Back" placement="top">
      <ArrowBackOutlinedIcon
        style={{ margin: 3, cursor: "pointer" }}
        className="icon-hover"
        onClick={handleClick}
        color="primary"
      />
    </Tooltip>
  );
};

export default ActionBack;
