import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import {
  Alert,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import SecondaryButton from "../buttons/SecondaryButton";

export const CustomTable = ({
  data,
  columns,
  defaultSort,
  searchable = true,
  searchPlaceholder = "Search...",
  onRowClick,
  isAddPatient = true,
  optionButtonText,
  setOptionButton,
  optionIcon,
  actions,
  dateFormatter,
  height = "50vh",
  overflow,
  tableHeight,
  disableRowSelectorKey,
  disableRowSelectorValue,
}: TableProps) => {
  const [sort, setSort] = useState<{
    key: keyof IData;
    direction: "asc" | "desc";
  }>(defaultSort);
  const [search, setSearch] = useState<string>("");
  const handleSort = (key: keyof IData) => {
    setSort({
      key,
      direction: sort.key === key && sort.direction === "asc" ? "desc" : "asc",
    });
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter((item) => {
    return Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  const sortedData = filteredData.sort((a, b) => {
    if (a[sort.key] < b[sort.key]) {
      return sort.direction === "asc" ? -1 : 1;
    }
    if (a[sort.key] > b[sort.key]) {
      return sort.direction === "asc" ? 1 : -1;
    }

    return 0;
  });

  return (
    <>
      <Grid
        container
        md={12}
        justifyContent="space-between"
        justifyItems="center"
        alignItems="center"
      >
        <Typography variant="body1">
          {data.length > 0 && searchable && (
            <TextField
              label={searchPlaceholder}
              variant="outlined"
              margin="normal"
              size="small"
              name="search"
              id="search"
              value={search}
              onChange={handleSearch}
            />
          )}
        </Typography>
        {optionButtonText && setOptionButton && (
          <Typography variant="body1">
            {/* <b>Total records </b> {sortedData.length} */}
            <SecondaryButton
              label={optionButtonText}
              className={"ms-2"}
              startIcon={optionIcon}
              type={"button"}
              onClick={() => {
                setOptionButton((prev) => !prev);
              }}
            />
          </Typography>
        )}
      </Grid>

      {sortedData.length > 0 ? (
        <Grid style={{ overflow: overflow, height: tableHeight }}>
          <Table striped bordered hover>
            <TableHead className="t-head">
              <TableRow>
                {/* <TableCell>SR. NO.</TableCell> */}
                {columns.map((column) => (
                  <TableCell
                    key={column.key as string}
                    onClick={() => column.sortable && handleSort(column.key)}
                    className={column.sortable ? "sortable" : ""}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={sort.key === column.key}
                        direction={sort.direction}
                      >
                        <Typography variant="body1">{column.label}</Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography variant="body1">{column.label}</Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow
                  key={row.publicId}
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                  className={onRowClick ? "clickable" : ""}
                  style={
                    disableRowSelectorKey &&
                    disableRowSelectorValue &&
                    disableRowSelectorValue.includes(row[disableRowSelectorKey])
                      ? { pointerEvents: "none", backgroundColor: "" }
                      : { cursor: "pointer" }
                  }
                >
                  {columns.map((column) => {
                    if (column.key === "actions") {
                      return (
                        <TableCell key={column.key as string}>
                          <Grid container spacing={2}>
                            {actions?.map((action, index) => {
                              // Check if the action has the `disableOn` property and its value is in the `disableRowSelectorValue`
                              const isDisabled =
                                action.disableSelectorKey &&
                                action.disableOn?.includes(
                                  row[action.disableSelectorKey]
                                );

                              return (
                                <Grid item key={index}>
                                  <Tooltip
                                    title={action.tooltipLabel ?? ""}
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() =>
                                        !isDisabled && action.onClick(row)
                                      } // Execute onClick only if not disabled
                                      style={{
                                        cursor: isDisabled
                                          ? "not-allowed"
                                          : "pointer",
                                        color: isDisabled ? "" : "#1976d2",
                                      }}
                                    >
                                      {action.icon}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </TableCell>
                      );
                    } else if (
                      (column.key.toString().includes("when") ||
                        column.key.toString().includes("created") ||
                        column.key.toString().includes("appointmentTime") ||
                        column.key.toString().includes("Date") ||
                        column.key.toString().includes("dateOfBirth")) &&
                      new Date(row[column.key]).toString() !== "Invalid Date"
                    ) {
                      return (
                        <TableCell key={column.key as string}>
                          {dateFormatter(row[column.key]).toUpperCase()}
                        </TableCell>
                      );
                    } else if (column.style) {
                      const cellStyle = column.style[row[column.key] as string];

                      return (
                        <TableCell key={column.key as string} style={cellStyle}>
                          {row[column.key]}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={column.key as string}>
                          {column.formatter
                            ? column.formatter(row[column.key])
                            : row[column.key]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>{" "}
        </Grid>
      ) : (
        <Grid
          container
          md={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: height }}
        >
          <Alert severity="error">No records found</Alert>
        </Grid>
      )}
    </>
  );
};
