import { AZURE_URL } from "../constants/env-constants";

export const addFirstConsultation = async (
  firstConsultationData: FirstConsultationPostRequest
) => {
  try {
    const response = await AZURE_URL.post(
      "/consultation/first/create",
      firstConsultationData
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getFirstConsultations = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/consultation/first/get?patientPublicId=${patientId}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const addFollowUpConsultation = async (
  followUpData: FollowUpPostRequest
) => {
  try {
    const response = await AZURE_URL.post(
      "/consultation/followUp/create",
      followUpData
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const getFollowUpConsultations = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/consultation/followUp/get?patientPublicId=${patientId}`
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};
