import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import FileUpload from "../files/FileUpload";
import { useSelector } from "react-redux";
import { DOCUMENTS_CONTAINER } from "../../constants/ContainerConstants";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  FILE_UPLOADED_SUCCESSFULLY,
  FILE_UPLOAD_FAILED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";
import { createDocument } from "../../apis/documents";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import DocumentScanner from "../files/ScanDocument";
import SecondaryButton from "../buttons/SecondaryButton";

interface FileObject {
  file: File;
  preview: string;
}
interface AddAdditionalDocumentsProps {
  setIsUploadDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  getAllDocuments: React.Dispatch<React.SetStateAction<void>>;
  type: string;
}

const AdditionalDocuments: React.FC<AddAdditionalDocumentsProps> = ({
  setIsUploadDocuments,
  getAllDocuments,
  type,
}) => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { publicId } = patientObject.patient;
  const [files, setFiles] = useState<FileObject[]>([]);
  const dispatch = useDispatch();
  const [documentUrls, setDocumentUrls] = useState<
    {
      name: string;
      url: string;
    }[]
  >([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const newFiles: FileObject[] = Array.from(fileList).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setFiles([...files, ...newFiles]);
      console.log(files);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const addDocumentsToPatient = async () => {
    for (const document of documentUrls) {
      let upload = {
        name: document.name,
        type: type,
        url: document.url,
        patientPublicId: publicId || "",
      };
      dispatch(loading());
      const [response, error] = await createDocument(upload);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: FILE_UPLOADED_SUCCESSFULLY + upload.name,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setFiles([]);
        getAllDocuments();
        setIsUploadDocuments(false);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: FILE_UPLOAD_FAILED + upload.name,
            variant: SNACKBAR_ERROR,
          })
        );
      }
      dispatch(doneLoading());
    }
  };

  useEffect(() => {
    if (documentUrls.length > 0) {
      addDocumentsToPatient();
    }
  }, [documentUrls]);

  const renderFileList = () => {
    if (files.length === 0) {
      return (
        <Typography variant="body1" color="text.secondary">
          No files selected
        </Typography>
      );
    } else {
      return files.map((file, index) => (
        <Grid container item key={index} alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <img src={file.preview} alt={file.file.name} width="50" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{file.file.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{file.file.size} bytes</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" onClick={() => handleRemoveFile(index)}>
              Remove
            </Button>
          </Grid>
        </Grid>
      ));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <FileUpload
          setFileUrls={setDocumentUrls}
          containerName={type}
          setIsUploadDocuments={setIsUploadDocuments}
          allowScanDocument={true}
        />
      </Grid>
      {/* <DocumentScanner /> */}
    </Grid>
  );
};

export default AdditionalDocuments;
