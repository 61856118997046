import React, { useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { getAllTeams } from "../../apis/team";

interface SearchTeamProps {
  setSelectedTeam?: any;
  className?: string;
  label?: string;
  id?: string;
  setValue?: any;
}

const TeamSearch = React.memo<SearchTeamProps>(
  ({ setSelectedTeam, className, label, id, setValue }) => {
    const [searchTeams, setSearchTeams] = useState<{}[]>([]);
    const getTeams = async () => {
      const [response, error] = await getAllTeams();
      if (response) {
        const results = response.map((element: Team) => ({
          publicId: element.publicId,
          label: element.name,
        }));
        setSearchTeams(results);
      } else {
        console.log(error);
      }
    };
    const handleTeamSelected = (event: React.SyntheticEvent, option: any) => {
      if (setSelectedTeam) {
        setSelectedTeam(option);
      }
      if (option && id) {
        setValue(id, option.publicId);
      }
    };

    useEffect(() => {
      getTeams();
    }, []);

    return (
      <Autocomplete
        freeSolo
        className={className ? className : "col-sm-10 p-0"}
        id="staffSearch"
        options={searchTeams}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "Select Team..."}
            required
          />
        )}
        onChange={handleTeamSelected}
      />
    );
  }
);

export default TeamSearch;
