import { AZURE_URL } from "../constants/env-constants";

export const getAllSites = async () => {
  try {
    const response = await AZURE_URL.get("/site/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const createNewSite = async (data: CreateSiteRequest) => {
  try {
    const response = await AZURE_URL.post("/site/create", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const updateSite = async (data: UpdateSiteRequest) => {
  try {
    const response = await AZURE_URL.put("/site/update", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSite = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/site/delete?publicId=${id}`);
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};
