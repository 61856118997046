import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import SearchSites from "../search/SearchSites";
import { deleteLocation, updateLocation } from "../../apis/location";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import {
  LOCATION_DELETE_FAILED,
  LOCATION_DELETE_SUCCESS,
  LOCATION_UPDATE_FAILED,
  LOCATION_UPDATE_SUCCESS,
} from "../../constants/SnackbarMessages";

const ShowLocationDetails: React.FC<LocationDetailsProps> = ({
  selectedLocation,
  setReload,
  setSelectedLocation,
  reload,
}) => {
  const [editing, setEditing] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [ownerStaff, setOwnerStaff] = useState<StaffInformation>(
    selectedLocation.site.ownerStaff
  );
  const dispatch = useDispatch();
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [selectedSite, setSelectedSite] = useState<SelectedSite>();
  const handleEdit = () => {
    setEditing(true);
    setNewValues({
      name: selectedLocation.name,
      address: selectedLocation.address,
      email: selectedLocation.email,
      phoneNo: selectedLocation.phoneNo,
      externalId: selectedLocation.externalId,
    });
  };

  const handleCancel = () => {
    setOwnerStaff(selectedLocation.site.ownerStaff);
    setEditing(false);
  };

  const handleSave = async () => {
    console.log(newValues);

    const updatedLocationReq: UpdateLocationRequest = {
      sitePublicId: selectedSite?.publicId
        ? selectedSite?.publicId
        : selectedLocation.site.publicId,
      publicId: selectedLocation.publicId,
      name: newValues.name || selectedLocation.name,
      externalId: newValues.externalId || selectedLocation.externalId,
      address: {
        street1: newValues.address.street1 || selectedLocation.address.street1,
        city: newValues.address.city || selectedLocation.address.city,
        postalCode:
          newValues.address.postalCode || selectedLocation.address.postalCode,
        country: newValues.address.country || selectedLocation.address.country,
      },
      email: newValues.email || selectedLocation.email,
      phoneNo: newValues.phoneNo || selectedLocation.phoneNo,
    };
    console.log(updatedLocationReq);
    const [response, error] = await updateLocation(updatedLocationReq);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: LOCATION_UPDATE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload(!reload);
      setSelectedLocation(null);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || LOCATION_UPDATE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
    setEditing(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleDelete = async () => {
    const [response, error] = await deleteLocation(selectedLocation.publicId);

    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: LOCATION_DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || LOCATION_DELETE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
    setSelectedLocation(null);
    setEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // If the field being updated is part of the address object, update the nested state
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setNewValues((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value,
        },
      }));
    } else {
      // Otherwise, update the top-level state
      setNewValues((prev) => ({ ...prev, [name]: value }));
    }
  };
  return (
    <>
      <Box
        padding={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        {editing ? (
          <TextField
            fullWidth
            label="Name"
            className="col-md-10 p-2"
            variant="outlined"
            required
            name="name"
            placeholder="Name"
            value={newValues?.name}
            onChange={handleChange}
          />
        ) : (
          <Typography
            variant="h4"
            className="text-capitalize"
            onDoubleClick={handleEdit}>
            {selectedLocation.name}
          </Typography>
        )}

        {!editing && (
          <Box>
            <ActionEdit handleClick={handleEdit} />
            <ActionDelete handleClick={openConfirmModal} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 3,
        }}>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Wix site Id</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            {editing ? (
              <TextField
                fullWidth
                label="Wix Site Id"
                className="col-md-10 p-2"
                variant="outlined"
                name="externalId"
                placeholder="Wix Site Id"
                value={newValues?.externalId}
                onChange={handleChange}
              />
            ) : (
              <Typography
                variant="body1"
                className="p-2"
                onDoubleClick={handleEdit}>
                {selectedLocation.externalId || ""}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Email</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            {editing ? (
              <TextField
                fullWidth
                label="Email"
                className="col-md-10 p-2"
                variant="outlined"
                required
                name="email"
                placeholder="Email"
                value={newValues?.email}
                onChange={handleChange}
              />
            ) : (
              <Typography
                variant="body1"
                className="p-2"
                onDoubleClick={handleEdit}>
                {selectedLocation.email || ""}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Phone number</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            {editing ? (
              <TextField
                fullWidth
                label="Phone Number"
                className="col-md-10 p-2"
                variant="outlined"
                required
                name="phoneNo"
                placeholder="Wix Site Id"
                value={newValues?.phoneNo}
                onChange={handleChange}
              />
            ) : (
              <Typography
                variant="body1"
                className="p-2"
                onDoubleClick={handleEdit}>
                {selectedLocation.phoneNo || ""}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Address</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            {editing ? (
              <Box sx={{ mb: 2 }}>
                <TextField
                  className="col-md-6 p-2"
                  name="address.street1"
                  label="Street Address"
                  placeholder="Street Address"
                  variant="outlined"
                  value={newValues?.address?.street1}
                  onChange={handleChange}
                />
                <TextField
                  className="col-md-6 p-2"
                  name="address.city"
                  label="City"
                  placeholder="City"
                  variant="outlined"
                  value={newValues?.address?.city}
                  onChange={handleChange}
                />
                <TextField
                  className="col-md-6 p-2"
                  name="address.country"
                  variant="outlined"
                  label="Country"
                  placeholder="Country"
                  value={newValues?.address?.country}
                  onChange={handleChange}
                />
                <TextField
                  className="col-md-6 p-2"
                  label="Postal Code"
                  placeholder="Postal Code"
                  name="address.postalCode"
                  variant="outlined"
                  value={newValues?.address?.postalCode}
                  onChange={handleChange}
                />
              </Box>
            ) : (
              <Typography
                variant="body1"
                className="p-2"
                onDoubleClick={handleEdit}>
                {selectedLocation.address.street1},{" "}
                {selectedLocation.address.city}{" "}
                {selectedLocation.address.country},{" "}
                {selectedLocation.address.postalCode}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Site Name</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            {editing ? (
              <SearchSites
                setSelectedSite={setSelectedSite}
                className="col-md-12 p-2"
              />
            ) : (
              <Typography
                variant="body1"
                className="p-2"
                onDoubleClick={handleEdit}>
                {selectedLocation.site.name}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2, width: "100%", display: "flex" }}>
          <Box sx={{ width: "30%" }}>
            <Typography variant="subtitle1">
              <b>Site Admin</b>
            </Typography>
          </Box>
          <Box sx={{ width: "70%" }}>
            <Typography
              variant="body1"
              className="p-2">{`${ownerStaff.title} ${ownerStaff.firstName} ${ownerStaff.lastName}`}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing ? (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}>
              Cancel
            </Button>{" "}
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete location '${selectedLocation?.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ShowLocationDetails;
