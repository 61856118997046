import {
  Tldraw,
  createTLStore,
  defaultShapeUtils,
  throttle,
} from "@tldraw/tldraw";
import "@tldraw/tldraw/tldraw.css";
import { useLayoutEffect, useState } from "react";

interface NotepadProps {
  persistenceKey: string;
}
const Notepad: React.FC<NotepadProps> = ({ persistenceKey }) => {
  const [store] = useState(() =>
    createTLStore({ shapeUtils: defaultShapeUtils })
  );
  const [loadingState, setLoadingState] = useState<
    | { status: "loading" }
    | { status: "ready" }
    | { status: "error"; error: string }
  >({
    status: "loading",
  });

  useLayoutEffect(() => {
    setLoadingState({ status: "loading" });

    const persistedSnapshot = localStorage.getItem(persistenceKey);

    if (persistedSnapshot) {
      try {
        const snapshot = JSON.parse(persistedSnapshot);
        store.loadSnapshot(snapshot);
        setLoadingState({ status: "ready" });
      } catch (error: any) {
        setLoadingState({ status: "error", error: error.message }); // Something went wrong
      }
    } else {
      setLoadingState({ status: "ready" }); // Nothing persisted, continue with the empty store
    }

    // Each time the store changes, run the (debounced) persist function
    const cleanupFn = store.listen(
      throttle(() => {
        const snapshot = store.getSnapshot();
        localStorage.setItem(persistenceKey, JSON.stringify(snapshot));
      }, 500)
    );

    return () => {
      cleanupFn();
    };
  }, [store]);

  if (loadingState.status === "loading") {
    return (
      <div className="tldraw__editor">
        <h2>Loading...</h2>
      </div>
    );
  }

  if (loadingState.status === "error") {
    return (
      <div className="tldraw__editor">
        <h2>Error!</h2>
        <p>{loadingState.error}</p>
      </div>
    );
  }

  function handleEvent(name: any, data: any) {
    console.log(name, data);
  }

  // Select the element with the class name "tlui-help-menu"
  const helpMenuElement = document.querySelector(
    ".tlui-help-menu"
  ) as HTMLElement;

  // Select elements with the class name "tlui-button tlui-button__button"
  const buttonElements = document.querySelector(
    ".tlui-debug-panel"
  ) as HTMLElement;

  // // Check if the help menu element exists before attempting to hide it
  if (helpMenuElement) {
    helpMenuElement.style.display = "none"; // Hide the help menu element
  }

  if (buttonElements) {
    buttonElements.style.display = "none"; // Hide the help menu element
  }

  return <Tldraw store={store} onUiEvent={handleEvent} />;
};

export default Notepad;

const data = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:2PcVW5SiUALSaOA3S30TJ": {
      meta: {},
      id: "page:2PcVW5SiUALSaOA3S30TJ",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },
    "shape:CqR0dpk4MBmp7NWRhk6eB": {
      x: -6612.031334411813,
      y: -5857.084348434325,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:CqR0dpk4MBmp7NWRhk6eB",
      type: "frame",
      props: {
        w: 7849.999999999999,
        h: 11339.999999999998,
        name: "Consultation Notes",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a1",
      typeName: "shape",
    },
    "shape:IB9FWcRYBS1epvb2eBZG0": {
      x: 6958.304366748401,
      y: 6184.568738250322,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:IB9FWcRYBS1epvb2eBZG0",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 16,
        text: "",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 1,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aC",
      typeName: "shape",
    },
    "shape:uSvauEx8Cext-pSv36rrF": {
      x: 6635.286231134386,
      y: 5857.084348434325,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:uSvauEx8Cext-pSv36rrF",
      type: "geo",
      props: {
        w: 7600.000000000001,
        h: 10700.000000000002,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "xl",
        font: "sans",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a1",
      typeName: "shape",
    },
    "shape:ePXmx_E5Ea6-bJhRSkI1v": {
      x: 6634.96580879694,
      y: 6514.124841161348,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:ePXmx_E5Ea6-bJhRSkI1v",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a2",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: 6.433129539650508,
            y: -3.7559631514468492,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7607.298765353897,
            y: -30.037146859924178,
          },
        },
      },
      typeName: "shape",
    },
    "shape:gACCIidfRGs4fvnsP2iVL": {
      x: 8649.045123907406,
      y: 7405.31593331208,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:gACCIidfRGs4fvnsP2iVL",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a4",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -2037.0137894955938,
            y: 41.02495382267989,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 5583.8806742459665,
            y: 98.40363235153382,
          },
        },
      },
      typeName: "shape",
    },
    "shape:i39FPO7oWGxLUYxs3bdJI": {
      x: 6639.224235291875,
      y: 8265.179816887914,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:i39FPO7oWGxLUYxs3bdJI",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a5",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: 0,
            y: 0,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7592.445935696377,
            y: -13.544733807088505,
          },
        },
      },
      typeName: "shape",
    },
    "shape:wfXBFRUhNrKc_BGiyjX8c": {
      x: 6607.932537039126,
      y: 8903.153585993465,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:wfXBFRUhNrKc_BGiyjX8c",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a6",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: 29.05505314222046,
            y: -135.56740642663362,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7630.317433502222,
            y: -120.33518300724882,
          },
        },
      },
      typeName: "shape",
    },
    "shape:ZUoP7u8173lfxmN2oZ1bh": {
      x: 6634.172386307928,
      y: 10887.217208059315,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:ZUoP7u8173lfxmN2oZ1bh",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a7",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: 0,
            y: 0,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7600.247909567007,
            y: 22.586388654849088,
          },
        },
      },
      typeName: "shape",
    },
    "shape:OeI4oO1lvhcLB8tBEQKPB": {
      x: 6624.8024632652605,
      y: 13189.38349451177,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:OeI4oO1lvhcLB8tBEQKPB",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a8",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: 0,
            y: 0,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7606.760289066315,
            y: 28.051325298234133,
          },
        },
      },
      typeName: "shape",
    },
    "shape:a1OlEDEY4Ms6MTiu1ybt8": {
      x: 6805.584348885117,
      y: 11849.328656825726,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:a1OlEDEY4Ms6MTiu1ybt8",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a9",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -165.09053106233569,
            y: -8.359014231004494,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7429.380767815295,
            y: 56.12384643694077,
          },
        },
      },
      typeName: "shape",
    },
    "shape:iQ8X1PppOFmm9_stCFR-F": {
      x: 6720.584348885117,
      y: 14199.328656825728,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:iQ8X1PppOFmm9_stCFR-F",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aA",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -83.94339701512581,
            y: -7.808688094430636,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7515.860366656396,
            y: 7.031358159701995,
          },
        },
      },
      typeName: "shape",
    },
    "shape:xbfmjZwMoQExSm8w40UGV": {
      x: 6665.411658475474,
      y: 9867.625818368248,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:xbfmjZwMoQExSm8w40UGV",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aB",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -28.71543470468987,
            y: 2.5337148268843066,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 7571.751324605298,
            y: -34.18958163719253,
          },
        },
      },
      typeName: "shape",
    },
    "shape:KA6w80OtZiXJOBUlyJKOr": {
      x: 6756.779844673404,
      y: 6050.405719364147,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:KA6w80OtZiXJOBUlyJKOr",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 119.40625,
        text: "Parity",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 4.41187666141767,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aD",
      typeName: "shape",
    },
    "shape:V4tiW1_r5t_e19K4QlEG4": {
      x: 6626.0670053678,
      y: 6522.840689116502,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:V4tiW1_r5t_e19K4QlEG4",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 132.43781648912096,
        text: "EDD",
        font: "sans",
        align: "middle",
        autoSize: false,
        scale: 5.370540646524547,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aE",
      typeName: "shape",
    },
    "shape:9YStDkSfJEQSi2h7Mbeni": {
      x: 6757.103885292668,
      y: 6982.8670924793105,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:9YStDkSfJEQSi2h7Mbeni",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 851.3877461299932,
        text: "Gestation",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 5.181393634360822,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aF",
      typeName: "shape",
    },
    "shape:B-HolwTTAaAC6aF5TSFEB": {
      x: 6758.065950689104,
      y: 7463.349352394664,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:B-HolwTTAaAC6aF5TSFEB",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1309.9212485484259,
        text: "Medical Issues",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 4.850904903998279,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aG",
      typeName: "shape",
    },
    "shape:jVPZrG1dgPa3pllFC19A6": {
      x: 6695.861781265421,
      y: 8254.68950422045,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:jVPZrG1dgPa3pllFC19A6",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1240.088361473685,
        text: " Medications",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 5.124071398242811,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aH",
      typeName: "shape",
    },
    "shape:Pjt_HJeFEtReXlurpe4xH": {
      x: 6718.702979533192,
      y: 9883.372721158306,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:Pjt_HJeFEtReXlurpe4xH",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1662.0412927324103,
        text: "Obstetric History",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 4.924044790053537,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aI",
      typeName: "shape",
    },
    "shape:VpiWIKxTSfdJ1UddFVOfe": {
      x: 6742.689162340279,
      y: 8769.862866703963,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:VpiWIKxTSfdJ1UddFVOfe",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1521.1376643490853,
        text: "Medical History",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 4.9116684547441025,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aJ",
      typeName: "shape",
    },
    "shape:1CNIyvWPSBmE3JxIWF8dg": {
      x: 6756.237680833704,
      y: 10891.136535069749,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:1CNIyvWPSBmE3JxIWF8dg",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1546.1501427603264,
        text: "Surgical History",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 5.2882975644892785,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aK",
      typeName: "shape",
    },
    "shape:qnOVck8GWcComyczp4gsp": {
      x: 6758.356752361526,
      y: 11854.991125482988,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:qnOVck8GWcComyczp4gsp",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1378.7939235723857,
        text: "Gynae History",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 6.067179668293159,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aL",
      typeName: "shape",
    },
    "shape:3hUIOU-ylJft_2wmWpfrv": {
      x: 6736.637526780589,
      y: 13233.193489439393,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:3hUIOU-ylJft_2wmWpfrv",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 1402.8968573141271,
        text: "Family History",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 5.996202061313409,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aM",
      typeName: "shape",
    },
    "shape:DICQd0kdd_czM0lNcBnOd": {
      x: 6731.152349113595,
      y: 14191.136535069749,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:DICQd0kdd_czM0lNcBnOd",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 2192.002653494222,
        text: "Consultation and Scan",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 4.850904903998279,
      },
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aN",
      typeName: "shape",
    },
    "shape:gtOvt8ptxEfekZfHJsNV4": {
      x: 8978.140345948676,
      y: 6005.612618282204,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:gtOvt8ptxEfekZfHJsNV4",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "aO",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -26.984261196927946,
            y: -147.71208595462394,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 10,
            y: 10547.869473985349,
          },
        },
      },
      typeName: "shape",
    },
    "shape:xTev8zr8em6I333iNhSZK": {
      x: 8702.198301830065,
      y: 6926.313331055459,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:xTev8zr8em6I333iNhSZK",
      type: "line",
      parentId: "shape:FHwCClrrAKvRqdw2NLWj6",
      index: "a3",
      props: {
        dash: "draw",
        size: "xl",
        color: "black",
        spline: "line",
        handles: {
          start: {
            id: "start",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a1",
            x: -2066.9632943399715,
            y: 32.73050002865784,
          },
          end: {
            id: "end",
            type: "vertex",
            canBind: false,
            canSnap: true,
            index: "a2",
            x: 5530.644647505089,
            y: 43.24813988165897,
          },
        },
      },
      typeName: "shape",
    },
    "shape:FHwCClrrAKvRqdw2NLWj6": {
      x: -6484.96580879694,
      y: -5764.124841161348,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:FHwCClrrAKvRqdw2NLWj6",
      type: "group",
      parentId: "shape:CqR0dpk4MBmp7NWRhk6eB",
      index: "aO",
      props: {},
      typeName: "shape",
    },
    "shape:MYLgluO9tpTawVj096huk": {
      x: 3442.394905252737,
      y: 382.6928471151923,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:MYLgluO9tpTawVj096huk",
      type: "draw",
      props: {
        segments: [
          {
            type: "free",
            points: [
              { x: 0, y: 0, z: 0.1 },
              { x: -10, y: 0, z: 0 },
              { x: -10, y: 10, z: 0 },
              { x: -20, y: 10, z: 0 },
              { x: -20, y: 0, z: 0 },
              { x: -20, y: -10, z: 0 },
            ],
          },
        ],
        color: "black",
        fill: "none",
        dash: "draw",
        size: "s",
        isComplete: true,
        isClosed: false,
        isPen: true,
      },
      parentId: "shape:CqR0dpk4MBmp7NWRhk6eB",
      index: "aQ",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 1,
    storeVersion: 4,
    recordVersions: {
      asset: {
        version: 1,
        subTypeKey: "type",
        subTypeVersions: { image: 2, video: 2, bookmark: 0 },
      },
      camera: { version: 1 },
      document: { version: 2 },
      instance: { version: 20 },
      instance_page_state: { version: 4 },
      page: { version: 1 },
      shape: {
        version: 3,
        subTypeKey: "type",
        subTypeVersions: {
          group: 0,
          text: 1,
          bookmark: 1,
          draw: 1,
          geo: 7,
          note: 4,
          line: 1,
          frame: 0,
          arrow: 1,
          highlight: 0,
          embed: 4,
          image: 2,
          video: 1,
        },
      },
      instance_presence: { version: 5 },
      pointer: { version: 1 },
    },
  },
};

const tempStore = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:2PcVW5SiUALSaOA3S30TJ": {
      meta: {},
      id: "page:2PcVW5SiUALSaOA3S30TJ",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },

    "shape:lAn64PMEhOE2Mly4oOCqW": {
      x: -350.237427260806,
      y: -139.1134407491151,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:lAn64PMEhOE2Mly4oOCqW",
      type: "geo",
      props: {
        w: 711.6518486913288,
        h: 53.58519245778028,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5",
      typeName: "shape",
    },
    "shape:YbOGyGv5A81kkYVvADgPC": {
      x: -351.4739843766302,
      y: -85.10949974369271,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:YbOGyGv5A81kkYVvADgPC",
      type: "geo",
      props: {
        w: 713.1040310730502,
        h: 52.16364538806355,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a6",
      typeName: "shape",
    },
    "shape:djn6uENM5rBIwL2_avRJx": {
      x: -350.3891799227108,
      y: -32.19457332902664,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:djn6uENM5rBIwL2_avRJx",
      type: "geo",
      props: {
        w: 711.6518486913288,
        h: 53.58519245778028,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a7",
      typeName: "shape",
    },
    "shape:IzAe08KeakCnTKO0o6qoA": {
      x: -350.82655383281053,
      y: 21.111652002072844,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:IzAe08KeakCnTKO0o6qoA",
      type: "geo",
      props: {
        w: 712.7693032768582,
        h: 102.11107099910862,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a8",
      typeName: "shape",
    },
    "shape:JtqUmPj9fdTQldMC8bT-4": {
      x: -351.22544659741715,
      y: 280.01274266985104,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:JtqUmPj9fdTQldMC8bT-4",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 102.20515622131059,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aA",
      typeName: "shape",
    },
    "shape:7zh0HfNt3EK97hvsueMiu": {
      x: -351.0879619291274,
      y: 382.9537081206033,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:7zh0HfNt3EK97hvsueMiu",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 102.20515622131059,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aB",
      typeName: "shape",
    },
    "shape:aBF0yygXm9egN8yVnZpOC": {
      x: -350.85364641317466,
      y: 483.347872658675,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:aBF0yygXm9egN8yVnZpOC",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 102.20515622131059,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a9V",
      typeName: "shape",
    },
    "shape:-_VfSxNIditWGEjySK_Yy": {
      x: -349.78186375063757,
      y: 584.8327082748422,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:-_VfSxNIditWGEjySK_Yy",
      type: "geo",
      props: {
        w: 712.4276633784599,
        h: 102.91592975616885,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aAV",
      typeName: "shape",
    },
    "shape:EyYwRxYoyuYUefGVApi4r": {
      x: -350.58678960366234,
      y: 687.0522977075805,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:EyYwRxYoyuYUefGVApi4r",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 161.73111667423146,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aC",
      typeName: "shape",
    },
    "shape:Iq0hNDETOHYf2WbAPlZVe": {
      x: -350.4079114436261,
      y: 848.2153930523422,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:Iq0hNDETOHYf2WbAPlZVe",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 102.20515622131059,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aD",
      typeName: "shape",
    },
    "shape:12D0Otfo05XloJWTnVoCl": {
      x: -344.96222901223257,
      y: -129.89565085897374,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:12D0Otfo05XloJWTnVoCl",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 72.3125,
        text: " Parity",
        font: "sans",
        align: "middle",
        autoSize: true,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aE",
      typeName: "shape",
    },
    "shape:pvYze7IyGgKo5DbalaKFI": {
      x: -348.61777681982295,
      y: -79.42264458853458,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:pvYze7IyGgKo5DbalaKFI",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 57.38264227946013,
        text: "TDD",
        font: "sans",
        align: "middle",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aF",
      typeName: "shape",
    },
    "shape:dpCYUSaO1lwL8J-DHAk1T": {
      x: -348.5729288607313,
      y: -24.85723240427609,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:dpCYUSaO1lwL8J-DHAk1T",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 122.40488823568104,
        text: "Gestation",
        font: "sans",
        align: "middle",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aG",
      typeName: "shape",
    },
    "shape:JsOKRdOiI2PnngYEx0VX_": {
      x: -338.97913376939465,
      y: 35.16472099840803,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:JsOKRdOiI2PnngYEx0VX_",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 122.40488823568104,
        text: "Medical Issues",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aH",
      typeName: "shape",
    },
    "shape:A4mhNz9nKqO26zHgfVw0G": {
      x: -337.5701505056966,
      y: 193.40441633275776,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:A4mhNz9nKqO26zHgfVw0G",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 122.40488823568104,
        text: "Obstetric History",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aI",
      typeName: "shape",
    },
    "shape:jZ608dtxOkagjGVdOKEq4": {
      x: -336.1611672419986,
      y: 291.62215826442286,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:jZ608dtxOkagjGVdOKEq4",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 122.40488823568104,
        text: "Medical History",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aJ",
      typeName: "shape",
    },
    "shape:LsIY7inas2B9shL7thtvI": {
      x: -338.84458989212004,
      y: 391.2040355006948,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:LsIY7inas2B9shL7thtvI",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 122.40488823568104,
        text: "Surgical History",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aK",
      typeName: "shape",
    },
    "shape:7m94habrXGeSg3zhr_Msn": {
      x: -338.7997419330285,
      y: 598.5526018008768,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:7m94habrXGeSg3zhr_Msn",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 115.02915013763189,
        text: "Family History",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aL",
      typeName: "shape",
    },
    "shape:cLoVLB-S3zM5iXNr0w0O2": {
      x: -341.9637603708651,
      y: 698.1344790371488,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:cLoVLB-S3zM5iXNr0w0O2",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 146.49754062458885,
        text: "Consultation & Scan",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aM",
      typeName: "shape",
    },
    "shape:dsE64CXF0ImO_RwQv-pP_": {
      x: -342.2604302314504,
      y: 132.01832762546712,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:dsE64CXF0ImO_RwQv-pP_",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 146.49754062458885,
        text: "Medications",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aN",
      typeName: "shape",
    },
    "shape:2zNnYEfWHWXMwdXEMG9Ce": {
      x: -337.87135445704587,
      y: 492.15004804157286,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:2zNnYEfWHWXMwdXEMG9Ce",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 115.02915013763189,
        text: "Gynae History",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aLV",
      typeName: "shape",
    },
    "shape:6V6dWcsEs984ZDXG1ekjV": {
      x: -344.03036323908907,
      y: 856.566254351732,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:6V6dWcsEs984ZDXG1ekjV",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 146.49754062458885,
        text: "Additional Comments",
        font: "sans",
        align: "start",
        autoSize: false,
        scale: 1.1748529435509787,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aMV",
      typeName: "shape",
    },
    "shape:_kLNzwCCfwvAElWpoOg7K": {
      x: -185.52854164518703,
      y: -232.24103537786243,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:_kLNzwCCfwvAElWpoOg7K",
      type: "geo",
      props: {
        w: 368.90382427500725,
        h: 71.6660295566097,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "sans",
        text: "P4 Pregnancy Consultation",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aO",
      typeName: "shape",
    },
    "shape:-HhtsdqlL5KNsH43oA9Fo": {
      x: -351.5009064863727,
      y: -139.81997485674714,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:-HhtsdqlL5KNsH43oA9Fo",
      type: "geo",
      props: {
        w: 180.25404474963005,
        h: 1090.5441468331728,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2",
      typeName: "shape",
    },
    "shape:rT2guVDZeUTG_mFWVKL9x": {
      x: -171.0955957143604,
      y: -138.46212412934176,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:rT2guVDZeUTG_mFWVKL9x",
      type: "geo",
      props: {
        w: 534.4188029147156,
        h: 1087.8390188078718,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3",
      typeName: "shape",
    },
    "shape:98LVg2bUD6Q4TbSugKZTm": {
      x: -351.66725089220904,
      y: 178.5279070536841,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:98LVg2bUD6Q4TbSugKZTm",
      type: "geo",
      props: {
        w: 713.1537545693205,
        h: 102.20515622131059,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a9",
      typeName: "shape",
    },
    "shape:U-Nm72N20QSPlL8LcafEW": {
      x: -348.89170360764854,
      y: 124.43487827482292,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:U-Nm72N20QSPlL8LcafEW",
      type: "geo",
      props: {
        w: 711.6518486913288,
        h: 53.58519245778028,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a7V",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 1,
    storeVersion: 4,
    recordVersions: {
      asset: {
        version: 1,
        subTypeKey: "type",
        subTypeVersions: { image: 2, video: 2, bookmark: 0 },
      },
      camera: { version: 1 },
      document: { version: 2 },
      instance: { version: 20 },
      instance_page_state: { version: 4 },
      page: { version: 1 },
      shape: {
        version: 3,
        subTypeKey: "type",
        subTypeVersions: {
          group: 0,
          text: 1,
          bookmark: 1,
          draw: 1,
          geo: 7,
          note: 4,
          line: 1,
          frame: 0,
          arrow: 1,
          highlight: 0,
          embed: 4,
          image: 2,
          video: 1,
        },
      },
      instance_presence: { version: 5 },
      pointer: { version: 1 },
    },
  },
};
