import { useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SITE_ADDED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SearchStaff from "../search/SearchStaff";
import { createNewSite } from "../../apis/site";
import TernaryButton from "../buttons/TernaryButton";
interface AddSiteProps {
  setIsAddSite: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddSite: React.FC<AddSiteProps> = ({ setIsAddSite, setReload }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<CreateSiteRequest>();
  const [selectedStaff, setSelectedStaff] = useState<{
    label: string;
    publicId: string;
  }>();
  const onSubmit = async (data: CreateSiteRequest) => {
    if (selectedStaff) {
      let obj = {
        name: data.name,
        ownerStaffPublicId: selectedStaff.publicId,
        externalId: data.externalId && data.externalId,
      };

      const [response, error] = await createNewSite(obj);

      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: SITE_ADDED,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setIsAddSite((prev) => !prev);
        setReload((prev) => !prev);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: WENT_WRONG_MSG,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Please select a staff",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4" className="text-capitalize">
            Add Site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="name"
            className="col-sm-10 "
            {...register("name", { required: true })}
            placeholder="Site name"
            id="name"
            label="Site Name"
            type="text"
            required
          />{" "}
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="externalId"
            className="col-sm-10 "
            {...register("externalId", { required: false })}
            placeholder="Wix site external Id"
            id="externalId"
            label="Wix site Id"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SearchStaff
            setSelectedStaff={setSelectedStaff}
            className="col-sm-10"
            label="Head Staff"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <TernaryButton
              onClick={() => {
                setIsAddSite(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Add"} type={"submit"} className="m-2" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddSite;
