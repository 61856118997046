import { Box, Card, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";

import HorizontalMenu from "./HorizontalMenu";
import Dictation from "../dictation/Dictation";
import { Left } from "react-bootstrap/lib/Media";

const TabPanel = (props: TabPanelProps) => {
  const { children, valueY, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueY !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}>
      {valueY === index && (
        <Box sx={{ pl: 3, pr: 3, width: "auto" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const VerticalMenu = React.memo<VerticalMenuOptionProps>(
  ({ verticalMenuOptions }) => {
    const [valueY, setValueY] = useState(0);

    const handleChangeY = (event: React.SyntheticEvent, newValue: number) => {
      setValueY(newValue);
    };
    const handleDictationResult = (result: string) => {
      console.log("Dictation result:", result);
      // Do something with the dictation result, such as updating state or calling an API
    };
    return (
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}>
        {/* <Dictation onDictationResult={handleDictationResult} /> */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "100%",
            flexWrap: "nowrap",
          }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={valueY}
            onChange={handleChangeY}
            aria-label="Vertical tabs example"
            sx={{
              paddingRight: 2,
              borderColor: "divider",
              borderRight: "1px solid #dbd9d9",
              alignItems: "flex-start",
              width: "fit",
            }}>
            {verticalMenuOptions.map((option, index: number) => (
              <Tab
                key={index}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      justifyContent: "space-between",
                    }}>
                    {option.icon && (
                      <Box
                        sx={{
                          width: "25px",
                          marginRight: 1,
                          fontSize: "1rem",
                        }}>
                        {option.icon}
                      </Box>
                    )}
                    <Typography variant="body1" sx={{ textAlign: "end" }}>
                      {option.label}
                    </Typography>
                  </Box>
                }
                {...a11yProps(index)}
                sx={{
                  whiteSpace: "nowrap",
                  alignItems: "flex-start",
                  fontWeight: valueY === index ? "bold" : "normal",
                }}
              />
            ))}
          </Tabs>
          {verticalMenuOptions.map((option, index: number) => (
            <TabPanel key={index} valueY={valueY} index={index}>
              {option.children ? (
                <>
                  <HorizontalMenu children={option.children} />
                </>
              ) : (
                option.component
              )}
            </TabPanel>
          ))}
        </Box>
      </Card>
    );
  }
);

export default VerticalMenu;
