import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";

const MissingPage: React.FC = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1 className="text-center">404 - Page Not Found</h1>
          <p className="text-center">
            The page you are looking for does not exist.
          </p>
          <div className="text-center">
            <BackButton />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MissingPage;
