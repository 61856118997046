import React, { useEffect, useRef, useState } from "react";
import ClinicalHistoryForm from "./ClinicalHistoryForm";
import Toolbar from "./Toolbar";
import amh from "../../assets/resources/amh-image.jpg";
import menstrualCycleDiagram from "../../assets/resources/MenstrualCycle-diagram.jpg";
import hpo from "../../assets/resources/hpo_axis.png";
import { Card } from "@mui/material";

type Tool = "pen" | "eraser";

const DrawingBoard: React.FC<DrawingBoardProps> = ({
  defaultSvg,
  width = 900,
  height,
  toolbarOptions,
}) => {
  const canvasRef = useRef<SVGSVGElement | null>(null);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [penColor, setPenColor] = useState<string>("black");
  const [penSize, setPenSize] = useState<number>(2);
  const [boardHeight, setBoardHeight] = useState<number>(height || 50);
  const boardWidth = width;
  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);
  const [isEraserActive, setIsEraserActive] = useState<boolean>(false);
  const [eraserSize, setEraserSize] = useState<number>(10);
  const [enableTouch, setEnableTouch] = useState<boolean>(true);
  const [prevSelectedTool, setPrevSelectedTool] = useState<Tool>("pen");
  const [isFingerEnabled, setIsFingerEnabled] = useState<boolean>(false);
  const [showDiagrams, setShowDiagrams] = useState<boolean>(false);
  const [diagramHeight, setDiagramHeight] = useState<number>(0);
  console.log(boardHeight);
  const startDrawing = (event: React.PointerEvent<SVGSVGElement>) => {
    if (event.pointerType === "touch" && enableTouch) {
      console.log("first-", isFingerEnabled);
      setIsFingerEnabled(true);
      return;
    } else {
      setIsFingerEnabled(false);
      console.log(event.pointerType, isDrawing, enableTouch);
      setIsDrawing(true);
      const svg = canvasRef.current;
      if (!svg) return;

      const { clientX, clientY } = event;
      const point = svg.createSVGPoint();
      point.x = clientX;
      point.y = clientY;

      const transformedPoint = point.matrixTransform(
        svg.getScreenCTM()?.inverse()
      );
      const pathData = `M ${transformedPoint.x} ${transformedPoint.y}`;

      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      pathElement.classList.add("drawing-path");
      pathElement.setAttribute("d", pathData);
      pathElement.setAttribute("stroke", penColor);
      pathElement.setAttribute("stroke-width", penSize.toString());
      pathElement.setAttribute("fill", "none");

      svg.appendChild(pathElement);

      const svgData = svg.innerHTML;
      setUndoStack((prevStack) => [...prevStack, svgData]);
      setRedoStack([]);
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };
  const draw = (event: React.PointerEvent<SVGSVGElement>): void => {
    if (isDrawing) {
      const svg = canvasRef.current;
      if (!svg) return;

      const { clientX, clientY, pointerType } = event;

      const point = svg.createSVGPoint();
      point.x = clientX;
      point.y = clientY;

      const transformedPoint = point.matrixTransform(
        svg.getScreenCTM()?.inverse() ?? svg.createSVGMatrix()
      );

      const pathElement = svg.lastElementChild as SVGPathElement;
      if (!pathElement) return;
      const pathData = pathElement.getAttribute("d") || "";

      if (isEraserActive) {
        // Eraser is active, change path color to match the canvas background
        pathElement.setAttribute("stroke", "white");
        pathElement.setAttribute("stroke-width", eraserSize.toString());
      } else {
        // Eraser is not active, use the selected pen color and size
        pathElement.setAttribute("stroke", penColor);
        pathElement.setAttribute("stroke-width", penSize.toString());
      }

      pathElement.setAttribute(
        "d",
        `${pathData} L ${transformedPoint.x} ${transformedPoint.y}`
      );
    }
  };

  const clearCanvas = () => {
    while (undoStack.length > 0) {
      handleUndo();
    }
    setRedoStack([]);
  };

  const handleUndo = () => {
    const svg = canvasRef.current;
    if (!svg) return;

    if (undoStack.length === 0) return;

    const currentSvgData = svg.innerHTML;
    setRedoStack((prevStack) => [...prevStack, currentSvgData]);

    const prevSvgData = undoStack.pop();
    if (prevSvgData) {
      svg.innerHTML = prevSvgData;
      setUndoStack([...undoStack]);
    }
  };

  const handleRedo = () => {
    const svg = canvasRef.current;
    if (!svg) return;

    if (redoStack.length === 0) return;

    const currentSvgData = svg.innerHTML;
    setUndoStack((prevStack) => [...prevStack, currentSvgData]);

    const nextSvgData = redoStack.pop();
    if (nextSvgData) {
      svg.innerHTML = nextSvgData;
      setRedoStack([...redoStack]);
    }
  };

  const handleDoubleClick = () => {
    setPrevSelectedTool(prevSelectedTool === "pen" ? "eraser" : "pen");
    setIsEraserActive((prev) => !prev);
  };

  const addDiagrams = async () => {
    setShowDiagrams(true);
    console.log(boardHeight, diagramHeight);
    setDiagramHeight(boardHeight);
    console.log(boardHeight, diagramHeight);
    setBoardHeight((prevHeight) => prevHeight + 2900);
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    const optionsContainer = optionsRef.current;
    if (!canvas || !optionsContainer) return;

    const preventDefaultTouchmove = (event: TouchEvent) => {
      event.preventDefault();
    };
    canvas.addEventListener("touchmove", preventDefaultTouchmove, {
      passive: isFingerEnabled,
    });

    return () => {
      canvas.removeEventListener("touchmove", preventDefaultTouchmove);
    };
  }, [isFingerEnabled]);

  const saveAsSVG = () => {
    const container = document.getElementById("print-content");
    if (!container) return;

    const svgs = container.querySelectorAll("svg");
    if (svgs.length === 0) return;

    svgs.forEach((svg, index) => {
      const svgData = svg.outerHTML;
      const blob = new Blob([svgData], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `drawing_${index + 1}.svg`;
      link.click();
      URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    if (defaultSvg) {
      const svg = canvasRef.current;
      if (!svg) return;

      // svg.innerHTML = defaultSvg;
    }
  }, [defaultSvg]);

  const handlePrint = () => {
    const printContent = document.getElementById("print-content");
    if (!printContent) return;

    const printWindow = window.open("", "_blank");
    if (!printWindow) return;

    printWindow.document.write(`
      <html>
        <head>
          <title>First consultation</title>
          <style>
            @media print {
              /* Add your print styles here */
              /* For example: */
              body {
                margin: 0;
              }
              . {
                /* Specify your print styles for the printContent element */
                /* For example: */
                background-color: white;
                border: none;
              }
            }
          </style>
          <link rel="stylesheet" type="text/css" href="path/to/your/mui-styles.css">
        </head>
        <body>
          <div id="print-content" class="">
            ${printContent.innerHTML}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div
      style={{
        // maxHeight: 1000,
        // overflowY: "auto",
        background: "linear-gradient(#f5f5f5, #eaeaea)",
        padding: "20px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}>
      <div
        ref={optionsRef}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}>
        <Toolbar
          clearCanvas={clearCanvas}
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          // saveAsSVG={saveAsSVG}
          handlePrint={handlePrint}
          undoDisabled={undoStack.length === 0}
          redoDisabled={redoStack.length === 0}
          setPenColor={setPenColor}
          selectedPenColor={penColor}
          setPenSize={setPenSize}
          selectedPenSize={penSize}
          setIsEraserActive={setIsEraserActive}
          isEraserActive={isEraserActive}
          setEraserSize={setEraserSize}
          selectedEraserSize={eraserSize}
          enableTouch={enableTouch}
          setEnableTouch={setEnableTouch}
          addDiagrams={addDiagrams}
          setBoardHeight={setBoardHeight}
          toolbarOptions={toolbarOptions}
        />
      </div>
      <Card
        sx={{
          borderBottomRadius: 5,
          maxHeight: 1000,
          overflowY: "scroll",
          scrollbarColor: "#fff5ee",
        }}
        elevation={0}>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="print-content">
          <svg
            ref={canvasRef}
            style={{
              width: boardWidth, // Set width to 100% to fill the available space
              height: boardHeight, // Use the boardHeight state for the height
              background: "white",
            }}
            onPointerDown={startDrawing}
            onPointerUp={stopDrawing}
            onPointerMove={draw}
            onDoubleClick={handleDoubleClick}></svg>
        </div>
      </Card>
    </div>
  );
};

export default DrawingBoard;
