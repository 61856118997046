import { AxiosRequestConfig } from "axios";
import { AZURE_URL } from "../constants/env-constants";

export const loginUser = async (
  data: LoginUser,
  twoFactorAuthCode?: string
) => {
  try {
    const headers: AxiosRequestConfig["headers"] = {};
    headers["Two-Factor-Auth-Code"] = twoFactorAuthCode;

    const response = await AZURE_URL.post("/user/auth/token", data, {
      headers,
    });

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getUser = async () => {
  try {
    const response = await AZURE_URL.get("/user/get");
    return response;
  } catch (error: any) {
    console.log(error);
    return error.response;
  }
};

export const preLoginCheck = async (data: LoginUser) => {
  try {
    const response = await AZURE_URL.post("/user/preLoginCheck", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data?.message || "Credentials do not match"];
    }
  } catch (error: any) {
    console.log(error);
    return [null, error.response.data];
  }
};

export const firstPasswordReset = async (data: FirstPasswordReset) => {
  try {
    const response = await AZURE_URL.put("/user/resetAuth", data);
    if (response?.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getQRAuthImage = async (data: QRAuthImage) => {
  try {
    const response = await AZURE_URL.put("/user/resetAuth", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
