import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

const ActionDelete = React.memo<ActionClickIconProps>(({ handleClick }) => {
  return (
    <Tooltip title="Delete" placement="top">
      <DeleteIcon
        style={{ margin: 3, cursor: "pointer" }}
        color="error"
        onClick={handleClick}
      />
    </Tooltip>
  );
});

export default ActionDelete;
