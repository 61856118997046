import { makeStyles } from "@mui/material";
import { Box, Typography } from "@mui/material";
import React from "react";

interface ShowNameValueProps {
  data: {
    label: string;
    name: string;
    value: string;
  }[];
}
const ShowNameValue = React.memo<ShowNameValueProps>(({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        p: 3,
      }}>
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
          <Box key={index + "label"} sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>{item.label}</b>
            </Typography>
          </Box>{" "}
          <Box key={index + "value"} sx={{ width: "50%" }}>
            {item.value && (
              <Typography variant="body1">{item.value}</Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
});

export default ShowNameValue;
