import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import PrimaryButton from "../buttons/PrimaryButton";
import TernaryButton from "../buttons/TernaryButton";
import { createNewSupplement } from "../../apis/supplements";
import {
  SUPPLEMENT_CREATE_FAILED,
  SUPPLEMENT_CREATE_SUCCESS,
} from "../../constants/SnackbarMessages";

interface AddSupplementProps {
  setIsAddSupplement: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}

const AddSupplement: React.FC<AddSupplementProps> = ({
  setIsAddSupplement,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<CreateSupplementRequest>();
  const onSubmit = async (data: CreateSupplementRequest) => {
    const [response, error] = await createNewSupplement(data);
    console.log(error, response);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: SUPPLEMENT_CREATE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setIsAddSupplement(false);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || SUPPLEMENT_CREATE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4" className="text-capitalize">
            Add Supplement
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="name"
            className="col-sm-10 "
            {...register("name", { required: true })}
            placeholder="Supplement Name"
            id="name"
            label="Name"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="dose"
            className="col-sm-10 "
            {...register("dose", { required: true })}
            placeholder="Dosage"
            id="dose"
            label="Dose"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="standardText"
            className="col-sm-10 "
            {...register("standardText", { required: false })}
            placeholder="Location email address"
            id="standardText"
            label="Standard Text"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="leafletUrl"
            className="col-sm-10 "
            {...register("leafletUrl", { required: false })}
            placeholder="Location phone/contact number"
            id="leafletUrl"
            label="Leaflet Url"
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <TernaryButton
              onClick={() => {
                setIsAddSupplement(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Save"} type={"submit"} className="m-2" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddSupplement;
