import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import EditableBox from "../adminCenterComponents/EditableBox";
import SecondaryButton from "../buttons/SecondaryButton";

interface ShowQueryResponseProps {
  response: WixQueryResponse;
  selectedDate: string;
}

const ShowQueryResponse: React.FC<ShowQueryResponseProps> = ({
  response,
  selectedDate,
}) => {
  const [showFailures, setShowFailures] = useState<boolean>(false);
  return (
    <Grid item xs={12} sm={12} md={12}>
      <EditableBox
        label={"Total Records from " + selectedDate}
        currentValue={response?.pagingMetadata.total}
        name={""}
      />
      <EditableBox
        label={"Getting records"}
        currentValue={`${response.pagingMetadata.offset} - ${
          response.pagingMetadata.offset + 100
        }`}
        name={""}
      />
      <EditableBox
        label={"Has more records"}
        currentValue={response.pagingMetadata.hasNext ? "Yes" : "No"}
        name={""}
      />{" "}
      <EditableBox
        label={"Total bookings trying to get"}
        currentValue={response.totalBookings}
        name={""}
      />
      <EditableBox
        label={"Total success"}
        currentValue={response.totalSuccess}
        name={""}
      />{" "}
      <EditableBox
        label={"Total failure"}
        currentValue={response.totalFailures}
        name={""}
      />
      {response.failedWixBookingIds.length > 0 && (
        <SecondaryButton
          label={`${showFailures ? "Hide" : "Show"} Failure Id's`}
          type={"button"}
          onClick={() => setShowFailures(!showFailures)}
        />
      )}
      {showFailures && (
        <Grid container spacing={1} mt={3}>
          {response.failedWixBookingIds.map((id) => (
            <Grid item xs={12} sm={6} md={4} key={id}>
              <Box>{id}</Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowQueryResponse;
