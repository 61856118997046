import { AZURE_URL } from "../constants/env-constants";

export const uploadFile = async (data: any) => {
  try {
    const response = await AZURE_URL.post("/storage/upload", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};
export const downloadFileByUrl = async (data: { url: string }) => {
  try {
    const response = await AZURE_URL.get("/storage/download", {
      params: data,
      responseType: "blob",
    });
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

// export const downloadFile2 = async (
//   containerName: string,
//   filename: string
// ) => {
//   try {
//     const response = await API_URL().get(
//       `/storage/download?containerName=${containerName}&fileName=${filename}`
//     );
//     return response.data;
//   } catch (error: any) {
//     return error.response.data;
//   }
// };
