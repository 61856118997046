import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import {
  SERVICE_DELETE_FAILED,
  SERVICE_DELETE_SUCCESS,
} from "../../constants/SnackbarMessages";
import EditableBox from "../adminCenterComponents/EditableBox";
import EditableTitleBox from "../adminCenterComponents/EditableTitleBox";
import { deleteService } from "../../apis/services";
import SearchServiceMultiSelect from "../search/ServiceMultiSelect";
import SearchPathway from "../search/SearchPathway";

const ShowServiceDetails: React.FC<ServiceDetailsProps> = ({
  selectedService,
  setSelectedService,
  reload,
  setReload,
}) => {
  const [editing, setEditing] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [values, setValues] = useState<MultiSelectOptionType[]>([]);
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditing(true);
    setNewValues({
      name: selectedService.name,
      pathwayStages: selectedService,
    });
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave: SubmitHandler<FieldValues> = async (data) => {
    console.log(newValues, values, data);
    // try {
    //   const [response, error] = await updatePathway(updatedSite);
    //   if (response) {
    //     dispatch(
    //       activateSnackbar({
    //         active: true,
    //         message: SITE_ADDED,
    //         variant: SNACKBAR_SUCCESS,
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       activateSnackbar({
    //         active: true,
    //         message: WENT_WRONG_MSG,
    //         variant: SNACKBAR_ERROR,
    //       })
    //     );
    //   }
    //   setEditing(false);
    // } catch (error) {
    //   dispatch(
    //     activateSnackbar({
    //       active: true,
    //       message: "Please select a staff",
    //       variant: SNACKBAR_ERROR,
    //     })
    //   );
    // }
  };

  const handleDelete = async () => {
    const [response, error] = await deleteService(selectedService.publicId);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: SERVICE_DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setSelectedService(null);
      // setEditing(false);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || SERVICE_DELETE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewValues((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box sx={{ p: 2 }}>
      <EditableTitleBox
        editing={editing}
        label={"Type"}
        handleChange={handleChange}
        newValue={newValues.name}
        currentValue={selectedService.name}
        name={"name"}
        handleEdit={handleEdit}
        openConfirmModal={openConfirmModal}
      />
      {(selectedService.type || editing) && (
        // <EditableBox
        //   editing={editing}
        //   label={"Type"}
        //   handleChange={handleChange}
        //   newValue={newValues.type}
        //   currentValue={selectedService.type}
        //   name={"type"}
        // />
        <Box
          sx={{ marginBottom: 2, marginTop: 2, width: "90%", display: "flex" }}
        >
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>{"Type"}</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <TextField
                fullWidth
                label="Type of service"
                id="type"
                defaultValue={selectedService.type}
                placeholder="Select type of service"
                required
                {...register("type", { required: true })}
                select
              >
                <MenuItem value="Treatment">Treatment</MenuItem>
                <MenuItem value="Consultation">Consultation</MenuItem>
                <MenuItem value="Test">Test</MenuItem>
              </TextField>
            ) : (
              <Typography variant="body1">{selectedService.type}</Typography>
            )}
          </Box>
        </Box>
      )}

      {(selectedService.externalId || editing) && (
        <EditableBox
          editing={editing}
          label={"External Id"}
          handleChange={handleChange}
          newValue={newValues.externalId}
          currentValue={selectedService.externalId}
          name={"externalId"}
        />
      )}
      {(selectedService.pathway || editing) && (
        // <EditableBox
        //   label={"Pathway"}
        //   currentValue={selectedService.pathway.name}
        //   name={"name"}
        // />
        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Pathway</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <>
                <SearchPathway
                  setValue={setValue}
                  id={`pathwayPublicId`}
                  label="Select pathway"
                  className="col-sm-12"
                  defaultValue={selectedService.pathway}
                />
              </>
            ) : (
              <Typography variant="body1">
                {selectedService.pathway.name}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {(selectedService.serviceSuggestions.length > 0 || editing) && (
        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Service Suggestions</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <>
                <SearchServiceMultiSelect
                  label={"Suggest services"}
                  id={""}
                  setValues={setValues}
                  className="col-sm-12"
                  values={values}
                />
                {selectedService.serviceSuggestions.map((ss) => (
                  <Typography variant="body1">
                    {ss.suggestedServiceName}
                  </Typography>
                ))}
              </>
            ) : (
              selectedService.serviceSuggestions.map((ss) => (
                <Typography variant="body1">
                  {ss.suggestedServiceName}
                </Typography>
              ))
            )}
          </Box>
        </Box>
      )}
      {selectedService.updatedBy && (
        <EditableBox
          label={"Updated By"}
          handleChange={handleChange}
          currentValue={selectedService.updatedBy}
          name={"updatedBy"}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing ? (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete service '${selectedService?.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default ShowServiceDetails;
