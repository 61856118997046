import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ModalSuccess from "../dialogBoxes/ModalSuccess";
import { createUserForm } from "../../util/superAdmin/createForm.util";
import TextInput from "../fromComponents/TextInput";
import { createUser, getAllRoles } from "../../apis/users";
import RoleCheckbox from "../roleCheckbox/RoleCheckbox";
import TextInputFull from "../fromComponents/TextInputFull";
import { useDispatch } from "react-redux";
import { loading } from "../../redux/slice/loaderSlice";
import { doneLoading } from "../../redux/slice/loaderSlice";
import { Box, Grid, Typography } from "@mui/material";
import PrimaryButton from "../buttons/PrimaryButton";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  USER_ADDED,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import TernaryButton from "../buttons/TernaryButton";

interface AddUserProps {
  setIsAddUser: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddUser: React.FC<AddUserProps> = ({
  setIsAddUser,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm<CreateUser>();
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [roles, setRoles] = useState<{ keyName: string; value: string }[]>([]);

  const onSubmit = async (data: CreateUser) => {
    const [response, error] = await createUser(data);

    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: USER_ADDED,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setIsAddUser(false);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error.data ? error.data : WENT_WRONG_MSG,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const getRoles = async () => {
    dispatch(loading);
    const [response, error] = await getAllRoles();
    if (response) {
      let rolesArray: { keyName: string; value: string }[] = [];

      for (const role of response) {
        rolesArray.push({ keyName: role.name, value: role.name });
      }
      setRoles(rolesArray);
    } else {
      console.log(error);
    }

    // dispatch(addRoles(rolesArray));
    dispatch(doneLoading);
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" paddingBottom={3}>
        Add User
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item spacing={2} xs={12} sm={12} md={12}>
            <Grid container>
              {createUserForm.map((item: InputFieldType) => {
                return (
                  // <Grid item xs={} key={item.id}>
                  <TextInput
                    keyName={item.id}
                    register={register}
                    placeholder={item.placeholder}
                    id={item.id}
                    label={item.label}
                    type={item.type}
                    listName={item.id + "list"}
                    options={item.options}
                    required={item.required}
                    tag={item.tag}
                  />
                  // </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextInputFull
              keyName={`roles`}
              register={register}
              setValue={setValue}
              label="Select Roles"
              id={`roles`}
              type={"checkbox"}
              required={true}
              checkboxOptions={roles}
              boxSize={12}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "right" }}>
            <TernaryButton
              onClick={() => {
                setIsAddUser(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Add"} type={"submit"} />
          </Box>
        </Grid>
        {/* <Button variant="contained" color="success" type="submit">
          Create User
        </Button> */}
      </form>
    </Box>
  );
};

export default AddUser;
