import { Box, Typography } from "@mui/material";

import HeaderElement from "./HeaderElement";

const ShowNote: React.FC<ShowNoteProps> = ({ note, setShowTable }) => {
  return (
    <>
      <HeaderElement
        name={note?.name}
        whenCreated={note?.whenCreated}
        backFunction={() => {
          setShowTable(true);
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 3,
        }}>
        <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Note</b>
            </Typography>
          </Box>{" "}
          <Box sx={{ width: "50%" }}>{note.note}</Box>
        </Box>
      </Box>
    </>
  );
};

export default ShowNote;
