import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TernaryButton from "../buttons/TernaryButton";
import { createPrescriptionPDF } from "../../util/prescription/prescription";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { createPrescription } from "../../apis/prescription";
import {
  CREATE_FAILED,
  CREATE_SUCCESS,
  DOWNLOAD_FAILED,
  DOWNLOAD_SUCCESS,
} from "../../constants/SnackbarMessages";
import useSnackbar from "../../hooks/useSnackbar";
import AddDownload from "../OptionsComponents/AddDownload";

const AddPrescription: React.FC<AddPrescriptionProps> = ({
  setIsAddPrescription,
  setReload,
}) => {
  const { id } = useParams();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const [prescription, setPrescription] = useState<Prescription>();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const userObject: any = useSelector<UserState>((state) => state.user);
  const { staff } = userObject.user;
  const [medicines, setMedicines] = useState<Medicine[]>([
    { medicine: "", directions: "", duration: "" },
  ]);
  const handleAddMedicine = () => {
    setMedicines([
      ...medicines,
      { medicine: "", directions: "", duration: "" },
    ]);
  };

  const handleRemoveMedicine = (index: number) => {
    const updatedMedicines = [...medicines];
    updatedMedicines.splice(index, 1);
    setMedicines(updatedMedicines);
  };

  const handleChangeMedicine = (
    index: number,
    field: keyof Medicine,
    value: string | number
  ) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value as never;
    setMedicines(updatedMedicines);
  };

  const handleSubmit = async () => {
    if (id) {
      let obj: CreatePrescriptionRequest = {
        patientPublicId: id,
        staffPublicId: staff.publicId,
        prescriptionRows: medicines,
      };

      const [response, error] = await createPrescription(obj);
      if (response) {
        successSnackbar("Prescription" + CREATE_SUCCESS);
        setIsDisable(false);
        setPrescription(response);
      } else {
        errorSnackbar(CREATE_FAILED + "prescription.");
      }
    } else {
      errorSnackbar("Patient not found");
    }
    console.log(medicines);
  };

  const handleDownload = async () => {
    if (prescription) {
      createPrescriptionPDF(prescription);
      handleReset();
      successSnackbar(DOWNLOAD_SUCCESS);
    } else {
      errorSnackbar(DOWNLOAD_FAILED);
    }
  };
  const handleReset = () => {
    setReload((prev) => !prev);
    setMedicines([{ medicine: "", directions: "", duration: "" }]);
    setIsAddPrescription(false);
  };
  return isDisable ? (
    <div>
      <Typography variant="h6">Prescription</Typography>
      {medicines.map((medicine, index) => (
        <Grid container spacing={2} key={index} margin={2}>
          <Grid item xs={4}>
            <Autocomplete
              freeSolo
              id={"medicineName"}
              options={[]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={medicine.medicine}
                  label="Medicine Name"
                  required={true}
                  onChange={(e) =>
                    handleChangeMedicine(index, "medicine", e.target.value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              freeSolo
              id={"directions"}
              options={[]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={medicine.medicine}
                  label="Directions"
                  onChange={(e) =>
                    handleChangeMedicine(index, "directions", e.target.value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Duration"
              type="text"
              value={medicine.duration}
              onChange={(e) =>
                handleChangeMedicine(index, "duration", e.target.value)
              }
              fullWidth
            />
          </Grid>
          {medicines.length > 1 && (
            <Grid item xs={2}>
              <IconButton
                color="error"
                onClick={() => handleRemoveMedicine(index)}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid container justifyContent="flex-end" spacing={2} margin={2}>
        <Button
          variant="outlined"
          color="primary"
          className="m-2"
          startIcon={<AddCircleIcon />}
          onClick={handleAddMedicine}
        >
          Add Medicine
        </Button>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2} margin={2}>
        <TernaryButton
          onClick={() => {
            setIsAddPrescription(false);
          }}
          label={"Cancel"}
          className="m-2"
        />
        <Button
          variant="contained"
          color="primary"
          className="m-2"
          onClick={handleSubmit}
          disabled={medicines[0].medicine === ""}
        >
          Submit
        </Button>
      </Grid>
      {/* <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={() => getUnique()}
        sx={{ mt: 3, mb: 2 }}>
        <b>create prescription</b>
      </Button> */}
    </div>
  ) : (
    <AddDownload onDownload={handleDownload} onReset={handleReset} />
  );
};

export default AddPrescription;
