import VerticalMenu from "../menus/VerticalMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FA_ICON_COLOR, FA_ICON_SIZE } from "../../constants/ColorsConstants";
import { faMicroscope } from "@fortawesome/free-solid-svg-icons";
import CreateTest from "./CreateTest";
import AllRequestedTests from "./testResults/AllRequestedTests";

const LabsModule = () => {
  const verticalMenuOptions: VerticalMenuOption[] = [
    {
      label: "Book A test",
      icon: (
        <FontAwesomeIcon
          icon={faMicroscope}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <CreateTest />,
    },
    {
      label: "Requested Tests",
      icon: (
        <FontAwesomeIcon
          icon={faMicroscope}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <AllRequestedTests />,
    },
  ];

  return (
    <div className="m-3">
      <VerticalMenu verticalMenuOptions={verticalMenuOptions} />
    </div>
  );
};

export default LabsModule;
