import React, { useEffect, useState } from "react";
import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { getTopTestFromTDL, searchTDLTest } from "../../apis/labs";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import useSnackbar from "../../hooks/useSnackbar";

const TestChips: React.FC<TestChipsProps> = ({
  setSelectedTests: setSelectedTest,
}) => {
  const [selectedTests, setSelectedTests] = useState<TDLTest[]>([]);
  const [allTests, setAllTests] = useState<TDLTest[]>([]);
  const [filteredTests, seFilteredTests] = useState<TDLTest[]>([]);
  const [topTest, setTopTests] = useState<TDLTest[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const getTopTests = async () => {
    setTopTests([]);
    dispatch(loading());
    const [response, error] = await getTopTestFromTDL();
    if (response) {
      const result = response.map((item: TDLTest) => {
        return { label: item.Description, ...item };
      });
      setTopTests(result);
    } else {
      errorSnackbar("Failed to get Top Tests.");
    }
    dispatch(doneLoading());
  };

  const handleTestClick = (test: TDLTest) => {
    if (selectedTests.some((t) => t.Id === test.Id)) {
      const updatedSelectedTests = selectedTests.filter(
        (t) => t.Id !== test.Id
      );
      setSelectedTests(updatedSelectedTests);
      setSelectedTest(updatedSelectedTests);
    } else {
      const updatedSelectedTests = [...selectedTests, test];
      setSelectedTests(updatedSelectedTests);
      setSelectedTest(updatedSelectedTests);
    }
    setInputValue("");
  };

  const search = async () => {
    setAllTests([]);
    const [response, error] = await searchTDLTest(inputValue);
    if (response) {
      const result = response.map((item: TDLTest) => {
        return { label: `${item.Description}(${item.TestCode})`, ...item };
      });
      setAllTests(result);
    }
  };

  const handleSearchInputChange = async (
    event: React.SyntheticEvent,
    option: any
  ) => {
    if (
      option &&
      !selectedTests.some((test) => test.Id === option.Id) &&
      !topTest.some((test) => test.Id === option.Id)
    ) {
      handleTestClick(option);
      setTopTests((prev) => [...prev, option]);
    }
    if (option && topTest?.some((test) => test.Id === option.Id)) {
      handleTestClick(option);
    }

    setInputValue("");
  };

  const handleSearch = (value: string) => {
    const result = allTests.filter((item: TDLTest) =>
      JSON.stringify(item).toLowerCase().includes(value.toLowerCase())
    );

    seFilteredTests(result);
  };

  useEffect(() => {
    getTopTests();
    search();
  }, []);

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item md={12}>
        {allTests && (
          <Autocomplete
            freeSolo
            className="col-sm-6"
            id="search"
            options={filteredTests}
            value={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              handleSearch(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={"search test"} value={inputValue} />
            )}
            onChange={handleSearchInputChange}
          />
        )}
      </Grid>
      {topTest.map((test) => (
        <Grid item key={test.Id}>
          <Chip
            label={test.Description}
            onClick={() => handleTestClick(test)}
            color={
              selectedTests.some((t) => t.Id === test.Id)
                ? "primary"
                : "default"
            }
            variant={
              selectedTests.some((t) => t.Id === test.Id)
                ? "filled"
                : "outlined"
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TestChips;
