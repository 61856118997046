import React, { useEffect, useRef, useState } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getAppointmentsWithDate } from "../../apis/bookings";
import { StripedTable } from "../table/StripedTable";
import { formatToDateTimeCalender } from "../../util/data.util";
import { useNavigate } from "react-router-dom";
import ComponentLoading from "../loading/ComponentLoading";
import { getNextDate, getPreviousDate } from "../../util/date.utl";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { removePartnerInformation } from "../../redux/slice/partnerInformation";
import { removePatientInformation } from "../../redux/slice/patientInformation";
import { removeFemalePatientInformation } from "../../redux/slice/femalePatientSlice";
import { useDispatch } from "react-redux";
import { paymentStatusStyle } from "../../util/appointment/util";
import { setAppointments } from "../../redux/slice/todayAppointments";
import { useSelector } from "react-redux";
import CustomCheckbox from "../checkboxs/CustomCheckbox";

const Calendar: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(currentDate.getTime() - userTimezoneOffset)
  );
  const [currentDateAppointments, setCurrentDateAppointments] = useState<
    Appointment[] | null
  >([]);
  const [filteredAppointments, setFilteredAppointments] = useState<
    Appointment[] | null
  >([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const allAppointments: any = useSelector<AllAppointmentState>(
    (state) => state.allAppointments
  );

  const dispatch = useDispatch();

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedDate(new Date(value));
  };

  const handlePreviousDate = () => {
    setSelectedDate(getPreviousDate(selectedDate));
  };

  const handleNextDate = () => {
    setSelectedDate(getNextDate(selectedDate));
  };

  const columns = [
    {
      label: "Name of Patient",
      key: "fullName",
    },
    {
      label: "Appointment Time",
      key: "appointmentTime",
      sortable: true,
    },
    { label: "service", key: "serviceName" },
    {
      label: "Payment Status",
      key: "paymentStatus",
      style: paymentStatusStyle,
    },
  ];

  const handleRowClick = (row: any) => {
    const id = row.patient.publicId;
    navigate(`/patients/information/${id}`);
  };

  const cardRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    setIsLoading(true);
    const fromDate = selectedDate.toISOString().split("T")[0];
    const toDate = getNextDate(selectedDate).toISOString().split("T")[0];
    const existingIndex = allAppointments?.allAppointments?.findIndex(
      (item: { date: string }) => item.date === fromDate
    );
    if (existingIndex !== -1) {
      filterAppointments(
        false,
        allAppointments?.allAppointments[existingIndex].appointments
      );
      setIsLoading(false);
    } else {
      try {
        const [res, error] = await getAppointmentsWithDate({
          fromDate,
          toDate,
        });

        if (res) {
          let data: Appointment[] = [];
          res.forEach((row: Appointment) => {
            data.push({
              fullName: `${
                row.patient.firstName ? row.patient.firstName : ""
              } ${row.patient.lastName ? row.patient.lastName : ""}`,
              serviceName: row.service.name,
              ...row,
            });
          });

          dispatch(setAppointments({ appointments: data, date: fromDate }));
          filterAppointments(false, data);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const filterAppointments = (showCancelled: boolean, data: Appointment[]) => {
    setIsChecked(showCancelled);
    if (data) {
      setCurrentDateAppointments(data);
      const filteredAppointments = showCancelled
        ? data // Show all appointments
        : data.filter((appointment) => !appointment.cancelled); // Filter out canceled appointments
      setFilteredAppointments(filteredAppointments);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  useEffect(() => {
    dispatch(removePartnerInformation());
    dispatch(removePatientInformation());
    dispatch(removeFemalePatientInformation());
  }, []);

  return (
    <>
      <Card ref={cardRef} sx={{ boxShadow: 1, height: 400, borderRadius: 4 }}>
        <Box sx={{}}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "sticky",
                top: 0,
                borderBottom: 1,
              }}
            >
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <IconButton>
                  <CalendarMonthOutlinedIcon />
                </IconButton>
                <Typography variant="h6">Calendar</Typography>
              </Grid>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                {currentDateAppointments && (
                  <CustomCheckbox
                    label={"Cancelled"}
                    onChange={(e) =>
                      filterAppointments(e, currentDateAppointments)
                    }
                    checked={isChecked}
                  />
                )}
                <IconButton onClick={handlePreviousDate}>
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
                <TextField
                  size="small"
                  variant="standard"
                  type="date"
                  value={selectedDate.toISOString().split("T")[0]}
                  onChange={handleDateChange}
                  sx={{ borderBottom: 0 }}
                />

                <IconButton onClick={handleNextDate}>
                  <ArrowForwardIosOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            {isLoading ? (
              <ComponentLoading />
            ) : (
              filteredAppointments && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ overflow: "auto", height: 350 }}
                >
                  <StripedTable
                    data={filteredAppointments}
                    onRowClick={handleRowClick}
                    dateFormatter={formatToDateTimeCalender}
                    columns={columns}
                    defaultSort={{
                      key: "",
                      direction: "desc",
                    }}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Card>
    </>
  );
});

export default Calendar;
