import React, { useState, useEffect } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { getAllSites } from "../../apis/site";

interface SearchSitesProps {
  setSelectedSite: any;
  className?: string;
  label?: string;
  defaultValue?: SelectedSite;
  isExternal?: boolean;
}

const SearchSites: React.FC<SearchSitesProps> = ({
  setSelectedSite,
  className,
  label,
  defaultValue,
  isExternal = false,
}) => {
  const [searchSites, setSearchSites] = useState<{}[]>([]);
  const handleSearch = async () => {
    const [response, error] = await getAllSites();
    if (response) {
      const results = response.map((element: Site) => {
        return {
          label: `${element.name} `,
          publicId: isExternal ? element.externalId : element.publicId,
        };
      });

      setSearchSites(results);
    } else {
      console.log(error);
    }
  };

  const handleSelect = (event: React.SyntheticEvent, option: any) => {
    setSelectedSite(option);
  };
  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Autocomplete
      freeSolo
      className={className ? className : "col-sm-10 p-0"}
      id="sites"
      options={searchSites}
      renderInput={(params) => (
        <TextField {...params} label={label ? label : "Add Site"} />
      )}
      value={defaultValue}
      onChange={handleSelect}
    />
  );
};

export default SearchSites;
