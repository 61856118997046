import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { useDispatch } from "react-redux";
import ComponentLoading from "../loading/ComponentLoading";
import AddPrescription from "./AddPrescription";
import ShowPrescriptionDetails from "./ShowPrescriptionDetails";
import { useParams } from "react-router-dom";
import { getPrescriptionsByPatientId } from "../../apis/prescription";
import useSnackbar from "../../hooks/useSnackbar";
import { FAILED_TO_FETCH } from "../../constants/SnackbarMessages";
import { formatToDateTime } from "../../util/data.util";

const ShowPrescriptions = () => {
  const { id } = useParams();
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [selectedPrescription, setSelectedPrescription] =
    useState<Prescription | null>(null);
  const [showTable, setShowTable] = useState<boolean>();
  const [isAddPrescription, setIsAddPrescription] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { errorSnackbar } = useSnackbar();
  const getPrescriptions = async () => {
    if (id) {
      const [response, error] = await getPrescriptionsByPatientId(id);
      if (response) {
        console.log(response);
        setPrescriptions(response);
      } else {
        errorSnackbar(FAILED_TO_FETCH + "prescriptions.");
      }
    }
  };

  const columns = [
    {
      label: "Date",
      key: "whenCreated",
      sortable: true,
    },
  ];

  const handleRowClick = (row: any) => {
    console.log(row);

    setIsAddPrescription(false);
    setSelectedPrescription(row);
  };
  useEffect(() => {
    getPrescriptions();
  }, [reload]);

  return (
    <>
      <Grid container xs={12} width={"inherit"} spacing={2}>
        {isLoading ? (
          <ComponentLoading />
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={6}
            p={2}
            minHeight={"50vh"}
            sx={{ borderRight: "1px solid #dbd9d9", mt: 2 }}
          >
            {prescriptions && (
              <CustomTable
                data={prescriptions}
                columns={columns}
                onRowClick={handleRowClick}
                searchable={true}
                optionButtonText={"Add Prescription"}
                setOptionButton={setIsAddPrescription}
                dateFormatter={formatToDateTime}
                defaultSort={{
                  key: columns[0].key,
                  direction: "desc",
                }}
                height={"50vh"}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} xl={6}>
          {isAddPrescription ? (
            <AddPrescription
              setIsAddPrescription={setIsAddPrescription}
              setReload={setReload}
            />
          ) : (
            selectedPrescription && (
              <ShowPrescriptionDetails
                selectedPrescription={selectedPrescription}
                setSelectedPrescription={setSelectedPrescription}
                setReload={setReload}
              />
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ShowPrescriptions;
