import { useState } from "react";
import { downloadFileByUrl } from "../apis/files.api";
import { getContentType } from "../util/files.utl";

const useViewFile = () => {
  const viewFile = async (url: string) => {
    try {
      // Download the file using downloadFileByUrl or any suitable method
      const [response, error] = await downloadFileByUrl({ url });

      if (response) {
        // Determine the file name from the URL
        const fileName = url.split("/").pop() || "file";

        // Set the appropriate Content-Type based on the file extension
        const blob = new Blob([response], { type: getContentType(fileName) });
        const blobUrl = URL.createObjectURL(blob);

        // Create a new window or tab to open the file
        const newWindow = window.open(blobUrl, "_blank");

        if (newWindow) {
          newWindow.focus();
        } else {
          alert("Pop-up blocked. Please allow pop-ups to view the file.");
        }

        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Error downloading file:", error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return { viewFile };
};

export default useViewFile;
