export const NoteForm = [
  {
    label: "Title",
    type: "text",
    id: "title",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Note",
    type: "text",
    id: "note",
    placeholder: "Enter here",
    required: false,
  },
];

export const LetterForm = [
  {
    label: "Letter",
    type: "text",
    id: "letterData",
    placeholder: "Enter here",
    required: false,
  },
];
