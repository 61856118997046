import { Button } from "@mui/material";
import React from "react";

const SecondaryButton = React.memo<CustomButtonType>((props) => {
  const { label, onClick, disable, className, startIcon, endIcon, variant } =
    props;

  return (
    <Button
      variant={variant ? variant : "outlined"}
      className={`${className}`}
      style={{ borderRadius: "5px" }}
      onClick={onClick}
      color="primary"
      disabled={disable}
      startIcon={startIcon} // Use start icon prop
      endIcon={endIcon} // Use end icon prop
    >
      <b>{label}</b>
    </Button>
  );
});

export default SecondaryButton;
