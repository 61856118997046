import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import EditableBox from "../../adminCenterComponents/EditableBox";
import { updatePatient } from "../../../apis/patients";
import { formatDate } from "../../../util/data.util";
import TestChips from "../TopTests";
import PrimaryButton from "../../buttons/PrimaryButton";
import SelectStaff from "../../select/SelectStaff";
import { useForm, FieldValues } from "react-hook-form";
import {
  TDLRequestDetailCheckbox,
  TDLRequestDetails,
} from "../../../util/labs/tdl";
import TextInputFull from "../../fromComponents/TextInputFull";
import { createTDLTest } from "../../../apis/labs";
import useSnackbar from "../../../hooks/useSnackbar";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../../redux/slice/loaderSlice";
import { Genders } from "../../../util/patient/patient.form.helper";
import { activateSnackbar } from "../../../redux/slice/snackbarSlice";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SUCCESS_PATIENT_UPDATED,
  WENT_WRONG_MSG,
} from "../../../constants/Constants";

const PatientBookTest: React.FC<PatientBookTestProps> = ({
  patient,
  setRequestData,
}) => {
  const [selectedTests, setSelectedTests] = useState<TDLTest[]>([]);
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [selectedStaffId, setSelectedStaffId] = useState<string>();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSubmit = async (data: any) => {
    let obj: any = {
      ...data,
      accountTo: "D",
      isFasting: data.isFasting?.length > 0,
      isGrey: data.isGrey?.length > 0,
      needsKit: data.needsKit?.length > 0,
      needsVenous: data.needsVenous?.length > 0,
      staffPublicId: selectedStaffId,
      patientPublicId: patient?.publicId,
      gender: patient?.gender,
      tests: selectedTests.map((item) => {
        delete item.label;
        return item;
      }),
    };
    dispatch(loading());
    const [response, error] = await createTDLTest(obj);
    if (response) {
      successSnackbar(`Test created successfully.`);
      setRequestData(response);
    } else {
      errorSnackbar("Failed to create the test.");
    }
    dispatch(doneLoading());
  };

  const handlePatientSubmit = async () => {
    let obj = {};

    if (newValues.gender) {
      obj = { ...obj, gender: newValues.gender };
    }
    if (newValues.dateOfBirth) {
      obj = { ...obj, dateOfBirth: newValues.dateOfBirth };
    }
    obj = { ...obj, publicId: patient?.publicId };
    if (obj) {
      const [response, error] = await updatePatient({
        ...obj,
      });
      if (response) {
        if (response) {
          dispatch(
            activateSnackbar({
              active: true,
              message: SUCCESS_PATIENT_UPDATED,
              variant: SNACKBAR_SUCCESS,
            })
          );
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: WENT_WRONG_MSG,
              variant: SNACKBAR_ERROR,
            })
          );
        }
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: error || WENT_WRONG_MSG,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setNewValues((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (!!!patient?.gender || !!!patient?.dateOfBirth) {
      setNeedsUpdate(true);
    } else {
      setNeedsUpdate(false);
    }
  }, [patient]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} mt={3}>
          <Typography variant="h6" mb={2}>
            Book A Test
          </Typography>
        </Grid>
        {patient && (
          <Grid container md={12} margin={3}>
            <Grid item md={6}>
              <EditableBox
                label={"Patient Id"}
                currentValue={patient?.publicId}
                name={""}
              />
              <EditableBox
                label={"Name"}
                currentValue={`${patient.title || ""} ${patient.firstName} ${
                  patient.lastName || ""
                }`}
                name={""}
              />

              <EditableBox
                label={"Address"}
                currentValue={`${
                  patient.address?.street1
                    ? `${patient.address?.street1}, `
                    : ""
                }${patient.address?.city} ${patient.address?.postalCode}`}
                name={""}
              />
            </Grid>
            <Grid item md={6}>
              {!!patient?.gender ? (
                <EditableBox
                  label={"Gender"}
                  currentValue={patient.gender || ""}
                  handleChange={handleChange}
                  newValue={newValues.gender}
                  name={"gender"}
                />
              ) : (
                <div className={`col-sm-10 p-2`}>
                  <div className="row">
                    <FormControl className="col-sm-12 p-0">
                      <InputLabel id="title">Gender</InputLabel>
                      <Select
                        id="string-select-option"
                        labelId={"Gender"}
                        label={"Gender"}
                        name="gender"
                        onChange={handleSelectChange}
                      >
                        {Genders?.map((option: string) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {!!patient?.dateOfBirth || !!patient?.dateOfBirthStr ? (
                <EditableBox
                  label={"Date of Birth"}
                  currentValue={formatDate(
                    patient?.dateOfBirth || patient?.dateOfBirthStr || ""
                  )}
                  handleChange={handleChange}
                  newValue={newValues.dateOfBirth}
                  name={"dateOfBirth"}
                />
              ) : (
                <div className={`col-sm-10 p-2`}>
                  <div className="row">
                    <TextField
                      name="dateOfBirth"
                      label={"Date of Birth"}
                      type={"date"}
                      placeholder={""}
                      required={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
            </Grid>
            {needsUpdate && (
              <PrimaryButton
                label={"Update"}
                type={"button"}
                onClick={handlePatientSubmit}
              />
            )}
          </Grid>
        )}
        {patient && !needsUpdate && (
          <>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <hr />
                <Typography variant="h6">Frequently Selected Tests</Typography>
                <TestChips setSelectedTests={setSelectedTests} />
                <hr />
                <Typography variant="h6">Additional Details</Typography>
                <div className="row">
                  <div className="col-sm-6 p-2">
                    <SelectStaff
                      label={"Attending Consultant"}
                      id="staffPublicId"
                      setValue={(value: string, staffId: string) => {
                        setSelectedStaffId(staffId);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 p-2">
                    <div className="row ms-1">
                      {TDLRequestDetails?.map((item: InputFieldType) => {
                        return (
                          <TextInputFull
                            keyName={`${item.id}`}
                            register={register}
                            placeholder={item.placeholder}
                            id={`${item.id}`}
                            label={item.label}
                            type={item.type}
                            listName={`${item.id}list`}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            boxSize={item.boxSize}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 p-2">
                    <div className="row ms-1">
                      {TDLRequestDetailCheckbox?.map((item: InputFieldType) => {
                        return (
                          <TextInputFull
                            keyName={`${item.id}`}
                            register={register}
                            placeholder={item.placeholder}
                            id={`${item.id}`}
                            label={item.label}
                            type={item.type}
                            listName={`${item.id}list`}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            boxSize={item.boxSize}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <Typography variant="caption">
                  * Note - Any Kit Ordered will be sent directly to{" "}
                  {patient?.title || ""} {patient?.firstName}{" "}
                  {patient?.lastName || ""}
                </Typography>
                <Grid item xs={12} pb={5} pt={3}>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <PrimaryButton label={"submit"} type={"submit"} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default PatientBookTest;
