import React, { useEffect } from "react";
import Dashboard from "../pages/dashboard/Dashboard";
import { LoginPage } from "../pages/login/LoginPage";
import SuperAdminDashboard from "../pages/superAdmin/SuperAdminDashboard";
import AccessControl from "../components/accessControl/AccessControl";
import Setup from "../pages/adminCenter/Setup";
import Layout from "../components/layout/Layout";
import RequireAuth from "./RequireAuth";
import MissingPage from "../pages/missingPage/MissingPage";
import Patients from "../pages/patients/Patients";
import PatientInfo from "../components/patientsModule/PatientInfo";
import KanbanBoard from "../components/kanbanBoard/KanbanBoard";
import Profile from "../pages/profile/Profile";
import Unauthorized from "../pages/unauthorized/unauthorized";
import ResetPassword from "../components/login/ResetPassword";
import TwoFactorAuthQR from "../components/login/TwoFactorAuthQR";
import TwoFactorAuthCode from "../components/login/TwoFactorAuthCode";
import DrawingBoard from "../components/notepad/DrawBoard";
import Labs from "../pages/labs/Labs";

const { Routes, Route, Navigate } = require("react-router-dom");

const RoutesPath: React.FC = React.memo(() => {
  const toolbarOptions: ToolbarOptions = {
    showTouch: true,
    showAddRemoveHeight: true,
    showAddDiagrams: false,
    showColors: true,
    showPencilSize: true,
    showEraserSize: true,
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}

        <Route path="login" element={<LoginPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="login/first-login" element={<ResetPassword />} />
        <Route path="login/two-factor-auth" element={<TwoFactorAuthQR />} />
        <Route path="login/two-factor-auth/:qr" element={<TwoFactorAuthQR />} />
        <Route
          path="login/two-factor-auth-code"
          element={<TwoFactorAuthCode />}
        />
        {/* Protected Routes */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ROLE_SUPERADMIN",
                "ROLE_ADMIN",
                "ROLE_SECRETARY",
                "ROLE_CONSULTANT",
              ]}
            />
          }
        >
          <Route path="/" element={<Navigate to={"dashboard"} />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="patients" element={<Patients />} />
          <Route path="/patients/information/:id" element={<PatientInfo />} />
          <Route path="clinic-board" element={<KanbanBoard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="labs" element={<Labs />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["ROLE_SUPERADMIN"]} />}>
          <Route path="admin-center" element={<Setup />} />
          <Route path="superadmindashboard" element={<SuperAdminDashboard />} />

          <Route path="access-control" element={<AccessControl />} />
        </Route>
        <Route
          path="drawboard"
          element={
            <DrawingBoard
              height={1000}
              width={"100%"}
              toolbarOptions={toolbarOptions}
            />
          }
        />
        {/* Catch All */}
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
});
export default RoutesPath;
