import { StaffTypes, Titles } from "../patient/patient.form.helper";

export const createStaffForm = [
  {
    label: "Title",
    type: "text",
    id: "title",
    placeholder: "Select Title",
    required: true,
    options: Titles,
  },
  {
    label: "First Name",
    type: "text",
    id: "firstName",
    placeholder: "Enter here",
    required: true,
    tag: "input",
  },
  {
    label: "Last Name",
    type: "text",
    id: "lastName",
    placeholder: "",
    required: true,
    tag: "input",
  },

  {
    label: "Email",
    type: "email",
    id: "email",
    placeholder: "",
    required: true,
    tag: "input",
  },
  {
    label: "Phone No",
    type: "text",
    id: "phoneNo",
    placeholder: "Enter here",
    required: true,
    tag: "input",
  },
  {
    label: "Type",
    type: "text",
    id: "type",
    placeholder: "Select staff type",
    required: true,
    // tag: "options",
    options: StaffTypes,
  },
  {
    label: "Staff Wix Id",
    type: "text",
    id: "externalId",
    placeholder: "Enter here",
    required: false,
    tag: "text",
  },
  {
    label: "GMC Number",
    type: "text",
    id: "gmcNumber",
    placeholder: "Enter here",
    required: false,
    tag: "text",
  },
  {
    label: "Signature",
    type: "text",
    id: "signature",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
  },
];

export const createUserForm = [
  {
    label: "Username",
    type: "text",
    id: "username",
    placeholder: "Enter here",
    required: true,
    tag: "input",
  },
  {
    label: "Email",
    type: "email",
    id: "email",
    placeholder: "",
    required: true,
    tag: "input",
  },

  {
    label: "password",
    type: "password",
    id: "password",
    placeholder: "Enter here",
    required: true,
    tag: "input",
  },
];
