import React, { useState, useRef, useEffect } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./image/canvasPreview";
import { useDebounceEffect } from "./image/useDebounceEffect";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import TernaryButton from "../buttons/TernaryButton";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCropper = (props: any) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [fileName, setFileName] = useState<string>();

  const onSelectFile = (e: File) => {
    if (e) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      console.log(e);
      setFileName(e.name);
      reader.readAsDataURL(e);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    if (fileName) {
      previewCanvasRef.current.toBlob(
        (blob) => {
          if (!blob) {
            throw new Error("Failed to create blob");
          }

          const maxFileSizeMB = 15;
          const croppedFile = new File([blob], fileName, {
            type: "image/png",
            lastModified: Date.now(),
          });
          console.log(croppedFile.size / (1024 * 1024));
          if (croppedFile.size > maxFileSizeMB * 1024 * 1024) {
            console.log(
              "Cropped file size exceeds the limit of 2 MB.",
              maxFileSizeMB
            );
            return;
          }
          console.log(croppedFile);
          // Provide the cropped file to the parent component
          props.handleFileUpload(croppedFile);
        },
        "image/png", // Specify the desired file type
        1 // Set the image quality (optional, default is 0.92)
      );
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );
  useEffect(() => {
    onSelectFile(props.image);
  }, [props.image]);
  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      fullWidth
      maxWidth={"lg"}
      sx={{ padding: 3, margin: 3 }}>
      <DialogTitle>Crop Image</DialogTitle>

      <Grid container md={10} p={3} spacing={3}>
        <Grid item md={6}>
          {!!imgSrc && (
            <>
              <Typography>Selected Image</Typography>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                locked={true}>
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                  width="100%"
                />
              </ReactCrop>
            </>
          )}
        </Grid>
        <Grid item md={6}>
          {!!completedCrop && (
            <>
              <Typography>Profile Image</Typography>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width * 0.5,
                  height: completedCrop.height * 0.5,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} p={3} direction={"row-reverse"}>
        <Grid item>
          <Button
            variant="contained"
            disabled={!completedCrop}
            onClick={onDownloadCropClick}>
            Upload
          </Button>
        </Grid>{" "}
        <Grid item>
          <TernaryButton onClick={() => props.setOpen(false)} label="Cancel" />
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default ImageCropper;
