import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { todaysDate } from "../../util/getTodaysDate";
import { objectBuilderForPDF } from "../../util/data.util";
import SecondaryButton from "../buttons/SecondaryButton";
import { TextField } from "@mui/material";
import { downloadGynaeLetter } from "../../util/letterCreation/p4GynaeLetterPdf";
import PrimaryButton from "../buttons/PrimaryButton";
import {
  LETTER_MAX_LENGTH,
  NEED_TO_UPDATE,
  P4_GYNAECARE_LETTER,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";
import {
  LETTER_DOWNLOAD_SUCCESS,
  LETTER_SAVE_FAILED,
  LETTER_SAVE_SUCCESS,
} from "../../constants/SnackbarMessages";
import { loading, doneLoading } from "../../redux/slice/loaderSlice";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { createLetter } from "../../apis/letter";
import CharacterCount from "../fromComponents/CharacterCount";
import { setProgressNotes } from "../../redux/slice/progressNotesSlice";
import AddDownload from "../OptionsComponents/AddDownload";

export const P4GynaeLetter = React.memo(() => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { patient } = patientObject;

  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const { partner } = partnerObject;

  const userObject: any = useSelector<UserState>((state) => state.user);
  const { signature } = userObject.user.staff;
  const [getSignature, setGetSignature] = useState<string | null>(signature);
  const [letterObj, setLetterObj] = useState<P4LetterType | null>();
  const [content, setContent] = useState<string | null>(null);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const progressNotes: any = useSelector<ProgressNotesState>(
    (state) => state.progressNotes
  );
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLetterObj(
      objectBuilderForPDF(patient, partner, {
        content: content,
        letterDate: todaysDate(),
        signature: getSignature,
      })
    );
  };

  const handleDownload = () => {
    if (letterObj) {
      downloadGynaeLetter(letterObj)
        .then(() => {
          dispatch(
            activateSnackbar({
              active: true,
              message: LETTER_DOWNLOAD_SUCCESS,
              variant: SNACKBAR_SUCCESS,
            })
          );
          setIsDisable(true);
          setContent(null);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("first");
    }
  };
  const handleSaveLetter = async () => {
    if (letterObj && content && (signature || letterObj.signature)) {
      dispatch(loading());
      let req: P4LetterRequest = {
        name: P4_GYNAECARE_LETTER,
        patientPublicId: letterObj.patientId,
        signature: letterObj.signature,
        content: content,
        type: P4_GYNAECARE_LETTER,
      };
      const [response, error] = await createLetter(req);
      console.log(response, error);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: LETTER_SAVE_SUCCESS,
            variant: SNACKBAR_SUCCESS,
          })
        );
        dispatch(setProgressNotes([...progressNotes.data, response]));
        setIsDisable(false);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: LETTER_SAVE_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
      dispatch(doneLoading());
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Please provide signature",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleReset = () => {
    setIsDisable(true);
    setLetterObj(null);
    setContent(null);
    setGetSignature(null);
  };

  useEffect(() => {
    if (letterObj) {
      handleSaveLetter();
    }
  }, [letterObj]);
  return isDisable ? (
    <>
      <div className="col-sm-12 ">
        <div className="col-sm-12 p-2">
          <div className="row ms-1">
            <TextField
              className="col-sm-12 p-2"
              label="Write your letter here..."
              sx={{ my: 1 }}
              value={content}
              minRows={10}
              inputProps={{ maxLength: LETTER_MAX_LENGTH }}
              multiline
              required
              onChange={(e) => setContent(e.target.value)}
            />
            {content && (
              <CharacterCount value={content} maxCount={LETTER_MAX_LENGTH} />
            )}
            <TextField
              className="col-sm-12 p-2"
              label="Add signature"
              sx={{ my: 1 }}
              value={getSignature}
              minRows={2}
              multiline
              onChange={(e) => {
                setGetSignature(e.target.value);
              }}
            />
          </div>
        </div>

        <PrimaryButton
          label={"Save"}
          type={"button"}
          disable={content ? false : true}
          onClick={onSubmit}
          className={""}
        />
      </div>
    </>
  ) : (
    <AddDownload onDownload={handleDownload} onReset={handleReset} />
  );
});
