import React, { useEffect, useState } from "react";
import { getAllServices } from "../../apis/services";
import MultiSelect from "../fromComponents/MultiSelect";
import { getAllStaff } from "../../apis/staff";

export interface Service {
  name: string;
  publicId: string;
}

interface SearchServiceMultiProps {
  className?: string;
  label: string;
  id: string;
  setValues: any;
  values: MultiSelectOptionType[];
}

const StaffMultiSelect: React.FC<SearchServiceMultiProps> = ({
  className,
  label,
  id,
  setValues,
  values,
}) => {
  const [search, setSearch] = useState<MultiSelectOptionType[]>([]);

  const getServices = async () => {
    const [response, error] = await getAllStaff();
    if (response) {
      const results = response.map((element: StaffInformation) => ({
        label: `${element.title || ""} ${element.firstName || ""} ${
          element.lastName || ""
        }`,
        publicId: element.publicId,
      }));
      setSearch(results);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <MultiSelect
      label={label}
      options={search}
      value={values}
      setValue={setValues}
      name={""}
      id={""}
    />
  );
};

export default StaffMultiSelect;
