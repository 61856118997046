export const BLUE_COLOR = "rgb(0, 0, 255)";
export const BLACK_COLOR = "rgb(0, 0, 0)";
export const HIGH_REPORT_FLAG_COLOR = "rgb(255, 73, 78)";
export const ELECTRONICALLY_APPROVED = "(Electronicaly approved)";
export const ALIGN_CENTER = "center";
export const ALIGN_LEFT = "left";
export const ALIGN_RIGHT = "right";
export const NAME = "Name: ";
export const ADDRESS = "Address: ";
export const CONTACT = "Contact: ";
export const EMAIL = "Email: ";
export const DATE_OF_BIRTH = "Date of Birth: ";
export const GENDER = "Gender: ";
export const PATIENT_ID = "Patient ID: ";
export const PARTNER_ID = "Partner ID: ";
export const ALLERGIES = "Allergies: ";
export const DATE = "Date: ";

export const PRIVATE_PRESCRIPTION = "PRIVATE PRESCRIPTION";
export const REPORT = "Report";
export const MEDICINE = "Medicine";
export const DIRECTIONS = "Directions";
export const DURATION = "Duration";
export const GMC_NO = "GMC No.: ";
