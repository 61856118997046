import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getAllTeams } from "../../apis/team";

interface SelectInputProps {
  label: string;
  className?: string;
  id?: string;
  setValue?: any;
  defaultValue?: Team;
}

const SelectTeam: React.FC<SelectInputProps> = ({
  id,
  label,
  setValue,
  defaultValue,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState<Team | undefined>(
    defaultValue || undefined
  );

  const [searchTeams, setSearchTeams] = useState<Team[]>([]);
  useEffect(() => {
    setSelectedValue(defaultValue);
    getStaffs();
  }, [defaultValue]);

  const getStaffs = async () => {
    const [response, error] = await getAllTeams();
    if (response) {
      setSearchTeams(response);
    } else {
      console.log(error);
    }
  };
  const handleAutoCompleteChange = (
    event: React.ChangeEvent<{}>,
    newValue: Team | null
  ) => {
    if (newValue) {
      setSelectedValue(newValue);

      if (newValue && id) {
        setValue(id, newValue.publicId);
      }
    } else {
      setSelectedValue(undefined);
    }
  };

  return (
    <Autocomplete
      id="staff-autocomplete"
      className={className}
      options={searchTeams}
      getOptionLabel={(option) => option.name}
      value={selectedValue}
      onChange={handleAutoCompleteChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
    />
  );
};

export default SelectTeam;
