import React from "react";

const SuperAdminDashboard = () => {
  return (
    <div>
      SuperAdmin
      {/* <SideNavBar modules={[]} /> */}
      {/* <AddUser />
      <ShowUsers /> */}
    </div>
  );
};

export default SuperAdminDashboard;
