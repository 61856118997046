import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CapturePhoto from "../photo/CapturePhoto";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faEgg,
  faFilePrescription,
  faFolder,
  faHandHoldingHand,
  faListCheck,
  faMicroscope,
  faNotesMedical,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { FA_ICON_COLOR, FA_ICON_SIZE } from "../../constants/ColorsConstants";
import useFetchPatient from "../../hooks/useFetchPatient";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import {
  AppointmentHistory,
  DonorSperm,
  FirstConsultation,
  FollowUpConsultation,
  FreshCycle,
  FrozenCycle,
  HycosyReport,
  IBSpain,
  KanbanBoard,
  NewFeatureComingSoon,
  Notes,
  P4FertilityLetter,
  P4GynaeLetter,
  PartnerBasicDetails,
  PatientBasicDetails,
  PregnancyReport,
  ProgressNotes,
  ShowAdditionalDocuments,
  UltrasoundReport,
  VerticalMenu,
} from "..";
import {
  getFirstConsultations,
  getFollowUpConsultations,
} from "../../apis/consultation";
import { getLettersByPatientId } from "../../apis/letter";
import { getPatientNotes } from "../../apis/notes";
import { getAllReports } from "../../apis/reports";
import { NOTE, CONSULTATION } from "../../constants/Constants";
import {
  setLoading,
  setProgressNotes,
  needsUpdate,
} from "../../redux/slice/progressNotesSlice";
import ShowPrescriptions from "../prescription/ShowPrescriptions";
import PatientRequestedTests from "../labs/patientLabs/PatientRequestedTests";
import useSnackbar from "../../hooks/useSnackbar";
import { OneStopFertility } from "../reports/one-stop/OneStopFertility";

const PatientInfo = () => {
  const [patient, setPatient] = useState<PatientInformation>();
  const fetchPatientDetails = useFetchPatient();
  const { errorSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const getPatientDetails = async () => {
    if (id) {
      dispatch(loading());
      const [response, error] = await fetchPatientDetails(id, false);

      if (response) {
        setPatient(response);
      } else {
        errorSnackbar(error);
        navigate(`/unauthorized`, {
          replace: true,
        });
      }

      dispatch(doneLoading());
    }
  };

  const getReports = async () => {
    if (id) {
      dispatch(setLoading(true));
      const letters = await getAllLetters();

      const firstConsultations = await getFirstConsultation();
      const followUpConsultations = await getFollowUpConsultation();
      const notes = await getNotes();
      const [response, error] = await getAllReports(id);
      if (
        response &&
        letters &&
        firstConsultations &&
        followUpConsultations &&
        notes
      ) {
        let data: any[] = [];

        response.forEach((row: any) => {
          data.push({ ...row });
        });
        const combined = [
          ...letters,
          ...data,
          ...firstConsultations,
          ...followUpConsultations,
          ...notes,
        ];
        dispatch(setProgressNotes(combined));
        dispatch(setLoading(false));
        dispatch(needsUpdate(false));
      } else {
        alert(error);
      }
    }
  };

  const getNotes = async () => {
    if (id) {
      const [response, error] = await getPatientNotes(id);
      if (response) {
        let data: any[] = [];

        response.forEach((row: CreateNote, index: number) => {
          data.push({
            name: row.title,
            type: NOTE,
            ...row,
          });
        });
        return data;
      } else {
        console.log(error);
      }
      return [];
    }
  };
  const getFirstConsultation = async () => {
    if (id) {
      const [response, error] = await getFirstConsultations(id);
      if (response) {
        let data: any[] = [];

        response.forEach((row: any, index: number) => {
          data.push({
            name: `First Consultation`,
            type: CONSULTATION,
            ...row,
          });
        });

        return data;
      } else {
        console.log(error);
      }
      return [];
    }
  };

  const getFollowUpConsultation = async () => {
    if (id) {
      const [response, error] = await getFollowUpConsultations(id);
      if (response) {
        let data: any[] = [];

        response.forEach((row: any, index: number) => {
          data.push({
            name: `Follow Up Consultation`,
            type: CONSULTATION,
            ...row,
          });
        });
        // setProgressNotes((prev: any) => [...prev, ...data]);
        return data;
      } else {
        console.log(error);
      }
      return [];
    }
  };
  const getAllLetters = async () => {
    if (id) {
      const [response, error] = await getLettersByPatientId(id);
      if (response) {
        let data: any[] = [];

        response.forEach((row: any, index: number) => {
          data.push({
            name: row.name,
            ...row,
          });
        });
        return data;
      } else {
        console.log(error);
      }
      return [];
    }
  };

  useEffect(() => {
    getPatientDetails();
    getReports();
  }, []);

  const verticalMenuOptions: VerticalMenuOption[] = [
    {
      label: "Medical Notes",
      icon: (
        <FontAwesomeIcon
          icon={faNotesMedical}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      children: [
        {
          label: "First Consultation",
          component: <FirstConsultation />,
        },

        {
          label: "Follow Up Consultation",
          component: <FollowUpConsultation />,
        },
        {
          label: "Reports",
          children: [
            {
              label: "Ultrasound Notes",
              component: <UltrasoundReport />,
            },
            {
              label: "Hycosy Notes",
              component: <HycosyReport />,
            },

            {
              label: "Pregnancy Notes",
              component: <PregnancyReport />,
            },

            {
              label: "One Stop Notes",
              component: <OneStopFertility />,
            },
          ],
        },
        {
          label: "Protocols",
          children: [
            {
              label: "Fresh Cycle",
              component: <FreshCycle />,
            },
            {
              label: "Frozen Cycle",
              component: <FrozenCycle />,
            },
            {
              label: "Donor Sperm",
              component: <DonorSperm />,
            },
            {
              label: "IB Spain",
              component: <IBSpain />,
            },
          ],
        },
        {
          label: "Theatre/Procedure Notes",
          // component: <ProcedureNotes />,
          component: <NewFeatureComingSoon />,
        },
      ],
    },
    {
      label: "Progress notes",
      icon: (
        <FontAwesomeIcon
          icon={faListCheck}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <ProgressNotes />,
    },
    {
      label: "Appointments",
      icon: (
        <FontAwesomeIcon
          icon={faCalendarCheck}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <AppointmentHistory />,
    },
    {
      label: "Labs",
      icon: (
        <FontAwesomeIcon
          icon={faMicroscope}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <PatientRequestedTests />,
    },

    {
      label: "Nursing Notes",
      icon: (
        <FontAwesomeIcon
          icon={faUserNurse}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <NewFeatureComingSoon />,
      // children: [
      //   {
      //     label: "Baseline Scan",
      //     component: <BaselineScan />,
      //   },

      //   {
      //     label: "Monitoring",
      //     component: <Monitoring />,
      //   },

      //   {
      //     label: "Checklist and Forms",
      //     component: <ChecklistAndForms />,
      //   },
      // ],
    },

    {
      label: "Embryology Notes",
      icon: (
        <FontAwesomeIcon
          icon={faEgg}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <NewFeatureComingSoon />,
      // component: <EmbryologyNotes />,
    },

    {
      label: "Counsellor",
      icon: (
        <FontAwesomeIcon
          icon={faHandHoldingHand}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: <NewFeatureComingSoon />,
      // component: <CounsellorNotes />,
    },
    {
      label: "Letter Writing",
      icon: <HistoryEduOutlinedIcon />,
      children: [
        {
          label: "P4 Fertility",
          component: <P4FertilityLetter />,
        },

        {
          label: "P4 Gynae",
          component: <P4GynaeLetter />,
        },
      ],
    },
    {
      label: "Prescriptions",
      icon: (
        <FontAwesomeIcon
          icon={faFilePrescription}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      // component: <NewFeatureComingSoon />,
      component: <ShowPrescriptions />,
    },
    {
      label: "Documents",
      icon: (
        <FontAwesomeIcon
          icon={faFolder}
          size={FA_ICON_SIZE}
          style={{ color: FA_ICON_COLOR }}
        />
      ),
      component: (
        <ShowAdditionalDocuments
          tableButtonLabel="Upload Documents"
          patientPublicId={patient?.publicId}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={2} padding={2}>
      <Notes />
      {patient ? (
        <>
          {/* <CapturePhoto /> */}
          {/* <PencilInput /> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PatientBasicDetails />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PartnerBasicDetails />
          </Grid>
          {/* {showDetails && (
            <Grid item xs={12} sm={12} md={12}>
              <AccordionComponent />
            </Grid>
          )} */}
          {/* <AccordionComponent /> */}
          <Grid item xs={12} sm={12} md={12}>
            <KanbanBoard patientId={id} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <VerticalMenu verticalMenuOptions={verticalMenuOptions} />
          </Grid>{" "}
        </>
      ) : (
        <Grid item xs={12}></Grid>
      )}
    </Grid>
  );
};

export default PatientInfo;
