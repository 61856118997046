import React from "react";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { Tooltip } from "@mui/material";

const ActionArchive = React.memo<ActionClickIconProps>(({ handleClick }) => {
  return (
    <Tooltip title="Archive" placement="top">
      <ArchiveOutlinedIcon
        style={{ margin: 3, cursor: "pointer" }}
        className="icon-hover"
        onClick={handleClick}
        color="primary"
      />
    </Tooltip>
  );
});

export default ActionArchive;
