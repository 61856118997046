import React from "react";
import VerticalMenu from "../../components/menus/VerticalMenu";
import { Grid } from "@mui/material";
import ShowStaffs from "../../components/staff/ShowStaffs";
import ShowPathways from "../../components/pathway/ShowPathways";
import ShowLocations from "../../components/location/ShowLocations";
import ShowSites from "../../components/site/ShowSites";
import ShowTeams from "../../components/teams/ShowTeams";
import ShowUsers from "../../components/users/ShowUsers";
import Files from "../../components/files/Files";
import WebIcon from "@mui/icons-material/Web";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import MedicationLiquidOutlinedIcon from "@mui/icons-material/MedicationLiquidOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faUpload,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FA_ICON_SIZE } from "../../constants/ColorsConstants";
import ShowServices from "../../components/services/ShowServices";
import ShowSupplements from "../../components/supplements/ShowSupplements";
import CreateWIXRequest from "../../components/wix/CreateWIXRequest";
import ShowNotepadImages from "../../components/notpadImages/ShowNotepadImages";
import { PermMediaOutlined } from "@mui/icons-material";
import ShowAdditionalDocuments from "../../components/additionalDocuments/ShowAdditionalDocuments";
import { NOTEPAD_IMAGES_CONTAINER } from "../../constants/ContainerConstants";
const Setup = () => {
  const verticalMenuOptions: VerticalMenuOption[] = [
    {
      label: "Sites",
      icon: <WebIcon />,
      component: <ShowSites />,
    },
    {
      label: "Locations",
      icon: <LocationOnOutlinedIcon />,
      component: <ShowLocations />,
    },
    {
      label: "Users",
      icon: <FontAwesomeIcon icon={faUsers} size={FA_ICON_SIZE} />,
      component: <ShowUsers />,
    },

    {
      label: "Staffs",
      icon: <AssignmentIndOutlinedIcon />,
      component: <ShowStaffs />,
    },

    {
      label: "Teams",
      icon: <Diversity1OutlinedIcon />,
      component: <ShowTeams />,
    },
    {
      label: "Pathways",
      icon: <AltRouteOutlinedIcon />,
      component: <ShowPathways />,
    },

    {
      label: "Services",
      icon: <MedicalServicesOutlinedIcon />,
      component: <ShowServices />,
      // services contain pricing plan
    },
    {
      label: "Supplements",
      icon: <MedicationLiquidOutlinedIcon />,
      component: <ShowSupplements />,
    },
    // {
    //   label: "Notepad Images",
    //   icon: <PermMediaOutlined />,
    //   component: (
    //     <ShowAdditionalDocuments
    //       tableButtonLabel={"Upload Images"}
    //       documentType={NOTEPAD_IMAGES_CONTAINER}
    //     />
    //   ),
    // },

    {
      label: "WIX",
      icon: <FontAwesomeIcon icon={faCalendarCheck} size={FA_ICON_SIZE} />,
      component: <CreateWIXRequest />,
    },
  ];
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} sm={12} md={12}>
        <VerticalMenu verticalMenuOptions={verticalMenuOptions} />
      </Grid>
    </Grid>
  );
};

export default Setup;
