import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchStaff from "../search/SearchStaff";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SITE_ADDED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import { updateSite } from "../../apis/site";
import TernaryButton from "../buttons/TernaryButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditableBox from "../adminCenterComponents/EditableBox";
import EditableTitleBox from "../adminCenterComponents/EditableTitleBox";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import { deleteTeam } from "../../apis/team";
import {
  TEAM_DELETE_FAILED,
  TEAM_DELETE_SUCCESS,
} from "../../constants/SnackbarMessages";
interface TeamDetailsProps {
  selectedTeam: Team;
  setShowTable: (value: boolean) => void;
  reload: Boolean;
  setReload: (value: boolean) => void;
}

const ShowTeamDetails: React.FC<TeamDetailsProps> = ({
  selectedTeam,
  setShowTable,
  reload,
  setReload,
}) => {
  const [editing, setEditing] = useState(false);
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditing(true);
    setNewValues(selectedTeam);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = async () => {
    console.log(newValues);
    // if (selectedStaff || ownerStaff) {
    //   const updatedSite: UpdateSiteRequest = {
    //     ...staffData,
    //     name,
    //     ownerStaffPublicId: selectedStaff?.publicId
    //       ? selectedStaff?.publicId
    //       : ownerStaff.publicId,
    //   };
    //   const [response, error] = await updateSite(updatedSite);
    //   if (response) {
    //     dispatch(
    //       activateSnackbar({
    //         active: true,
    //         message: SITE_ADDED,
    //         variant: SNACKBAR_SUCCESS,
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       activateSnackbar({
    //         active: true,
    //         message: WENT_WRONG_MSG,
    //         variant: SNACKBAR_ERROR,
    //       })
    //     );
    //   }
    //   setEditing(false);
    // } else {
    //   dispatch(
    //     activateSnackbar({
    //       active: true,
    //       message: "Please select a staff",
    //       variant: SNACKBAR_ERROR,
    //     })
    //   );
    // }
  };

  const handleDelete = async () => {
    const [response, error] = await deleteTeam(selectedTeam.publicId);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: TEAM_DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: TEAM_DELETE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
    // setstaffData(null);
    setEditing(false);
  };
  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <EditableTitleBox
        editing={false}
        label={""}
        handleChange={handleChange}
        newValue={""}
        currentValue={selectedTeam.name}
        handleEdit={undefined}
        openConfirmModal={openConfirmModal}
        name={"name"}
      />

      <EditableBox
        editing={editing}
        label={"Team Leader"}
        currentValue={`${selectedTeam.teamLeader.title} ${selectedTeam.teamLeader.firstName} ${selectedTeam.teamLeader.lastName}`}
        name={"teamLeader"}
      />

      <EditableBox
        editing={editing}
        label={"Team Members"}
        currentValue={""}
        name={"teamMembers"}
      />
      {selectedTeam.teamMembers.map((member, index) => (
        <EditableBox
          editing={editing}
          label={""}
          currentValue={`${member.title} ${member.firstName} ${member.lastName}`}
          name={"teamMember" + index}
        />
      ))}

      <EditableBox
        editing={editing}
        label={"Location"}
        currentValue={selectedTeam.location.name}
        name={"location"}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing ? (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>
      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete team '${selectedTeam.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default ShowTeamDetails;
