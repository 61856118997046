import jsPDF from "jspdf";
import {
  addCreatedDate,
  addFooter,
  addHeader,
  addPatientInfo,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";
//created by Ranjit Jeve

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 8;
const textFontPoint = 11;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10; //
const drNamePosition = 40; // globalPosition + drNamePosition
const pregnancyReportTitle = "Pregnancy Scan Report";

const pregnancyTextPara =
  "Chaperone present. Patient verbally consented. Probe cleaned with Tristel Duo.";
const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
export const downloadPregnancyReport = async (
  patientData: PregnancyScanType
) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  addReportTitle(doc, pregnancyReportTitle);
  addPregnancyReportData(doc, patientData, pregnancyTextPara);
  addSignature(doc, patientData.signature);

  doc.save(
    "R_Pregnancy_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );

  globalPosition = 10;
  return;
};

// const addHeader = (doc: any) => {
//   doc.addImage(headerImage, "PNG", 10, 10, 30, 15);
//   doc.addImage(logo, "PNG", 140, 5, 60, 30);
// };

// const addPatientInfo = (
//   doc: any,
//   patientData: PregnancyScanType | UltrasoundScanType | HycosyScanType
// ) => {
//   getGlobalPosition(25);

//   doc
//     .setFontSize(textFontPoint)
//     .text(
//       "Name of Patient: " + patientData.patientName,
//       marginLeft,
//       globalPosition
//     )
//     .text(
//       "Date of Birth: " + patientData.patientDOB,
//       marginLeft + 120,
//       globalPosition
//     )

//     .text(
//       "Mobile Number: " + patientData.mobileNumber,
//       marginLeft,
//       getGlobalPosition(spaceBetween)
//     )
//     .text(
//       "Report Date: " + todaysDate(),
//       marginLeft + 120,
//       getGlobalPosition(0)
//     )
//     .text(
//       "Address: " + patientData.patientAddress,
//       marginLeft,
//       getGlobalPosition(spaceBetween)
//     );

//   generateHr(doc, getGlobalPosition(spaceBetween));
// };

const addReportTitle = (doc: any, title: string) => {
  doc
    .setFontSize(reportTitlePoint)
    .text(title, marginLeft + 57, getGlobalPosition(spaceBetween + 5));
};

const addPregnancyReportData = (
  doc: any,
  patientData: PregnancyScanType,
  textPara: string
) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "•  Gestational age by date: " + patientData.gestationalAgeByDate,
      marginLeft,
      getGlobalPosition(commonSpaceBetween + 5)
    )

    .text(`•  ${textPara}`, marginLeft, getGlobalPosition(commonSpaceBetween), {
      width: 460,
      lineGap: 7,
      align: "justify",
    })
    .text(
      "•  Gestational Sac: " + patientData.gestationalSac,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  CRL: " + patientData.crl,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Fetal Heart Beats: " + patientData.fetalHeartBeats,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Additional Comments: " + patientData.additionalComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
};

const addSignature = (doc: any, drName: string) => {
  doc
    .text(drName, marginLeft, getGlobalPosition(drNamePosition))
    .text(
      "(Electronically approved)",
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    );
};

// const addFooter = (doc: any) => {
//   doc.addImage(headerImage, "PNG", 160, 270, 30, 15);
// };
// function to generate a line
// const generateHr = (doc: any, y: number) => {
//   doc.line(marginLeft, y, 195, y, "S");
// };

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};
