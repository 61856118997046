import { AZURE_URL } from "../constants/env-constants";

export const triggerWixFetchCall = async (startDate: Date, toDate: Date) => {
  try {
    const response = await AZURE_URL.post(`/booking/loadWixBookings`, {
      startDate: startDate,
      toDate: toDate,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getAppointmentsWithDate = async (formData: {
  fromDate: string;
  toDate: string;
}) => {
  try {
    const response = await AZURE_URL.get("/booking/get", { params: formData });
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAppointmentByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`/booking/get?patientPublicId=${id}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};
