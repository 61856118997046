import jsPDF from "jspdf";
import {
  ALIGN_LEFT,
  BLACK_COLOR,
  BLUE_COLOR,
} from "../../constants/jspdf.constants";

import {
  addFooter,
  addHeader,
  addPatientInfo,
  addTitle,
  addSignature,
  commonSpaceBetween,
  getTextHeight,
  marginLeft,
  textFontPoint,
  addCreatedDate,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";

//created by Ranjit Jeve

let globalPosition = 10;

const ReportTitle =
  "Referral letter for IVF treatment using donor gamete at Instituto Bernabeu S.L";

const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
const text = "Please feel free to contact us for any further questions.";

export const downloadIBSpainCycleProtocol = async (patientData: IBSpain) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  globalPosition = addTitle(doc, ReportTitle, globalPosition);

  ibSpainCycleProtocolData(doc, patientData);
  addSignature(
    doc,
    patientData.signature,
    globalPosition + commonSpaceBetween * 3
  );
  // addProtocolInfo(doc, clinicAddress, email, phone);
  doc.save(
    "RL_IBSpain_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );
  globalPosition = 10;
  return;
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};

//report specific functions

const ibSpainCycleProtocolData = (doc: any, patientData: IBSpain) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "Dear Medical Team,",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )

    .text(
      "•    Reason for Referral: " + patientData.reasonForReferral,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )

    .text(
      "•    Previous Cycles and Outcome: " +
        patientData.previousCycleAndOutcome,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "•    Allergy: " + patientData.allergies,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "•    Need for Mammogram, ECG and measles testing: Not required clinically, please sign waiver",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "•    Sperm Analysis: " + patientData.spermAnalysis,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "•    Medical History: " + patientData.medicalHistory,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "left" }
    )

    .text(
      "If you require any additional information or have any questions or concerns, please do not hesitate to contact us.",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 12),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "Please note that monitoring scans in the UK can be booked through P4 Fertility by visiting their website at ",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" },
      null
    )
    .setTextColor(BLUE_COLOR)
    .textWithLink(
      "www.p4fertility.co.uk",
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      ALIGN_LEFT,
      "www.p4fertility.co.uk"
    );
  doc
    .setTextColor(BLACK_COLOR)
    .text(
      ". Please guid patient to book an appointment for monitoring and pregnancy scan using p4fertility",
      marginLeft + 33,
      getGlobalPosition(0),
      { maxWidth: 150, align: "left" },
      null
    )
    .text(
      "service. All correspondence should be emailed to secretary on above email address.",
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 180, align: "left" },
      null
    )
    .text(
      "Yours Sincerely.",
      marginLeft,
      getTextHeight(
        doc,
        globalPosition + commonSpaceBetween * 2,
        text,
        clinicAddress,
        globalPosition + commonSpaceBetween * 2,
        email,
        phone
      )
    );
};
