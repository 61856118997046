import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  FemaleFertilityDetailsForm,
  FertilityDetails,
  FertilityLivingChildren,
  FertilityTryingSince,
  MaleFertilityDetailsForm,
} from "../../util/patient/firstConsultation.form.helper";
import TextInputFull from "../fromComponents/TextInputFull";
import TextInput from "../fromComponents/TextInput";
import PrimaryButton from "../buttons/PrimaryButton";
import { useSelector } from "react-redux";
import { FEMALE } from "../../constants/Constants";
import { FormDialogBox } from "../dialogBoxes/FormDialogBox";
import { NewPatientFormInputs } from "../../util/patient/patient.form.helper";
import { removeEmptyValues } from "../../util/data.util";
import { CreateOutlined, KeyboardOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notepad from "../notepad/CustomTLDraw";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import useCreateJsonFile from "../../hooks/useCreateJsonFile";

const FertilityDetailsComponent: React.FC<FertilityDetailsProps> = ({
  details,
  setDetails,
  defaultTemplateUrl,
  setExpandedAccordion,
}) => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const {
    publicId: patientPublicId,
    partnerPublicId,
    gender: patientGender,
  } = patientObject.patient;
  const { gender: partnerGender } = partnerObject.partner;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [patientForm, setPatientForm] = useState<InputFieldType[]>();
  const [partnerForm, setPartnerForm] = useState<InputFieldType[]>();
  const [commonData, setCommonData] = useState<string>();
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { extractJson } = useCreateJsonFile();
  const persistenceKey = patientPublicId;

  const handleDownload = async () => {
    const persistedSnapshot = localStorage.getItem(persistenceKey);
    if (persistedSnapshot === null) {
      console.log("downloading json");
      const [data, error] = await extractJson(defaultTemplateUrl);
      if (data) {
        localStorage.setItem(persistenceKey, JSON.stringify(data));
        setIsLoading(false);
      }
    } else {
      console.log("already preset");
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const formData = data as FirstConsultation;
    setDetails(removeEmptyValues(formData));
    sessionStorage.setItem(
      `fertilityDetails-${persistenceKey}`,
      JSON.stringify(removeEmptyValues(formData))
    );
    setExpandedAccordion("prev-treatment-accordion");
  };

  useEffect(() => {
    if (patientGender === FEMALE) {
      setPatientForm(FemaleFertilityDetailsForm);
      setCommonData("patient");
    } else {
      setPatientForm(MaleFertilityDetailsForm);
    }

    if (partnerGender === FEMALE) {
      setPartnerForm(FemaleFertilityDetailsForm);
      setCommonData("partner");
    } else {
      setPartnerForm(MaleFertilityDetailsForm);
    }
    handleDownload();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <div>Loading SVG...</div>
      ) : (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                onClick={() => setIsWriting((prev) => !prev)}
                aria-label="Toggle Writing Mode"
                color="primary"
              >
                {isWriting ? <KeyboardOutlined /> : <CreateOutlined />}
              </IconButton>
              {isWriting && (
                <IconButton
                  size="medium"
                  aria-label="Toggle Full Screen"
                  color="primary"
                  onClick={() => {
                    setIsFullScreen(!isFullScreen);
                    sessionStorage.setItem(
                      "isFullScreenActive",
                      JSON.stringify(true)
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={isFullScreen ? faCompress : faExpand}
                  />
                </IconButton>
              )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isWriting ? (
                <>
                  <div
                    style={{
                      background: "linear-gradient(#f5f5f5, #eaeaea)",

                      padding: "20px",
                      marginBottom: "20px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "5px",
                      position: isFullScreen ? "fixed" : "relative",
                      height: isFullScreen ? "" : "85vh",
                      width: isFullScreen ? "" : "auto",
                      inset: isFullScreen ? "0" : "",
                      zIndex: isFullScreen ? "6" : "0",
                    }}
                  >
                    {isFullScreen && (
                      <div
                        style={{
                          display: "flex", // Add this line to make the content flexbox
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          size="medium"
                          aria-label="Toggle Full Screen"
                          color="primary"
                          onClick={() => {
                            setIsFullScreen(!isFullScreen);
                            sessionStorage.setItem(
                              "isFullScreenActive",
                              JSON.stringify(true)
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faCompress} />
                        </IconButton>
                      </div>
                    )}
                    <Notepad persistenceKey={persistenceKey} />
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-sm-6 p-2">
                    <Typography variant="h6" gutterBottom>
                      Patient
                    </Typography>
                    <div className="row ms-1">
                      <div className=" d-flex align-items-center col-sm-3">
                        <Typography>Trying Since</Typography>
                      </div>
                      {FertilityTryingSince?.map((item: InputFieldType) => {
                        return (
                          <TextInput
                            keyName={"patient." + item.id}
                            register={register}
                            placeholder={item.placeholder}
                            id={"patient." + item.id}
                            label={item.label}
                            type={item.type}
                            listName={"patient." + item.id + "list"}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            disabled={patientGender === FEMALE ? false : true}
                            boxSize={item.boxSize}
                          />
                        );
                        // }
                      })}
                    </div>
                    <div className="row ms-1">
                      <div className=" d-flex align-items-center col-sm-3">
                        <Typography>Living Children</Typography>
                      </div>
                      {FertilityLivingChildren?.map((item: InputFieldType) => {
                        return (
                          <TextInput
                            keyName={"patient." + item.id}
                            register={register}
                            placeholder={item.placeholder}
                            id={"patient." + item.id}
                            label={item.label}
                            type={item.type}
                            listName={"patient." + item.id + "list"}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            disabled={patientGender === FEMALE ? false : true}
                            boxSize={item.boxSize}
                          />
                        );
                      })}
                    </div>
                    <div className="row ms-1">
                      {patientForm?.map((item: InputFieldType) => {
                        return (
                          <TextInputFull
                            keyName={"patient." + item.id}
                            register={register}
                            placeholder={item.placeholder}
                            id={"patient." + item.id}
                            label={item.label}
                            type={item.type}
                            listName={"patient." + item.id + "list"}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            disabled={item.disabled}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {partnerPublicId ? (
                    <div className="col-sm-6 p-2">
                      <Typography variant="h6" gutterBottom>
                        Partner
                      </Typography>

                      <div className="row ms-1">
                        <div className=" d-flex align-items-center col-sm-3">
                          <Typography>Trying Since</Typography>
                        </div>
                        {FertilityTryingSince?.map((item: InputFieldType) => {
                          return (
                            <TextInput
                              keyName={"partner." + item.id}
                              register={register}
                              placeholder={item.placeholder}
                              id={"partner." + item.id}
                              label={item.label}
                              type={item.type}
                              listName={"partner." + item.id + "list"}
                              options={item.options}
                              required={item.required}
                              tag={item.tag}
                              maxLength={item.maxLength}
                              minRows={item.minRows}
                              checkboxOptions={item.checkboxOptions}
                              setValue={setValue}
                              unregister={unregister}
                              actionOn={item.actionOn}
                              radioOptions={item.radioOptions}
                              children={item.children}
                              disabled={partnerGender === FEMALE ? false : true}
                              boxSize={item.boxSize}
                            />
                          );
                          // }
                        })}
                      </div>
                      <div className="row ms-1">
                        <div className=" d-flex align-items-center col-sm-3">
                          <Typography>Living Children</Typography>
                        </div>
                        {FertilityLivingChildren?.map(
                          (item: InputFieldType) => {
                            return (
                              <TextInput
                                keyName={"partner." + item.id}
                                register={register}
                                placeholder={item.placeholder}
                                id={"partner." + item.id}
                                label={item.label}
                                type={item.type}
                                listName={"partner." + item.id + "list"}
                                options={item.options}
                                required={item.required}
                                tag={item.tag}
                                maxLength={item.maxLength}
                                minRows={item.minRows}
                                checkboxOptions={item.checkboxOptions}
                                setValue={setValue}
                                unregister={unregister}
                                actionOn={item.actionOn}
                                radioOptions={item.radioOptions}
                                children={item.children}
                                disabled={item.disabled}
                                boxSize={item.boxSize}
                              />
                            );
                            // }
                          }
                        )}
                      </div>
                      <div className="row ms-1">
                        {partnerForm?.map((item: InputFieldType) => {
                          return (
                            <TextInputFull
                              keyName={"partner." + item.id}
                              register={register}
                              placeholder={item.placeholder}
                              id={"partner." + item.id}
                              label={item.label}
                              type={item.type}
                              listName={"partner." + item.id + "list"}
                              options={item.options}
                              required={item.required}
                              tag={item.tag}
                              maxLength={item.maxLength}
                              minRows={item.minRows}
                              checkboxOptions={item.checkboxOptions}
                              setValue={setValue}
                              unregister={unregister}
                              actionOn={item.actionOn}
                              radioOptions={item.radioOptions}
                              children={item.children}
                              disabled={item.disabled}
                            />
                          );
                          // }
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-6 text-center">
                      <div className="p-2 mx-auto">
                        <PrimaryButton
                          className="btn btn-primary"
                          type={"button"}
                          label={"Add Partner"}
                          onClick={() => setIsOpen(true)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <hr />
              <div className="row">
                <div className="col-sm-12 p-2">
                  <div className="row ms-1">
                    {FertilityDetails?.map((item: InputFieldType) => {
                      return (
                        <TextInputFull
                          keyName={`${commonData}.${item.id}`}
                          register={register}
                          placeholder={item.placeholder}
                          id={`${commonData}.${item.id}`}
                          label={item.label}
                          type={item.type}
                          listName={`${commonData}.${item.id}list`}
                          options={item.options}
                          required={item.required}
                          tag={item.tag}
                          maxLength={item.maxLength}
                          minRows={item.minRows}
                          checkboxOptions={item.checkboxOptions}
                          setValue={setValue}
                          unregister={unregister}
                          actionOn={item.actionOn}
                          radioOptions={item.radioOptions}
                          children={item.children}
                          boxSize={item.boxSize}
                        />
                      );
                      // }
                    })}
                  </div>
                </div>
              </div>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <PrimaryButton
                    label={"Next"}
                    type={"submit"}
                    className={""}
                  />
                </Grid>
              </Grid>
            </form>
            {isOpen && (
              <FormDialogBox
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={"Partner Information"}
                formFields={NewPatientFormInputs}
                width={"xl"}
              />
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default FertilityDetailsComponent;
