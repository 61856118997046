import React, { useState } from "react";
import PasswordInput from "../password/PasswordInput";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import logo from "../../assets/logo/P4FertilityLogo_Full.png";
import { firstPasswordReset, loginUser, preLoginCheck } from "../../apis/auth";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  LOGIN_FAILED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";
import {
  PASSWORD_UPDATE_FAILED,
  PASSWORD_UPDATE_SUCCESS,
} from "../../constants/SnackbarMessages";
import { useNavigate } from "react-router-dom";
import { loginDetails, removeLoginDetails } from "../../redux/slice/loginSlice";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import { addUserCredentials } from "../../redux/slice/userSlice";
import { getUserDetails } from "../../apis/users";
import { login } from "../../redux/slice/authSlice";
const theme = createTheme();

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const loginObject: any = useSelector<Login>((state) => state.login);
  const clientObject: any = useSelector<SwivoClient>((state) => state.client);
  const { username, twoFactorReset, passwordReset } = loginObject.login;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dispatch(doneLoading());
  const handleUpdatePassword = async () => {
    if (username) {
      dispatch(loading());
      let obj: FirstPasswordReset = {
        clientId: clientObject.client.clientId,
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
        twoFactorReset: twoFactorReset,
      };
      const [response, error] = await firstPasswordReset(obj);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: PASSWORD_UPDATE_SUCCESS,
            variant: SNACKBAR_SUCCESS,
          })
        );
        dispatch(
          loginDetails({
            username: username,
            password: newPassword,
            passwordReset: passwordReset,
            twoFactorReset: twoFactorReset,
          })
        );

        if (response) {
          if (response.data) {
            navigate(`/login/two-factor-auth`, {
              state: { qrCode: response.data },
              replace: true,
            });
          } else {
            getToken(username, newPassword);
          }
        } else {
          dispatch(doneLoading());
          navigate(`/login`, {
            replace: true,
          });
        }
      } else {
        dispatch(doneLoading());
        dispatch(
          activateSnackbar({
            active: true,
            message: error || PASSWORD_UPDATE_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const getToken = async (username: string, password: string) => {
    const [res, error] = await loginUser({
      clientId: clientObject.client.clientId,
      username: username,
      password: password,
    });
    if (res) {
      dispatch(login(res));
      let { data } = await getUserDetails();

      let userRoles: string[] = [];
      for (const role of data.roles) {
        userRoles.push(role.name);
      }
      data.roles = userRoles;
      if (data.firstLogin) {
      }
      dispatch(addUserCredentials(data));
      dispatch(removeLoginDetails());
      dispatch(doneLoading());
      navigate("/dashboard", { replace: true });
    } else {
      dispatch(doneLoading());
      dispatch(
        activateSnackbar({
          active: true,
          message: error || LOGIN_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            marginBottom={3}
            style={{ textAlign: "center" }}
          >
            <img alt="logo" src={logo} width={"75%"} />
          </Typography>
          <Typography>
            You are logging in for the first time. Please change your password.
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <PasswordInput
              label="New Password"
              setOldPassword={setOldPassword}
              setNewPassword={setNewPassword}
            />
            <Button
              type="button"
              onClick={handleUpdatePassword}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update Password
            </Button>
          </Box>{" "}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
