export const ACCESS_TOKEN = "";
export const IS_ALLOWED = "is_allowed";
export const FILE = "file";
export const FILE_NAME = "fileName";
export const CONTAINER_NAME = "containerName";
export const REPORT_CONTAINER = "ReportContainer";
export const URL = "url";
export const TOKEN_TYPE = "tokenType";
export const TOKEN = "token";
export const HYCOSY = "hycosy";
export const PREGNANCY_SCAN = "pregnancy_scan";
export const ULTRASOUND_SCAN = "ultrasound_scan";
export const ONE_STOP_FERTILITY = "one_stop_fertility";
export const PROTOCOL_DONOR_SPERM = "protocol_donor_sperm";
export const PROTOCOL_IBSPAIN = "protocol_ib_spain";
export const PROTOCOL_FROZEN_CYCLE = "protocol_frozen_cycle";
export const PROTOCOL_FRESH_CYCLE = "protocol_fresh_cycle";
export const MALE = "Male";
export const FEMALE = "Female";
export const OTHER = "Other";
export const YES = "Yes";
export const NO = "No";
export const PLACEHOLDER_VALUE = "Enter here";
export const STATUS_200 = 200;
export const SUCCESS = "Success";
export const ERROR = "Error";
export const WENT_WRONG_MSG = "Something went wrong";
export const SUCCESS_PATIENT_UPDATED = "Patient information updated";
export const PARTNER_ADDED = "Partner added";
export const SNACKBAR_SUCCESS = "success";
export const SNACKBAR_ERROR = "error";
export const SNACKBAR_INFO = "info";
export const SNACKBAR_WARNING = "warning";
export const CONSULTATION = "Consultation";
export const STAFF_ADDED = "Staff successfully added";
export const USER_ADDED = "User successfully added";
export const SITE_ADDED = "Site successfully added";
export const USER_DELETED = "User deleted";
export const PATIENT_ADDED_SUCCESSFULLY = "Patient added successfully";
export const FILE_UPLOADED_SUCCESSFULLY = "File uploaded successfully";
export const FILES_UPLOADED_SUCCESSFULLY = "Files uploaded successfully";
export const FILE_UPLOAD_FAILED = "File upload failed";
export const MAXIMUM_UPLOAD_SIZE_EXCEEDED = "Maximum upload size exceeded";
export const FILE_DELETED_SUCCESSFULLY = "File deleted successfully";
export const FILE_DELETE_FAILED = "Failed to delete file";
export const FILE_NOT_FOUND = "File not found";
export const FILE_DOWNLOADED_SUCCESSFULLY = "File downloaded successfully";
export const NOTE_ADDED_SUCCESSFULLY = "Note added successfully";
export const NOTE_FAILED = "Could not add note";
export const NOTE = "Note";

export const P4_FERTILITY_LETTER = "P4 Fertility Letter";
export const P4_GYNAECARE_LETTER = "P4 Gynaecare Letter";
export const REFERRAL_LETTER = "Referral Letter";

export const LOGIN_FAILED = "Login failed";
export const FIRST_CONSULTATION = "First Consultation";
export const FOLLOW_UP_CONSULTATION = "Follow-up Consultation";

export const NEED_TO_UPDATE = "needToUpdate";
export const LETTER = "Letter";

export const LETTER_MAX_LENGTH = 2048;
