import { Box } from "@mui/system";
import React from "react";

const CustomBox = (props: any) => {
  return (
    <Box
      component="span"
      onClick={props.onClick}
      sx={{
        cursor: props.onClick ? "pointer" : "default",
        color: props.textColor,
        p: 1,
        pl: 2,
        pr: 2,
        mr: 2,
        border: `1px solid ${props.color}`,
        borderRadius: "20px",
        backgroundColor: props.color,
      }}>
      <b>{props.label}</b>
    </Box>
  );
};

export default CustomBox;
