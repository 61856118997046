import React from "react";
import ActionBack from "../actionIcons/ActionBack";
import { Grid, Typography } from "@mui/material";
import { formatToDateTime } from "../../util/data.util";
import ActionDownload from "../actionIcons/ActionDownload";

interface HeaderElementProps {
  name: string;
  whenCreated: string;
  downloadFunction?: () => void;
  backFunction: () => void;
}

const HeaderElement: React.FC<HeaderElementProps> = ({
  name,
  whenCreated,
  downloadFunction,
  backFunction,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          minWidth: "15%",
        }}>
        <ActionBack handleClick={backFunction} />
        {downloadFunction && <ActionDownload handleClick={downloadFunction} />}
      </div>
      <Typography variant="h5" fontWeight={700}>
        {name}
      </Typography>
      <Typography variant="h6">{formatToDateTime(whenCreated)}</Typography>
    </Grid>
  );
};

export default HeaderElement;
