import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../apis/users";
import { Box, Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import SecondaryButton from "../buttons/SecondaryButton";
import AddUser from "./AddUser";
import ShowUserDetails from "./ShowUserDetails";
import ComponentLoading from "../loading/ComponentLoading";

const ShowUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isAddUser, setIsAddUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const getUsers = async () => {
    const [response, error] = await getAllUsers();
    if (response) {
      setUsers(
        response.map((user: User) => {
          return {
            ...user,
          };
        })
      );
      setIsLoading(false);
    } else {
      console.log(error);
    }
  };

  const columns = [
    {
      label: "Username",
      key: "username",
      sortable: true,
    },
    {
      label: "Email",
      key: "email",
      sortable: true,
    },
  ];

  const handleRowClick = (row: any) => {
    setIsAddUser(false);
    setSelectedUser(row);
  };
  useEffect(() => {
    getUsers();
  }, [reload]);

  return (
    <Grid container xs={12} sm={12} md={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {users && (
            <CustomTable
              data={users}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              optionButtonText="Add User"
              setOptionButton={setIsAddUser}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "desc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}
      {(isAddUser || selectedUser) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}>
          {isAddUser ? (
            <AddUser
              setIsAddUser={setIsAddUser}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            selectedUser && (
              <ShowUserDetails
                userData={selectedUser}
                setSelectedUser={setSelectedUser}
                setReload={setReload}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowUsers;
