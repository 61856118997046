import { Button } from "@mui/material";
import React from "react";

interface TernaryButtonProps {
  variant?: "outlined" | "contained" | "text";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
  onClick: (() => void) | ((index: any) => void);
  label: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

const TernaryButton = ({
  variant = "text",
  startIcon,
  endIcon,
  className,
  onClick,
  label,
  color = "primary",
}: TernaryButtonProps) => {
  return (
    <Button
      variant={variant}
      color={color}
      type="button"
      startIcon={startIcon}
      endIcon={endIcon}
      className={className}
      style={{ borderRadius: "5px", margin: 0 }}
      onClick={onClick}
    >
      {label && <b>{label}</b>}
    </Button>
  );
};

export default TernaryButton;
