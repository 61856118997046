import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import TextInput from "../fromComponents/TextInput";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import { createPatient, getAllAllergies } from "../../apis/patients";
import SearchPatient from "../fromComponents/SearchPatient";
import SearchStaff from "../search/SearchStaff";
import MultiSelectInput from "../fromComponents/MultiSelectInput";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  PATIENT_ADDED_SUCCESSFULLY,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import {
  AddressForm,
  Genders,
  NewPatientFormInputs,
  Titles,
} from "../../util/patient/patient.form.helper";

interface FormDialogBox {
  isOpen: boolean;
  setIsOpen: { (value: boolean): void };
  header: string;
  width: Breakpoint;
  partnerPublicId?: string;
  reload?: boolean;
  setReload?: { (value: boolean): void };
}

export const NewPatientForm = React.memo<FormDialogBox>(
  ({
    isOpen,
    setIsOpen,
    header,
    width,
    partnerPublicId,
    reload,
    setReload,
  }) => {
    const [value, setValue] = useState<string[]>([]);
    const { register, handleSubmit } = useForm<CreatePatientInterface>();
    const [allergies, setAllergies] = useState<string[]>();
    const dispatch = useDispatch();

    const [selectedPartner, setSelectedPartner] = useState<{
      label: string;
      publicId: string;
    }>();
    const [selectedStaff, setSelectedStaff] = useState<{
      label: string;
      publicId: string;
    }>();

    const onSubmit = async (data: CreatePatientInterface) => {
      console.log(value);
      let obj: CreatePatientInterface = {
        phoneNo: "",
        ownerStaffPublicId: "",
        firstName: "",
        lastName: "",
        gender: "",
        dateOfBirth: "",
        address: { street1: "", city: "", state: "", postalCode: "" },
        email: "",
        title: "",
        partnerPublicId: partnerPublicId || "",
        patientDetails: { allergies: "" },
      };
      if (data) {
        obj = { ...obj, ...data };
      }
      if (!partnerPublicId) {
        if (selectedPartner) {
          obj = { ...obj, partnerPublicId: selectedPartner.publicId };
        }
      }
      if (selectedStaff) {
        obj = { ...obj, ownerStaffPublicId: selectedStaff.publicId };
      }

      if (value) {
        obj = { ...obj, patientDetails: { allergies: value.join(",") } };
      }
      if (obj.ownerStaffPublicId) {
        Object.keys(obj).forEach((key) => {
          if (obj[key as keyof CreatePatientInterface] === "") {
            delete obj[key as keyof CreatePatientInterface];
          }
          if (key === "address") {
            if (Object.keys(obj.address).length > 0) {
              Object.keys(obj.address).forEach((key) => {
                if (obj.address[key as keyof Address] === "") {
                  delete obj.address[key as keyof Address];
                }
              });
            } else {
              if (obj[key as keyof CreatePatientInterface] === "") {
                delete obj[key as keyof CreatePatientInterface];
              }
            }
          }
        });

        if (obj) {
          const [response, error] = await createPatient(obj);
          console.log(response, error);
          if (response) {
            dispatch(
              activateSnackbar({
                active: true,
                message: PATIENT_ADDED_SUCCESSFULLY,
                variant: SNACKBAR_SUCCESS,
              })
            );
            setIsOpen(false);
            if (setReload) {
              setReload(!reload);
            }
          } else {
            dispatch(
              activateSnackbar({
                active: true,
                message: error || WENT_WRONG_MSG,
                variant: SNACKBAR_ERROR,
              })
            );
          }
        }
      } else {
      }
    };
    const getAllergies = async () => {
      const [response, error] = await getAllAllergies();
      if (response) {
        setAllergies(response);
      } else {
        console.log(error);
      }
    };
    useEffect(() => {
      getAllergies();
    }, []);

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={width}
          open={isOpen}
          onClose={() => setIsOpen(false)}>
          <DialogTitle>{header}</DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)} className="ps-3 pb-3">
            <DialogContent>
              <DialogContentText>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4 p-2">
                      <div className="row">
                        <FormControl className="col-sm-10">
                          <InputLabel id="title" required>
                            Title
                          </InputLabel>
                          <Select
                            id="title-select"
                            labelId="title"
                            label="Title"
                            required
                            {...register("title", {
                              required: true,
                            })}>
                            {Titles?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-sm-4 p-2">
                      <div className="row">
                        <FormControl className="col-sm-10">
                          <InputLabel id="gender" required>
                            Gender
                          </InputLabel>
                          <Select
                            id="gender-select"
                            labelId="gender"
                            label="Gender"
                            required
                            {...register("gender", {
                              required: true,
                            })}>
                            {Genders?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {allergies && (
                      <div className="col-sm-4 p-2">
                        <div className="row ">
                          <MultiSelectInput
                            label={"Allergies"}
                            name={"allergies"}
                            id="allergies"
                            className="col-sm-10 p-0"
                            setValue={setValue}
                            value={value}
                            options={allergies}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </DialogContentText>
              <hr />
              <DialogContentText>
                <div className="col-sm-12">
                  <div className="row">
                    {NewPatientFormInputs?.map((item: InputFieldType) => (
                      <TextInput
                        keyName={item.id}
                        register={register}
                        placeholder={item.placeholder}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        listName={item.id + "list"}
                        options={item.options}
                        required={item.required}
                      />
                    ))}
                  </div>
                </div>
              </DialogContentText>
              <hr />
              <DialogContentText>
                <div className="col-sm-12">
                  <div className="row">
                    {AddressForm?.map((item: InputFieldType) => (
                      <TextInput
                        keyName={item.id}
                        register={register}
                        placeholder={item.placeholder}
                        id={"address." + item.id}
                        label={item.label}
                        type={item.type}
                        listName={item.id + "list"}
                        options={item.options}
                        required={item.required}
                      />
                    ))}
                  </div>
                </div>
              </DialogContentText>
              <hr />
              <DialogContentText>
                <div className="col-sm-12 ">
                  <div className="row ">
                    <div className="col-sm-4 p-0">
                      <SearchStaff setSelectedStaff={setSelectedStaff} />
                    </div>
                    <div className="col-sm-4 p-0">
                      {/* <div className="row"> */}
                      {!partnerPublicId && (
                        <SearchPatient
                          label="Search Partner"
                          patient={selectedPartner}
                          setPatient={setSelectedPartner}
                        />
                      )}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </DialogContentText>{" "}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsOpen(false)}
                autoFocus
                variant="outlined"
                color="error">
                Cancel
              </Button>{" "}
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
);
