import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { OneStopFormInputs } from "../../../util/reportsCreation/report.formHelper";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../fromComponents/TextInput";
import {
  getNameValueObject,
  objectBuilderForPDF,
} from "../../../util/data.util";
import { ONE_STOP_FERTILITY } from "../../../constants/Constants";
import { createReport } from "../../../apis/reports";
import { ReportObjCreator } from "../../../util/api.helpers";
import { useDispatch, useSelector } from "react-redux";
import { todaysDate } from "../../../util/getTodaysDate";
import ComponentLoading from "../../loading/ComponentLoading";
import AddDownload from "../../OptionsComponents/AddDownload";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  DOWNLOAD_SUCCESS,
  CREATE_SUCCESS,
  CREATE_FAILED,
  DOWNLOAD_FAILED,
} from "../../../constants/SnackbarMessages";
import TextInputFull from "../../fromComponents/TextInputFull";
import { setProgressNotes } from "../../../redux/slice/progressNotesSlice";
import { downloadOneStopFertility } from "../../../util/reportsCreation/oneStopFertility";

export const OneStopFertility = React.memo(() => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { patient } = patientObject;
  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const { partner } = partnerObject;
  const userObject: any = useSelector<UserState>((state) => state.user);
  const { signature } = userObject.user.staff;
  const [reportObj, setReportObj] = useState<OneStopFertility>();
  const { register, handleSubmit, reset } = useForm<OneStopFertility>();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const progressNotes: any = useSelector<ProgressNotesState>(
    (state) => state.progressNotes
  );
  const dispatch = useDispatch();

  const onSubmit = async (patientData: OneStopFertility) => {
    setIsLoading(true);
    patientData.reportDate = todaysDate();
    patientData.signature = signature;
    setReportObj(
      objectBuilderForPDF(
        patient,
        patient.partnerPublicId ? partner : null,
        patientData
      )
    );
  };

  const handleDownload = async () => {
    if (reportObj) {
      await downloadOneStopFertility(reportObj);
      handleReset();
      successSnackbar(DOWNLOAD_SUCCESS);
    } else {
      errorSnackbar(DOWNLOAD_FAILED);
    }
  };

  const createNewReport = async () => {
    if (reportObj) {
      const values = getNameValueObject(reportObj);

      const [response, error] = await createReport(
        ReportObjCreator(
          ONE_STOP_FERTILITY,
          reportObj.patientId,
          reportObj.patientName,
          reportObj.patientDOB,
          values
        )
      );
      if (response) {
        successSnackbar(`Report ${CREATE_SUCCESS}`);
        setIsDisable(false);
        setIsLoading(false);
        dispatch(setProgressNotes([...progressNotes.data, response]));
      } else {
        errorSnackbar(`${CREATE_FAILED} Report.${error}`);
      }
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset();
    setIsDisable(true);
    setReportObj(undefined);
  };

  useEffect(() => {
    createNewReport();
  }, [reportObj]);

  return isDisable ? (
    <>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <div className="col-sm-12 mb-5">
          <div className="col-sm-12 p-3">
            <h3 className="text-center">One-Stop Fertility Clinic Report</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-sm-12 p-2">
              <div className="row ms-1">
                {OneStopFormInputs.map((item: InputFieldType) => {
                  return item.id !== "additionalComments" ? (
                    <TextInput
                      keyName={item.id}
                      register={register}
                      placeholder={item.placeholder}
                      id={item.id}
                      label={item.label}
                      type={item.type}
                      listName={item.id + "list"}
                      options={item.options}
                      required={item.required}
                      tag={item.tag}
                      maxLength={item.maxLength}
                      minRows={item.minRows}
                    />
                  ) : (
                    <TextInputFull
                      keyName={item.id}
                      register={register}
                      placeholder={item.placeholder}
                      id={item.id}
                      label={item.label}
                      type={item.type}
                      listName={item.id + "list"}
                      options={item.options}
                      required={item.required}
                      tag={item.tag}
                      maxLength={item.maxLength}
                      minRows={item.minRows}
                    />
                  );
                })}
              </div>
            </div>
            <PrimaryButton label={"Save"} type={"submit"} className={""} />
          </form>
        </div>
      )}
    </>
  ) : (
    <AddDownload onDownload={handleDownload} onReset={handleReset} />
  );
});
