import { useState } from "react";

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import RadioInput from "./RadioInput";
import MultiSelectInput from "./MultiSelectInput";
const TextInput = (props: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [characterCount, setCharacterCount] = useState(0);
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (event: any) => {
    const count = event.target.value.length;
    setCharacterCount(count);
  };

  return (
    <div className="col-sm-4 p-2" key={props.keyName}>
      <div className="row">
        {props.type === "select" ? (
          <>
            <MultiSelectInput
              label={"Allergies"}
              name={"Allergies"}
              id="allergies"
              setValue={null}
              value={null}
              options={[]}
            />
          </>
        ) : props.type === "radio" ? (
          <>
            <RadioInput
              item={props}
              register={props.register}
              id={props.id}
              unregister={props.unregister}
            />
          </>
        ) : props.tag === "textarea" ? (
          <>
            <TextField
              label={props.label}
              className={
                props.id === "signature"
                  ? "col-sm-10"
                  : "col-sm-10 text-capitalize"
              }
              id={props.id}
              placeholder={props.placeholder}
              required={props.required}
              multiline
              // onChange={handleChange}
              minRows={props.minRows}
              {...props.register(props.id, { required: props.required })}
            />
            {/* <Typography
              variant="caption"
              color={
                characterCount > props.maxLength ? "error" : "text.primary"
              }>
              {characterCount}/{props.maxLength}
            </Typography> */}
          </>
        ) : props.type === "password" ? (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password*
            </InputLabel>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              className="col-sm-10"
              id={props.id}
              placeholder={props.placeholder}
              required={props.required}
              {...props.register(props.id, { required: props.required })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        ) : props.options?.length > 0 ? (
          <Autocomplete
            freeSolo
            className={"col-sm-10 p-0"}
            id={props.id}
            options={props.options}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label}
                required={props.required}
                {...props.register(props.id, { required: props.required })}
              />
            )}
          />
        ) : (
          <>
            <TextField
              label={props.label}
              type={props.type}
              className={`col-sm-10${
                props.id === "email" ||
                props.id === "additionalComments" ||
                props.id === "recommendations" ||
                props.id === "username"
                  ? ""
                  : " text-capitalize"
              }`}
              size={props.size}
              id={props.id}
              list={props.listName}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              disabled={props.disabled}
              required={props.required}
              {...props.register(props.id, { required: props.required })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TextInput;
