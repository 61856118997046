import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../apis/users";
import { Box, Grid } from "@mui/material";
import { CustomTable } from "../table/Table";

import SecondaryButton from "../buttons/SecondaryButton";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddTeam from "./AddTeam";
import ShowTeamDetails from "./ShowTeamDetails";
import ComponentLoading from "../loading/ComponentLoading";
import NewFeatureComingSoon from "../messages/NewFeatureComming";
import { getAllTeams } from "../../apis/team";

const ShowTeams = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [showTable, setShowTable] = useState<boolean>();
  const dispatch = useDispatch();
  const [isAddTeam, setIsAddTeam] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getTeams = async () => {
    const [response, error] = await getAllTeams();
    if (response) {
      setTeams(
        response.map((team: Team) => {
          return {
            teamLeaderName: `${team.teamLeader?.title} ${team.teamLeader?.firstName} ${team.teamLeader?.lastName}`,
            teamLocation: team.location?.name,
            ...team,
          };
        })
      );

      //   setSelectedTeam(teams[0]);
      //   setShowTable(true);
    } else {
      console.log(error);
    }
    setIsLoading(false);
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Location",
      key: "teamLocation",
      sortable: true,
    },
    {
      label: "Team Leader",
      key: "teamLeaderName",
    },
  ];

  const handleRowClick = (row: any) => {
    console.log(row);

    setIsAddTeam(false);
    setSelectedTeam(row);
  };

  useEffect(() => {
    getTeams();
    setSelectedTeam(null);
    setIsAddTeam(false);
  }, [reload]);
  return (
    <Grid container xs={12} sm={12} md={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {teams && (
            <CustomTable
              data={teams}
              columns={columns}
              // onRowClick={handleRowClick}
              searchable={true}
              optionButtonText="Add Team"
              setOptionButton={setIsAddTeam}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "desc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}

      {(isAddTeam || selectedTeam) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}
        >
          {isAddTeam ? (
            <AddTeam
              setIsAddSite={setIsAddTeam}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            selectedTeam && (
              <ShowTeamDetails
                selectedTeam={selectedTeam}
                setShowTable={setIsAddTeam}
                reload={reload}
                setReload={setReload}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowTeams;
