import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint, useTheme } from "@mui/material/styles";
import TextInput from "../fromComponents/TextInput";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  RegisterOptions,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";
import { createPatient, searchPatient } from "../../apis/patients";
import { getAllStaff } from "../../apis/staff";
import SearchPatient from "../fromComponents/SearchPatient";
import SearchStaff from "../search/SearchStaff";
import PatientEditForm from "../patientsModule/PatientEditForm";
import MultiSelectInput from "../fromComponents/MultiSelectInput";

interface NotificationAndAlertsType {
  isOpen: boolean;
  setIsOpen: { (value: boolean): void };
}

const NotificationAndAlerts = React.memo<NotificationAndAlertsType>(
  ({ isOpen, setIsOpen }) => {
    useEffect(() => {}, []);

    return (
      <Dialog
        fullWidth={false}
        maxWidth={"md"}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <DialogTitle>Notifications & Alerts</DialogTitle>
      </Dialog>
    );
  }
);

export default NotificationAndAlerts;
