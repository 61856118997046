import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  Alert,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

interface StripedTableProps {
  data: IData[];
  columns: TableColumn[];
  actions?: Action[];
  defaultSort: { key: keyof IData; direction: "asc" | "desc" };
  onRowClick: (row: IData) => void;
  dateFormatter?: any;
}

export const StripedTable = ({
  data,
  columns,
  defaultSort,
  onRowClick,
  actions,
  dateFormatter,
}: StripedTableProps) => {
  const [sort, setSort] = useState<{
    key: keyof IData;
    direction: "asc" | "desc";
  }>(defaultSort);
  const [search, setSearch] = useState<string>("");

  const filteredData = data.filter((item) => {
    return Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  const sortedData = filteredData.sort((a, b) => {
    if (a[sort.key] < b[sort.key]) {
      return sort.direction === "asc" ? -1 : 1;
    }
    if (a[sort.key] > b[sort.key]) {
      return sort.direction === "asc" ? 1 : -1;
    }

    return 0;
  });

  useEffect(() => {
    const sortableColumn = columns.find((col) => col.sortable);
    if (sortableColumn) {
      setSort({ key: sortableColumn.key, direction: "asc" });
    }
  }, []);
  //
  return (
    <>
      {sortedData.length > 0 ? (
        <>
          <Table striped hover>
            <TableBody style={{ fontSize: 20 }}>
              {sortedData.map((row, index) => (
                <TableRow
                  key={row.publicId}
                  hover
                  style={{
                    cursor: "pointer",
                    textDecoration: row.cancelled ? "line-through" : "none",
                    backgroundColor: row.cancelled ? "#ffcac6" : "",
                  }}
                  onClick={() => onRowClick && onRowClick(row)}
                  className={"clickable"}
                >
                  {columns.map((column) => {
                    if (column.key === "actions") {
                      return (
                        <TableCell key={column.key as string}>
                          <Grid container spacing={2}>
                            {actions?.map((action, index) => (
                              <Grid item key={index}>
                                <IconButton onClick={() => action.onClick(row)}>
                                  {action.icon}
                                </IconButton>
                              </Grid>
                            ))}
                          </Grid>
                        </TableCell>
                      );
                    } else if (
                      (column.key.toString().includes("appointment") ||
                        column.key.toString().includes("created") ||
                        column.key.toString().includes("dateOfBirth")) &&
                      new Date(row[column.key]).toString() !== "Invalid Date"
                    ) {
                      return (
                        <TableCell
                          key={column.key as string}
                          sx={{ textAlign: "center" }}
                        >
                          {dateFormatter(row[column.key])}
                        </TableCell>
                      );
                    } else if (column.style) {
                      const cellStyle = column.style[row[column.key] as string];

                      return (
                        <TableCell key={column.key as string} style={cellStyle}>
                          {row[column.key]}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={column.key as string}>
                          {row[column.key]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Grid
          container
          md={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "35vh" }}
        >
          <Alert severity="error">No records found</Alert>
        </Grid>
      )}
    </>
  );
};
