export const getNextDate = (date: Date) => {
  const nextDate = new Date(date);
  nextDate.setDate(date.getDate() + 1);
  return nextDate;
};

export const getPreviousDate = (date: Date) => {
  const previousDate = new Date(date);
  previousDate.setDate(date.getDate() - 1);
  return previousDate;
};

export const getTimestamp = () => {
  const currentDate = new Date();
  const timestampInMs = currentDate.getTime();
  return timestampInMs;
};

export const addDaysToDateTime = (
  dateTimeString: Date,
  days: number
): { newDate: string; isPast: boolean } => {
  // Convert the dateTimeString to a Date object
  const startDate = new Date(dateTimeString);

  // Calculate the end date by adding days to the start date
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + days);

  // Check if the endDate is greater than the current date (Date.now())
  const isPast = endDate.getTime() < Date.now();

  // Get the locale options for date and time formatting
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",

    hour12: false,
  };

  // Convert the Date object to a formatted string using the locale options
  const formattedDateTime = endDate.toLocaleString(undefined, options);

  // Return an object with the new date value and a boolean indicating if it's in the past or not
  return {
    newDate: formattedDateTime,
    isPast: isPast,
  };
};
