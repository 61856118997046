import { useEffect, DependencyList } from "react";

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: any = []
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps); // Use type assertion to convert to mutable array
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
