import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";

interface EditableTitleBoxProps {
  editing?: boolean;
  label: string;
  handleChange: any;
  newValue: string | number;
  currentValue: string | number;
  handleEdit: any;
  openConfirmModal: any;
  name: string;
}
const EditableTitleBox: React.FC<EditableTitleBoxProps> = ({
  editing = false,
  label,
  handleChange,
  newValue,
  currentValue,
  handleEdit,
  openConfirmModal,
  name,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {editing ? (
        <TextField
          fullWidth
          className="col-md-10 p-2"
          variant="outlined"
          required
          type="text"
          name={name}
          value={newValue}
          onChange={handleChange}
        />
      ) : (
        <Typography variant="h4" gutterBottom>
          {currentValue}
        </Typography>
      )}
      {!editing && (
        <Box>
          <ActionEdit handleClick={handleEdit} />
          <ActionDelete handleClick={openConfirmModal} />
        </Box>
      )}
    </Box>
  );
};

export default EditableTitleBox;
