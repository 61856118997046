import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQRAuthImage } from "../../apis/auth";

import { Box, Button, CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TwoFactorAuthCode from "./TwoFactorAuthCode";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";

const TwoFactorAuthQR = () => {
  const loginObject: any = useSelector<Login>((state) => state.login);
  const [image, setImage] = useState<string | undefined>();
  const location = useLocation();
  const [hideQRView, setHideQRView] = useState<boolean>(false);
  const qrCode = location.state && location.state.qrCode;
  const clientObject: any = useSelector<SwivoClient>((state) => state.client);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getQRImage = async () => {
    if (
      loginObject.login.username &&
      loginObject.login.password &&
      loginObject.login.twoFactorReset
    ) {
      dispatch(loading());
      let resetAuthObject = {
        clientId: clientObject.client.clientId,
        username: loginObject.login.username,
        oldPassword: loginObject.login.password,
        twoFactorReset: loginObject.login.twoFactorReset,
      };

      const [response, error] = await getQRAuthImage(resetAuthObject);

      if (response) {
        setImage(response);
      } else {
        console.error(error);
      }
      dispatch(doneLoading());
    }
  };

  useEffect(() => {
    if (!qrCode) {
      getQRImage();
    }
  }, []);

  useEffect(() => {
    if (qrCode) {
      dispatch(doneLoading());
      setImage(qrCode);
    }
  }, [qrCode]);
  const goToLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {!hideQRView ? (
        <>
          {image ? (
            <img src={image} alt="QR Code" style={{ margin: 20 }} />
          ) : (
            <div>No QR image available</div>
          )}
          <p>Please scan the QR code with your authenticator app.</p>
          <Button variant="contained" onClick={() => setHideQRView(true)}>
            Continue
          </Button>
        </>
      ) : (
        <>
          <TwoFactorAuthCode setHideQRView={setHideQRView} showBack={true} />
        </>
      )}
    </Box>
  );
};

export default TwoFactorAuthQR;
