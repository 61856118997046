import { Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ManagementPlan from "../firstConsultation/ManagementPlan";
import { useSelector } from "react-redux";
import PrimaryButton from "../buttons/PrimaryButton";
import { addFollowUpConsultation } from "../../apis/consultation";
import useSnackbar from "../../hooks/useSnackbar";
import {
  CREATE_FAILED,
  CREATE_SUCCESS,
  SAVED,
  SAVE_FAILED,
} from "../../constants/SnackbarMessages";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import {
  CONTAINER_NAME,
  FILE,
  FILE_NAME,
  FOLLOW_UP_CONSULTATION,
  NEED_TO_UPDATE,
} from "../../constants/Constants";
import DrawingBoard from "../notepad/DrawBoard";
import { KeyboardOutlined, CreateOutlined } from "@mui/icons-material";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { getTimestamp } from "../../util/date.utl";
import Notepad from "../notepad/CustomTLDraw";
import useCreateJsonFile from "../../hooks/useCreateJsonFile";
import { uploadFile } from "../../apis/files.api";
import {
  getConsultationContainerName,
  getConsultationFileName,
} from "../../util/consultation/consultation.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";

const FollowUpConsultation = () => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState<string>("");
  const [tests, setTests] = useState<string[]>([]);
  const [supplements, setSupplements] = useState<string[]>([]);
  const [treatments, setTreatments] = useState<string[]>([]);
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const persistenceKey = patientObject.patient.publicId;
  const [fileName, handleFileChange] = useFileUpload();
  const [height, setHeight] = useState<number>();
  const { createJsonFile } = useCreateJsonFile();
  const saveAsSVG = async () => {
    const persistedSnapshot = localStorage.getItem(
      persistenceKey + "-followUp"
    );
    console.log(persistedSnapshot);
    if (persistedSnapshot) {
      console.log("creating file");
      const file = await createJsonFile(JSON.parse(persistedSnapshot));
      if (file) {
        const formData = new FormData();
        formData.append(FILE, file);
        formData.append(
          FILE_NAME,
          getConsultationFileName(persistenceKey, FOLLOW_UP_CONSULTATION)
          // "undefined_First Consultation_2023-10-14T09-23-04-872Z.json"
        );
        formData.append(
          CONTAINER_NAME,
          getConsultationContainerName(persistenceKey)
        );
        console.log(formData);
        try {
          console.log("uploading file");
          const [response, error] = await uploadFile(formData);
          if (response) {
            return response;
          }
          return false;
        } catch {
          return false;
        }
      }
    }

    // const container = document.getElementById("print-content");
    // if (!container) return;

    // const svg = container.querySelector("svg");
    // if (!svg) return;

    // const svgData = svg.outerHTML;

    // const blob = new Blob([svgData], { type: "image/svg+xml" });
    // const file = new File(
    //   [blob],
    //   `followup_${patientObject.publicId}_${getTimestamp()}.svg`,
    //   { type: "image/svg+xml" }
    // );
    // const [response, error] = await handleFileChange(file, "test");
    // if (response) {
    //   return response;
    // } else {
    //   return false;
    // }
  };

  const handleSubmit = async () => {
    dispatch(loading());
    const url = await saveAsSVG();
    if (comments || url) {
      let obj: FollowUpPostRequest = {
        followUpComments: comments || "",
        patientPublicId: patientObject.patient.publicId,
        supplementPublicIds: supplements,
        testsPublicIds: tests,
        treatmentsPublicIds: treatments,
      };

      console.log("inside", url);
      if (url && isWriting) {
        obj = {
          ...obj,
          handwrittenNotes: [
            {
              url: url as string,
              label: FOLLOW_UP_CONSULTATION,
              height: 0,
            },
          ],
        };
      }
      console.log(obj);
      const [response, error] = await addFollowUpConsultation(obj);
      if (response) {
        successSnackbar("Follow up" + SAVED);
        sessionStorage.setItem(NEED_TO_UPDATE, JSON.stringify(true));
        setTimeout(() => window.location.reload(), 1000);
        localStorage.removeItem(persistenceKey + "-followUp");
      } else {
        errorSnackbar(SAVE_FAILED + "follow up");
      }
      dispatch(doneLoading());
    }
  };
  const options: ToolbarOptions = {
    showTouch: true,
    showAddRemoveHeight: true,
    showAddDiagrams: false,
    showColors: true,
    showPencilSize: true,
    showEraserSize: true,
  };

  return (
    <Grid container flex={1} spacing={2} mt={3}>
      <Grid item xs={12} sm={12} md={12}>
        <div
          style={{
            display: "flex", // Add this line to make the content flexbox
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            size="large"
            onClick={() => setIsWriting((prev) => !prev)}
            aria-label="Toggle Writing Mode"
            color="primary"
          >
            {isWriting ? <KeyboardOutlined /> : <CreateOutlined />}
          </IconButton>
          {isWriting && (
            <IconButton
              size="medium"
              aria-label="Toggle Full Screen"
              color="primary"
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                sessionStorage.setItem(
                  "isFullScreenActive",
                  JSON.stringify(true)
                );
              }}
            >
              <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
            </IconButton>
          )}
        </div>
        {isWriting ? (
          // <DrawingBoard height={1000} width={900} toolbarOptions={options} />
          <div
            style={{
              background: "linear-gradient(#f5f5f5, #eaeaea)",

              padding: "20px",
              marginBottom: "20px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              position: isFullScreen ? "fixed" : "relative",
              height: isFullScreen ? "" : "80vh",
              width: isFullScreen ? "" : "auto",
              inset: isFullScreen ? "0" : "",
              zIndex: isFullScreen ? "6" : "0",
            }}
          >
            {isFullScreen && (
              <div
                style={{
                  display: "flex", // Add this line to make the content flexbox
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  size="medium"
                  aria-label="Toggle Full Screen"
                  color="primary"
                  onClick={() => {
                    setIsFullScreen(!isFullScreen);
                    sessionStorage.setItem(
                      "isFullScreenActive",
                      JSON.stringify(true)
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCompress} />
                </IconButton>
              </div>
            )}
            <Notepad persistenceKey={persistenceKey + "-followUp"} />
          </div>
        ) : (
          <TextField
            label="Follow up Comments"
            className="col-sm-12"
            id="follow-up-comments"
            placeholder="Follow up details..."
            required
            multiline
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            minRows={6}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <ManagementPlan
          tests={tests}
          setTests={setTests}
          preTreatmentPlan={supplements}
          setPreTreatmentPlan={setSupplements}
          treatments={treatments}
          setTreatments={setTreatments}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <PrimaryButton
            label={"Save"}
            type={"Button"}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FollowUpConsultation;
