import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/authSlice";
import partnerSlice from "../slice/partnerInformation";
import patientSlice from "../slice/patientInformation";
import roleSlice from "../slice/roleSlice";
import userSlice from "../slice/userSlice";
import snackbarSlice from "../slice/snackbarSlice";
import loaderSlice from "../slice/loaderSlice";
import femaleSlice from "../slice/femalePatientSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import loginSlice from "../slice/loginSlice";
import progressNotesSlice from "../slice/progressNotesSlice";
import allAppointments from "../slice/todayAppointments";
import sitesSlice from "../slice/sitesSlice";
import testsSlice from "../slice/testsSlice";
import treatmentsSlice from "../slice/treatmentsSlice";
import supplementsSlice from "../slice/supplementsSlice";
import patientPathwaySlice from "../slice/patientPathwaySlice";
import clientSlice from "../slice/clientSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "user",
    "auth",
    "allAppointments",
    "sites",
    "roles",
    "tests",
    "treatments",
    "supplements",
    "progressNotes",
    "client",
  ],
};
const reducer = combineReducers({
  user: userSlice,
  auth: authSlice,
  roles: roleSlice,
  patient: patientSlice,
  partner: partnerSlice,
  snackbar: snackbarSlice,
  loader: loaderSlice,
  female: femaleSlice,
  login: loginSlice,
  progressNotes: progressNotesSlice,
  allAppointments: allAppointments,
  sites: sitesSlice,
  tests: testsSlice,
  treatments: treatmentsSlice,
  supplements: supplementsSlice,
  patientPathways: patientPathwaySlice,
  client: clientSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof persistedReducer>;
