import React, { useEffect, useState } from "react";
import { getAllStaff } from "../../apis/staff";
import { Autocomplete, TextField } from "@mui/material";
import { getAllPathways } from "../../apis/pathway";

interface SearchPathwayProps {
  setSelectedPathway?: any;
  className?: string;
  label?: string;
  id?: string;
  setValue?: any;
  defaultValue?: Pathway | null;
}

const SearchPathway = React.memo<SearchPathwayProps>(
  ({ setSelectedPathway, className, label, id, setValue, defaultValue }) => {
    const [searchPathways, setSearchPathways] = useState<Select[]>([]);
    console.log(defaultValue);
    const getPathways = async () => {
      const [response, error] = await getAllPathways();
      if (response) {
        const results = response.map((element: Pathway) => ({
          label: element.name,
          publicId: element.publicId,
        }));
        console.log(results);
        setSearchPathways(results);
      } else {
        console.log(error);
      }
    };

    const handlePathwaySelected = (
      event: React.SyntheticEvent,
      option: any
    ) => {
      if (setSelectedPathway) {
        setSelectedPathway(option);
      }
      if (option && id) {
        // id = option.publicId;
        setValue(id, option.publicId);
      }
    };

    useEffect(() => {
      getPathways();
    }, []);
    return (
      <Autocomplete
        freeSolo
        className={className ? className : "col-sm-10 p-0"}
        id={id}
        options={searchPathways}
        value={{ label: defaultValue?.name, publicId: defaultValue?.publicId }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "Select Pathway..."}
            required
          />
        )}
        onChange={handlePathwaySelected}
      />
    );
  }
);

export default SearchPathway;
