import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Tests: React.FC<TestsProps> = ({ tests, setTests }) => {
  const patientObject: any = useSelector<FemalePatientState>(
    (state) => state.female
  );
  const { publicId, ownerStaff } = patientObject.female;
  const { handleSubmit } = useForm<FieldValues>();
  const [services, setServices] = useState<Service[]>([]);
  const allTests: any = useSelector<TestsState>((state) => state.tests);
  const onSubmit: SubmitHandler<FieldValues> = async () => {
    // Call the createEvent API for each selected service
    console.log(tests);
    // values.forEach(async (event) => {
    //   try {
    //     await createEvent(event)
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // });
  };

  const handleCheckboxChange = (
    option: Service,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    if (checked) {
      setTests((prevValues) => [...prevValues, option.publicId]);
    } else {
      setTests((prevValues) =>
        prevValues.filter((value) => value !== option.publicId)
      );
    }
  };
  useEffect(() => {
    if (allTests.tests.length > 0) {
      setServices(allTests.tests);
    }
  }, []);

  return (
    <>
      {services?.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-12 p-2">
              <Typography variant="h6" gutterBottom>
                Tests
              </Typography>
              <hr />
              <Grid container spacing={2}>
                {services?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.publicId}>
                    <FormControl component="fieldset" className={""}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handleCheckboxChange(item, e)}
                              name={`${item.name}`}
                              checked={tests.some(
                                (value) => value === item.publicId
                              )}
                            />
                          }
                          label={item.name}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          {/* <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <PrimaryButton
                label={"Save"}
                type={"submit"}
                disable={values.length === 0}
                className={""}
              />
            </Grid>
          </Grid> */}
        </form>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}>
          <Typography variant="h5" component="div" color={"red"} gutterBottom>
            Tests not available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Tests;
