import { useDispatch } from "react-redux";
import { activateSnackbar } from "../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../constants/Constants";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const successSnackbar = (message: string) => {
    dispatch(
      activateSnackbar({
        active: true,
        message,
        variant: SNACKBAR_SUCCESS,
      })
    );
  };

  const errorSnackbar = (message: string) => {
    dispatch(
      activateSnackbar({
        active: true,
        message,
        variant: SNACKBAR_ERROR,
      })
    );
  };

  return { successSnackbar, errorSnackbar };
};

export default useSnackbar;
