import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { jsPDF } from "jspdf";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { getTimestamp } from "../../util/date.utl";
import ActionClose from "../actionIcons/ActionClose";
interface ScanDocumentsProps {
  setIsScanDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

const ScanDocument: React.FC<ScanDocumentsProps> = ({
  setIsScanDocuments,
  setFiles,
}) => {
  // const [capturedImages, setCapturedImages] = useState<any[]>([]);
  const [facingMode, setFacingMode] = useState<"user" | "environment">(
    "environment"
  );
  const imagesContainerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const aspectRatio = 297 / 420;
  const width = 400;
  const calculatedHeight = Math.floor(width / aspectRatio);

  const handleRemoveImage = (indexToRemove: number) => {
    const updatedImages = capturedImages.filter(
      (_, index) => index !== indexToRemove
    );
    setCapturedImages(updatedImages);
  };

  // const generateAndDownloadPDF = async () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const a4Width = 210;
  //   const a4Height = 297;
  //   const imageMargin = 10;

  //   for (let index = 0; index < capturedImages.length; index++) {
  //     const capturedImage = capturedImages[index];

  //     if (index > 0) {
  //       pdf.addPage();
  //     }

  //     const yOffset = pdf.internal.pageSize.getHeight() / 2 - a4Height / 2;

  //     const img = new Image();
  //     img.src = capturedImage;

  //     await new Promise((resolve) => {
  //       img.onload = resolve;
  //     });

  //     const imageWidth = Math.min(a4Width - 2 * imageMargin, img.width);
  //     const imageHeight = Math.min(a4Height - 2 * imageMargin, img.height);
  //     console.log(index, img.width / img.height);
  //     const aspectRatio = img.width / img.height;

  //     if (0 < aspectRatio && aspectRatio < 1) {
  //       console.log("if", index);
  //       const imageX = (a4Width - imageWidth) / 2;
  //       pdf.addImage(
  //         capturedImage,
  //         "JPEG",
  //         10,
  //         -180,
  //         257,
  //         190,
  //         JSON.stringify(index),
  //         "NONE",
  //         270
  //       );
  //     } else {
  //       console.log("else", index);
  //       const imageX = (a4Height - imageHeight) / 2;
  //       pdf.addImage(
  //         capturedImage,
  //         "JPEG",
  //         imageX,
  //         yOffset + imageMargin,
  //         190,
  //         150
  //       );
  //     }
  //   }

  //   const pdfBlob = pdf.output("blob");
  //   const pdfFile: File = new File([pdfBlob], `sc_${getTimestamp()}.pdf`, {
  //     type: "application/pdf",
  //   });

  //   // const downloadLink = document.createElement("a");
  //   // downloadLink.href = URL.createObjectURL(pdfBlob);
  //   // downloadLink.download = pdfFile.name;
  //   // downloadLink.click();
  // };

  const generateAndDownloadPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const a4Width = 210;
    const a4Height = 297;
    const imageMargin = 10;

    capturedImages.forEach((imageSrc, index) => {
      if (index > 0) {
        pdf.addPage();
      }

      const yOffset = pdf.internal.pageSize.getHeight() / 2 - a4Height / 2;

      const imageWidth = a4Width - 2 * imageMargin;
      const imageAspectRatio = calculatedHeight / width;

      const imageHeight = imageWidth * imageAspectRatio;

      if (imageAspectRatio > 1) {
        console.log("if", index);
        const imageX = (a4Width - imageWidth) / 2;
        pdf.addImage(
          imageSrc,
          "JPEG",
          10,
          -180,
          257,
          190,
          JSON.stringify(index),
          "SLOW",
          270 // Additional options
        );
      } else {
        console.log("else", index);
        const imageX = (a4Height - imageHeight) / 2;
        pdf.addImage(
          imageSrc,
          "JPEG",
          imageX,
          yOffset + imageMargin,
          190,
          150,
          JSON.stringify(index),
          "SLOW"
        );
      }
    });
    const pdfBlob = pdf.output("blob");
    const pdfFile: File = new File([pdfBlob], `sc_${getTimestamp()}.pdf`, {
      type: "application/pdf",
    });

    const newPDFFile: FileObject = {
      file: pdfFile,
      preview: URL.createObjectURL(pdfFile),
      name: pdfFile.name,
    };

    setFiles((prevImages) => [...prevImages, newPDFFile]);

    setIsScanDocuments(false);
  };

  useEffect(() => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollTop =
        imagesContainerRef.current.scrollHeight + 100;
    }
  }, []);

  const [capturedImages, setCapturedImages] = useState<string[]>([]);

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages: string[] = [];
      for (let i = 0; i < files.length; i++) {
        const imageSrc = URL.createObjectURL(files[i]);
        newImages.push(imageSrc);
      }
      setCapturedImages((prevImages) => [...prevImages, ...newImages]);
    }
  };
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <Dialog
      open={true}
      onClose={() => setIsScanDocuments(false)}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { height: "90%", maxHeight: "90%" } }}
    >
      <DialogContent>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <ActionClose
                handleClick={() => {
                  setIsScanDocuments(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div>
            {capturedImages.map((imageSrc, index) => (
              <img
                key={index}
                src={imageSrc}
                alt={`Captured ${index}`}
                style={{ maxWidth: "40%", height: "auto", margin: 10 }}
              />
            ))}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Tooltip title="Save" placement="top">
              <IconButton className="mb-2" onClick={generateAndDownloadPDF}>
                <SaveIcon
                  style={{ fontSize: "3rem", margin: 3, cursor: "pointer" }}
                  color="primary"
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <div>
              <Tooltip title="Add Page" placement="top">
                <IconButton
                  className="mb-2"
                  onClick={() => inputRef.current?.click()}
                >
                  <AddIcon
                    style={{
                      position: "relative",
                      margin: 2,
                      cursor: "pointer",
                      border: "1px solid",
                      padding: 3,
                      borderRadius: "50%",
                      fontSize: "4rem",
                    }}
                    color="primary"
                  />
                </IconButton>
              </Tooltip>
              <input
                ref={inputRef}
                accept="image/*"
                capture={facingMode}
                type="file"
                multiple
                style={{ display: "none" }} // Hide the input element
                onChange={handleCapture}
              />
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ScanDocument;
