import jsPDF from "jspdf";
import {
  addCreatedDate,
  addFooter,
  addHeader,
  addPatientInfo,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";
//created by Ranjit Jeve

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 8;
const textFontPoint = 11;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10; //
const drNamePosition = 40; // globalPosition + drNamePosition
const ultrasoundReportTitle = "Ultrasound Scan Report";
const ultrasoundTextPara =
  "Chaperone present. Patient verbally consented. Probe cleaned with Tristel Duo.";
const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
export const downloadUltrasoundReport = async (
  patientData: UltrasoundScanType
) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  addReportTitle(doc, ultrasoundReportTitle);
  addUltrasoundReportData(doc, patientData, ultrasoundTextPara);
  addSignature(doc, patientData.signature);
  addFooter(doc);
  doc.save(
    "R_Ultrasound_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );

  globalPosition = 10;
  return;
};

const addReportTitle = (doc: any, title: string) => {
  doc
    .setFontSize(reportTitlePoint)
    .text(title, marginLeft + 57, getGlobalPosition(spaceBetween + 5));
};

const addUltrasoundReportData = (
  doc: any,
  patientData: UltrasoundScanType,
  textPara: string
) => {
  doc
    .setFontSize(textFontPoint)
    // .text(
    //   "•  Clinical Indication: " + patientData.clinicalIndication,
    //   marginLeft,
    //   getGlobalPosition(commonSpaceBetween + 10)
    // )

    .text(`•  ${textPara}`, marginLeft, getGlobalPosition(commonSpaceBetween), {
      width: 460,
      lineGap: 7,
      align: "justify",
    })
    .text(
      "•  Uterus: " + patientData.uterus,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Cavity: " + patientData.cavity,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Endometrial Thickness: " + patientData.endometrialThickness,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Fibroid / Polyp: " + patientData.fibroidOrPolyp,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Left ovary: " + patientData.leftOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Right ovary: " + patientData.rightOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Additional Comments: " + patientData.additionalComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
};

const addSignature = (doc: any, drName: string) => {
  doc
    .text(drName, marginLeft, getGlobalPosition(drNamePosition))
    .text(
      "(Electronically approved)",
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    );
};

// const addFooter = (doc: any) => {
//   doc.addImage(headerImage, "PNG", 160, 270, 30, 15);
// };

// function to generate a line
// const generateHr = (doc: any, y: number) => {
//   doc.line(marginLeft, y, 195, y, "S");
// };

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};
