export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
//return dd mmmmmm yyyy
export const todaysDate = () => {
  return (
    new Date().getDate() +
    " " +
    months[new Date().getMonth()] +
    " " +
    new Date().getFullYear()
  );
};
