import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import { deleteUser, reset2FA } from "../../apis/users";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";
import EditableBox from "../adminCenterComponents/EditableBox";
import { RESET_SUCCESS } from "../../constants/SnackbarMessages";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";

const ShowUserDetails: React.FC<UserDetailsProps> = ({
  userData,
  setReload,
  setSelectedUser,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [password, setPassword] = useState<string>("");
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isReset2FA, setIsReset2FA] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleEdit = () => {
    setEditing(true);
    setNewValues(userData);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const resetAuthRequest = async (req: ResetAuthRequest) => {
    const [response, error] = await reset2FA(req);
    console.log(response, error);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: RESET_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload((prev) => !prev);
      setEditing(false);
      setSelectedUser(null);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error.data ? error.data : error ? error : WENT_WRONG_MSG,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleSave = async () => {
    if (password.length > 0) {
      const reqObj: ResetAuthRequest = {
        username: userData.username,
        newPassword: password,
        twoFactorReset: isReset2FA,
      };
      resetAuthRequest(reqObj);
    } else {
      const reqObj: ResetAuthRequest = {
        username: userData.username,
        twoFactorReset: isReset2FA,
      };
      resetAuthRequest(reqObj);
    }
  };

  const handleDelete = async () => {
    const [response, error] = await deleteUser(userData.username);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: response,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload((prev) => !prev);
      setSelectedUser(null);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error.data ? error.data : error ? error : WENT_WRONG_MSG,
          variant: SNACKBAR_ERROR,
        })
      );
    }

    setEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsReset2FA(checked);
  };

  const openConfirmModal = () => {
    // setFileToDelete(row);
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    // setFileToDelete(null);
  };

  return (
    <>
      <Box
        padding={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">{userData.username}</Typography>
        {!editing && (
          <Box>
            <ActionEdit handleClick={handleEdit} />
            <ActionDelete handleClick={openConfirmModal} />
          </Box>
        )}
      </Box>

      <EditableBox
        label={"Email"}
        currentValue={userData.email}
        name={"Email"}
      />
      <EditableBox
        label={"Password"}
        currentValue={"***********"}
        name={"password"}
        editing={editing}
        handleChange={handleChange}
        newValue={newValues.password}
        type={"password"}
        setValue={setPassword}
      />

      <EditableBox
        label={"Two Factor Authentication"}
        currentValue={userData.twoFactorEnabled ? "Active" : "Inactive"}
        name={"twoFactorReset"}
        editing={editing}
        type={"checkbox"}
        checkboxLabel="Reset 2FA"
        setCheckboxValue={handleCheckboxChange}
      />

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing && (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        )}
      </Box>
      {userData && (
        <ConfirmationDialog
          open={confirmModalOpen}
          onClose={closeConfirmModal}
          title={`Are you sure you want to delete ${userData?.username}?`}
          onCancel={closeConfirmModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default ShowUserDetails;
