import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { SyncLoader } from "react-spinners";
import { BRAND_COLOR_DARK } from "../../constants/BrandConstants";

interface LoadingProps {
  size?: number;
  thickness?: number;
}

const ComponentLoading: React.FC<LoadingProps> = ({
  size = 15,
  thickness = 4,
}) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ position: "relative", top: "120px" }}>
      <Grid item>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}>
          <SyncLoader color={BRAND_COLOR_DARK} size={size} margin={5} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ComponentLoading;
