import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchStaff from "../search/SearchStaff";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SITE_ADDED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import { deleteSite, updateSite } from "../../apis/site";
import TernaryButton from "../buttons/TernaryButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import EditableBox from "../adminCenterComponents/EditableBox";
import {
  DELETE_FAILED,
  DELETE_SUCCESS,
} from "../../constants/SnackbarMessages";

const ShowSiteDetails: React.FC<SiteDetailsProps> = ({
  selectedSite,
  setSelectedSite,
  setReload,
}) => {
  const [editing, setEditing] = useState<boolean>();
  const [name, setName] = useState<string>(selectedSite.name);
  const [ownerStaff, setOwnerStaff] = useState<StaffInformation>(
    selectedSite.ownerStaff
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedStaff, setSelectedStaff] = useState<SelectedStaff>();
  const [externalId, setExternalId] = useState<string | null>(
    selectedSite.externalId || null
  );
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setName(selectedSite.name);
    setOwnerStaff(selectedSite.ownerStaff);
    setEditing(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleSave = async () => {
    if (selectedStaff || ownerStaff) {
      const updatedSite: UpdateSiteRequest = {
        ...selectedSite,
        name,
        ownerStaffPublicId: selectedStaff?.publicId
          ? selectedStaff?.publicId
          : ownerStaff.publicId,
      };
      const [response, error] = await updateSite(updatedSite);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: SITE_ADDED,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setReload((prev) => !prev);
        setSelectedSite(null);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: WENT_WRONG_MSG,
            variant: SNACKBAR_ERROR,
          })
        );
      }
      setEditing(false);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Please select a staff",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleDelete = async () => {
    const [response, error] = await deleteSite(selectedSite.publicId);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Site" + DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setReload((prev) => !prev);
      setSelectedSite(null);
      setEditing(false);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || DELETE_FAILED + "Site",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleExternalIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExternalId(event.target.value);
  };

  useEffect(() => {
    setName(selectedSite.name);
    setOwnerStaff(selectedSite.ownerStaff);
    setExternalId(selectedSite.externalId || null);
  }, [selectedSite]);

  return (
    <>
      <Box
        padding={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        {editing ? (
          <TextField
            fullWidth
            className="col-md-10"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
        ) : (
          <Typography
            variant="h4"
            className="text-capitalize"
            onDoubleClick={handleEdit}>
            {name}
          </Typography>
        )}
        {!editing && (
          <Box>
            <ActionEdit handleClick={handleEdit} />
            <ActionDelete handleClick={openConfirmModal} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 3,
        }}>
        <EditableBox
          label={"Internal Site Id"}
          currentValue={selectedSite.publicId}
          name={""}
        />
        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>External Site ID</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <TextField
                fullWidth
                className="col-md-12"
                variant="outlined"
                value={externalId}
                onChange={handleExternalIdChange}
              />
            ) : (
              <Typography variant="body1" onDoubleClick={handleEdit}>
                {externalId}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Head Staff</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <SearchStaff
                setSelectedStaff={setSelectedStaff}
                className="col-md-12"
                label="Head Staff"
              />
            ) : (
              <Typography
                variant="body1"
                onDoubleClick={
                  handleEdit
                }>{`${ownerStaff.title} ${ownerStaff.firstName} ${ownerStaff.lastName}`}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing && (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete site '${selectedSite?.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ShowSiteDetails;
