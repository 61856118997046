import React, { FC, useEffect, useRef, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { deactivateSnackbar } from "../../redux/slice/snackbarSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SnackbarData {
  id: number;
  variant: AlertColor | undefined;
  message: string;
  createdAt: number;
}

type SnackbarComponentProps = {
  active: boolean;
  variant?: AlertColor;
  message: string;
};

const CustomSnackbar: FC<SnackbarComponentProps> = ({
  active,
  variant = "info",
  message,
}) => {
  const dispatch = useDispatch();
  const snackbarsRef = useRef<SnackbarData[]>([]);
  const [activeSnackbars, setActiveSnackbars] = useState<SnackbarData[]>([]);

  const handleClose = (id: number) => {
    snackbarsRef.current = snackbarsRef.current.filter(
      (snackbar) => snackbar.id !== id
    );
    setActiveSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snackbar) => snackbar.id !== id)
    );
    dispatch(deactivateSnackbar());
  };

  useEffect(() => {
    if (active) {
      const id = Date.now();
      const createdAt = Date.now();
      const newSnackbar: SnackbarData = {
        id,
        variant,
        message,
        createdAt,
      };
      snackbarsRef.current = [newSnackbar, ...snackbarsRef.current];
      setActiveSnackbars((prevSnackbars) => [newSnackbar, ...prevSnackbars]);
      // Schedule automatic closing of the Snackbar after the specified duration
      const timeout = setTimeout(() => handleClose(id), 2000);

      // Clear the timeout when the component is unmounted or the Snackbar is closed manually
      return () => clearTimeout(timeout);
    }
  }, [active, variant, message]);

  return (
    <>
      {activeSnackbars.map((snackbar, index) => (
        <Snackbar
          key={snackbar.id}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={true}
          sx={{
            marginTop: `${index * 60}px`,
            borderRadius: "4px",
          }}>
          <Alert
            onClose={() => handleClose(snackbar.id)}
            severity={snackbar.variant}
            sx={{ width: "100%" }}>
            <b>{snackbar.message}</b>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default CustomSnackbar;
