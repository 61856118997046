import { useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  SITE_ADDED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import PrimaryButton from "../buttons/PrimaryButton";
import TernaryButton from "../buttons/TernaryButton";
import SearchSites from "../search/SearchSites";
import { createNewLocation } from "../../apis/location";
import {
  LOCATION_CREATE_FAILED,
  LOCATION_CREATE_SUCCESS,
} from "../../constants/SnackbarMessages";

interface AddLocationProps {
  setIsAddLocation: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}

const AddLocation: React.FC<AddLocationProps> = ({
  setIsAddLocation,
  setReload,
  reload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<CreateLocationRequest>();
  const [selectedSite, setSelectedSite] = useState<SelectedSite>();
  const onSubmit = async (data: CreateLocationRequest) => {
    console.log(selectedSite);
    if (selectedSite) {
      let obj: CreateLocationRequest = {
        name: data.name,
        address: data.address,
        externalId: data.externalId,
        email: data.email,
        phoneNo: data.phoneNo,
        sitePublicId: selectedSite.publicId,
      };

      const [response, error] = await createNewLocation(obj);
      console.log(error, response);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: LOCATION_CREATE_SUCCESS,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setIsAddLocation(false);
        setReload(!reload);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: error || LOCATION_CREATE_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: "Please select a site",
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4" className="text-capitalize">
            Add Location
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="name"
            className="col-sm-10 "
            {...register("name", { required: false })}
            placeholder="Location Name"
            id="name"
            label="Name"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="name"
            className="col-sm-10 "
            {...register("externalId", { required: false })}
            placeholder="Wix Location Id"
            id="externalId"
            label="Wix Location Id"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="email"
            className="col-sm-10 "
            {...register("email", { required: false })}
            placeholder="Location email address"
            id="email"
            label="Location Email Address"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="phoneNo"
            className="col-sm-10 "
            {...register("phoneNo", { required: false })}
            placeholder="Location phone/contact number"
            id="phoneNo"
            label="Location phone/contact number"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <hr />
          <Typography>Address </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="StreetAddress"
            className="col-sm-10 "
            {...register("address.street1", { required: false })}
            placeholder="Street Address"
            id="StreetAddress"
            label="Street Address"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="city"
            className="col-sm-10 "
            {...register("address.city", { required: false })}
            placeholder="City"
            id="city"
            label="City"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="postalCode"
            className="col-sm-10 "
            {...register("address.postalCode", { required: false })}
            placeholder="Street Address"
            id="postalCode"
            label="Postal Code"
            type="text"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            key="country"
            className="col-sm-10 "
            {...register("address.country", { required: false })}
            placeholder="Country"
            id="country"
            label="Country"
            type="text"
          />
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SearchSites setSelectedSite={setSelectedSite} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <TernaryButton
              onClick={() => {
                setIsAddLocation(false);
              }}
              label={"Cancel"}
              className="m-2"
            />
            <PrimaryButton label={"Add"} type={"submit"} className="m-2" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddLocation;
