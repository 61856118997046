export const getNameValueObject = (data: any) => {
  const nameValueObject: ReportFields[] = [];
  Object.keys(data).forEach((key) => {
    nameValueObject.push({
      name: key,
      value: data[key],
    });
  });
  return nameValueObject;
};

export const getObjectFromNameValue = (nameValueObject: ReportFields[]) => {
  const data: any = {};

  nameValueObject.forEach(({ name, value }) => {
    data[name] = value ? value : "";
  });

  return data;
};

export const formatDate = (dateString: string): string => {
  try {
    const dateArray = dateString.split("-");
    const date = new Date(dateString);
    if (dateArray[0] > "1960") {
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
      })} ${date.getFullYear()}`;
    } else {
      return "";
    }
  } catch (err) {
    console.log(dateString, err);
    return "";
  }
};
export const formatToDateTime = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: userTimeZone,
      // timeZone: "Europe/London",
    };
    return date.toLocaleString("en-GB", options);
  } catch (err) {
    console.log(dateString, err);
    return "";
  }
};

export const formatToDateTimeCalender = (dateString: string): string => {
  try {
    const date = new Date(dateString);

    return `  ${date
      .toLocaleString("en-UK", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .replace(/am|pm/g, (match) => match.toUpperCase())}`;
  } catch (err) {
    console.log(dateString, err);
    return "";
  }
};

export const formatDateStr = (dateString: string) => {
  try {
    const date = new Date(
      dateString.replace(/\s/g, "").replace(/(\d{2})(\d{2})(\d{4})/, "$2/$1/$3")
    );
    const dateArray = dateString.split("-");
    if (dateArray[0] > "1960") {
      const day = date.getDate().toString().padStart(2, "0");
      const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
        date
      );
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    } else {
      return "";
    }
  } catch (err) {
    console.log(dateString, err);
    return "";
  }
};

export const parseDate = (dateString: string): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset();
  const isDST = isUKDST(date);

  const userTimezoneOffset = timezoneOffset + (isDST ? 60 : 0); // subtract 1 hour if DST is in effect
  const userDate = new Date(
    date.getTime() +
      userTimezoneOffset * 60000 -
      new Date().getTimezoneOffset() * 60000
  );
  return userDate.toISOString().split("T")[0];
};

const isUKDST = (date: Date): boolean => {
  const year = date.getFullYear();
  const dstStart = new Date(Date.UTC(year, 2, 31, 1)); // last Sunday in March
  const dstEnd = new Date(Date.UTC(year, 9, 31, 1)); // last Sunday in October
  dstStart.setUTCDate(dstStart.getUTCDate() - dstStart.getUTCDay());
  dstEnd.setUTCDate(dstEnd.getUTCDate() - dstEnd.getUTCDay());
  dstStart.setUTCHours(1);
  dstEnd.setUTCHours(1);
  return date >= dstStart && date < dstEnd;
};

export const objectBuilderForPDF = (
  patient: PatientInformation,
  partner?: PatientInformation,
  pdfData?: any
) => {
  const patientInformation: any = {
    patientId: patient.publicId,
    patientName:
      (patient.title ? patient.title + " " : "") +
      (patient.firstName ? patient.firstName + " " : "") +
      (patient.lastName ? patient.lastName : ""),
    patientDOB: patient.dateOfBirth ? patient.dateOfBirth : "",
    patientAddress:
      (patient.address.street1
        ? patient.address.street1 + ", "
        : patient.address.street2
        ? patient.address.street2 + ", "
        : "") + (patient.address.postalCode ? patient.address.postalCode : ""),
    patientContact: patient.phoneNo
      ? patient.phoneNo
      : patient.email
      ? patient.email
      : "",
    patientAge: patient.age ? patient.age : "",
  };

  if (partner) {
    if (partner.publicId) {
      patientInformation.partnerId = partner.publicId;
    }
    if (partner.title || partner.firstName || partner.lastName) {
      patientInformation.partnerName =
        (partner.title ? partner.title + " " : "") +
        (partner.firstName ? partner.firstName + " " : "") +
        (partner.lastName ? partner.lastName : "");
    }
    if (partner.dateOfBirth) {
      patientInformation.partnerDOB = partner.dateOfBirth;
    }
    if (partner.address) {
      patientInformation.partnerAddress =
        (partner.address.street1
          ? partner.address.street1 + ", "
          : partner.address.street2
          ? partner.address.street2 + ", "
          : "") +
        (partner.address.postalCode ? partner.address.postalCode : "");
    }
    if (partner.phoneNo || partner.email) {
      patientInformation.partnerContact =
        partner.phoneNo || partner.email || "";
    }
    if (partner.age) {
      patientInformation.partnerAge = partner.age || "";
    }
  }

  const object = { ...patientInformation, ...pdfData };

  return object;
};

export const removeEmptyValues = (obj: any) => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      const innerObj = removeEmptyValues(obj[key]);
      if (Object.keys(innerObj).length > 0) {
        newObj[key] = innerObj;
      }
    } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const calculateAge = (dateOfBirth: Date): number => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const objectToArray = <T>(obj: Record<string, T>): T[] => {
  return Object.values(obj).map((value) => ({ ...value }));
};

export const combineArrays = (
  input1: InputFieldType[],
  input2: InputFieldType[],
  data: any[]
) => {
  const combinedInputs = input1.concat(input2, [
    // {
    //   label: "Report date",
    //   type: "text",
    //   id: "reportDate",
    //   placeholder: "Enter here",
    //   required: false,
    // },
    {
      label: "Signature",
      type: "text",
      id: "signature",
      placeholder: "Enter here",
      required: false,
    },
  ]);
  return combinedInputs
    .map((input) => {
      const matchingData = data.find((d) => d.name === input.id);

      if (matchingData) {
        return {
          label: input.label,
          id: matchingData.name,
          value: matchingData.value || "",
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);
};

export const formatNameValuePairs = (
  inputArray: { name: string; value: any }[]
): { label: string; value: any; inputName: string }[] => {
  const formattedArray: { label: string; value: any; inputName: string }[] = [];

  for (const pair of inputArray) {
    const { name, value } = pair;
    let formattedName = name
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (str) => str.toUpperCase());

    if (typeof value === "object") {
      const nestedFormattedArray = formatNameValuePairs(
        Object.entries(value).map(([nestedName, nestedValue]) => ({
          name: nestedName,
          value: nestedValue,
        }))
      );
      // console.log("******", nestedFormattedArray);
      // value.push(...nestedFormattedArray);
      formattedArray.push({
        label: formattedName,
        value: nestedFormattedArray,
        inputName: name,
      });
    } else if (typeof value === "boolean") {
      formattedArray.push({
        label: formattedName,
        value: value ? "Yes" : "No",
        inputName: name,
      });
    } else if (
      name.includes("when") ||
      name.toString().includes("dateOfBirth")
    ) {
      formattedArray.push({
        label: formattedName,
        value: value ? formatToDateTime(value) : "",
        inputName: name,
      });
    } else {
      formattedArray.push({ label: formattedName, value, inputName: name });
    }
  }

  return formattedArray;
};
