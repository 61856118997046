import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addDaysToDateTime } from "../../util/date.utl";
import ActionArchive from "../actionIcons/ActionArchive";
import { archiveEvent } from "../../apis/events";
import useSnackbar from "../../hooks/useSnackbar";
import { formatToDateTime } from "../../util/data.util";

const StageCard: React.FC<TaskCardProps> = ({
  task,
  onDragStart,
  patientId,
  removeArchivedEvent,
}) => {
  const navigate = useNavigate();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const handlePatientClick = () => {
    navigate(`/patients/information/${task.patient.publicId}`);
  };

  const getLastStageUpdatedDate = () => {
    if (task.whenLastStageUpdated && task.currentStage.maxWaitTimeDays) {
      const result = addDaysToDateTime(
        task.whenLastStageUpdated,
        task.currentStage.maxWaitTimeDays
      );

      return (
        <>
          <Typography
            variant="body2"
            sx={{ color: "gray", fontSize: 12, display: "inline" }}
          >
            Deadline
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              fontSize: 12,
              flex: 1,
              textAlign: "end",
              color: result.isPast ? "red" : "green",
            }}
          >
            {result.newDate}
          </Typography>
        </>
      );
    } else {
      return (
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            fontSize: 12,
            flex: 1,
            textAlign: "end",
            color: "black",
          }}
        ></Typography>
      );
    }
  };

  const handleArchiveEvent = async () => {
    const [response, error] = await archiveEvent({
      publicId: task.eventPublicId,
      archived: true,
    });
    if (response) {
      removeArchivedEvent(task);
      successSnackbar("Event archived successfully.");
    } else {
      errorSnackbar("Failed to archive event.");
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        cursor: "pointer",
        width: "90%",
        boxShadow: "2",
        borderRadius: 3,
        "&:active": {
          cursor: "grab",
        },
      }}
      draggable
      onClick={() => console.log(task)}
      onDragStart={(e) => onDragStart(e, task)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={(task) => console.log(task)}
      >
        <Box width="70%">
          {!patientId && (
            <Typography
              variant="body1"
              sx={{ mb: 1, cursor: "pointer", color: "#007BFF" }}
              onClick={handlePatientClick}
            >
              {`${task.patient.firstName} ${task.patient.lastName}`}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              mb: 1,
              color: task.booking?.cancelled ? "red" : "",
              textDecoration: task.booking?.cancelled ? "line-through" : "none",
            }}
          >
            {task.name}
          </Typography>

          <Typography variant="body2" sx={{ mb: 1 }}>
            {`${task.assignee.title} ${task.assignee.firstName} ${task.assignee.lastName}`}
          </Typography>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" sx={{ mb: 1 }}>
              {task.assigneeTeam.name}
            </Typography>
          </Box>
          {task.booking && (
            <Box display="flex" justifyContent="space-between">
              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  color: task.booking?.cancelled ? "red" : "green",
                  textDecoration: task.booking?.cancelled
                    ? "line-through"
                    : "none",
                }}
              >
                {formatToDateTime(task.booking?.appointmentTime) || ""}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          width="30%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          {task.booking?.cancelled ? <></> : getLastStageUpdatedDate()}
          {task.currentStage.finalStage && (
            <ActionArchive handleClick={handleArchiveEvent} />
          )}
        </Box>
      </Box>
    </Paper>
  );
};
export default StageCard;
