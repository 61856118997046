import React from "react";
import { Button, Grid } from "@mui/material";

type Props = {
  onDownload: () => void;
  onReset: () => void;
};

const AddDownload: React.FC<Props> = ({ onDownload, onReset }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="50vh"
      width="100%"
      spacing={3}>
      <Grid item>
        <Button variant="contained" onClick={onDownload}>
          Download
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={onReset}>
          Create new
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddDownload;
