import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentByPatientId } from "../../apis/bookings";
import { Grid } from "@mui/material";
import { formatToDateTime } from "../../util/data.util";
import { CustomTable } from "../table/Table";
import { paymentStatusStyle } from "../../util/appointment/util";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import ComponentLoading from "../loading/ComponentLoading";
import {
  FILE_DELETED_SUCCESSFULLY,
  FILE_DELETE_FAILED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { deleteDocumentById } from "../../apis/documents";

interface ReportRowInterface {
  publicId: string;
  name: string;
  url: string;
  type: string;
  patient: PatientInformation;
  reportFields: [];
  createdBy: string;
  whenCreated: Date;
  read: false;
}
const AppointmentHistory = () => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { publicId } = patientObject.patient;
  const [history, setHistory] = useState<Appointment[]>();
  const [fileToDelete, setFileToDelete] = useState<ReportRowInterface | null>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getAppointmentHistory = async () => {
    const [response, error] = await getAppointmentByPatientId(publicId);
    if (response) {
      let data: Appointment[] = [];
      response.forEach((row: Appointment, index: number) => {
        data.push({
          serviceName: row.service.name,
          locationName: row.location.name,
          ...row,
        });
      });
      setHistory(data);
    } else {
      setHistory([]);
    }
  };
  const openConfirmModal = (row: ReportRowInterface) => {
    setFileToDelete(row);
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setFileToDelete(null);
  };

  const columns = [
    {
      label: "Appointment Time",
      key: "appointmentTime",
      sortable: true,
    },
    {
      label: "Service Name",
      key: "serviceName",
      sortable: true,
    },
    {
      label: "Location Name",
      key: "locationName",
      sortable: true,
    },
    {
      label: "Payment Status",
      key: "paymentStatus",
      style: paymentStatusStyle,
    },
    // {
    //   label: "Actions",
    //   key: "actions",
    // },
  ];

  const actions: Action[] = [
    {
      label: "",
      onClick: openConfirmModal,
      tooltipLabel: "Delete",
      icon: <DeleteOutlinedIcon style={{ cursor: "pointer" }} color="error" />,
    },
    // {
    //   label: "",
    //   onClick: handleView,
    //   icon: <VisibilityOutlinedIcon />,
    // },
  ];

  const handleDelete = async (row: ReportRowInterface) => {
    setIsLoading(true);
    // const [response, error] = await deleteDocumentById(row.publicId);
    // console.log(response, error);
    // if (response) {
    //   dispatch(
    //     activateSnackbar({
    //       active: true,
    //       message: `${FILE_DELETED_SUCCESSFULLY}  ${row.name}`,
    //       variant: SNACKBAR_SUCCESS,
    //     })
    //   );
    //   // getAllPatientDocuments();
    // } else {
    //   dispatch(
    //     activateSnackbar({
    //       active: true,
    //       message: `${FILE_DELETE_FAILED}  ${row.name}`,
    //       variant: SNACKBAR_ERROR,
    //     })
    //   );
    //   setIsLoading(false);
    // }
    // closeConfirmModal();
  };

  useEffect(() => {
    getAppointmentHistory();
  }, []);
  return (
    <>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ overflow: "auto", height: 400 }}
          >
            {history && (
              <CustomTable
                data={history}
                columns={columns}
                //   onRowClick={handleRowClick}
                searchable={true}
                // actions={actions}
                dateFormatter={formatToDateTime}
                defaultSort={{
                  key: columns[0].key,
                  direction: "desc",
                }}
                height={"70vh"}
              />
            )}
          </Grid>
        </Grid>
      )}
      {fileToDelete && (
        <ConfirmationDialog
          open={confirmModalOpen}
          onClose={closeConfirmModal}
          title={`Are you sure you want to delete ${fileToDelete?.name}?`}
          onCancel={closeConfirmModal}
          onDelete={() => handleDelete(fileToDelete)}
        />
      )}
    </>
  );
};

export default AppointmentHistory;
