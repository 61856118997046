import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface CustomCheckboxProps {
  label: string;
  className?: string;
  size?: "small" | "medium";
  onChange: (checked: boolean) => void;
  checked: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  className = "",
  size = "medium",
  onChange,
  checked,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={className}
          size={size}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
