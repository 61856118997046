import jsPDF from "jspdf";

import {
  addFooter,
  addHeader,
  addPatientInfo,
  addProtocolInfo,
  addTitle,
  addSignature,
  centerPosition,
  commonSpaceBetween,
  getTextHeight,
  marginLeft,
  textFontPoint,
  addCreatedDate,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";

//created by Ranjit Jeve

let globalPosition = 10;

const ReportTitle = "Referral Letter (Doner Sperm)";

const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
const text = "Please feel free to contact us for any further questions.";

export const downloadDonarSpermCycleProtocol = async (
  patientData: DonorSperm
) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  globalPosition = addTitle(doc, ReportTitle, globalPosition);
  donorSpermProtocolData(doc, patientData);
  addSignature(doc, patientData.signature, globalPosition);
  addProtocolInfo(doc, clinicAddress, email, phone);
  doc.save(
    "RL_DonorSperm_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );
  globalPosition = 10;
  return;
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};

//report specific functions

const donorSpermProtocolData = (doc: any, patientData: DonorSperm) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "Dear Team in Charge of Donor Sperm Coordination,",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "Could you please arrange an appointment with the patient to discuss the option of donor sperm treatment and coordinate the procurement of the necessary sperm samples. Additionally, please advise the patient to schedule a counseling appointment to address any related concerns.",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      "Below is a summary of the patient's medical history and the proposed course of treatment.",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 4)
    )

    .setFontSize(12)
    .text(
      "Treatment Protocol",
      centerPosition,
      getGlobalPosition(commonSpaceBetween * 2),
      null,
      null,
      "center"
    )
    .setFontSize(textFontPoint)
    .text(
      "•    Treatment Type: " + patientData.treatmentType,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "•    Protocol: " + patientData.protocolName,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Stimulation Dose: " + patientData.stimulationDose,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Drug Regime: " + patientData.drugRegime,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Trigger " + patientData.trigger,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Embryo Transfer: " + patientData.embryoTransfer,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Luteal Support: " + patientData.lutealSupport,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•    Additional Comments: " + patientData.additionalComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 180, align: "left" },
      null
    )
    .text(
      "We extend our warmest wishes to you for a successful treatment cycle and are committed to providing you with the highest quality of care and support throughout your journey. ",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 12),
      { maxWidth: 180, align: "justify" }
    )
    .text(
      text,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 3),
      { maxWidth: 180, align: "justify" },
      null
    )
    .text(
      "Yours Sincerely.",
      marginLeft,
      getTextHeight(
        doc,
        globalPosition,
        text,
        clinicAddress,
        globalPosition,
        email,
        phone
      )
    );
};
