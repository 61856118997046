import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const PreTreatmentPlan: React.FC<PreTreatmentPlanProps> = ({
  preTreatmentPlan,
  setPreTreatmentPlan,
}) => {
  const [supplements, setSupplements] = useState<Supplement[]>([]);
  const allSupplements: any = useSelector<SupplementsState>(
    (state) => state.supplements
  );
  const { handleSubmit } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    // Call the createService API for each selected service
    console.log(preTreatmentPlan);
    // values.forEach(async (event) => {
    //   try {
    //     await createEvent(event)
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // });
  };

  const handleCheckboxChange = (
    option: Supplement,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    if (checked) {
      setPreTreatmentPlan((prevValues) => [...prevValues, option.publicId]);
    } else {
      setPreTreatmentPlan((prevValues) =>
        prevValues.filter((value) => value !== option.publicId)
      );
    }
  };

  useEffect(() => {
    if (allSupplements.supplements.length > 0) {
      setSupplements(allSupplements.supplements);
    } else {
      setSupplements([]);
    }
    // getAllSupplements()
    //   .then((response) => {
    //     setSupplements(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }, []);

  return (
    <>
      {supplements.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <div className="row">
              <div className="col-sm-12 p-2">
                <Typography variant="h6" gutterBottom>
                  Pre-treatment Plan
                </Typography>
                <hr />
                <Grid container spacing={2}>
                  {supplements?.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.publicId}>
                      <FormControl component="fieldset" className={""}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => handleCheckboxChange(item, e)}
                                name={`${item.name}`}
                                checked={preTreatmentPlan.some(
                                  (value) => value === item.publicId
                                )}
                              />
                            }
                            label={`${item.name} - ${item.dose}`}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            {/* <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <PrimaryButton
                  label={"Save"}
                  type={"submit"}
                  disable={values.length === 0}
                  className={""}
                />
              </Grid>
            </Grid> */}
          </>{" "}
        </form>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}>
          <Typography variant="h5" component="div" color={"red"} gutterBottom>
            Supplements not available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PreTreatmentPlan;
