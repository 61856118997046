import { Grid } from "@mui/material";
import React from "react";
import ShowNameValue from "./ShowNameValue";
import HeaderElement from "./HeaderElement";
const ShowReports: React.FC<any> = ({ data, setShowTable, handleDownload }) => {
  return (
    <>
      <HeaderElement
        name={data?.name}
        whenCreated={data?.whenCreated}
        downloadFunction={() => handleDownload(data)}
        backFunction={() => {
          setShowTable(true);
        }}
      />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ShowNameValue data={data.newFields} />
      </Grid>
    </>
  );
};

export default ShowReports;
