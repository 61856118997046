import { Box, Card, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import UnderConstruction from "../messages/UnderConstruction";
import NewFeatureComingSoon from "../messages/NewFeatureComming";

const Alerts = () => {
  const rows = [
    { id: 1, name: "John", age: 32 },
    { id: 2, name: "Jane", age: 28 },
    { id: 3, name: "Bob", age: 45 },
  ];

  const columns = [
    { id: "name", label: "Name" },
    { id: "age", label: "Age" },
  ];

  return (
    <Card sx={{ boxShadow: 1, height: 400, borderRadius: 4 }}>
      {/* <Box sx={{}}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: 0,
              borderBottom: 1,
            }}>
            <IconButton>
              <CampaignOutlinedIcon />
            </IconButton>
            <Typography variant="h6">Alerts</Typography>
            <hr />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ overflow: "auto", height: 350 }}>
            <StripedTable />
           
          </Grid>
        </Grid>
      </Box> */}
      <NewFeatureComingSoon />
    </Card>
  );
};

export default Alerts;
