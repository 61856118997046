import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState: SnackbarState = {
  snackbar: {
    active: false,
    variant: "",
    message: "",
    // setIsOpen: (isOpen: boolean) => {},
  },
};

export const snackbarSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    activateSnackbar: (
      state: SnackbarState,
      action: PayloadAction<ModalEntities>
    ) => {
      state.snackbar.active = action.payload.active;
      state.snackbar.variant = action.payload.variant;
      state.snackbar.message = action.payload.message;
    },
    deactivateSnackbar: (state: SnackbarState) => {
      state.snackbar = initialState.snackbar;
    },
  },
});

export default snackbarSlice.reducer;
export const { activateSnackbar, deactivateSnackbar } = snackbarSlice.actions;
