import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TermsOfUse = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        paddingTop: "16px",
      }}>
      <Typography variant="body2" sx={{ mb: "16px", fontSize: 12 }}>
        <hr />
        Access to the Swivo Technologies product or portal through this webpage
        is password protected and is available only to authorized users. By
        clicking on the log in button, you acknowledge that you have read,
        understood, and agreed to abide by Swivo Technologies' current{" "}
        <a
          href="https://www.swivo.io/terms-conditions"
          target="_blank"
          rel="noopener noreferrer">
          Terms of Use
        </a>{" "}
        governing your access to and use of{" "}
        <a
          href="https://www.swivo.io/"
          target="_blank"
          rel="noopener noreferrer">
          www.swivo.io
        </a>
        , as well as any applicable license agreement with Swivo Technologies.
        Please ensure the confidentiality of your login credentials (username
        and password) and do not share them with any unauthorized third parties.
        Swivo Technologies is not responsible for any misuse of your account due
        to unauthorized access. For more information on how Swivo Technologies
        handles your personal data, please refer to our{" "}
        <a
          href="https://www.swivo.io/privacy-policy"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
        . If you have any questions or need assistance, please contact our
        support team at <a href="mailto:info@swivo.io">info@swivo.io</a>. Please
        note that third-party notices may apply to certain features or resources
        provided by Swivo Technologies. For more details, please review the{" "}
        <a
          href="https://www.swivo.io/3rd-party-notices"
          target="_blank"
          rel="noopener noreferrer">
          3rd Party Notices
        </a>{" "}
        section.
      </Typography>
    </Box>
  );
};

export default TermsOfUse;
