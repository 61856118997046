import { Button } from "@mui/material";
import React from "react";

const PrimaryButton = React.memo<CustomButtonType>((props: any) => {
  if (props.type === "submit") {
    return (
      <Button
        variant="contained"
        style={{ borderRadius: "5px" }}
        color="primary"
        startIcon={props.startIcon}
        className={props.className}
        type={props.type}
        disabled={props.disable || false}
      >
        <b> {props.label}</b>
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        style={{ borderRadius: "5px" }}
        onClick={props.onClick}
        color="primary"
        startIcon={props.startIcon}
        className={props.className}
        type={props.type}
        disabled={props.disable || false}
      >
        <b> {props.label}</b>
      </Button>
    );
  }
});

export default PrimaryButton;
