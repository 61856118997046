import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { useDispatch, useSelector } from "react-redux";
import AddSupplement from "./AddSupplement";
import ShowSupplementDetails from "./ShowSupplementDetails";
import { getAllSupplements } from "../../apis/supplements";
import ComponentLoading from "../loading/ComponentLoading";

const ShowSupplements = () => {
  const [supplements, setSupplements] = useState<Supplement[]>([]);
  const [selectedSupplement, setSelectedSupplement] =
    useState<Supplement | null>(null);
  const [isAddSupplement, setIsAddSupplement] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const allSupplements: any = useSelector<SupplementsState>(
    (state) => state.supplements
  );
  const getSupplements = async () => {
    if (allSupplements.supplements.length > 0) {
      setSupplements(allSupplements.supplements);
      setIsLoading(false);
    } else {
      const [response, error] = await getAllSupplements();
      if (response) {
        console.log(response);

        setSupplements(
          response.map((item: Supplement) => {
            return {
              ...item,
            };
          })
        );
        setIsLoading(false);
      } else {
        console.log(error);
      }
    }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Dose",
      key: "dose",
    },
  ];

  const handleRowClick = (row: any) => {
    console.log(row);
    setIsAddSupplement(false);
    setSelectedSupplement(row);
  };
  useEffect(() => {
    getSupplements();
  }, []);

  useEffect(() => {
    getSupplements();
  }, [reload]);
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {supplements && (
            <CustomTable
              data={supplements}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              optionButtonText="Add Supplement"
              setOptionButton={setIsAddSupplement}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "asc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}

      {(isAddSupplement || selectedSupplement) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}>
          {isAddSupplement ? (
            <AddSupplement
              setIsAddSupplement={setIsAddSupplement}
              setReload={setReload}
              reload={reload}
            />
          ) : (
            selectedSupplement && (
              <ShowSupplementDetails
                selectedSupplement={selectedSupplement}
                setSelectedSupplement={setSelectedSupplement}
                setReload={setReload}
                reload={reload}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowSupplements;
