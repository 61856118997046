import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

type CapturePhotoModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (dataURL: string) => void;
};

const CapturePhotoModal: React.FC<CapturePhotoModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [photo, setPhoto] = useState("");
  const [photo1, setPhoto1] = useState<Blob | null>(null);

  const handleStartCapture = async () => {
    stream = await navigator.mediaDevices.getUserMedia({ video: true });
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  };

  const handleCapturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        const width = videoRef.current.videoWidth;
        const height = videoRef.current.videoHeight;
        canvasRef.current.width = height * (4 / 3); // use 4:3 aspect ratio
        canvasRef.current.height = height;
        context.drawImage(
          videoRef.current,
          0,
          0,
          width,
          height,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const data = canvasRef.current.toDataURL("image/png", 1); // use maximum quality
        setPhoto(data);
        canvasRef.current.toBlob(
          (blob) => {
            setPhoto1(blob);
          },
          "image/png",
          1
        ); // use maximum quality
        // stream?.removeTrack(stream?.current?.srcObject?.getTracks()).forEach;
      }
    }
  };

  const handleSavePhoto = async () => {
    onSave(photo);
    // if (photo1) {
    //   var formData: FormData = new FormData();
    //   formData.append(FILE, photo1);
    //   formData.append(FILE_NAME, "test_1.png");
    //   formData.append(CONTAINER_NAME, "pictures");
    //   const response = await uploadFile(formData);
    //   console.log(response);
    // }
    setPhoto("");
  };
  let stream: MediaStream | null = null;

  const handleClose = async () => {
    console.log("first");
    setPhoto("");
    onClose();
    stream = await navigator.mediaDevices.getUserMedia({ video: false });
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      // videoRef.current.srcObject = null;
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={{ p: 2, borderRadius: 5, height: "100%" }}>
        <CardHeader
          title="Capture Photo"
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardActions>
          <Button onClick={handleStartCapture}>Start</Button>
          <Button onClick={handleCapturePhoto}>Capture</Button>

          <Button onClick={handleSavePhoto}>Save</Button>
          <Button onClick={() => setPhoto("")}>Remove</Button>
        </CardActions>
        <CardContent>
          <video ref={videoRef} />
          <canvas ref={canvasRef} style={{ display: "none" }} />
          {photo && <img src={photo} alt="Captured photo" />}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CapturePhotoModal;
