import React, { useEffect, useState } from "react";
import {
  DOCUMENTS_CONTAINER,
  NOTEPAD_IMAGES_CONTAINER,
} from "../../constants/ContainerConstants";
import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import AdditionalDocuments from "./AdditionalDocuments";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import DocumentViewer from "./DoxumentViewer";
import { useDispatch } from "react-redux";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  FILE_DELETED_SUCCESSFULLY,
  FILE_DELETE_FAILED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import {
  deleteDocumentById,
  getDocuments,
  getDocumentsByType,
} from "../../apis/documents";
import ComponentLoading from "../loading/ComponentLoading";
import useViewFile from "../../hooks/useViewFile";

interface ReportRowInterface {
  publicId: string;
  name: string;
  url: string;
  type: string;
  patient: PatientInformation;
  reportFields: [];
  createdBy: string;
  whenCreated: Date;
  read: false;
}

interface ShowAdditionalDocumentsProps {
  tableButtonLabel: string;
  patientPublicId?: string;
  documentType?: string;
}

const ShowAdditionalDocuments: React.FC<ShowAdditionalDocumentsProps> = ({
  tableButtonLabel,
  patientPublicId,
  documentType,
}) => {
  const [documents, setDocuments] = useState<ReportResponse[]>([]);
  const [isUploadDocuments, setIsUploadDocuments] = useState<boolean>(false);
  const { downloadFile } = useDownloadFile();
  const [src] = useState<string>();
  const [type, setType] = useState<string>();
  const dispatch = useDispatch();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<ReportRowInterface | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { viewFile } = useViewFile();
  const getAllPatientDocuments = async () => {
    if (patientPublicId) {
      setIsLoading(true);
      const [response, error] = await getDocuments(patientPublicId);
      console.log(response);
      if (response) {
        setDocuments(response);
      }
      setIsLoading(false);
    }
  };

  const getAllDocumentsByType = async () => {
    if (documentType) {
      setIsLoading(true);
      const [response, error] = await getDocumentsByType(documentType);
      if (response) {
        setDocuments(response);
      }
      setIsLoading(false);
    }
  };

  const handleDownload = async (row: ReportRowInterface) => {
    setIsLoading(true);
    await downloadFile(row.url);
    setIsLoading(false);
  };
  const handleView = async (row: any) => {
    setIsLoading(true);
    await viewFile(row.url);
    setIsLoading(false);
  };

  const openConfirmModal = (row: ReportRowInterface) => {
    setFileToDelete(row);
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setFileToDelete(null);
  };

  const handleDelete = async (row: ReportRowInterface) => {
    setIsLoading(true);
    const [response, error] = await deleteDocumentById(row.publicId);
    console.log(response, error);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: `${FILE_DELETED_SUCCESSFULLY}  ${row.name}`,
          variant: SNACKBAR_SUCCESS,
        })
      );
      getAllPatientDocuments();
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: `${FILE_DELETE_FAILED}  ${row.name}`,
          variant: SNACKBAR_ERROR,
        })
      );
      setIsLoading(false);
    }
    closeConfirmModal();
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Actions",
      key: "actions",
    },
  ];
  const actions: Action[] = [
    {
      label: "",
      onClick: handleView,
      tooltipLabel: "View Document",
      icon: (
        <VisibilityOutlinedIcon style={{ cursor: "pointer" }} color="primary" />
      ),
    },
    {
      label: "",
      onClick: handleDownload,
      tooltipLabel: "Download Document",
      icon: (
        <FileDownloadOutlinedIcon
          style={{ cursor: "pointer" }}
          color="primary"
        />
      ),
    },
    {
      label: "",
      onClick: openConfirmModal,
      tooltipLabel: "Delete Document",
      icon: <DeleteOutlinedIcon style={{ cursor: "pointer" }} color="error" />,
    },
  ];

  useEffect(() => {
    if (patientPublicId) {
      setType(DOCUMENTS_CONTAINER);
      getAllPatientDocuments();
    } else if (documentType) {
      setType(NOTEPAD_IMAGES_CONTAINER);
      getAllDocumentsByType();
    }
  }, []);

  return isLoading ? (
    <ComponentLoading />
  ) : (
    <Grid container xs={12} sm={12} md={12} lg={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
        {documents && (
          <CustomTable
            data={documents}
            columns={columns}
            actions={actions}
            searchable={true}
            optionButtonText={tableButtonLabel}
            setOptionButton={setIsUploadDocuments}
            defaultSort={{
              key: columns[0].key,
              direction: "desc",
            }}
            height={"50vh"}
          />
        )}
      </Grid>
      {isUploadDocuments && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}
        >
          {type === DOCUMENTS_CONTAINER
            ? isUploadDocuments && (
                <AdditionalDocuments
                  setIsUploadDocuments={setIsUploadDocuments}
                  getAllDocuments={getAllPatientDocuments}
                  type={type}
                />
              )
            : type && (
                <AdditionalDocuments
                  setIsUploadDocuments={setIsUploadDocuments}
                  getAllDocuments={getAllDocumentsByType}
                  type={type}
                />
              )}
          {src && type && <DocumentViewer type={type} src={src} />}
        </Grid>
      )}
      {fileToDelete && (
        <ConfirmationDialog
          open={confirmModalOpen}
          onClose={closeConfirmModal}
          title={`Are you sure you want to delete ${fileToDelete?.name}?`}
          onCancel={closeConfirmModal}
          onDelete={() => handleDelete(fileToDelete)}
        />
      )}
    </Grid>
  );
};

export default ShowAdditionalDocuments;
