import { AZURE_URL } from "../constants/env-constants";

export const getAllSupplements = async () => {
  try {
    const response = await AZURE_URL.get("/supplement/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewSupplement = async (data: CreateSupplementRequest) => {
  try {
    const response = await AZURE_URL.post("/supplement/create", data);
    console.log(response);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    console.log(error);
    return [null, error.response.data];
  }
};

export const updateSupplement = async (data: UpdateSupplementRequest) => {
  try {
    const response = await AZURE_URL.put("/supplement/update", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSupplement = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/supplement/delete?publicId=${id}`
    );
    console.log(response);
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    console.log(error);
    return [null, "Server Error: " + error.message];
  }
};
