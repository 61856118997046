import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: RoleState = { sites: [] };

export const roleSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    addRoles: (state, action: PayloadAction<string[]>) => {
      state.sites = action.payload;
    },
  },
});

export default roleSlice.reducer;
export const { addRoles } = roleSlice.actions;
