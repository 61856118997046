import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { UseFormRegister } from "react-hook-form";

interface OptionType {
  value: string;
  label: string;
}

interface MultiSelectInputProps {
  label: string;
  name: string;
  className?: string;
  options: string[];
  id: string;
  value: any;
  setValue: any;
}

const filter = createFilterOptions<string>();

const MultiSelectInput = ({
  label,
  name,
  className,
  options,
  id,
  setValue,
  value,
}: MultiSelectInputProps): JSX.Element => {
  return (
    <Autocomplete
      multiple
      className={className || ""}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push(`${params.inputValue}`);
        }
        return filtered;
      }}
      getOptionLabel={(option) => option}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Select options..."
        />
      )}
    />
  );
};

export default MultiSelectInput;
