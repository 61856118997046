export const getConsultationContainerName = (patientPublicId: string) => {
  return `consultation`;
};

export const getConsultationFileName = (
  patientPublicId: string,
  consultationType: string
) => {
  const currentTime = new Date();
  const timestamp = currentTime.toISOString().replace(/[:.]/g, "-");
  return `${patientPublicId}_${consultationType}_${timestamp}.json`;
};
