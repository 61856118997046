import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PatientPathwayState = {
  patientPathways: [],
};

const patientPathwaySlice = createSlice({
  name: "patientPathways",
  initialState,
  reducers: {
    addPathway: (state, action: PayloadAction<PatientPathwaySlice[]>) => {
      state.patientPathways = action.payload;
    },
    removePathway: (state, action: PayloadAction<string>) => {
      state.patientPathways = state.patientPathways.filter(
        (pathway) => pathway.servicePublicId !== action.payload
      );
    },
    updateCurrentStage: (
      state,
      action: PayloadAction<{
        eventPublicId: string;
        currentStage: Stage;
      }>
    ) => {
      const { eventPublicId, currentStage } = action.payload;
      const pathway = state.patientPathways.find(
        (pathway) => pathway.eventPublicId === eventPublicId
      );
      if (pathway) {
        pathway.currentStage = currentStage;
      }
    },
  },
});

export const { addPathway, removePathway, updateCurrentStage } =
  patientPathwaySlice.actions;
export default patientPathwaySlice.reducer;
