import { useState } from "react";
import { downloadFileByUrl } from "../apis/files.api";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../redux/slice/snackbarSlice";
import {
  FILE_DOWNLOADED_SUCCESSFULLY,
  FILE_NOT_FOUND,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
} from "../constants/Constants";
import { getContentType } from "../util/files.utl";

type DownloadFileResponse = [string | null, any | null];

type UseDownloadFile = {
  getFileSrc: (url: string) => Promise<DownloadFileResponse>;
  downloadFile: (url: string) => Promise<void>;
  getSVGFile: (url: string) => Promise<DownloadFileResponse>;
};

export const useDownloadFile = (): UseDownloadFile => {
  const dispatch = useDispatch();

  const getFileSrc = async (url: string): Promise<DownloadFileResponse> => {
    try {
      const [response, error] = await downloadFileByUrl({ url });
      if (response) {
        const blobUrl = URL.createObjectURL(response);
        return [blobUrl, null];
      } else {
        return [null, error];
      }
    } catch (error: any) {
      return [null, error];
    }
  };

  const getSVGFile = async (url: string): Promise<DownloadFileResponse> => {
    try {
      const [response, error] = await downloadFileByUrl({ url });
      if (response) {
        const blobUrl = URL.createObjectURL(response);
        const svgResponse = await fetch(blobUrl);
        const svgContent = await svgResponse.text();
        return [svgContent, null];
      } else {
        return [null, error];
      }
    } catch (error: any) {
      return [null, error];
    }
  };

  const downloadFile = async (url: string): Promise<void> => {
    if (url) {
      const fileName = url.split("/").pop() || "file";
      const data: { url: string } = {
        url: url,
      };
      try {
        const [response, error] = await downloadFileByUrl(data);
        if (response) {
          // Set the appropriate Content-Type based on the file extension
          const blob = new Blob([response], { type: getContentType(fileName) });
          const blobUrl = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = fileName; // Set the downloaded file name
          // Programmatically trigger the download
          link.click();

          // Clean up the blob URL
          URL.revokeObjectURL(blobUrl);
          dispatch(
            activateSnackbar({
              active: true,
              message: FILE_DOWNLOADED_SUCCESSFULLY,
              variant: SNACKBAR_INFO,
            })
          );
        } else {
          console.log(error);
        }
      } catch (error: any) {
        console.error("Error downloading file:", error.response.data);
      }
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: FILE_NOT_FOUND,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  return {
    getFileSrc,
    downloadFile,
    getSVGFile,
  };
};
