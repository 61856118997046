import { Box, Card, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  formatNameValuePairs,
  formatToDateTime,
  getNameValueObject,
} from "../../util/data.util";
import SecondaryButton from "../buttons/SecondaryButton";
import ShowNameValue from "./ShowNameValue";
import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  FemaleFertilityDetailsForm,
  FertilityDetails,
} from "../../util/patient/firstConsultation.form.helper";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import HeaderElement from "./HeaderElement";
import ShowTldrawData from "../notepad/ShowTldrawData";

const ShowConsultation: React.FC<ShowFirstConsultationProps> = ({
  data,
  setShowTable,
}) => {
  const [previousTreatment, setPreviousTreatment] = useState<any>();
  const [isLoadingSVG, setIsLoadingSVG] = useState(true);
  const { getSVGFile } = useDownloadFile();
  const [svgContent, setSvgContent] = useState<any>();
  const canvasRef = useRef<SVGSVGElement | null>(null);
  const [svgWidth, setSvgWidth] = useState(900);
  const [svgHeight, setSvgHeight] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const { previousTreatments } = data;
    console.log(data);
    if (data.handwrittenNotes?.length) {
      download(data.handwrittenNotes[0].url);
      setSvgHeight(data.handwrittenNotes[0].height);
    }
    let resultArray: any = [];
    previousTreatments?.map((item: any) => {
      const formattedData = getNameValueObject(item);
      const formattedArray = formatNameValuePairs(formattedData);

      resultArray.push(formattedArray);
      setPreviousTreatment(resultArray);
    });
  }, []);

  const download = async (url: string) => {
    dispatch(loading());
    const [response, error] = await getSVGFile(url);
    setIsLoadingSVG(false);
    setSvgContent(response);
    dispatch(doneLoading());
  };

  useEffect(() => {
    const svg = canvasRef.current;
    if (!svg) return;

    svg.innerHTML = svgContent.toString();
  }, [svgContent]);

  useEffect(() => {
    const handleResize = () => {
      // Calculate the new height based on the width and aspect ratio
      const newHeight = (svgWidth * svgContent?.height) / svgContent?.width;
      setSvgHeight(newHeight);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial calculation for the height
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [svgWidth]);
  const showData = (patient: PatientInformation) => {
    const { patientDetails } = patient;

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {patientDetails?.tryingSinceMonths && (
          <React.Fragment>
            <Box sx={{ marginBottom: 1, display: "flex" }}>
              <Box sx={{ width: "50%" }}>
                <Typography variant="subtitle1">
                  <b>Tying Since</b>
                </Typography>
              </Box>{" "}
              <Box sx={{ width: "50%" }}>
                <Typography variant="body1">
                  {patientDetails.tryingSinceMonths
                    ? `${patientDetails.tryingSinceMonths} Months `
                    : " -"}
                  {patientDetails.tryingSinceYears
                    ? `${patientDetails.tryingSinceYears} Years`
                    : " -"}
                </Typography>
              </Box>
            </Box>
          </React.Fragment>
        )}

        {patientDetails?.cpLiveChildren && (
          <React.Fragment>
            <Box sx={{ marginBottom: 1, display: "flex" }}>
              <Box sx={{ width: "50%" }}>
                <Typography variant="subtitle1">
                  <b>Living Children</b>
                </Typography>
              </Box>{" "}
              <Box sx={{ width: "50%" }}>
                <Typography variant="body1">
                  {patientDetails.cpLiveChildren
                    ? `${patientDetails.cpLiveChildren} from current partner`
                    : " -"}
                  <br />
                  {patientDetails.ppLiveChildren
                    ? `${patientDetails.ppLiveChildren} from previous partner`
                    : " -"}
                </Typography>
              </Box>
            </Box>
          </React.Fragment>
        )}
        {patientDetails &&
          FemaleFertilityDetailsForm?.map((item: InputFieldType) => (
            <React.Fragment>
              <Box sx={{ marginBottom: 1, display: "flex" }}>
                <Box sx={{ width: "50%" }}>
                  <Typography variant="subtitle1">
                    {patientDetails[item.id] && <b>{item.label}</b>}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography variant="body1">
                    {patientDetails[item.id]}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          ))}

        {patientDetails &&
          FertilityDetails?.map((item: InputFieldType) => (
            <React.Fragment>
              <Box sx={{ marginBottom: 1, display: "flex" }}>
                <Box sx={{ width: "50%" }}>
                  <Typography variant="subtitle1">
                    {patientDetails[item.id] && <b>{item.label}</b>}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography variant="body1">
                    {patientDetails[item.id]}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          ))}
      </Grid>
    );
  };
  return (
    <div id="print-content">
      <HeaderElement
        name={data?.name}
        whenCreated={data?.whenCreated}
        backFunction={() => {
          setShowTable(true);
          localStorage.removeItem("persistenceKey");
        }}
      />
      <Grid container>
        <Grid container xs={12} sm={12} md={12} padding={2}>
          {data.patient && data.name.includes("First") && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h6">Patient </Typography>
              {/* <ShowNameValueForConsultation data={patientInfo} /> */}
              {showData(data.patient)}
            </Grid>
          )}
          {data.partner && data.name.includes("First") && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h6">Partner</Typography>
              {/* <ShowNameValueForConsultation data={partnerInfo} /> */}
              {showData(data.partner)}
            </Grid>
          )}
        </Grid>
        {data.handwrittenNotes?.length && (
          <>
            {data.handwrittenNotes?.map((item: any) => (
              <ShowTldrawData url={item.url} />
            ))}
          </>
          // <div
          //   style={{
          //     background: "linear-gradient(#f5f5f5, #eaeaea)",
          //     padding: "20px",
          //     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          //     borderRadius: "5px",
          //   }}
          // >
          //   <Card
          //     sx={{
          //       borderBottomLeftRadius: 5,
          //       borderBottomRightRadius: 5,
          //       border: "5px solid 0",
          //     }}
          //     elevation={0}
          //   >
          //     <svg
          //       ref={canvasRef}
          //       style={{
          //         width: svgWidth,
          //         height: data?.handwrittenNotes[0]?.height + 100, // Set width to 100% to fill the available space
          //       }}
          //     />
          //   </Card>
          // </div>
        )}
        {data.patient && (
          <Grid item xs={12} sm={12} md={12}>
            <hr />
          </Grid>
        )}
        {previousTreatment && (
          <>
            <Grid container xs={12} sm={12} md={12} padding={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5">Previous Treatments</Typography>{" "}
                {previousTreatment.map((prev: any) => (
                  <ShowNameValue data={prev} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
          </>
        )}
        {data.treatments && (
          <>
            <Grid container xs={12} sm={12} md={12} padding={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5">Treatments</Typography>{" "}
                {data.treatments?.map((treatment: Treatment, index: number) => (
                  <Typography variant="subtitle1" key={index}>
                    <b>{treatment.service.name}</b>
                  </Typography>
                ))}
              </Grid>
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
          </>
        )}

        {data.pretreatmentPlan && (
          <>
            <Grid container xs={12} sm={12} md={12} padding={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6">Pre-Treatment Plan</Typography>
              </Grid>
              {data.pretreatmentPlan?.supplements.map(
                (supplement: Supplement, index: number) => (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Box sx={{ width: "30%" }}>
                        <Typography variant="subtitle1">
                          <b>{supplement.name}</b>
                        </Typography>
                      </Box>{" "}
                      <Box sx={{ width: "50%" }}>{supplement.dose}</Box>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
          </>
        )}

        {data.tests && (
          <Grid container xs={12} sm={12} md={12} padding={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6">Tests</Typography>
            </Grid>
            {data.tests?.map((test: Test, index: number) => (
              <Grid item xs={12} sm={12} md={12} key={index}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="subtitle1">
                      <b>{test.service.name}</b>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ShowConsultation;
