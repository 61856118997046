import { useEffect } from "react";
import {
  HYCOSY,
  PREGNANCY_SCAN,
  ULTRASOUND_SCAN,
  PROTOCOL_FRESH_CYCLE,
  PROTOCOL_DONOR_SPERM,
  PROTOCOL_FROZEN_CYCLE,
  PROTOCOL_IBSPAIN,
  FIRST_CONSULTATION,
  FOLLOW_UP_CONSULTATION,
  NOTE,
  P4_FERTILITY_LETTER,
  P4_GYNAECARE_LETTER,
  REFERRAL_LETTER,
  ONE_STOP_FERTILITY,
} from "../constants/Constants";
import { getObjectFromNameValue } from "../util/data.util";
import { NoteForm } from "../util/forms/form.helper";
import { downloadFertilityLetter } from "../util/letterCreation/p4FertilityLetterPdf";
import { downloadGynaeLetter } from "../util/letterCreation/p4GynaeLetterPdf";
import { downloadDonarSpermCycleProtocol } from "../util/protocolCreation/donorSpermCycleProtocol";
import { downloadFrozenCycleProtocol } from "../util/protocolCreation/forzenCycleProtcol";
import { downloadFreshCycleProtocol } from "../util/protocolCreation/freshCycleProtocol";
import { downloadIBSpainCycleProtocol } from "../util/protocolCreation/ibSpainCyclePeotocol";
import { downloadHycosyReport } from "../util/reportsCreation/hycosyReportPdf";
import { downloadPregnancyReport } from "../util/reportsCreation/pregnancyReportPdf";
import { downloadUltrasoundReport } from "../util/reportsCreation/ultrasoundReportPdf";
import { downloadOneStopFertility } from "../util/reportsCreation/oneStopFertility";

const usePDFDownload = () => {
  const downloadPDF = (toConvert: any) => {
    switch (toConvert.type) {
      case HYCOSY:
        downloadHycosyReport(getObjectFromNameValue(toConvert.reportFields));
        break;
      case PREGNANCY_SCAN:
        downloadPregnancyReport(getObjectFromNameValue(toConvert.reportFields));
        break;
      case ULTRASOUND_SCAN:
        downloadUltrasoundReport(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case ONE_STOP_FERTILITY:
        downloadOneStopFertility(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case PROTOCOL_FRESH_CYCLE:
        downloadFreshCycleProtocol(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case PROTOCOL_DONOR_SPERM:
        downloadDonarSpermCycleProtocol(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case PROTOCOL_FROZEN_CYCLE:
        downloadFrozenCycleProtocol(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case PROTOCOL_IBSPAIN:
        downloadIBSpainCycleProtocol(
          getObjectFromNameValue(toConvert.reportFields)
        );
        break;
      case P4_FERTILITY_LETTER:
        downloadFertilityLetter(toConvert.pdfData);
        break;
      case P4_GYNAECARE_LETTER:
        downloadGynaeLetter(toConvert.pdfData);
        break;
      case REFERRAL_LETTER:
        downloadGynaeLetter(toConvert.pdfData);
        break;
    }
  };

  return downloadPDF;
};

export default usePDFDownload;
