import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import React from "react";

import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import NewFeatureComingSoon from "../messages/NewFeatureComming";

const Communications = () => {
  return (
    <Card sx={{ boxShadow: 1, height: 400, borderRadius: 4 }}>
      {/* <Box sx={{}}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: 0,
              borderBottom: 1,
            }}>
            <IconButton>
              <ForumOutlinedIcon />
            </IconButton>
            <Typography variant="h6">Comunications</Typography>
            <hr />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ overflow: "auto", height: 350 }}>
            <StripedTable
              data={[]}
              onRowClick={function (row: Row): void {
                throw new Error("Function not implemented.");
              }}
            />
          </Grid>
        </Grid>
      </Box> */}
      <NewFeatureComingSoon />
    </Card>
  );
};

export default Communications;
