import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { getAllPathways } from "../../apis/pathway";
import { formatToDateTime } from "../../util/data.util";
import { formatDate } from "../../util/data.util";
import SecondaryButton from "../buttons/SecondaryButton";
import ShowPathwayDetails from "./ShowPathwayDetails";
import AddPathway from "./AddPathway";
import ComponentLoading from "../loading/ComponentLoading";
const ShowPathways = () => {
  const [pathways, setPathways] = useState<PathwayInformation[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPathway, setSelectedPathway] =
    useState<PathwayInformation | null>(null);
  const [showTable, setShowTable] = useState<boolean>();
  const [isAddPathway, setIsAddPathway] = useState<boolean>(false);
  const getPathways = async () => {
    const [response, error] = await getAllPathways();
    if (response) {
      setPathways(
        response.map((pathway: PathwayInformation) => {
          return {
            ...pathway,
          };
        })
      );
      setIsLoading(false);
      setShowTable(true);
    } else {
      console.log(error);
    }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
  ];

  const handleRowClick = (row: any) => {
    setIsAddPathway(false);
    setSelectedPathway(row);
  };
  useEffect(() => {
    getPathways();
  }, [reload]);

  return (
    <Grid container xs={12} sm={12} md={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {pathways && (
            <CustomTable
              data={pathways}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              dateFormatter={formatDate}
              optionButtonText={"Add Pathway"}
              setOptionButton={setIsAddPathway}
              defaultSort={{
                key: columns[0].key,
                direction: "desc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}
      {(isAddPathway || selectedPathway) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}
        >
          {isAddPathway && (
            <AddPathway
              setIsAddPathway={setIsAddPathway}
              setReload={setReload}
              reload={reload}
            />
          )}
          {selectedPathway && (
            <ShowPathwayDetails
              selectedPathway={selectedPathway}
              setSelectedPathway={setSelectedPathway}
              setReload={setReload}
              reload={reload}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowPathways;
