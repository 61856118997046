import React from "react";

const AccessControl = () => {
  return (
    <div className="card shadow mt-3 p-3 container">
      <div className="col-sm-12 ">Access Control Panel</div>
      <div className="col-3">sdjsadbah</div>
      <div className="col-12">sdsakdbksan</div>
    </div>
  );
};

export default AccessControl;
