import { WENT_WRONG_MSG } from "../constants/Constants";
import { AZURE_URL } from "../constants/env-constants";

export const getTopTestFromTDL = async () => {
  try {
    const response = await AZURE_URL.get("/lab/tdl/test/top");
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

export const searchTDLTest = async (searchTerm: string) => {
  try {
    const response = await AZURE_URL.get(
      `lab/tdl/test/search?searchTerm=${searchTerm}`
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

export const createTDLTest = async (testDetails: any) => {
  try {
    const response = await AZURE_URL.post(`lab/tdl/test/create`, testDetails);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

export const getAllRequestedTests = async (fromDate: any, toDate: any) => {
  try {
    const response = await AZURE_URL.get(
      `lab/tdl/test/request/search?fromDate=${fromDate}&toDate=${toDate}`
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

export const getAllTestResults = async (fromDate: any, toDate: any) => {
  try {
    const response = await AZURE_URL.get(
      `/lab/tdl/test/result/search?fromDate=${fromDate}&toDate=${toDate}`
    );
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

export const getAllTDLTestRequests = async (
  queryParams?: ApiGetTDLRequestedTestsParams
): Promise<[any, any]> => {
  try {
    let finalUrl: string = "";
    if (queryParams) {
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${key}=${queryParams[key as keyof ApiGetTDLRequestedTestsParams]}`
        )
        .join("&");

      finalUrl = `lab/tdl/test/get?${queryString}`;
    } else {
      finalUrl = `lab/tdl/test/get`;
    }
    const response = await AZURE_URL.get(finalUrl);

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response.data];
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error || WENT_WRONG_MSG];
    }
  }
};

interface ApiGetTDLRequestedTestsParams {
  publicId?: string;
  testRequestId?: string;
  testResultId?: string;
  testReferenceId?: string;
  patientPublicId?: string;
  staffPublicId?: string;
  testCode?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;

  // [key: string]: string;
}
