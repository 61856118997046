export const PROFILE_PICTURE_UPLOADED_SUCCESS =
  "Profile picture uploaded successfully";
export const PROFILE_PICTURE_UPLOADED_FAILED = "Profile picture upload failed";

export const PATHWAY_CREATE_SUCCESS = "Pathway created successfully";
export const PATHWAY_UPDATE_SUCCESS = "Pathway updated successfully";
export const PATHWAY_DELETE_SUCCESS = "Pathway deleted successfully";
export const PATHWAY_UPDATE_FAILED = "Failed to update pathway";
export const PATHWAY_CREATE_FAILED = "Failed to create pathway";
export const PATHWAY_DELETE_FAILED = "Failed to delete pathway";

export const SERVICE_CREATE_SUCCESS = "Service created successfully";
export const SERVICE_UPDATE_SUCCESS = "Service updated successfully";
export const SERVICE_DELETE_SUCCESS = "Service deleted successfully";
export const SERVICE_UPDATE_FAILED = "Failed to update Service";
export const SERVICE_CREATE_FAILED = "Failed to create Service";
export const SERVICE_DELETE_FAILED = "Failed to delete Service";

export const SUPPLEMENT_CREATE_SUCCESS = "Supplement created successfully";
export const SUPPLEMENT_UPDATE_SUCCESS = "Supplement updated successfully";
export const SUPPLEMENT_DELETE_SUCCESS = "Supplement deleted successfully";
export const SUPPLEMENT_UPDATE_FAILED = "Failed to update supplement";
export const SUPPLEMENT_CREATE_FAILED = "Failed to create supplement";
export const SUPPLEMENT_DELETE_FAILED = "Failed to delete supplement";

export const STAFF_CREATE_SUCCESS = "Staff created successfully";
export const STAFF_UPDATE_SUCCESS = "Staff updated successfully";
export const STAFF_DELETE_SUCCESS = "Staff deleted successfully";
export const STAFF_UPDATE_FAILED = "Failed to update Staff";
export const STAFF_CREATE_FAILED = "Failed to create Staff";
export const STAFF_DELETE_FAILED = "Failed to delete Staff";

export const TEAM_CREATE_SUCCESS = "Team created successfully";
export const TEAM_UPDATE_SUCCESS = "Team updated successfully";
export const TEAM_DELETE_SUCCESS = "Team deleted successfully";
export const TEAM_UPDATE_FAILED = "Failed to update Team";
export const TEAM_CREATE_FAILED = "Failed to create Team";
export const TEAM_DELETE_FAILED = "Failed to delete Team";

export const LOCATION_CREATE_SUCCESS = "Location created successfully";
export const LOCATION_UPDATE_SUCCESS = "Location updated successfully";
export const LOCATION_DELETE_SUCCESS = "Location deleted successfully";
export const LOCATION_UPDATE_FAILED = "Failed to update Location";
export const LOCATION_CREATE_FAILED = "Failed to create Location";
export const LOCATION_DELETE_FAILED = "Failed to delete Location";

export const CREATE_SUCCESS = " created successfully";
export const UPDATE_SUCCESS = " updated successfully";
export const DELETE_SUCCESS = " deleted successfully";
export const UPDATE_FAILED = "Failed to update ";
export const CREATE_FAILED = "Failed to create ";
export const DELETE_FAILED = "Failed to delete ";

export const FAILED_TO_FETCH = "Failed to get ";

export const SAVED = " saved successfully";
export const UPDATED = " updated successfully";
export const DELETED = " deleted successfully";
export const SAVE_FAILED = "Failed to save ";
// export const CREATE_FAILED = "Failed to create ";
// export const DELETE_FAILED = "Failed to delete ";

export const LETTER_SAVE_SUCCESS = "Letter saved successfully";
export const LETTER_SAVE_FAILED = "Failed to save letter";
export const LETTER_DOWNLOAD_SUCCESS = "Letter downloaded successfully";

export const PARTNER_REMOVE_SUCCESS = "Partner removed successfully";
export const PARTNER_REMOVE_FAILED = "Failed to remove partner";

export const RESET_SUCCESS = "Reset successful";
export const RESET_2FA_FAILED = "Two factor authentication reset failed";

export const PASSWORD_UPDATE_SUCCESS = "Password updated successfully";
export const PASSWORD_UPDATE_FAILED = "Failed to update password";

export const DOWNLOAD_SUCCESS = "Download successful";
export const DOWNLOAD_FAILED = "Failed to download";
