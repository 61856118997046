import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { searchPatient } from "../../apis/patients";
import { formatDate } from "../../util/data.util";
interface SearchPatientProps {
  label: string;
  patient:
    | {
        label: string;
        publicId: string;
      }
    | undefined;
  setPatient: any;
}
const SearchPatient = React.memo<SearchPatientProps>(
  ({ setPatient, patient, label }) => {
    const [searchResults, setSearchResults] = useState<{}[]>([]);
    const handlePatientSearch = async (event: {
      preventDefault: () => void;
      target: { value: any };
    }) => {
      event.preventDefault();
      const { value } = event.target;
      if (value.length > 2) {
        await searchPatient(value as string).then(({ data }) => {
          let results: { label: string; publicId: string }[] = [];
          data.forEach((element: any) => {
            results.push({
              label:
                (element.title ? element.title + " " : "") +
                (element.firstName ? element.firstName + " " : "") +
                (element.lastName ? element.lastName : "") +
                (element.dateOfBirth
                  ? " (" + formatDate(element.dateOfBirth) + ")"
                  : ""),
              publicId: element.publicId,
            });
          });
          setSearchResults(results);
        });
      }
    };
    const handlePartnerSelected = (
      event: React.SyntheticEvent,
      option: any
    ) => {
      setPatient(option);
    };
    return (
      <Autocomplete
        freeSolo
        className="col-sm-10"
        id="search"
        options={searchResults}
        renderInput={(params) => (
          <TextField {...params} label={label} onChange={handlePatientSearch} />
        )}
        onChange={handlePartnerSelected}
      />
    );
  }
);

export default SearchPatient;
