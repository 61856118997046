import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const excludedFields = [
  "publicId",
  "age",
  "createdBy",
  "whenCreated",
  "updatedBy",
  "updatedAt",
  "whenLastUpdated",
  "ownerStaff",
  "patientDetails",
];

interface PatientInformationDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: PatientInformation) => void;
  patientInformation: PatientInformation;
}

const PatientInformationDialog: React.FC<PatientInformationDialogProps> = ({
  open,
  onClose,
  onSave,
  patientInformation,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { register, handleSubmit } = useForm<PatientInformation>();

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const onSubmit = (data: PatientInformation) => {
    onSave(data);
    setEditMode(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Patient Information</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {Object.entries(patientInformation).map(([key, value]) => {
              if (excludedFields.includes(key) || key === "address")
                return null;

              return (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    {editMode ? (
                      <TextField
                        {...register(key)}
                        name={key}
                        label={key}
                        defaultValue={value}
                        fullWidth
                      />
                    ) : (
                      <Typography variant="body1">{value}</Typography>
                    )}
                  </Grid>
                </React.Fragment>
              );
            })}
            {editMode && (
              <>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.street1")}
                    name="address.street1"
                    label="Street 1"
                    defaultValue={patientInformation.address.street1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.street2")}
                    name="address.street2"
                    label="Street 2"
                    defaultValue={patientInformation.address.street2}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.city")}
                    name="address.city"
                    label="City"
                    defaultValue={patientInformation.address.city}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.state")}
                    name="address.state"
                    label="State"
                    defaultValue={patientInformation.address.state}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.postalCode")}
                    name="address.postalCode"
                    label="Postal Code"
                    defaultValue={patientInformation.address.postalCode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register("address.country")}
                    name="address.country"
                    label="Country"
                    defaultValue={patientInformation.address.country}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {editMode ? (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              form="patient-form">
              Save
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleEditToggle}>
            Edit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PatientInformationDialog;
