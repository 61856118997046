import { AZURE_URL } from "../constants/env-constants";

export const getAllStaff = async () => {
  try {
    const response = await AZURE_URL.get("/staff/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

// export const getAllTeams = async () => {
//   try {
//     const response = await AZURE_URL.get("/staff/team/get/all");
//     if (response.status === 200) {
//       return [response.data, null];
//     } else {
//       return [null, response];
//     }
//   } catch (error: any) {
//     return error.response;
//   }
// };

export const deleteStaff = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/staff/delete?publicId=${id}`);
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    console.log(error);
    return [null, "Server Error: " + error.message];
  }
};

export const updateStaff = async (data: any) => {
  try {
    const response = await AZURE_URL.put("/staff/update", data);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      console.log(response);
      return [null, response.data];
    }
  } catch (error: any) {
    return [null, "Server Error: " + error.response];
  }
};

export const getStaffDetailsById = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`/staff/get?publicId=${id}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};
