import React, { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import { Box, Grid, Typography } from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { PreviousTreatmentForm } from "../../util/patient/firstConsultation.form.helper";
import TextInput from "../fromComponents/TextInput";
import SecondaryButton from "../buttons/SecondaryButton";
import { useSelector } from "react-redux";
import { objectToArray } from "../../util/data.util";
import TernaryButton from "../buttons/TernaryButton";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const PreviousTreatment: React.FC<PreviousTreatmentsProps> = ({
  previousTreatments,
  setPreviousTreatments,
  setExpandedAccordion,
}) => {
  const patientObject: any = useSelector<FemalePatientState>(
    (state) => state.female
  );
  const { publicId: patientPublicId } = patientObject.female;

  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [formCount, setFormCount] = useState(1);

  const addForm = () => {
    setFormCount(formCount + 1);
  };
  const removeForm = (index: any) => {
    setFormCount(formCount - 1);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const treatments = objectToArray(data).map((item: TreatmentInputs) => {
      return {
        ...item,
        patientPublicId: patientPublicId,
      };
    });
    console.log(treatments);
    setPreviousTreatments(treatments);
    sessionStorage.setItem(
      `prev-treatment-${patientPublicId}`,
      JSON.stringify(treatments)
    );
    // await createPreviousTreatments(treatments).then((res) => {
    //   console.log(res);
    //   if (res.status === 200) {
    //     console.log(SUCCESS);
    //   } else {
    //     console.error("Failure: " + res.status);
    //   }
    //   //need to add snackbar
    // });
    setExpandedAccordion("management-accordion");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-sm-12 p-2">
          <div>
            {[...Array(formCount)].map((form, index) => {
              return (
                <div key={index} className="card m-3 p-3">
                  <div className="row ms-1">
                    {PreviousTreatmentForm.map((item: InputFieldType) => {
                      return (
                        <TextInput
                          keyName={`${index}.${item.id}`}
                          register={register}
                          placeholder={item.placeholder}
                          id={`${index}.${item.id}`}
                          label={item.label}
                          type={item.type}
                          listName={`${index}.${item.id}list`}
                          options={item.options}
                          required={item.required}
                          tag={item.tag}
                          maxLength={item.maxLength}
                          minRows={item.minRows}
                          checkboxOptions={item.checkboxOptions}
                          setValue={setValue}
                          unregister={unregister}
                          actionOn={item.actionOn}
                          radioOptions={item.radioOptions}
                          children={item.children}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <SecondaryButton
                  className={"m-2"}
                  type={"button"}
                  endIcon={<AddOutlinedIcon />}
                  label={"Add"}
                  onClick={addForm}
                />
                <TernaryButton
                  className={"m-2"}
                  label={"Remove"}
                  endIcon={<RemoveOutlinedIcon />}
                  onClick={removeForm}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <PrimaryButton label={"Next"} type={"submit"} className={""} />
        </Grid>
      </Grid>
    </form>
  );
};

export default PreviousTreatment;
