import React, { useState, useEffect } from "react";
import { TextField, Card, Fab, Box, Grid } from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import TernaryButton from "../buttons/TernaryButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { createNewNote } from "../../apis/notes";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  NEED_TO_UPDATE,
  NOTE_ADDED_SUCCESSFULLY,
  NOTE_FAILED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../constants/Constants";

interface CreateNote {
  title: string;
  note: string;
  patientPublicId: string;
}

interface PatientState {
  patient: {
    publicId: string;
  };
}

const Notes: React.FC = React.memo(() => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { publicId } = patientObject.patient;
  const initialState: CreateNote = {
    title: "",
    note: "",
    patientPublicId: publicId,
  };
  const [note, setNote] = useState<CreateNote>(initialState);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setNote((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsDisabled(true);
    e.preventDefault();
    const [response, error] = await createNewNote(note);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: note.title + " " + NOTE_ADDED_SUCCESSFULLY,
          variant: SNACKBAR_SUCCESS,
        })
      );
      sessionStorage.setItem(NEED_TO_UPDATE, JSON.stringify(true));
      setNote(initialState);
      window.location.reload();
      setShowCard(false);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || NOTE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
      setIsDisabled(false);
      setShowCard(true);
    }
  };

  useEffect(() => {
    setNote((prevNote) => ({
      ...prevNote,
      patientPublicId: publicId,
    }));
  }, [publicId]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "2rem",
        right: "6rem",
        zIndex: 5,
      }}>
      <Fab
        color="primary"
        aria-label="scroll to top"
        onClick={() => setShowCard(!showCard)}>
        <NotesIcon />
      </Fab>
      {showCard && (
        <Card elevation={6} sx={{ padding: 3 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              name="title"
              value={note.title}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />

            <TextField
              label="Note"
              placeholder="Note"
              name="note"
              type="tea"
              value={note.note}
              onChange={handleChange}
              multiline
              minRows={3}
              maxRows={6}
              style={{ width: "100%", marginBottom: "16px", resize: "none" }}
              required={true}
            />

            <Grid container spacing={3} pl={3} direction="row-reverse">
              <Grid item>
                <PrimaryButton
                  type="submit"
                  label="Add Note"
                  disable={isDisabled}
                />
              </Grid>
              <Grid item>
                <TernaryButton
                  label="Clear"
                  onClick={() => {
                    setNote(initialState);
                    setShowCard(!showCard);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      )}
    </Box>
  );
});

export default Notes;
