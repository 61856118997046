import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { BLACK_COLOR } from "../../constants/jspdf.constants";

const SubTabPanel = (props: TabPanelProps) => {
  const { children, valueX, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueX !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}>
      {valueX === index && (
        <Box sx={{ maWidth: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const a11xProps = (index: number) => {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`,
  };
};

const HorizontalMenu = React.memo<HorizontalMenuProp>(({ children }) => {
  const [valueX, setValueX] = useState(0);
  const handleChangeX = (event: React.SyntheticEvent, newValue: number) => {
    setValueX(newValue);
  };
  return (
    <>
      <Tabs
        variant="fullWidth"
        value={valueX}
        onChange={handleChangeX}
        aria-label="Horizontal tabs example"
        textColor="inherit"
        sx={{
          minWidth: "100%",
          width: "100%",
          borderBottom: 1,
          backgroundColor: "",
          overflow: "auto",
        }}>
        {children.map((child: SingleMenu, index: number) => (
          <Tab label={child.label} {...a11xProps(index)} />
        ))}
      </Tabs>

      {children.map((child: SingleMenu, index: number) => (
        <SubTabPanel valueX={valueX} index={index}>
          {child.children ? (
            <HorizontalMenu children={child.children} />
          ) : (
            child.component
          )}
        </SubTabPanel>
      ))}
    </>
  );
});

export default HorizontalMenu;
