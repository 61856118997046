import { AZURE_URL } from "../constants/env-constants";

export const getServicesByType = async (typeOfTreatment: string) => {
  try {
    const response = await AZURE_URL.get(
      `/service/get?type=${typeOfTreatment}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const getAllServices = async () => {
  try {
    const response = await AZURE_URL.get("/service/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const deleteService = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/service/delete?publicId=${id}`);
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};

export const createService = async (service: CreateServiceRequest) => {
  try {
    const response = await AZURE_URL.post("/service/create", service);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};
