import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: TestsState = { tests: [] };

export const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    addTests: (state, action: PayloadAction<Test[]>) => {
      state.tests = action.payload;
    },
  },
});

export default testsSlice.reducer;
export const { addTests } = testsSlice.actions;
