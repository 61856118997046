import React, { useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { getAllTeams } from "../../apis/team";
import { getAllLocations } from "../../apis/location";

interface SearchTeamProps {
  setSelected?: any;
  className?: string;
  label?: string;
  id?: string;
  setValue?: any;
}

const SearchLocation = React.memo<SearchTeamProps>(
  ({ setSelected, className, label, id, setValue }) => {
    const [search, setSearch] = useState<{}[]>([]);
    const getLocation = async () => {
      const [response, error] = await getAllLocations();
      console.log(response);
      if (response) {
        const results = response.map((element: Location) => ({
          publicId: element.publicId,
          label: element.name,
        }));
        setSearch(results);
      } else {
        console.log(error);
      }
    };
    const handleLocationSelected = (
      event: React.SyntheticEvent,
      option: any
    ) => {
      if (setSelected) {
        setSelected(option);
      }
      if (option && id) {
        setValue(id, option.publicId);
      }
    };

    useEffect(() => {
      getLocation();
    }, []);

    return (
      <Autocomplete
        freeSolo
        className={className ? className : ""}
        id="locationSearch"
        options={search}
        renderInput={(params) => (
          <TextField {...params} label={label ? label : "Select location..."} />
        )}
        onChange={handleLocationSelected}
      />
    );
  }
);

export default SearchLocation;
