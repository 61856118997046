import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { FieldValues, UseFormUnregister } from "react-hook-form";
import TextInputFull from "./TextInputFull";

interface RadioInputProps {
  item: InputFieldType;
  register: any;
  id: string;
  unregister: UseFormUnregister<FieldValues>;
}

const RadioInput = React.memo<RadioInputProps>(
  ({ item, register, id, unregister }) => {
    const [value, setValue] = useState<string>("");
    const [showInputBox, setShowInputBox] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      unregister(id);
      setValue(value);
      console.log(value, item.actionOn);
      register(id, { value: value });
      if (event.target.value === item.actionOn) {
        setShowInputBox(true);
      } else {
        setShowInputBox(false);
      }
    };

    return (
      <>
        <FormControl component="fieldset">
          <FormLabel id="children-radio-buttons-group">{item.label}</FormLabel>
          <RadioGroup
            aria-labelledby="children-radio-buttons-group"
            name={id}
            value={value}
            onChange={handleChange}
            id={id}
            row>
            {item.radioOptions?.map((_option) => (
              <FormControlLabel
                key={_option.name}
                value={_option.value}
                control={<Radio />}
                label={_option.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {showInputBox &&
          item.children?.map((child) => (
            <>
              <TextInputFull
                keyName={id + child.id}
                register={register}
                placeholder={child.placeholder}
                id={id + "." + child.id}
                label={child.label}
                type={child.type}
                listName={id + child.id + "list"}
                options={child.options}
                required={child.required}
                tag={child.tag}
                maxLength={child.maxLength}
                minRows={child.minRows}
                unregister={unregister}
              />
            </>
          ))}
      </>
    );
  }
);

export default RadioInput;
