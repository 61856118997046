import { NO, PLACEHOLDER_VALUE, YES } from "../../constants/Constants";

export const FertilityTryingSince = [
  {
    label: "Months",
    type: "number",
    id: "tryingSinceMonths",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 4,
  },
  {
    label: "Years",
    type: "number",
    id: "tryingSinceYears",
    boxSize: 4,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
];

export const FertilityLivingChildren = [
  {
    label: "from current partner",
    type: "number",
    id: "cpLiveChildren",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 4,
  },
  {
    label: "from previous partner",
    type: "number",
    id: "ppLiveChildren",
    boxSize: 4,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
];
export const FemaleFertilityDetailsForm: InputFieldType[] = [
  {
    label: "Obstetrics History",
    type: "text",
    id: "obstetricsHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Medical History",
    type: "text",
    tag: "textarea",
    id: "medicalHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 512,
    minRows: 4,
  },
  {
    label: "Surgery History",
    type: "text",
    tag: "textarea",
    id: "surgeryHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 512,
    minRows: 4,
  },
  {
    label: "Medications",
    type: "text",
    id: "medications",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },

  {
    label: "Significant Genetic History",
    type: "text",
    tag: "textarea",
    id: "significantGeneticHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 4,
  },

  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    tag: "textarea",
    maxLength: 512,
    minRows: 4,
  },
];

export const MaleFertilityDetailsForm: InputFieldType[] = [
  {
    label: "Obstetrics History",
    type: "text",
    id: "obstetricsHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    disabled: true,
  },
  {
    label: "Medical History",
    type: "text",
    tag: "textarea",
    id: "medicalHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 512,
    minRows: 4,
  },
  {
    label: "Surgery History",
    type: "text",
    tag: "textarea",
    id: "surgeryHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 512,
    minRows: 4,
  },
  {
    label: "Medications",
    type: "text",
    id: "medications",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },

  {
    label: "Significant Genetic History",
    type: "text",
    tag: "textarea",
    id: "significantGeneticHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 4,
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    tag: "textarea",
    maxLength: 512,
    minRows: 4,
  },
];
export const FertilityDetails = [
  {
    label: "Smears/Cervix",
    type: "radio",
    id: "smearsCervix",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 6,
    radioOptions: [
      { name: "Normal", value: "Normal" },
      { name: "Abnormal", value: "Abnormal" },
    ],
  },
  {
    label: "Treatment on cervix",
    type: "text",
    id: "treatmentOnCervix",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Periods",
    type: "radio",
    id: "periods",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    radioOptions: [
      { name: "Regular", value: "Regular" },
      { name: "Irregular", value: "Irregular" },
      { name: "LPD", value: "LPD" },
      { name: "Heavy", value: "Heavy" },
      { name: "Painful", value: "Painful" },
    ],
  },
  {
    label: "Periods Comments",
    type: "text",
    id: "periodsComment",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Rubella Immune",
    type: "radio",
    id: "rubellaImmune",
    boxSize: 6,
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    radioOptions: [
      { name: YES, value: "true" },
      { name: NO, value: "false" },
    ],
  },
  {
    label: "Primary Diagnosis",
    type: "checkbox",
    id: "primaryDiagnosis",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "pcos",
        value: "PCOS",
      },
      {
        keyName: "ovulatoryDysfunction",
        value: "Ovulatory dysfunction",
      },
      {
        keyName: "tubalFactor",
        value: "Tubal factor",
      },
      {
        keyName: "lowEggReserve",
        value: "low egg reserve",
      },
      {
        keyName: "maleFactor",
        value: "Male factor",
      },
      {
        keyName: "unexplained",
        value: "Unexplained",
      },
      {
        keyName: "recurrentMiscarriage",
        value: "Recurrent miscarriage",
      },
      {
        keyName: "recurrentImplantationFailure",
        value: "Recurrent Implantation Failure",
      },
      {
        keyName: "endometriosis",
        value: "Endometriosis",
      },
    ],
  },
  {
    label: "Secondary Diagnosis",
    type: "checkbox",
    id: "secondaryDiagnosis",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    boxSize: 12,
    checkboxOptions: [
      {
        keyName: "pcos",
        value: "PCOS",
      },
      {
        keyName: "ovulatoryDysfunction",
        value: "Ovulatory dysfunction",
      },
      {
        keyName: "tubalFactor",
        value: "Tubal factor",
      },
      {
        keyName: "lowEggReserve",
        value: "low egg reserve",
      },
      {
        keyName: "maleFactor",
        value: "Male factor",
      },
      {
        keyName: "unexplained",
        value: "Unexplained",
      },
      {
        keyName: "recurrentMiscarriage",
        value: "Recurrent miscarriage",
      },
      {
        keyName: "recurrentImplantationFailure",
        value: "Recurrent Implantation Failure",
      },
      {
        keyName: "endometriosis",
        value: "Endometriosis",
      },
    ],
  },
  {
    label: "Subfertility Type",
    type: "radio",
    id: "subfertility",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    radioOptions: [
      { name: "Primary", value: "Primary" },
      { name: "Secondary", value: "Secondary" },
    ],
  },
];

export const PreviousTreatmentForm: InputFieldType[] = [
  {
    label: "Clinic Name",
    type: "text",
    id: "clinicName",
    placeholder: PLACEHOLDER_VALUE,
    required: true,
  },
  {
    label: "Type of treatment",
    type: "text",
    id: "type",
    placeholder: PLACEHOLDER_VALUE,
    required: true,
  },
  {
    label: "Protocol used",
    type: "text",
    id: "protocol",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Drugs given",
    type: "text",
    id: "drugs",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Number of Eggs",
    type: "number",
    id: "eggs",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Embryos created",
    type: "number",
    id: "embryos",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Outcome",
    type: "text",
    id: "outcome",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Remarks",
    type: "text",
    id: "remarks",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
];
