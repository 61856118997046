import React from "react";
import EditableBox from "../adminCenterComponents/EditableBox";
interface DisplayStagesProps {
  selectedPathway: PathwayInformation;
}
const DisplayStages: React.FC<DisplayStagesProps> = ({ selectedPathway }) => {
  return (
    <>
      {selectedPathway.pathwayStages
        .sort((a, b) => a.stageIndex - b.stageIndex)
        .map((stage, index) => (
          <EditableBox
            editing={false}
            label={""}
            key={index}
            currentValue={stage.name}
            name={"stage" + index}
          />
        ))}
    </>
  );
};

export default DisplayStages;
