import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { FC } from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import TernaryButton from "../buttons/TernaryButton";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content?: string;
  onCancel: () => void;
  onDelete: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  content,
  onCancel,
  onDelete,
}) => {
  const handleDelete = () => {
    if (onDelete && typeof onDelete === "function") {
      onDelete();
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={onCancel}
          label="Cancel"
          className="m-2"
          variant="text"
          type="button"
        />
        <TernaryButton
          onClick={handleDelete}
          label="Delete"
          color="error"
          variant="contained"
          className="m-2"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
