import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { getServicesByType } from "../../apis/services";
import { useSelector } from "react-redux";

const Treatments: React.FC<TreatmentsProps> = ({
  treatments,
  setTreatments,
}) => {
  const { handleSubmit } = useForm<FieldValues>();
  const [services, setServices] = useState<Service[]>([]);
  const allTreatments: any = useSelector<TreatmentsState>(
    (state) => state.treatments
  );
  const onSubmit: SubmitHandler<FieldValues> = async () => {
    console.log(treatments);
  };

  const handleCheckboxChange = (
    option: Service,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    if (checked) {
      setTreatments((prevValues) => [...prevValues, option.publicId]);
    } else {
      setTreatments((prevValues) =>
        prevValues.filter((value) => value !== option.publicId)
      );
    }
  };
  useEffect(() => {
    if (allTreatments.treatments.length > 0) {
      setServices(allTreatments.treatments);
    }
  }, []);

  return (
    <>
      {" "}
      {services?.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="row">
            <div className="col-sm-12 p-2">
              <Typography variant="h6" gutterBottom>
                Treatments
              </Typography>
              <hr />
              <Grid container spacing={2}>
                {services?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.publicId}>
                    <FormControl component="fieldset" className={""}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handleCheckboxChange(item, e)}
                              name={`${item.name}`}
                              checked={treatments.some(
                                (treatment) => treatment === item.publicId
                              )}
                            />
                          }
                          label={item.name}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          {/* <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <PrimaryButton
                label={"Save"}
                type={"submit"}
                disable={values.length === 0}
                className={""}
              />
            </Grid>
          </Grid> */}
        </form>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}>
          <Typography variant="h5" component="div" color={"red"} gutterBottom>
            Treatments not available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Treatments;
