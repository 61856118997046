import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import schedule from "node-schedule";
import { useDispatch } from "react-redux";
import { addSites } from "../../redux/slice/sitesSlice";
import { useSelector } from "react-redux";
import { addTests } from "../../redux/slice/testsSlice";
import { addTreatments } from "../../redux/slice/treatmentsSlice";
import { addSupplements } from "../../redux/slice/supplementsSlice";
import {
  getAllSites,
  getAllSupplements,
  getServicesByType,
  getWixBookings,
} from "../../apis";

const InitialAPICalls = () => {
  const dispatch = useDispatch();
  const [searchSites, setSearchSites] = useState<
    { label: string; publicId: string }[]
  >([]);
  const allSites: any = useSelector<SitesState>((state) => state.sites);

  const getSites = async () => {
    if (allSites.sites.length === 0) {
      const [response, error] = await getAllSites();
      if (response) {
        const results = response.map((element: Site) => {
          return {
            label: `${element.name} `,
            publicId: element.publicId,
          };
        });
        dispatch(addSites(response));
        setSearchSites(results);
      } else {
        console.log(error);
      }
    } else {
      const results = allSites.sites.map((element: Site) => {
        return {
          label: `${element.name} `,
          publicId: element.publicId,
        };
      });
      setSearchSites(results);
    }
  };

  const getServices = async (type: string) => {
    const [response, error] = await getServicesByType(type);
    if (response) {
      return response;
    } else {
    }
  };

  const getAllTests = async () => {
    dispatch(addTests(await getServices("Test")));
  };

  const getAllTreatments = async () => {
    dispatch(addTreatments(await getServices("Treatment")));
  };

  const getSupplements = async () => {
    const [response, error] = await getAllSupplements();
    if (response) dispatch(addSupplements(response));
  };

  useEffect(() => {
    let cronJob: schedule.Job | null = null;

    const startCronJob = () => {
      cronJob = schedule.scheduleJob("*/15 * * * *", async () => {
        console.log("started");
        const currentDate = dayjs().format("YYYY-MM-DD"); // Get today's date
        const formattedDate = currentDate + "T00:00:00.000Z";
        const nextDate =
          dayjs(currentDate).add(1, "day").format("YYYY-MM-DD") +
          "T00:00:00.000Z";

        for (const site of searchSites) {
          const query = {
            query: {
              filter: {
                startTime: {
                  $gte: formattedDate,
                },
                // endTime: {
                //   $lte: nextDate,
                // },
              },
              paging: {
                limit: 500,
                offset: 0,
              },
              sort: [
                {
                  fieldName: "createdDate",
                  order: "DESC",
                },
              ],
            },
            sitePublicId: site.publicId,
          };

          try {
            await getWixBookings(query);
          } catch (error) {
            console.error(error);
          }
        }
      });
    };

    const stopCronJob = () => {
      if (cronJob) {
        cronJob.cancel();
        cronJob = null;
      }
    };

    // Start the cron job when the component mounts
    startCronJob();

    // Clean up the cron job when the component unmounts
    return () => {
      console.log("cleanup");
      stopCronJob();
    };
  }, [searchSites]);

  useEffect(() => {
    getSites();
    getAllTests();
    getAllTreatments();
    getSupplements();
  }, []);

  return <></>;
};

export default InitialAPICalls;
