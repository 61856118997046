import React, { useEffect, useState } from "react";
import { getAllStaff } from "../../apis/staff";
import { Autocomplete, TextField } from "@mui/material";

interface SearchStaffProps {
  setSelectedStaff?: any;
  className?: string;
  label?: string;
  id?: string;
  setValue?: any;
}

const SearchStaff = React.memo<SearchStaffProps>(
  ({ setSelectedStaff, className, label, id, setValue }) => {
    const [searchStaffs, setSearchStaffs] = useState<{}[]>([]);
    const getStaffs = async () => {
      const [response, error] = await getAllStaff();
      if (response) {
        const results = response.map((element: StaffInformation) => ({
          label: `${element.title || ""} ${element.firstName || ""} ${
            element.lastName || ""
          }`,
          publicId: element.publicId,
        }));
        setSearchStaffs(results);
      } else {
        console.log(error);
      }
    };

    const handleStaffSelected = (event: React.SyntheticEvent, option: any) => {
      if (setSelectedStaff) {
        setSelectedStaff(option);
      }
      if (option && id) {
        // id = option.publicId;
        setValue(id, option.publicId);
      }
    };

    useEffect(() => {
      getStaffs();
    }, []);
    return (
      <Autocomplete
        freeSolo
        className={className ? className : "col-sm-10 p-0"}
        id="staffSearch"
        options={searchStaffs}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "Select consultant"}
            required
          />
        )}
        onChange={handleStaffSelected}
      />
    );
  }
);

export default SearchStaff;
