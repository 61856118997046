import { AZURE_URL } from "../constants/env-constants";

export const createLetter = async (data: P4LetterRequest) => {
  try {
    const response = await AZURE_URL.post("/letter/create", data);

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getLetters = async (data: P4LetterRequest) => {
  try {
    const response = await AZURE_URL.get("/letter/get/all");

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error "];
  }
};

export const getLettersByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`letter/get?patientPublicId=${id}`);

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
