import { useState } from "react";
import { CONTAINER_NAME, FILE, FILE_NAME } from "../constants/Constants";
import { uploadFile } from "../apis/files.api";

export const useFileUpload = (): [
  string,
  (file: File, containerName: string) => Promise<[string | null, Error | null]>
] => {
  const [fileName, setFileName] = useState<string>("");

  const handleFileChange = async (
    file: File,
    containerName: string
  ): Promise<[string | null, Error | null]> => {
    setFileName(file.name);

    const formData = new FormData();
    formData.append(FILE, file);
    formData.append(FILE_NAME, file.name);
    formData.append(CONTAINER_NAME, containerName);

    try {
      const [response, error] = await uploadFile(formData);
      if (response) {
        // Successful response
        return [response, null];
      } else {
        // Error response
        return [null, error];
      }
    } catch (error) {
      // Error caught during the request

      const customError = new Error(
        "An error occurred while uploading the file."
      );
      return [null, customError];
    }
  };

  return [fileName, handleFileChange];
};
