import React from "react";
import QueryComponent from "./QueryComponent";

const CreateWIXRequest = () => {
  return (
    <div>
      <QueryComponent />
    </div>
  );
};

export default CreateWIXRequest;
