import React, { FC, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from "@mui/material";
import { FieldValues, UseFormUnregister } from "react-hook-form";

type CheckboxProps = {
  item: InputFieldType;
  register: any;
  setValue: any;
};

const FormCheckbox: FC<CheckboxProps> = ({ item, register, setValue }) => {
  const [values, setValues] = useState<string[]>([]);

  const handleCheckboxChange = (option: any, e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setValues((prevValues) => [...prevValues, option.value]);

      setValue(item.id, [...values, option.value]);
    } else {
      setValues((prevValues) =>
        prevValues.filter((value) => value !== option.value)
      );
      setValue(
        item.id,
        values.filter((value) => value !== option.value)
      );
      // console.log("second");
    }
    // console.log(values);
  };
  // const addValues = () => {
  //   setValue(item.id, { values });
  // };

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel id="children-radio-buttons-group">{item.label}</FormLabel>
        <FormGroup>
          <Grid container spacing={2}>
            {item.checkboxOptions?.map((option: any) => (
              <Grid item xs={4} key={option.keyName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(option, e)}
                      name={`${item.id}.${option.keyName}`}
                      checked={values.includes(option.value)}
                    />
                  }
                  label={option.value}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        {/* <Button onClick={addValues}>Add</Button> */}
      </FormControl>
    </>
  );
};

export default FormCheckbox;
