import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

const filter = createFilterOptions<MultiSelectOptionType>();

const MultiSelect = ({
  label,
  className,
  options,
  value,
  setValue,
}: MultiSelectInputProps): JSX.Element => {
  return (
    <Autocomplete
      multiple
      className={className || "col-sm-4 "}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            label: params.inputValue,
            publicId: params.inputValue,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Select options..."
        />
      )}
    />
  );
};

export default MultiSelect;
