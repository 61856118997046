import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SecondaryButton from "../buttons/SecondaryButton";
import TernaryButton from "../buttons/TernaryButton";
import SearchStaff from "../search/SearchStaff";
import TeamSearch from "../search/TeamSearch";

const Stages: React.FC<StageProps> = ({
  register,
  setValue,
  unregister,
  defaultValues,
}) => {
  const [formCount, setFormCount] = useState(1);
  console.log(defaultValues);
  const addForm = () => {
    setFormCount(formCount + 1);
  };
  const removeForm = (index: any) => {
    console.log(index);
    unregister(`pathwayStages.${index}`);
    setFormCount((prevCount) => prevCount - 1);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    console.log(event.target.checked);
    setValue(`pathwayStages.${index}.finalStage`, event.target.checked);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {[...Array(formCount)].map((form, index) => {
          return (
            <Grid
              className="card"
              container
              key={index}
              xs={12}
              sm={12}
              md={12}
              p={3}
              m={2}
            >
              <Grid item key={index + "name"} xs={12} sm={6} md={4}>
                <TextField
                  key={`name-${index}`}
                  className="col-sm-10"
                  {...register(`pathwayStages.${index}.name`, {
                    required: true,
                  })}
                  placeholder="Stage name"
                  defaultValue={defaultValues?.[index]?.name || ""}
                  id={`pathwayStages.${index}.name`}
                  label="Stage Name"
                  type="text"
                  required
                />
              </Grid>
              <Grid item key={index + "staff"} xs={12} sm={6} md={4}>
                fsdfds
                <SearchStaff
                  id={`pathwayStages.${index}.defaultAssigneePublicId`}
                  label="Default Assignee"
                  setValue={setValue}
                />
              </Grid>
              <Grid item key={index + "team"} xs={12} sm={6} md={4}>
                <TeamSearch
                  setValue={setValue}
                  id={`pathwayStages.${index}.defaultAssigneeTeamPublicId`}
                  label="Select Assignee Team"
                />
              </Grid>
              <Grid item key={index + "finalStage"} xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => handleChange(e, index)} />
                  }
                  label="Final Stage"
                />
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            {formCount > 1 && (
              <TernaryButton
                className={"m-2"}
                label={"Remove"}
                onClick={() => removeForm(formCount - 1)}
              />
            )}
            <SecondaryButton
              className={"m-2"}
              type={"button"}
              label={"Add"}
              onClick={addForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stages;
