import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
type DocumentViewerProps = {
  src: string;
  type: string;
  openInNewTab?: boolean;
};

const DocumentViewer: React.FC<DocumentViewerProps> = ({
  src,
  type,
  openInNewTab = false,
}) => {
  const [numPages, setNumPages] = React.useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const renderDocument = () => {
    switch (type) {
      case "pdf":
        return (
          <div>
            <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (_, index) => (
                <Page key={index + 1} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        );
      case "doc":
      case "docx":
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
              src
            )}`}
            width="100%"
            height="600px"
          />
        );
      case "xls":
      case "xlsx":
        return (
          <iframe
            src={`https://docs.google.com/spreadsheets/d/${encodeURIComponent(
              src
            )}/pubhtml?widget=true&amp;headers=false`}
            width="100%"
            height="600px"
          />
        );
      case "jpg":
      case "jpeg":
      case "png":
        return <img src={src} alt="Image" width={800} />;
      default:
        return <div>Unsupported file type</div>;
    }
  };

  const openDocumentInNewTab = () => {
    window.open(src, "_blank");
  };

  React.useEffect(() => {
    if (openInNewTab && src) {
      openDocumentInNewTab();
    }
  }, [src, openInNewTab]);

  return (
    <div>
      <h2>Document Viewer</h2>
      {src && !openInNewTab && (
        <button onClick={openDocumentInNewTab}>Open in new tab</button>
      )}
      {src && renderDocument()}
    </div>
  );
};

export default DocumentViewer;
