import { AZURE_URL } from "../constants/env-constants";

export const createEvent = async (event: CreateEvent) => {
  try {
    const response = await AZURE_URL.post(`/event/create`, event);

    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getEventByPatientID = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response.data;
  }
};

export const updateEventStage = async (event: UpdateEventRequest) => {
  try {
    const response = await AZURE_URL.put("event/update", event);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const archiveEvent = async (event: ArchiveEventRequest) => {
  try {
    const response = await AZURE_URL.put("event/update", event);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getEventsByPathwayId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`/event/get?pathwayPublicId=${id}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getAllEvents = async () => {
  try {
    const response = await AZURE_URL.get("/event/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
export const getAllEventByIds = async (
  patientId: string,
  pathwayId: string
) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}&pathwayPublicId=${pathwayId}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPathwayId = async (pathwayId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?pathwayPublicId=${pathwayId}&archived=true`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPatientId = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}&archived=true`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
