import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import PrimaryButton from "../buttons/PrimaryButton";
import TernaryButton from "../buttons/TernaryButton";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  MAXIMUM_UPLOAD_SIZE_EXCEEDED,
  SNACKBAR_ERROR,
} from "../../constants/Constants";
import SecondaryButton from "../buttons/SecondaryButton";
import ScanDocument from "../documentScanner/ScanDocument";
import { getFileSize } from "../../util/files.utl";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";

const FileUpload: React.FC<FileUploadProps> = ({
  setFileUrls,
  containerName,
  setIsUploadDocuments,
  allowScanDocument = false,
}) => {
  const [files, setFiles] = useState<FileObject[]>([]);
  const [fileName, handleFileChange] = useFileUpload();
  const [isScanDocuments, setIsScanDocuments] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const newFiles: FileObject[] = Array.from(fileList).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        name: file.name, // Set the default name as the file name
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      event.target.value = ""; // Clear the input field
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleSave = async () => {
    const response: {
      name: string;
      url: string;
    }[] = [];
    for (const file of files) {
      dispatch(loading());
      const [url, err] = await handleFileChange(file.file, containerName);

      if (url) {
        let fileObj = {
          name: file.name,
          url: url,
        };
        response.push(fileObj);
      } else if (err) {
        dispatch(
          activateSnackbar({
            active: true,
            message: `${MAXIMUM_UPLOAD_SIZE_EXCEEDED} for ${file.file.name}`,
            variant: SNACKBAR_ERROR,
          })
        );
      }
      dispatch(doneLoading());
    }

    if (response.length > 0) {
      setFileUrls(response);
    }
    setFiles([]);
  };

  const renderFileList = () => {
    if (files.length === 0) {
      return (
        <Typography variant="body1" color="text.secondary">
          No files selected
        </Typography>
      );
    } else {
      return (
        <>
          {files.map((file, index) => {
            const fileNameWithoutExtension = file.name
              .split(".")
              .slice(0, -1)
              .join(".");
            const fileExtension = file.name.split(".").pop() || "";

            return (
              <Grid
                container
                item
                key={index}
                alignItems="center"
                spacing={1}
                p={1}
              >
                <Grid item md={8}>
                  <TextField
                    size="small"
                    fullWidth
                    type="text"
                    value={fileNameWithoutExtension}
                    onChange={(e) => {
                      const newFiles = [...files];
                      const updatedFileName =
                        e.target.value + "." + fileExtension;
                      newFiles[index] = {
                        ...file,
                        name: updatedFileName,
                      };
                      setFiles(newFiles);
                    }}
                    inputProps={{
                      maxLength: 128,
                      minLength: 2,
                    }}
                    error={
                      fileNameWithoutExtension.length < 2 ||
                      fileNameWithoutExtension.length > 128
                    }
                    helperText={
                      fileNameWithoutExtension.length < 4
                        ? "Filename must be at least 2 characters"
                        : fileNameWithoutExtension.length > 128
                        ? "Filename cannot exceed 128 characters"
                        : ""
                    }
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body1">
                    {getFileSize(file.file.size, "MB")}
                  </Typography>
                </Grid>

                <Grid item md={2}>
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveFile(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    }
  };

  return (
    <Grid container spacing={2} p={3}>
      <Grid container spacing={2} p={3}>
        <Grid item>
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
            multiple
            onChange={handleChange}
          />
        </Grid>
        {allowScanDocument && (
          <Grid item>
            <SecondaryButton
              label={"Scan document"}
              type={"button"}
              onClick={() => setIsScanDocuments(true)}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {renderFileList()}
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <TernaryButton
            onClick={() => {
              setIsUploadDocuments(false);
            }}
            label={"Cancel"}
            className="m-2"
          />
          <PrimaryButton
            label={"Upload documents"}
            type={"button"}
            disable={files.length === 0}
            onClick={handleSave}
            className="m-2"
          />
        </Grid>
      </Grid>

      {isScanDocuments && (
        <ScanDocument
          setIsScanDocuments={setIsScanDocuments}
          setFiles={setFiles}
        />
      )}
    </Grid>
  );
};

export default FileUpload;
