import React, { useState } from "react";
import { Login } from "../../components/login/Login";
import { SignUp } from "../../components/signUp/SignUp";
import ExcelToJsonConverter from "../../components/fileConvertors/XlsxToJson";

export const LoginPage = React.memo(() => {
  const [isSignUp, setIsSignUp] = useState(false);
  return (
    <>
      {isSignUp ? (
        <SignUp setIsSignUp={setIsSignUp} isSignUp={isSignUp} />
      ) : (
        <Login setIsSignUp={setIsSignUp} isSignUp={isSignUp} />
      )}
      {/* <ExcelToJsonConverter /> */}
    </>
  );
});
