import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { WENT_WRONG_MSG } from "../../constants/Constants";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import {
  deleteStaff,
  getStaffDetailsById,
  updateStaff,
} from "../../apis/staff";
import {
  STAFF_UPDATE_FAILED,
  STAFF_UPDATE_SUCCESS,
} from "../../constants/SnackbarMessages";
import { StaffTypes, Titles } from "../../util/patient/patient.form.helper";
import EditableBox from "../adminCenterComponents/EditableBox";
import SearchSites from "../search/SearchSites";
import MultiSelectStaff from "../search/MultiSelectStaff";
import useApi from "../../hooks/useApi";
import { getAllSites } from "../../apis/site";
interface StaffDetailsProps {
  staffData: StaffInformation;
  setShowTable: (value: boolean) => void;
  reload: Boolean;
  setReload: (value: boolean) => void;
}

const ShowStaffDetails: React.FC<StaffDetailsProps> = ({
  staffData,
  setShowTable,
  reload,
  setReload,
}) => {
  const [selectedStaffData, setSelectedStaffData] =
    useState<StaffInformation>();
  const [editing, setEditing] = useState(false);
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<SelectedSite>();
  const [selectedStaff, setSelectedStaff] = useState<MultiSelectOptionType[]>(
    []
  );
  const [defaultStaff, setDefaultStaff] = useState<MultiSelectOptionType[]>([]);
  const [updateStaffHook] = useApi();
  const [deleteStaffHook] = useApi();

  const handleEdit = () => {
    setEditing(true);
    if (selectedStaffData) {
      setNewValues(selectedStaffData);
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleSave = async () => {
    await updateStaffHook(
      updateStaff,
      newValues,
      STAFF_UPDATE_SUCCESS,
      STAFF_UPDATE_FAILED,
      WENT_WRONG_MSG,
      afterSuccess
    );
  };

  const afterSuccess = () => {
    setReload(!reload);
    setEditing(false);
  };

  const handleDelete = async () => {
    await deleteStaffHook(
      deleteStaff,
      selectedStaffData?.publicId,
      STAFF_UPDATE_SUCCESS,
      STAFF_UPDATE_FAILED,
      WENT_WRONG_MSG,
      afterSuccess
    );
  };

  const getSites = async () => {
    const [response, error] = await getAllSites();
    if (response) {
      response.map((element: Site) => {
        if (element.publicId === staffData?.siteExternalId) {
          setSelectedSite({
            label: `${element.name || ""}`,
            publicId: element.publicId,
          });
        }
        return 0;
      });
    } else {
      console.log(error);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    setNewValues((prev) => ({ ...prev, [name]: value }));
  };
  const getStaffDetails = async (id: string) => {
    const [response, error] = await getStaffDetailsById(id);
    if (response) {
      setSelectedStaffData(response);
      if (response.secretaries) {
        setDefaultStaff(
          response.secretaries.map((value: Secretaries) => {
            return {
              publicId: value.publicId,
              label: `${value.title} ${value.firstName} ${value.lastName}`,
            };
          })
        );
      }
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaffDetails(staffData.publicId);
    getSites();
    setEditing(false);
  }, [staffData]);

  useEffect(() => {
    if (selectedStaff.length > 0) {
      setNewValues((prev) => ({
        ...prev,
        secretaryPublicIds: selectedStaff.map((value) => value.publicId) || [],
      }));
    }
  }, [selectedStaff]);

  useEffect(() => {
    if (selectedSite) {
      setNewValues((prev) => ({
        ...prev,
        siteExternalId: selectedSite.publicId,
      }));
    }
  }, [selectedSite]);

  return (
    <Box sx={{ p: 2 }}>
      {selectedStaffData && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {editing ? (
              <>
                <div className="col-sm-4">
                  <div className="row m-2">
                    <FormControl>
                      <InputLabel id="title">Title</InputLabel>
                      <Select
                        id="title-select"
                        labelId="title"
                        label="Title"
                        name="title"
                        defaultValue={newValues.title}
                        required
                        onChange={handleSelectChange}
                      >
                        {Titles?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <TextField
                  className="col-md-4 p-2"
                  variant="outlined"
                  name="firstName"
                  value={newValues.firstName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  className="col-md-4 p-2"
                  variant="outlined"
                  name="lastName"
                  value={newValues.lastName}
                  onChange={handleChange}
                  required
                />
              </>
            ) : (
              <Typography
                variant="h4"
                className="text-capitalize"
                onDoubleClick={handleEdit}
              >
                {`${selectedStaffData.title} ${staffData.firstName} ${staffData.lastName}`}
              </Typography>
            )}
            {!editing && (
              <Box>
                <ActionEdit handleClick={handleEdit} />
                <ActionDelete handleClick={openConfirmModal} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              pt: 3,
            }}
          >
            <EditableBox
              label={"Username"}
              currentValue={selectedStaffData.username}
              name={selectedStaffData.username}
            />
            <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
              <Box sx={{ width: "40%" }}>
                <Typography variant="subtitle1">
                  <b>Type</b>
                </Typography>
              </Box>
              <Box sx={{ width: "50%" }}>
                {editing ? (
                  <FormControl fullWidth>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      id="type-select"
                      labelId="type"
                      label="Type"
                      name="type"
                      defaultValue={newValues.type}
                      onChange={handleSelectChange}
                    >
                      {StaffTypes?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="body1" onDoubleClick={handleEdit}>
                    {selectedStaffData.type}
                  </Typography>
                )}
              </Box>
            </Box>

            <EditableBox
              editing={editing}
              label={"Email"}
              handleChange={handleChange}
              newValue={newValues.email}
              currentValue={selectedStaffData.email || ""}
              type="email"
              name={"email"}
            />
            <EditableBox
              editing={editing}
              label={"Contact Number"}
              handleChange={handleChange}
              newValue={newValues.phoneNo}
              currentValue={selectedStaffData.phoneNo || ""}
              type="text"
              name="phoneNo"
            />

            <EditableBox
              editing={editing}
              label={"GMC Number"}
              handleChange={handleChange}
              newValue={newValues.gmcNumber}
              currentValue={selectedStaffData.gmcNumber || ""}
              type="text"
              name="gmcNumber"
            />
            <EditableBox
              editing={editing}
              label={"Signature"}
              handleChange={handleChange}
              newValue={newValues.signature}
              currentValue={selectedStaffData.signature || ""}
              type="text"
              name="signature"
            />
            <EditableBox
              editing={editing}
              label={"Staff External Id"}
              handleChange={handleChange}
              newValue={newValues.externalId}
              currentValue={selectedStaffData.externalId || ""}
              type="text"
              name="externalId"
            />
            <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
              <Box sx={{ width: "40%" }}>
                <Typography variant="subtitle1">
                  <b>Site</b>
                </Typography>
              </Box>

              <Box sx={{ width: "50%" }}>
                {editing ? (
                  <SearchSites
                    setSelectedSite={setSelectedSite}
                    className="col-md-12 "
                    defaultValue={selectedSite}
                    isExternal={true}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    className=""
                    onDoubleClick={handleEdit}
                  >
                    {selectedSite?.label}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
              <Box sx={{ width: "40%" }}>
                <Typography variant="subtitle1">
                  <b>Related Staff</b>
                </Typography>
              </Box>

              <Box sx={{ width: "50%" }}>
                {editing ? (
                  <MultiSelectStaff
                    label="Select related staff"
                    id="secretaryPublicIds"
                    setValues={setSelectedStaff}
                    values={selectedStaff}
                    className="col-sm-12"
                    defaultValue={defaultStaff}
                  />
                ) : (
                  selectedStaffData.secretaries?.map((staff) => (
                    <Typography
                      variant="body1"
                      className=""
                      key={staff.publicId}
                    >
                      <span>{`${staff.title} ${staff.firstName} ${staff.lastName}`}</span>
                    </Typography>
                  ))
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {editing ? (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  className="m-2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="m-2"
                  color="success"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </>
            ) : (
              <></>
            )}
          </Box>
          <ConfirmationDialog
            open={confirmModalOpen}
            onClose={closeConfirmModal}
            title={`Are you sure you want to delete staff '${selectedStaffData.title} ${selectedStaffData.firstName} ${selectedStaffData.lastName}'?`}
            onCancel={closeConfirmModal}
            onDelete={handleDelete}
          />
        </>
      )}
    </Box>
  );
};

export default ShowStaffDetails;
