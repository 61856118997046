import { AZURE_URL } from "../constants/env-constants";

export const createPrescription = async (
  Prescription: CreatePrescriptionRequest
) => {
  try {
    const response = await AZURE_URL.post("/prescription/create", Prescription);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const getPrescriptionsByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(
      `/prescription/get?patientPublicId=${id}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const deletePrescription = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/prescription/delete?publicId=${id}`
    );
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};
