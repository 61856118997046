import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import TernaryButton from "../buttons/TernaryButton";
import {
  deletePathway,
  getPathwayById,
  updatePathway,
} from "../../apis/pathway";
import { FieldValues, SubmitHandler } from "react-hook-form";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import {
  PATHWAY_DELETE_FAILED,
  PATHWAY_DELETE_SUCCESS,
  PATHWAY_UPDATE_FAILED,
  PATHWAY_UPDATE_SUCCESS,
} from "../../constants/SnackbarMessages";
import EditableTitleBox from "../adminCenterComponents/EditableTitleBox";
import EditableBox from "../adminCenterComponents/EditableBox";
import DisplayStages from "./DisplayStages";
import EditableStageForm from "./EditableStageForm";

const ShowPathwayDetails: React.FC<PathwayDetailsProps> = ({
  selectedPathway,
  setSelectedPathway,
  setReload,
  reload,
}) => {
  const [editing, setEditing] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [pathwayName, setPathwayName] = useState<string>(selectedPathway.name);
  const [pathwayDetails, setPathwayDetails] = useState<Pathway>();
  const [newStages, setNewStages] = useState<PathwayStages[]>([]);
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditing(true);
    setPathwayName(selectedPathway.name);
  };

  const handleCancel = () => {
    setEditing(false);
  };
  const handleSave: SubmitHandler<FieldValues> = async (data) => {
    if (pathwayDetails) {
      const obj = {
        publicId: pathwayDetails.publicId,
        name: pathwayName,
        pathwayStages: newStages,
      };

      try {
        const [response, error] = await updatePathway(obj as Pathway);
        if (response) {
          dispatch(
            activateSnackbar({
              active: true,
              message: PATHWAY_UPDATE_SUCCESS,
              variant: SNACKBAR_SUCCESS,
            })
          );
          setReload(true);
          setSelectedPathway(null);
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: PATHWAY_UPDATE_FAILED,
              variant: SNACKBAR_ERROR,
            })
          );
        }
        setEditing(false);
      } catch (error) {
        dispatch(
          activateSnackbar({
            active: true,
            message: "Please select a staff",
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const handleDelete = async () => {
    const [response, error] = await deletePathway(selectedPathway.publicId);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: PATHWAY_DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: PATHWAY_DELETE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
    setSelectedPathway(null);
    // setEditing(false);
    setReload(!reload);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setPathwayName(newName);
  };

  const getPathwayDetails = async () => {
    const [response] = await getPathwayById(selectedPathway.publicId);
    if (response) {
      setPathwayDetails(response);
    }
  };

  useEffect(() => {
    getPathwayDetails();
  }, [selectedPathway]);

  return (
    <Box sx={{ p: 2 }}>
      <EditableTitleBox
        editing={editing}
        label={""}
        handleChange={handleNameChange}
        newValue={pathwayName}
        currentValue={selectedPathway.name}
        handleEdit={handleEdit}
        openConfirmModal={openConfirmModal}
        name={""}
      />

      <EditableBox
        editing={false}
        label={"Stages"}
        currentValue={""}
        name={"stages"}
      />

      {editing && pathwayDetails ? (
        <EditableStageForm
          stages={pathwayDetails.pathwayStages}
          setNewStages={setNewStages}
          setIsFormComplete={setIsFormComplete}
        />
      ) : (
        <DisplayStages selectedPathway={selectedPathway} />
      )}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing ? (
          <>
            <TernaryButton onClick={handleCancel} label={"Cancel"} />
            <Button
              variant="contained"
              className="m-2"
              color="success"
              disabled={!isFormComplete}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete pathway '${selectedPathway?.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default ShowPathwayDetails;
