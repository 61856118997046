import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type PasswordInputProps = {
  label: string;

  setOldPassword?: React.Dispatch<React.SetStateAction<string>>;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  setNewPassword,
  setOldPassword,
}) => {
  const [oldPassword, setOldPasswordComp] = useState<string>();
  const [newPassword, setNewPasswordComp] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>("");
  const [confirmError, setConfirmError] = useState<string>("");
  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPassword = e.target.value;
    setOldPasswordComp(inputPassword);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPassword = e.target.value;
    setNewPasswordComp(inputPassword);
    setError("");
    validateForm(inputPassword);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputConfirmPassword = e.target.value;
    setConfirmPassword(inputConfirmPassword);
    setConfirmError("");
    validateConfirmPassword(inputConfirmPassword);
  };

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  const validateForm = (password: string) => {
    if (password) {
      if (!validatePassword(password)) {
        setError(
          "Password must contain at least 8 characters, including uppercase and lowercase letters, and at least one digit."
        );
        return false;
      } else {
        setError("");
        setConfirmError("");
        return true;
      }
    }
  };

  const validateConfirmPassword = (password: string) => {
    if (password) {
      if (newPassword && newPassword !== password) {
        setConfirmError("Passwords do not match");
        return false;
      } else {
        setError("");
        setConfirmError("");
        if (oldPassword && setOldPassword) {
          setOldPassword(oldPassword);
        }
        if (newPassword) setNewPassword(newPassword);
        return true;
      }
    }
  };
  return (
    <>
      {setOldPassword && (
        <FormControl fullWidth sx={{ pb: 2, maxWidth: "100%" }}>
          <TextField
            id="old-password"
            label={"Old Password"}
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={handleOldPasswordChange}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword((prev) => !prev)}
                    edge="end">
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      )}
      <FormControl fullWidth sx={{ pb: 2, maxWidth: "100%" }}>
        <TextField
          id="password-input"
          label={label}
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={handlePasswordChange}
          error={!!error}
          helperText={error}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((prev) => !prev)}
                  edge="end">
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ pb: 2, maxWidth: "100%" }}>
        <TextField
          id="confirm-password-input"
          label={`Confirm ${label}`}
          disabled={!!error}
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          error={!!confirmError}
          autoComplete="off"
          helperText={confirmError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  edge="end">
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default PasswordInput;
