import React from "react";
import ShowProfile from "../../components/profile/ShowProfile";

const Profile = () => {
  return (
    <div>
      <ShowProfile />
    </div>
  );
};

export default Profile;
