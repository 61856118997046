import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import PrimaryButton from "../buttons/PrimaryButton";
import SearchStaff from "../search/SearchStaff";
import TernaryButton from "../buttons/TernaryButton";
import StaffMultiSelect from "./StaffMultiSelect";
import SearchLocation from "../search/SearchLocation";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import { createNewTeam } from "../../apis/team";
import { TEAM_CREATE_SUCCESS } from "../../constants/SnackbarMessages";
import { TEAM_CREATE_FAILED } from "../../constants/SnackbarMessages";
import { removeEmptyValues } from "../../util/data.util";
interface AddSiteProps {
  setIsAddSite: React.Dispatch<React.SetStateAction<boolean>>;
  reload: Boolean;
  setReload: (value: boolean) => void;
}
const AddTeam: React.FC<AddSiteProps> = ({
  setIsAddSite,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm<{
    name: string;
    locationPublicId: string;
  }>();
  const [values, setValues] = useState<MultiSelectOptionType[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<{
    label: string;
    publicId: string;
  }>();
  const onSubmit = async (data: { name: string; locationPublicId: string }) => {
    if (selectedStaff) {
      let obj: CreateTeamRequest = {
        name: data.name,
        locationPublicId: data.locationPublicId || "",
        teamLeaderPublicId: selectedStaff.publicId,
        teamMembersPublicIds: values.map((value) => value.publicId),
      };

      obj = removeEmptyValues(obj);
      const [response, error] = await createNewTeam(obj);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: TEAM_CREATE_SUCCESS,
            variant: SNACKBAR_SUCCESS,
          })
        );
        setReload(!reload);
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: TEAM_CREATE_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add New Team
      </Typography>{" "}
      <Box sx={{}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                key="name"
                className="col-sm-12 "
                {...register("name", { required: true })}
                placeholder="Team name"
                id="name"
                label="Team Name"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SearchLocation setValue={setValue} id="locationPublicId" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SearchStaff
                label="Select Team Leader"
                setSelectedStaff={setSelectedStaff}
                className="col-sm-10 "
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <StaffMultiSelect
                label={"Select Team Members"}
                id={""}
                setValues={setValues}
                values={values}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <TernaryButton
                  onClick={() => {
                    setIsAddSite(false);
                  }}
                  label={"Cancel"}
                  className="m-2"
                />
                <PrimaryButton label={"Save"} type={"submit"} className="m-2" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddTeam;
