import { useState } from "react";
import { useDispatch } from "react-redux";
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from "../constants/Constants";
import { activateSnackbar } from "../redux/slice/snackbarSlice";
import { doneLoading, loading } from "../redux/slice/loaderSlice";

type ApiFunction<T extends any[], R> = (...args: T) => Promise<R>;

const useApi = <T>() => {
  const [error, setError] = useState<any>(null);
  const dispatch = useDispatch();
  const handleApiCall = async (
    apiFunction: ApiFunction<any[], any>,
    data: T,
    successMessage: string,
    errorMessage: string,
    catchMessage: string,
    successFunction?: (response: any) => void
  ) => {
    setError(null);

    try {
      dispatch(loading());
      const [response, error] = await apiFunction(data);
      if (response) {
        dispatch(
          activateSnackbar({
            active: true,
            message: successMessage,
            variant: SNACKBAR_SUCCESS,
          })
        );
        if (successFunction) {
          successFunction(response);
        }
        // Additional logic or state updates upon successful response
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: error || errorMessage,
            variant: SNACKBAR_ERROR,
          })
        );
        // Additional error handling logic or state updates
      }
    } catch (error: any) {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || catchMessage,
          variant: SNACKBAR_ERROR,
        })
      );
      // Additional error handling logic or state updates
    } finally {
      dispatch(doneLoading());
    }
  };

  return [handleApiCall, loading, error] as const;
};

export default useApi;
