import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UserState = {
  user: {
    publicId: "",
    username: "",
    email: "",
    roles: [],
    staff: {
      publicId: "",
      username: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      signature: "",
      createdBy: "",
      whenCreated: "",
      updatedBy: "",
      whenLastUpdated: "",
      siteExternalId: "",
    },
    createdBy: "",
    whenCreated: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserCredentials: (
      state: UserState,
      action: PayloadAction<UserEntities>
    ) => {
      state.user = action.payload;
    },
    getUserCredentials: (state: UserState) => {
      return state;
    },
  },
});

export default userSlice.reducer;
export const { addUserCredentials, getUserCredentials } = userSlice.actions;

export const getCurrentUser = (state: UserState) => state.user;
