import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import EditableBox from "../../adminCenterComponents/EditableBox";
import { formatDate, formatToDateTime } from "../../../util/data.util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

interface TestResultsProps {
  selectedTest: TDLRequestedTestResponse;
}

const ShowTestResults: React.FC<TestResultsProps> = ({ selectedTest }) => {
  const result = (result: string) => {
    return result.replace(/\\/g, "\\\\");
  };
  return (
    <Grid container spacing={2} paddingTop={2}>
      <Paper
        elevation={0}
        style={{ padding: 16 }}
        sx={{ margin: 3, width: "100%" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Patient Details</Typography>
        </Grid>
        <Grid container md={12} margin={3}>
          <Grid item md={6}>
            <EditableBox
              label={"Name"}
              currentValue={`${selectedTest.patient.title || ""} ${
                selectedTest.patient.firstName
              } ${selectedTest.patient.lastName || ""}`}
              name={""}
            />
            <EditableBox
              label={"Gender"}
              currentValue={selectedTest.patient.gender || ""}
              name={""}
            />
            <EditableBox
              label={"Address"}
              currentValue={`${
                selectedTest.patient.address?.street1
                  ? `${selectedTest.patient.address?.street1}, `
                  : ""
              }${selectedTest.patient.address?.city} ${
                selectedTest.patient.address?.postalCode
              }`}
              name={""}
            />
          </Grid>
          <Grid item md={6}>
            <EditableBox
              label={"Patient Id"}
              currentValue={selectedTest.patient.publicId}
              name={""}
            />

            <EditableBox
              label={"Date of Birth"}
              currentValue={formatDate(selectedTest.patient.dateOfBirth || "")}
              name={""}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={1}
        style={{ padding: 16 }}
        sx={{ margin: 3, width: "100%" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <Typography variant="h5">Test Results</Typography>
              {selectedTest.testResult?.SampleDate && (
                <EditableBox
                  label={"Sample Date"}
                  currentValue={formatToDateTime(
                    selectedTest.testResult?.SampleDate
                  )}
                  name={""}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {selectedTest.testResult?.ReportedDate && (
                <EditableBox
                  label={"Report Date"}
                  currentValue={formatToDateTime(
                    selectedTest.testResult?.ReportedDate
                  )}
                  name={""}
                />
              )}
            </Grid>
            <hr />
          </Grid>
        </Grid>
        {selectedTest.testResult.Tests.map(
          (testResult: TestResult, index: number) => (
            <Grid container key={index} md={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ borderBottom: 1, marginBottom: 1 }}
                >
                  {testResult.TestName}
                </Typography>
              </Grid>
              {testResult.ResultLines.map((item: ResultLine, index: number) => (
                <>
                  <Grid container spacing={-3} paddingLeft={6}>
                    <Grid item md={2}>
                      <Typography
                        className="courier"
                        style={{
                          color: item.Flag === "High" ? "#ff4d4d" : "",
                        }}
                      >
                        {item.TestName}
                      </Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <pre
                        className={`courier ${
                          item.Flag === "High"
                            ? "high"
                            : item.Flag === "Low"
                            ? "low"
                            : item.Flag === "A"
                            ? "abnorm"
                            : ""
                        }`}
                        style={{
                          color: item.Flag === "High" ? "#ff4d4d" : "",
                        }}
                      >
                        {result(item.Result)}
                      </pre>{" "}
                    </Grid>{" "}
                    <Grid item md={1}>
                      {item.Flag === "High" && (
                        <FontAwesomeIcon icon={faArrowUp} color={"#ff4d4d"} />
                      )}{" "}
                      {item.Flag === "Low" && (
                        <FontAwesomeIcon icon={faArrowDown} color={""} />
                      )}
                    </Grid>
                    <Grid
                      item
                      md={2}
                      style={{
                        color: item.Flag === "High" ? "#ff4d4d" : "",
                      }}
                    >
                      <Typography className="courier">{item.UOM}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      style={{
                        color: item.Flag === "High" ? "#ff4d4d" : "",
                      }}
                    >
                      <Typography className="courier">
                        {item.RangeText}
                      </Typography>
                    </Grid>
                  </Grid>
                  {index === testResult.ResultLines.length - 1 && (
                    <Grid item md={12}>
                      <hr />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          )
        )}
      </Paper>
    </Grid>
  );
};

export default ShowTestResults;
