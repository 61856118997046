import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AuthState = {
  auth: {
    token: null,
    expiresIn: 0,
    tokenType: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<AuthEntities>) => {
      state.auth.token = action.payload.token;
      state.auth.expiresIn = action.payload.expiresIn;
      state.auth.tokenType = action.payload.tokenType;
    },
    logout: () => initialState,
    getAuth: (state: AuthState) => {
      return state;
    },
  },
});

export default authSlice.reducer;
export const { login, logout, getAuth } = authSlice.actions;
