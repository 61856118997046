import * as React from "react";
import { Box, Fab } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const isTop: boolean = window.scrollY < 100;
      setShowButton(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "2rem",
        right: "2rem",
        display: showButton ? "block" : "none",
      }}>
      <Fab
        color="primary"
        aria-label="scroll to top"
        onClick={handleScrollToTop}>
        <KeyboardArrowUp />
      </Fab>
    </Box>
  );
};

export default ScrollToTopButton;
