import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordInput from "../password/PasswordInput";

interface Address {
  street1: string;
  street2?: string;
  city: string;
  state?: string;
  postalCode: string;
  country?: string;
}

interface EditableBoxProps {
  editing?: boolean;
  label: string;
  handleChange?: any;
  newValue?: string | number;
  currentValue: string | number | Address | boolean;
  type?: "email" | "text" | "password" | "number" | "checkbox";
  name: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  checkboxLabel?: string;
  setCheckboxValue?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const EditableBox: React.FC<EditableBoxProps> = ({
  editing = false,
  label,
  handleChange,
  newValue,
  currentValue,
  type = "text",
  name,
  setValue,
  checkboxLabel,
  setCheckboxValue,
}) => {
  const renderValue = (value: any) => {
    if (typeof value === "object" && value !== null) {
      if ("street1" in value) {
        const address = value as Address;
        return (
          <>
            <Typography variant="body1">
              <b>Street 1: </b>
              {address.street1}
            </Typography>
            <Typography variant="body1">
              <b>Street 2: </b>
              {address.street2 || ""}
            </Typography>
            <Typography variant="body1">
              <b>City: </b>
              {address.city}
            </Typography>
            <Typography variant="body1">
              <b>State: </b>
              {address.state || ""}
            </Typography>
            <Typography variant="body1">
              <b>Postal Code: </b>
              {address.postalCode}
            </Typography>
            <Typography variant="body1">
              <b>Country: </b>
              {address.country || ""}
            </Typography>
          </>
        );
      } else {
        // Handle other object types if needed
      }
    } else {
      return <Typography variant="body1">{value}</Typography>;
    }
  };

  return (
    <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
      <Box sx={{ width: "40%" }}>
        <Typography variant="subtitle1">
          <b>{label}</b>
        </Typography>
      </Box>
      <Box sx={{ width: "50%" }}>
        {editing ? (
          type === "password" && setValue ? (
            <>
              <PasswordInput label="New Password" setNewPassword={setValue} />
            </>
          ) : type === "checkbox" && setCheckboxValue ? (
            <>
              <FormControlLabel
                control={<Checkbox onChange={setCheckboxValue} />}
                label="reset2FA"
              />
              {/* <Checkbox onChange={setCheckboxValue} />
              <label htmlFor="">Reset 2FA</label> */}
            </>
          ) : (
            <TextField
              id={name}
              fullWidth
              className="col-md-12"
              variant="outlined"
              type={type}
              name={name}
              defaultValue={currentValue}
              value={newValue}
              onChange={handleChange}
            />
          )
        ) : (
          renderValue(currentValue)
        )}
      </Box>
    </Box>
  );
};

export default EditableBox;
