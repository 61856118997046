import { AZURE_URL } from "../constants/env-constants";

export const getAllPathways = async () => {
  try {
    const response = await AZURE_URL.get("/pathway/get/all");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const createPathway = async (pathway: CreatePathwayRequest) => {
  try {
    const response = await AZURE_URL.post("/pathway/create", pathway);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const updatePathway = async (pathway: Pathway) => {
  try {
    const response = await AZURE_URL.put("/pathway/update", pathway);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const deletePathway = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/pathway/delete?publicId=${id}`);
    if (response.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};

export const getPathwayById = async (pathwayId: string) => {
  try {
    const response = await AZURE_URL.get(`/pathway/get?publicId=${pathwayId}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};
