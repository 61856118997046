import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint, useTheme } from "@mui/material/styles";
import TextInput from "../fromComponents/TextInput";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  RegisterOptions,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";
import {
  createPatient,
  getAllAllergies,
  searchPatient,
} from "../../apis/patients";
import { getAllStaff } from "../../apis/staff";
import SearchPatient from "../fromComponents/SearchPatient";
import SearchStaff from "../search/SearchStaff";
import PatientEditForm from "../patientsModule/PatientEditForm";
import MultiSelectInput from "../fromComponents/MultiSelectInput";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import {
  PATIENT_ADDED_SUCCESSFULLY,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  WENT_WRONG_MSG,
} from "../../constants/Constants";

interface FormDialogBox {
  isOpen: boolean;
  setIsOpen: { (value: boolean): void };
  header: string;
  formFields: InputFieldType[];
  width: Breakpoint;
  partnerPublicId?: string;
}

export const FormDialogBox = React.memo<FormDialogBox>(
  ({ isOpen, setIsOpen, header, formFields, width, partnerPublicId }) => {
    const { register, handleSubmit } = useForm<CreatePatientInterface>();
    const [allergies, setAllergies] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [selectedPatient, setSelectedPatient] = useState<{
      label: string;
      publicId: string;
    }>();

    const [selectedPartner, setSelectedPartner] = useState<{
      label: string;
      publicId: string;
    }>();
    const [selectedStaff, setSelectedStaff] = useState<{
      label: string;
      publicId: string;
    }>();

    const onSubmit = async (data: CreatePatientInterface) => {
      console.log(value);
      let obj: CreatePatientInterface = {
        phoneNo: "",
        ownerStaffPublicId: "",
        firstName: "",
        lastName: "",
        gender: "",
        dateOfBirth: "",
        address: { street1: "", city: "", state: "", postalCode: "" },
        email: "",
        title: "",
        partnerPublicId: partnerPublicId || "",
        patientDetails: { allergies: "" },
      };
      if (data) {
        obj = { ...obj, ...data };
      }
      if (!partnerPublicId) {
        if (selectedPartner) {
          obj = { ...obj, partnerPublicId: selectedPartner.publicId };
        }
      }
      if (selectedStaff) {
        obj = { ...obj, ownerStaffPublicId: selectedStaff.publicId };
      }

      if (value) {
        obj = { ...obj, patientDetails: { allergies: value.join(",") } };
      }
      Object.keys(obj).forEach((key) => {
        if (obj[key as keyof CreatePatientInterface] === "") {
          delete obj[key as keyof CreatePatientInterface];
        }
        if (key === "address") {
          if (Object.keys(obj.address).length > 0) {
            Object.keys(obj.address).forEach((key) => {
              if (obj.address[key as keyof Address] === "") {
                delete obj.address[key as keyof Address];
              }
            });
          } else {
            if (obj[key as keyof CreatePatientInterface] === "") {
              delete obj[key as keyof CreatePatientInterface];
            }
          }
        }
      });
      console.log(obj);
      if (obj) {
        const [response, error] = await createPatient(obj);
        console.log(response, error);
        if (response) {
          dispatch(
            activateSnackbar({
              active: true,
              message: PATIENT_ADDED_SUCCESSFULLY,
              variant: SNACKBAR_SUCCESS,
            })
          );
          setIsOpen(false);
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: error || WENT_WRONG_MSG,
              variant: SNACKBAR_ERROR,
            })
          );
        }
      }
    };
    const getAllergies = async () => {
      const [response, error] = await getAllAllergies();

      if (response) {
        setAllergies(response);
      }
    };
    useEffect(() => {
      getAllergies();
    }, []);
    useEffect(() => {
      // formFields.forEach((element) => {
      //   element.required = false;
      // });
    }, [selectedPatient]);
    const [value, setValue] = useState<string[]>([]);
    console.log(value);

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={width}
          open={isOpen}
          onClose={() => setIsOpen(false)}>
          <DialogTitle>{header}</DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>
                <div className="col-sm-12 p-2">
                  <div className="row ms-1">
                    <div className="col-sm-4 p-2">
                      <div className="row">
                        <MultiSelectInput
                          label={"Allergies"}
                          name={"allergies"}
                          id="allergies"
                          setValue={setValue}
                          value={value}
                          options={allergies}
                        />
                      </div>
                    </div>

                    {formFields?.map((item: InputFieldType) => {
                      return item.id === "title" ? (
                        <div className="col-sm-4 p-2" key={item.id}>
                          <div className="row">
                            <FormControl className="col-sm-10">
                              <InputLabel id="demo-simple-select-label">
                                {item.label}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Age"
                                {...register(item.id, {
                                  required: item.required,
                                })}>
                                {item.options?.map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ) : (
                        <TextInput
                          keyName={item.id}
                          register={register}
                          placeholder={item.placeholder}
                          id={item.id}
                          label={item.label}
                          type={item.type}
                          listName={item.id + "list"}
                          options={item.options}
                          required={item.required}
                        />
                      );
                    })}
                  </div>
                </div>
              </DialogContentText>
              <hr />
              <DialogContentText>
                <div className="col-sm-12 p-2">
                  <div className="row ms-1">
                    {!partnerPublicId && (
                      <SearchPatient
                        label="Search Partner"
                        patient={selectedPartner}
                        setPatient={setSelectedPartner}
                      />
                    )}
                    <SearchStaff setSelectedStaff={setSelectedStaff} />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
              <Button
                onClick={() => setIsOpen(false)}
                autoFocus
                variant="outlined"
                color="error">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
);
