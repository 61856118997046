import jsPDF from "jspdf";

import {
  addCreatedDate,
  addFooter,
  addHeader,
  addOnePage,
  addPatientInfo,
  pageHeight,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";
//created by Ranjit Jeve

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 8;
const textFontPoint = 11;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10; //
const drNamePosition = 25;
const oneStopTitle = "One-Stop Fertility Clinic Report";

const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";

const amhText =
  "AMH is the blood test to test ovarian reserve. It indicates the number of eggs in the ovary. It does not predict the chances of natural conception, but it helps to understand the fertility potential and dose required for IVF. With low egg reserve, we advise considering fertility treatment sooner rather than later.";
export const downloadOneStopFertility = async (
  patientData: OneStopFertility
) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  addOneStopReportTitle(doc, oneStopTitle);
  addOneStopReportData(doc, patientData);
  addSignature(doc, patientData.signature);

  doc.save(
    "R_One_Stop_Fertility_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );
  globalPosition = 10;
  return doc.output("arraybuffer");
};

const addOneStopReportTitle = (doc: any, title: string) => {
  doc
    .setFontSize(reportTitlePoint)
    .text(title, marginLeft + 55, getGlobalPosition(0));
};

const addSignature = (doc: any, drName: string) => {
  if (globalPosition + 33 > pageHeight) {
    addOnePage(doc, clinicAddress, email, phone);
    globalPosition = 30;
    doc
      .text(drName, marginLeft, getGlobalPosition(drNamePosition))
      .text(
        "(Electronically approved)",
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
  } else {
    doc
      .text(drName, marginLeft, getGlobalPosition(drNamePosition))
      .text(
        "(Electronically approved)",
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
  }
};

// const generateHr = (doc: any, y: number) => {
//   doc.line(marginLeft, y, 195, y, "S");
// };

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};

const addOneStopReportData = (doc: any, patientData: OneStopFertility) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "•  Medical History: " + patientData.medicalHistory,
      marginLeft,
      getGlobalPosition(spaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
  getGlobalPosition(gettextLength(patientData.medicalHistory) + 5);
  doc.text(
    "•  Obstetric History: " + patientData.obstetricHistory,
    marginLeft,
    getGlobalPosition(spaceBetween),
    { maxWidth: 170, align: "justify" },
    null
  );
  getGlobalPosition(gettextLength(patientData.obstetricHistory) + 5);
  doc.text(
    "•  Significant Clinical History: " +
      patientData.significantClinicalHistory,
    marginLeft,
    getGlobalPosition(commonSpaceBetween),
    { maxWidth: 170, align: "justify" },
    null
  );
  getGlobalPosition(gettextLength(patientData.significantClinicalHistory) + 5);
  doc
    .text(
      "•  Period History: " + patientData.periodHistory,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Ultrasound Scan Report: " + patientData.ultrasoundScanReport,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Uterus: " + patientData.uterus,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Cavity: " + patientData.cavity,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Left ovary: " + patientData.leftOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Right ovary: " + patientData.rightOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Both Adnexa: " + patientData.bothAdnexa,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )

    .text(
      "•  AFC: " + patientData.afc,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  HyCoSY (Tube Test): " + patientData.hycosy,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    );
  doc = addPageIfNeeded(doc, getGlobalPosition(0) + 0);
  if (patientData.hycosy === "Performed") {
    doc
      .text(
        "•  Left Fallopian Tube: " + patientData.leftTube,
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      )
      .text(
        "•  Left Fallopian Tube: " + patientData.rightTube,
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
    doc = addPageIfNeeded(
      doc,
      getGlobalPosition(0) + gettextLength(patientData.anyComments)
    );
    doc.text(
      "•  Comments: " + patientData.anyComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
    doc = addPageIfNeeded(doc, getGlobalPosition(0) + gettextLength(amhText));
    getGlobalPosition(gettextLength(patientData.anyComments) + 0);
  }
  doc
    .text(
      "•  AMH (Anti Mullerian hormone) Report: " + patientData.amh,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      amhText,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
  doc = addPageIfNeeded(
    doc,
    getGlobalPosition(0) + gettextLength(patientData.advice)
  );

  getGlobalPosition(gettextLength(amhText) + 0);
  doc.text(
    "•  Advice: " + patientData.advice,
    marginLeft,
    getGlobalPosition(commonSpaceBetween),
    { maxWidth: 170, align: "justify" },
    null
  );
  getGlobalPosition(gettextLength(patientData.advice) + 0);
};

const addPageIfNeeded = (doc: any, position: number) => {
  if (position > 200) {
    doc = addOnePage(doc, clinicAddress, email, phone);
    globalPosition = 35;
  }

  return doc;
};
export const gettextLength = (text: string) => {
  if (text) {
    let arr = text.split(" ");

    let h = arr.length / 12;

    return h * 3;
  }
  return 0;
};
