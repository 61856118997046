import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import PrimaryButton from "../buttons/PrimaryButton";
import { FormDialogBox } from "../dialogBoxes/FormDialogBox";
import { NewPatientFormInputs } from "../../util/patient/patient.form.helper";
import CustomBox from "../buttons/CustomBox";
import { BRAND_COLOR_LIGHT } from "../../constants/BrandConstants";
import {
  ALLERGY_BG_COLOR_RED,
  GENDER_COLOR_BLUE,
  GENDER_COLOR_PINK,
  TEXT_COLOR_BLACK,
  TEXT_COLOR_WHITE,
} from "../../constants/ColorsConstants";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PatientEditForm from "./PatientEditForm";
import { FEMALE, MALE } from "../../constants/Constants";
import NotificationAndAlerts from "./NotificationAndAlerts";
import SearchPatient from "../fromComponents/SearchPatient";
import AddPartnerForm from "./AddPartnerForm";
import SecondaryButton from "../buttons/SecondaryButton";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import ImageClickUploader from "../fromComponents/ImageClickUploader";
import useFetchPatient from "../../hooks/useFetchPatient";
import { NewPatientForm } from "./NewPatientForm";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import { useNavigate } from "react-router-dom";
import ComponentLoading from "../loading/ComponentLoading";

const PartnerBasicDetails = React.memo<PatientDetailsProps>(() => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const { partner } = partnerObject;
  const [selectedPartner, setSelectedPartner] = useState<{
    label: string;
    publicId: string;
  }>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const patient: PatientInformation = patientObject.patient;
  const { partnerPublicId, allergies } = patient;
  const [partnerDetails, setPartnerDetails] = useState<PatientInformation>();
  const [initialData, setInitialData] = useState<PatientInformation>();
  const [initialPartnerData, setInitialPartnerData] =
    useState<PatientInformation>();
  const dispatch = useDispatch();
  const { getFileSrc } = useDownloadFile();
  const [, setImageUrl] = useState<string>();
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const fetchPatientDetails = useFetchPatient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getPartnerDetails = async (partnerPublicId: string) => {
    setIsLoading(true);
    const [response, error] = await fetchPatientDetails(partnerPublicId, true);
    if (selectedPartner && response) {
      setInitialPartnerData(response);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (partnerPublicId) {
      getPartnerDetails(partnerPublicId);
    }
  }, [partnerPublicId, reload]);

  useEffect(() => {
    if (selectedPartner) {
      getPartnerDetails(selectedPartner?.publicId);
      setIsEdit(true);
    }
  }, [selectedPartner]);
  useEffect(() => {
    if (partnerObject.partner.publicId)
      setPartnerDetails(partnerObject.partner);
    getProfileImage();
  }, [partnerObject]);

  const getProfileImage = async () => {
    if (partner.profilePictureUrl) {
      const url = partner.profilePictureUrl;
      const [src] = await getFileSrc(url);
      if (src) {
        setImageUrl(src);
      }
    }
  };
  const handlePatientChange = () => {
    navigate(`/patients/information/${partner.publicId}`);
    window.location.reload();
  };

  return (
    <>
      <Card sx={{ p: 2, borderRadius: 5, height: "100%" }}>
        {isLoading ? (
          <ComponentLoading />
        ) : (
          <Grid container>
            {partnerDetails ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex">
                  <ImageClickUploader patient={partner} />
                </Grid>

                <Grid item xs={12} sm={12} md={9}>
                  <Box paddingBottom={2}>
                    <CustomBox
                      label={partner.publicId}
                      onClick={handlePatientChange}
                      color={BRAND_COLOR_LIGHT}
                      textColor="white"
                    />
                    {partner?.gender === FEMALE ? (
                      <CustomBox
                        label={FEMALE}
                        color={GENDER_COLOR_PINK}
                        textColor={TEXT_COLOR_BLACK}
                      />
                    ) : partner?.gender === MALE ? (
                      <CustomBox
                        label={MALE}
                        color={GENDER_COLOR_BLUE}
                        textColor={TEXT_COLOR_WHITE}
                      />
                    ) : null}
                    {/* temp removed */}
                    {/* <IconAction
                    icon={<NotificationsActiveOutlinedIcon />}
                    badgeValue={0}
                    onClick={() => setIsNotificationOpen(true)}
                  /> */}
                  </Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight={"bold"}>
                        {`${partner.title ? partner.title + " " : ""}${
                          partner.firstName ? partner.firstName + " " : ""
                        }${partner.lastName ? partner.lastName : ""}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">DOB</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {partner.dateOfBirth && (
                        <Typography variant="body1">
                          {`${
                            partner.dateOfBirth ? partner.dateOfBirth : ""
                          } (${partner.age})`}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Email</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">{`${
                        partner.email ? partner.email : ""
                      }`}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Address</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">{`${
                        partner.address
                          ? (partner.address.street1 || "") +
                            ", " +
                            (partner.address.postalCode || "")
                          : ""
                      }`}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Allergies</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        {allergies?.length === 0 ? (
                          <Typography variant="body1" color={"error"}>
                            Allergies not added
                          </Typography>
                        ) : (
                          allergies?.map((allergy: string) => (
                            <CustomBox
                              label={allergy}
                              color={ALLERGY_BG_COLOR_RED}
                              textColor={TEXT_COLOR_BLACK}
                            />
                          ))
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    {/* <SecondaryButton
                    className={"m-2"}
                    type={"button"}
                    label={"Show Details"}
                    onClick={() => setShowDetails(true)}
                  /> */}

                    <SecondaryButton
                      className={"m-2"}
                      label={"Edit"}
                      type={"button"}
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setIsEdit(true);
                        setInitialData(partner);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="subtitle1">
                      Consultant
                      {` ${
                        patient?.ownerStaff?.title
                          ? patient.ownerStaff?.title + " "
                          : ""
                      } ${
                        patient?.ownerStaff?.firstName
                          ? patient.ownerStaff?.firstName + " "
                          : ""
                      } ${
                        patient?.ownerStaff?.lastName
                          ? patient.ownerStaff?.lastName + " "
                          : ""
                      }`}
                    </Typography>
                    <Typography variant="body1"></Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                spacing={2}
                xs={12}
                sm={12}
                md={12}
                justifyContent="center"
                alignItems="center">
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  md={6}>
                  <SearchPatient
                    label="Search In Existing Patients"
                    patient={selectedPartner}
                    setPatient={setSelectedPartner}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center">
                  <Typography variant="body1" color="textSecondary">
                    or
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center">
                  <PrimaryButton
                    className="btn btn-primary"
                    type={"button"}
                    label={"Add Partner"}
                    onClick={() => setIsOpen(true)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Card>

      {isOpen && (
        // <FormDialogBox
        //   isOpen={isOpen}
        //   setIsOpen={setIsOpen}
        //   header={"Add Partner Information"}
        //   formFields={NewPatientFormInputs}
        //   partnerPublicId={patient.publicId}
        //   width={"xl"}
        // />
        <NewPatientForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          header={"Add Partner Information"}
          partnerPublicId={patient.publicId}
          width={"xl"}
          reload={reload}
          setReload={setReload}
        />
      )}
      {isEdit && initialPartnerData && (
        <AddPartnerForm
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          header={"header"}
          setInitialData={setPartnerDetails}
          initialData={initialPartnerData}
          formFields={[]}
          patientPublicId={patient.publicId}
          setReload={setReload}
        />
      )}
      {isEdit && initialData && (
        <PatientEditForm
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          header={"header"}
          initialData={initialData}
          formFields={[]}
          setReload={setReload}
        />
      )}

      <NotificationAndAlerts
        isOpen={isNotificationOpen}
        setIsOpen={setIsNotificationOpen}
      />
    </>
  );
});

export default PartnerBasicDetails;
