import jsPDF from "jspdf";

import {
  addCreatedDate,
  addFooter,
  addHeader,
  addOnePage,
  addPatientInfo,
  pageHeight,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";
//created by Ranjit Jeve

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 8;
const textFontPoint = 11;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10; //
const drNamePosition = 25; // globalPosition + drNamePosition
const hycosyReportTitle = "Hycosy (Tube Test) / 3D Ultrasound Scan Report";

// const hycosyTextPara =
//   "Chaperone present. Patient consented. Risks and benefits explained, pain relief medications advised\n\n prior to procedure. Confirmed with patient not pregnant. No history of chlamydia infection. ExEm Foam kit used as media. Argon/ AngioTech Balloon Catheter used. Probe cleaned with Tristel Duo.";
const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
export const downloadHycosyReport = async (patientData: HycosyScanType) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  addHycosyReportTitle(doc, hycosyReportTitle);
  addHycosyReportData(doc, patientData);
  addSignature(doc, patientData.signature);

  doc.save(
    "R_HyCoSy_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );
  globalPosition = 10;
  return doc.output("arraybuffer");
};

const addHycosyReportTitle = (doc: any, title: string) => {
  doc
    .setFontSize(reportTitlePoint)
    .text(title, marginLeft + 25, getGlobalPosition(spaceBetween + 2));
};

const addSignature = (doc: any, drName: string) => {
  if (globalPosition + 33 > pageHeight) {
    addOnePage(doc, clinicAddress, email, phone);
    globalPosition = 30;
    doc
      .text(drName, marginLeft, getGlobalPosition(drNamePosition))
      .text(
        "(Electronically approved)",
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
  } else {
    doc
      .text(drName, marginLeft, getGlobalPosition(drNamePosition))
      .text(
        "(Electronically approved)",
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
  }
};

// const generateHr = (doc: any, y: number) => {
//   doc.line(marginLeft, y, 195, y, "S");
// };

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};

const addHycosyReportData = (doc: any, patientData: HycosyScanType) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "•  Clinical Indication: " + patientData.clinicalIndication,
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(
      "•  Settings used: 2 D along with 3 D for cavity of uterus and 4D for Hycosy",
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(
      "•  Chaperone present. Patient consented. Risks and benefits explained, pain relief medications advised prior to procedure. Confirmed with patient not pregnant. No history of chlamydia infection. ExEm Foam kit used as media. Argon/ AngioTech Balloon Catheter used. Probe cleaned with Tristel Duo.",
      marginLeft,
      getGlobalPosition(spaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    )
    .text(
      "•  Uterus: " + patientData.uterus,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "•  Cavity: " + patientData.cavity,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Endometrial Thickness: " + patientData.endometrialThickness,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Fibroid / Polyp: " + patientData.fibroidOrPolyp,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Left Tube: " + patientData.leftTube,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Right Tube: " + patientData.rightTube,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Adnexa: " + patientData.adnexa,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Left ovary: " + patientData.leftOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Right ovary: " + patientData.rightOvary,
      marginLeft,
      getGlobalPosition(commonSpaceBetween)
    )
    .text(
      "•  Recommendation: " + patientData.recommendation,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    )
    .text(
      "•  Additional Comments: " + patientData.additionalComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170, align: "justify" },
      null
    );
  getGlobalPosition(gettextLength(patientData.additionalComments) + 0);
};

export const gettextLength = (text: string) => {
  if (text) {
    let arr = text.split(" ");

    let h = arr.length / 12;

    return h * 3;
  }
  return 0;
};
