export const patientAndPartnerFormInputs = [
  {
    label: "Patient Id",
    type: "text",
    id: "patientId",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Partner Id",
    type: "text",
    id: "partnerId",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Patient Name",
    type: "text",
    id: "patientName",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Partner Name",
    type: "text",
    id: "partnerName",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Patient DOB",
    type: "Date",
    id: "patientDOB",
    placeholder: "",
    required: false,
  },
  {
    label: "Partner DOB",
    type: "Date",
    id: "partnerDOB",
    placeholder: "",
    required: false,
  },
  {
    label: "Patient Address",
    type: "text",
    id: "patientAddress",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Patient Address",
    type: "text",
    id: "partnerAddress",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Patient Contact",
    type: "text",
    id: "patientContact",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Partner Contact",
    type: "text",
    id: "partnerContact",
    placeholder: "Enter here",
    required: false,
  },
];

export const ProtocolFreshCycleForm = [
  {
    label: "Treatment Type",
    type: "text",
    id: "treatmentType",
    placeholder: "Enter here",
    required: false,
    options: ["IVF", "ICSI", "Egg Freezing", "50-50 IVF/ICSI"],
  },
  {
    label: "Protocol Name",
    type: "text",
    id: "protocolName",
    placeholder: "Enter here",
    required: false,
    options: ["Short-Antagonist", "Long-Agonist"],
  },
  {
    label: "Stimulation Dose",
    type: "text",
    id: "stimulationDose",
    placeholder: "Enter here",
    required: false,
    options: [
      "150 Menopure",
      "225 Menopure",
      "300 Menopure",
      "450 Menopure",
      "150 Menopure 300 Ovaleap",
      "150 Menopure 300 Bemfola",
    ],
  },
  {
    label: "Drug Regime",
    type: "text",
    id: "drugRegime",
    placeholder: "Enter here",
    required: false,
    options: ["Single", "Mixed"],
  },
  {
    label: "Trigger",
    type: "text",
    id: "trigger",
    placeholder: "Enter here",
    required: false,
    options: ["Routine", "Routine Buserline if freeze all"],
  },
  {
    label: "Embryo Transfer",
    type: "text",
    id: "embryoTransfer",
    placeholder: "Enter here",
    required: false,
    options: ["Single", "SET or DET To discuss based on quality of embryos"],
  },
  {
    label: "Luteal Support",
    type: "text",
    id: "lutealSupport",
    placeholder: "Enter here",
    required: false,
    options: [
      "Routine",
      "Routine, Lubion",
      "Routine, Clexane, Aspirin",
      "Routine, Lubion, Clexane, Aspirin",
    ],
  },
  {
    label: "Additional Comments",
    type: "text", //add textarea tag
    id: "additionalComments",
    placeholder: "Enter here",
    tag: "textarea",
    required: false,
    maxLength: 250,
    minRows: 4,
  },
];

export const ProtocolFrozenCycleForm = [
  {
    label: "Treatment Type",
    type: "text",
    id: "treatmentType",
    placeholder: "Enter here",
    required: false,
    options: ["FET"],
  },
  {
    label: "Protocol Name",
    type: "text",
    id: "protocolName",
    placeholder: "Enter here",
    required: false,
    options: ["Standard FET", "Estrogen only", "Natural cycle"],
  },
  {
    label: "Embryo Transfer",
    type: "text",
    id: "embryoTransfer",
    placeholder: "Enter here",
    required: false,
    options: ["Single", "SET or DET To discuss based on quality of embryos"],
  },
  {
    label: "Luteal Support",
    type: "text",
    id: "lutealSupport",
    placeholder: "Enter here",
    required: false,
    options: [
      "Routine",
      "Routine, Lubion",
      "Routine, Clexane, Aspirin",
      "Routine, Lubion, Clexane, Aspirin",
    ],
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
    maxLength: 250,
    minRows: 4,
  },
];

export const ProtocolDonorSpermForm = [
  {
    label: "Treatment Type",
    type: "text",
    id: "treatmentType",
    placeholder: "Enter here",
    required: false,
    options: ["Donor sperm IVF", "Donor sperm IUI"],
  },
  {
    label: "Protocol Name",
    type: "text",
    id: "protocolName",
    placeholder: "Enter here",
    required: false,
    options: ["Short-Antagonist", "Long-Agonist"],
  },
  {
    label: "Stimulation Dose",
    type: "text",
    id: "stimulationDose",
    placeholder: "Enter here",
    required: false,
    options: [
      "150 Menopure",
      "225 Menopure",
      "300 Menopure",
      "450 Menopure",
      "150 Menopure 300 Ovaleap",
      "150 Menopure 300 Bemfola",
    ],
  },
  {
    label: "Drug Regime",
    type: "text",
    id: "drugRegime",
    placeholder: "Enter here",
    required: false,
    options: ["Single", "Mixed"],
  },
  {
    label: "Trigger",
    type: "text",
    id: "trigger",
    placeholder: "Enter here",
    required: false,
    options: ["Routine", "Routine Buserline if freeze all"],
  },
  {
    label: "Embryo Transfer",
    type: "text",
    id: "embryoTransfer",
    placeholder: "Enter here",
    required: false,
    options: ["Single", "SET or DET To discuss based on quality of embryos"],
  },
  {
    label: "Luteal Support",
    type: "text",
    id: "lutealSupport",
    placeholder: "Enter here",
    required: false,
    options: [
      "Routine",
      "Routine, Lubion",
      "Routine, Clexane, Aspirin",
      "Routine, Lubion, Clexane, Aspirin",
    ],
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
    maxLength: 250,
    minRows: 4,
  },
];

export const ProtocolIBSpainForm = [
  {
    label: "Reason for Referral",
    type: "text",
    id: "reasonForReferral",
    placeholder: "Enter here",
    required: false,
    options: [
      "Low egg reserve - Donor egg treatment",
      "Premature ovarian insufficiency - Donor egg treatment",
      "Multiple failed IVF cycles - Donor egg treatment",
      "Donor embryo treatment",
    ],
  },
  {
    label: "Previous Cycle and Outcome",
    type: "text",
    id: "previousCycleAndOutcome",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Allergies",
    type: "text",
    id: "allergies",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Sperm Analysis",
    type: "text",
    id: "spermAnalysis",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Medical History",
    type: "text",
    tag: "textarea",
    minRows: 4,
    id: "medicalHistory",
    placeholder: "Enter here",
    required: false,
  },
];
