import React, { useEffect, useState } from "react";
import { getAllStaff } from "../../apis/staff";
import MultiSelect from "../fromComponents/MultiSelect";

interface MultiSelectProps {
  className?: string;
  label: string;
  id: string;
  setValues: any;
  values: MultiSelectOptionType[];
  defaultValue?: MultiSelectOptionType[]; // Add defaultValue prop
}

const MultiSelectStaff: React.FC<MultiSelectProps> = ({
  className,
  label,
  id,
  setValues,
  values,
  defaultValue, // Include defaultValue prop in destructuring
}) => {
  const [search, setSearch] = useState<MultiSelectOptionType[]>([]);

  const getStaff = async () => {
    const [response, error] = await getAllStaff();
    if (response) {
      const results = response.map((element: StaffInformation) => ({
        label: `${element.title} ${element.firstName} ${element.lastName}`,
        publicId: element.publicId,
      }));
      setSearch(results);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setValues(defaultValue);
    }
  }, [defaultValue, setValues]);

  return (
    <MultiSelect
      className={className}
      label={label}
      options={search}
      value={values}
      setValue={setValues}
      name={""}
      id={""}
    />
  );
};

export default MultiSelectStaff;
