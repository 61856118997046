import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { getAllSites } from "../../apis/site";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddService from "./AddService";
import ShowServiceDetails from "./ShowServiceDetails";
import { getAllServices } from "../../apis/services";
import ComponentLoading from "../loading/ComponentLoading";

const ShowServices = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [showTable, setShowTable] = useState<boolean>();
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const [isAddService, setIsAddService] = useState<boolean>(false);

  const getServices = async () => {
    const [response, error] = await getAllServices();
    if (response) {
      setServices(
        response.map((service: Service) => {
          return {
            ...service,
          };
        })
      );
      setIsLoading(false);
    } else {
      console.log(error);
    }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Type",
      key: "type",
      sortable: true,
    },
  ];

  const handleRowClick = (row: any) => {
    // row = combineArrays(
    //     patientAndPartnerFormInputs,
    //     getForm(row.type) || [],
    //     row.reportFields
    //   );
    setIsAddService(false);
    setSelectedService(row);
    // setShowTable(false);
  };

  useEffect(() => {
    getServices();
  }, [reload]);
  return (
    <Grid container xs={12} sm={12} md={12} spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {services && (
            <CustomTable
              data={services}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              optionButtonText={"Add Service"}
              setOptionButton={setIsAddService}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "asc",
              }}
              height={"50vh"}
              overflow="auto"
              tableHeight="600px"
            />
          )}
        </Grid>
      )}
      {(isAddService || selectedService) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}>
          {isAddService ? (
            <AddService
              setIsAddService={setIsAddService}
              setReload={setReload}
              reload={reload}
            />
          ) : (
            selectedService && (
              <ShowServiceDetails
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                setReload={setReload}
                reload={reload}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowServices;
