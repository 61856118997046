import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import ActionEdit from "../actionIcons/ActionEdit";
import ActionDelete from "../actionIcons/ActionDelete";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import { deleteSupplement, updateSupplement } from "../../apis/supplements";
import {
  SUPPLEMENT_DELETE_FAILED,
  SUPPLEMENT_DELETE_SUCCESS,
  SUPPLEMENT_UPDATE_FAILED,
  SUPPLEMENT_UPDATE_SUCCESS,
} from "../../constants/SnackbarMessages";

const ShowSupplementDetails: React.FC<SupplementDetailsProps> = ({
  selectedSupplement,
  setSelectedSupplement,
  setReload,
  reload,
}) => {
  const [editing, setEditing] = useState<boolean>();

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [newValues, setNewValues] = useState<{ [key: string]: any }>({});
  const dispatch = useDispatch();
  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleSave = async () => {
    console.log(newValues);
    let obj: UpdateSupplementRequest = {
      publicId: selectedSupplement.publicId,
      name: newValues.name ? newValues.name : selectedSupplement.name,
      dose: newValues.dose ? newValues.dose : selectedSupplement.dose,
      standardText: newValues.standardText
        ? newValues.standardText
        : selectedSupplement.standardText,
      leafletUrl: newValues.leafletUrl
        ? newValues.leafletUrl
        : selectedSupplement.leafletUrl,
    };
    console.log(obj);
    const [response, error] = await updateSupplement(obj);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: SUPPLEMENT_UPDATE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setEditing(false);
      setSelectedSupplement(null);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || SUPPLEMENT_UPDATE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleDelete = async () => {
    const [response, error] = await deleteSupplement(
      selectedSupplement.publicId
    );
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: SUPPLEMENT_DELETE_SUCCESS,
          variant: SNACKBAR_SUCCESS,
        })
      );
      setEditing(false);
      setSelectedSupplement(null);
      setReload(!reload);
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: error || SUPPLEMENT_DELETE_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    setEditing(false);
  }, [selectedSupplement]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewValues((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Box
        padding={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        {editing ? (
          <TextField
            fullWidth
            label="Name"
            className="col-md-10 p-2"
            variant="outlined"
            required
            name="name"
            placeholder="Name"
            defaultValue={selectedSupplement.name}
            value={newValues?.name}
            onChange={handleChange}
          />
        ) : (
          <Typography variant="h4" className="text-capitalize">
            {selectedSupplement.name}
          </Typography>
        )}

        {!editing && (
          <Box>
            <ActionEdit handleClick={handleEdit} />
            <ActionDelete handleClick={openConfirmModal} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 3,
        }}>
        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Dose</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <TextField
                fullWidth
                className="col-md-12"
                variant="outlined"
                defaultValue={selectedSupplement.dose}
                value={newValues?.dose}
                onChange={handleChange}
                name="dose"
              />
            ) : (
              <Typography variant="body1" onDoubleClick={handleEdit}>
                {selectedSupplement.dose}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Standard text</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <TextField
                fullWidth
                className="col-md-12"
                variant="outlined"
                defaultValue={selectedSupplement.standardText}
                value={newValues?.standardText}
                onChange={handleChange}
                name="standardText"
              />
            ) : (
              <Typography variant="body1" onDoubleClick={handleEdit}>
                {selectedSupplement.standardText}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ marginBottom: 2, width: "90%", display: "flex" }}>
          <Box sx={{ width: "40%" }}>
            <Typography variant="subtitle1">
              <b>Leaflet Url</b>
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            {editing ? (
              <TextField
                fullWidth
                className="col-md-12"
                variant="outlined"
                defaultValue={selectedSupplement.leafletUrl}
                value={newValues?.leafletUrl}
                onChange={handleChange}
                name="leafletUrl"
              />
            ) : (
              <Typography variant="body1" onDoubleClick={handleEdit}>
                {selectedSupplement.leafletUrl}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {editing && (
          <>
            <Button
              variant="outlined"
              color="error"
              className="m-2"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="m-2"
              color="success"
              onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        title={`Are you sure you want to delete site '${selectedSupplement?.name}'?`}
        onCancel={closeConfirmModal}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ShowSupplementDetails;
