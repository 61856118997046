import jsPDF from "jspdf";

import {
  addFooter,
  addHeader,
  addPatientInfo,
  addProtocolInfo,
  addTitle,
  addSignature,
  centerPosition,
  commonSpaceBetween,
  getTextHeight,
  marginLeft,
  textFontPoint,
  addCreatedDate,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";

//created by Ranjit Jeve

let globalPosition = 10;

const ReportTitle = "Referral Letter (Frozen cycle)";

const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
const text = "Please feel free to contact us for any further questions.";

export const downloadFrozenCycleProtocol = async (patientData: FrozenCycle) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.reportDate || todaysDate(),
    globalPosition
  );
  globalPosition = addTitle(doc, ReportTitle, globalPosition);
  frozenCycleProtocolData(doc, patientData);
  addSignature(doc, patientData.signature, globalPosition);
  addProtocolInfo(doc, clinicAddress, email, phone);
  doc.save(
    "RL_FrozenCycle_" +
      patientData.patientName +
      "_" +
      patientData.patientDOB +
      ".pdf"
  );
  globalPosition = 10;
  return;
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};

//report specific functions

const frozenCycleProtocolData = (doc: any, patientData: FrozenCycle) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      "Dear Colleague,",
      marginLeft,
      getGlobalPosition(commonSpaceBetween + commonSpaceBetween)
    )
    .setFontSize(12)
    .text(
      "Treatment Protocol",
      centerPosition,
      getGlobalPosition(commonSpaceBetween + commonSpaceBetween),
      null,
      null,
      "center"
    )
    .setFontSize(textFontPoint)
    .text(
      "•    Treatment Type: " + patientData.treatmentType,
      marginLeft,
      getGlobalPosition(commonSpaceBetween + commonSpaceBetween * 2)
    )
    .text(
      "•    Protocol: " + patientData.protocolName,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "•    Embryo Transfer: " + patientData.embryoTransfer,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "•    Luteal Support: " + patientData.lutealSupport,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2)
    )
    .text(
      "•    Additional Comments: " + patientData.additionalComments,
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 2),
      { maxWidth: 180, align: "left" },
      null
    )
    .text(
      "We wish you the very best for your treatment cycle. ",
      marginLeft,
      getGlobalPosition(commonSpaceBetween * 15)
    )
    .text(
      text,
      marginLeft,
      getGlobalPosition(commonSpaceBetween + commonSpaceBetween),
      { maxWidth: 180, align: "justify" },
      null
    )
    .text(
      "Yours Sincerely.",
      marginLeft,
      getTextHeight(
        doc,
        globalPosition,
        text,
        clinicAddress,
        globalPosition,
        email,
        phone
      )
    );
};
