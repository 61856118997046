import { SetStateAction, useEffect, useState } from "react";

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomCheckbox from "./FormCheckbox";
import RadioInput from "./RadioInput";
import { Titles } from "../../util/patient/patient.form.helper";

const TextInputFull = (props: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [characterCount, setCharacterCount] = useState(0);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (event: any) => {
    const count = event.target.value.length;
    setCharacterCount(count);
  };

  return (
    <div className={`col-sm-${props.boxSize} p-2`} key={props.keyName}>
      <div className="row">
        {props.type === "radio" ? (
          <>
            <RadioInput
              item={props}
              register={props.register}
              id={props.id}
              unregister={props.unregister}
            />
          </>
        ) : props.type === "checkbox" ? (
          <>
            <CustomCheckbox
              item={props}
              register={props.register}
              setValue={props.setValue}
            />
          </>
        ) : props.tag === "textarea" ? (
          <TextField
            label={props.label}
            className={
              props.id === "signature"
                ? "col-sm-11 "
                : "col-sm-11 text-capitalize"
            }
            id={props.id}
            placeholder={props.placeholder}
            required={props.required}
            multiline
            onChange={handleChange}
            minRows={props.minRows}
            maxLength={props.maxLength}
            {...props.register(props.id, { required: props.required })}
          />
        ) : props.type === "password" ? (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              className="col-sm-11"
              id={props.id}
              placeholder={props.placeholder}
              required={props.required}
              {...props.register(props.id, { required: props.required })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        ) : props.options?.length > 0 ? (
          props.options?.label ? (
            <Autocomplete
              freeSolo
              className={
                props.id === "email" ||
                props.id === "additionalComments" ||
                props.id === "recommendation" ||
                props.id === "username"
                  ? "col-sm-10 p-0"
                  : "col-sm-10 text-capitalize p-0"
              }
              id={props.id}
              options={props.options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.label}
                  required={props.required}
                  {...props.register(props.id, { required: props.required })}
                />
              )}
            />
          ) : (
            <FormControl className="col-sm-11 p-0">
              <InputLabel id="title">{props.label}</InputLabel>
              <Select
                id="string-select-option"
                labelId={props.label}
                label={props.label}
                {...props.register(props.id, {
                  required: props.required,
                })}
              >
                {props.options?.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : (
          <TextField
            label={props.label}
            type={props.type}
            // InputLabelProps={{
            //   shrink:
            //     props.type.includes("Date") || props.type.includes("date"),
            //   //  true
            //   // : false,
            // }}
            className={
              props.id === "email" ||
              props.id === "additionalComments" ||
              props.id === "recommendations" ||
              props.id === "username"
                ? "col-sm-11"
                : "col-sm-11 text-capitalize"
            }
            size={props.size}
            id={props.id}
            disabled={props.disabled}
            placeholder={props.placeholder}
            required={props.required}
            {...props.register(props.id, { required: props.required })}
          />
        )}
      </div>
    </div>
  );
};

export default TextInputFull;
