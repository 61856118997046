import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CustomTable } from "../table/Table";
import { getAllStaff } from "../../apis/staff";
import AddStaff from "./AddStaff";
import ShowStaffDetails from "./ShowStaffDetails";
import ComponentLoading from "../loading/ComponentLoading";

const ShowStaffs = () => {
  const [staffs, setStaffs] = useState<StaffInformation[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation | null>(
    null
  );
  const [showTable, setShowTable] = useState<boolean>();
  const [isAddStaff, setIsAddStaff] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getStaff = async () => {
    const [response, error] = await getAllStaff();
    if (response) {
      setStaffs(
        response.map((user: StaffInformation) => {
          return {
            name: `${user.title} ${user.firstName} ${user.lastName}`,
            ...user,
          };
        })
      );

      setShowTable(true);
      setIsLoading(false);
    } else {
      console.log(error);
    }
  };

  const columns = [
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Contact",
      key: "phoneNo",
    },
    {
      label: "Type",
      key: "type",
      sortable: true,
    },
  ];

  const handleRowClick = (row: any) => {
    setIsAddStaff(false);
    setSelectedStaff(row);
  };

  useEffect(() => {
    getStaff();
    setSelectedStaff(null);
  }, [reload]);
  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          {staffs && (
            <CustomTable
              data={staffs}
              columns={columns}
              onRowClick={handleRowClick}
              searchable={true}
              optionButtonText="Add Staff"
              setOptionButton={setIsAddStaff}
              //   dateFormatter={formatToDateTime}
              defaultSort={{
                key: columns[0].key,
                direction: "desc",
              }}
              height={"50vh"}
            />
          )}
        </Grid>
      )}
      {(isAddStaff || selectedStaff) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          minHeight={"50vh"}
          sx={{
            borderLeft: {
              md: "none",
              lg: "1px solid #dbd9d9",
            },
            borderBottom: {
              md: "none",
              lg: "none",
            },
            mt: 2,
          }}>
          {isAddStaff ? (
            <AddStaff setIsAddStaff={setIsAddStaff} setReload={setReload} />
          ) : (
            selectedStaff && (
              <ShowStaffDetails
                reload={reload}
                setReload={setReload}
                staffData={selectedStaff}
                setShowTable={setShowTable}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShowStaffs;
