import React from "react";
import NewFeatureComingSoon from "../messages/NewFeatureComming";

const ShowProfile = () => {
  return (
    <div>
      <NewFeatureComingSoon />
    </div>
  );
};

export default ShowProfile;
