import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPatientByPublicId } from "../apis/patients";
import { calculateAge, formatDate, formatDateStr } from "../util/data.util";
import { addPartnerInformation } from "../redux/slice/partnerInformation";
import { FEMALE } from "../constants/Constants";
import { addFemalePatientInformation } from "../redux/slice/femalePatientSlice";
import { addPatientInformation } from "../redux/slice/patientInformation";

const useFetchPatient = () => {
  const [patientDetails, setPatientDetails] =
    useState<PatientInformation | null>(null);
  const dispatch = useDispatch();

  const fetchPatientDetails = async (
    patientPublicId: string,
    isPartner: boolean
  ) => {
    try {
      const { data } = await getPatientByPublicId(patientPublicId);
      if (data.publicId) {
        data.dateOfBirth = data.dateOfBirth
          ? formatDate(data.dateOfBirth)
          : data.dateOfBirthStr
          ? formatDateStr(data.dateOfBirthStr)
          : "";
        data.allergies = data.allergies ? data.allergies.split(", ") : [];
        data.age = data.dateOfBirth ? calculateAge(data.dateOfBirth) : "";
        setPatientDetails(data);
        if (data.gender === FEMALE) {
          dispatch(addFemalePatientInformation(data));
        }
        if (isPartner) {
          dispatch(addPartnerInformation(data));
        } else {
          dispatch(addPatientInformation(data));
        }
        return [data, null] as const;
      } else {
        return [null, data];
      }
    } catch (error) {
      console.error(error);
      return [null, error] as const;
    }
  };

  return fetchPatientDetails;
};

export default useFetchPatient;
