import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import PrimaryButton from "../buttons/PrimaryButton";
import FertilityDetailsComponent from "./FertilityDetailsComponent";
import PreviousTreatment from "./PreviousTreatment";
import ManagementPlan from "./ManagementPlan";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFirstConsultation } from "../../apis/consultation";
import useSnackbar from "../../hooks/useSnackbar";
import { SAVED, SAVE_FAILED } from "../../constants/SnackbarMessages";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import {
  CONTAINER_NAME,
  FILE,
  FILE_NAME,
  FIRST_CONSULTATION,
  NEED_TO_UPDATE,
} from "../../constants/Constants";
import { useFileUpload } from "../../hooks/useFileUpload";
import useCreateJsonFile from "../../hooks/useCreateJsonFile";
import {
  getConsultationContainerName,
  getConsultationFileName,
} from "../../util/consultation/consultation.helper";
import { uploadFile } from "../../apis/files.api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setProgressNotes } from "../../redux/slice/progressNotesSlice";

const FirstConsultation = () => {
  const [tests, setTests] = useState<string[]>([]);
  const [preTreatmentPlan, setPreTreatmentPlan] = useState<string[]>([]);
  const [treatments, setTreatments] = useState<string[]>([]);
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { createJsonFile } = useCreateJsonFile();
  const [previousTreatments, setPreviousTreatments] = useState<
    TreatmentInputs[]
  >([]);
  const [details, setDetails] = useState<FirstConsultation | null>(null);
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const progressNotes: any = useSelector<ProgressNotesState>(
    (state) => state.progressNotes
  );
  const convertObjectToArray = (
    obj: { [s: string]: string } | ArrayLike<string>
  ): string[] => {
    return Object.values(obj);
  };
  const patientPublicId = patientObject.patient.publicId;

  const handleSubmit = async () => {
    dispatch(loading());
    const url = await saveNotepadData();

    console.log(details);
    if (details && details.patient?.primaryDiagnosis?.length > 0) {
      details.patient.primaryDiagnosis = convertObjectToArray(
        details?.patient.primaryDiagnosis
      );
    }
    if (details && details.patient?.secondaryDiagnosis?.length > 0) {
      details.patient.secondaryDiagnosis = convertObjectToArray(
        details?.patient.secondaryDiagnosis
      );
    }

    const prevTreatmentsString = sessionStorage.getItem(
      `prev-treatment-${patientPublicId}`
    );
    if (previousTreatments.length === 0 && prevTreatmentsString) {
      try {
        const prevTreatmentsData = JSON.parse(prevTreatmentsString);

        setPreviousTreatments(prevTreatmentsData);
      } catch (error) {
        console.error("Error parsing previous treatments data:", error);
      }
    }

    const detailsString = sessionStorage.getItem(
      `fertilityDetails-${patientPublicId}`
    );
    if (!details && detailsString) {
      try {
        const detailsData = JSON.parse(detailsString);
        setDetails(detailsData);
      } catch (error) {
        console.error("Error parsing fertility details data:", error);
      }
    }

    let obj: FirstConsultationPostRequest = {
      patientPublicId: patientObject?.patient?.publicId,
      partnerPublicId: partnerObject?.partner?.publicId,
      patientDetails: details?.patient ? details.patient : null,
      partnerDetails: details?.partner ? details.partner : null,
      previousTreatments:
        previousTreatments.length > 0 ? previousTreatments : [],
      supplementPublicIds:
        preTreatmentPlan.length > 0 ? preTreatmentPlan : null,
      testsPublicIds: tests.length > 0 ? tests : null,
      treatmentsPublicIds: treatments.length > 0 ? treatments : null,
    };
    if (url) {
      obj = {
        ...obj,
        handwrittenNotes: [
          {
            url: url as string,
            label: FIRST_CONSULTATION,
            height: 0,
          },
        ],
      };
    }
    const [response, error] = await addFirstConsultation(obj);
    if (response) {
      successSnackbar("Follow up" + SAVED);
      sessionStorage.setItem(NEED_TO_UPDATE, JSON.stringify(true));
      setTimeout(() => window.location.reload(), 1000);
      localStorage.removeItem(patientPublicId);
      sessionStorage.removeItem(`prev-treatment-${patientPublicId}`);
      sessionStorage.removeItem(`fertilityDetails-${patientPublicId}`);

      dispatch(setProgressNotes([...progressNotes.data, response]));
    } else {
      errorSnackbar(SAVE_FAILED + "follow up");
    }
    dispatch(doneLoading());
  };

  const saveNotepadData = async () => {
    const persistedSnapshot = localStorage.getItem(patientPublicId);
    if (persistedSnapshot) {
      const file = await createJsonFile(JSON.parse(persistedSnapshot));
      if (file) {
        const formData = new FormData();
        formData.append(FILE, file);
        formData.append(
          FILE_NAME,
          getConsultationFileName(patientPublicId, FIRST_CONSULTATION)
        );
        formData.append(
          CONTAINER_NAME,
          getConsultationContainerName(patientPublicId)
        );
        try {
          const [response, error] = await uploadFile(formData);
          if (response) {
            // setIsWriting(false);
            return response;
            // saveConsultation(consultationData, response);
          }
          return false;
        } catch {
          return false;
        }
      }
    }
  };

  const [expandedAccordion, setExpandedAccordion] =
    useState<AccordionPanel | null>("details-accordion");

  const handleAccordionChange =
    (panel: AccordionPanel) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (isExpanded) {
        setExpandedAccordion(panel);
      } else {
        setExpandedAccordion(null);
      }
    };

  return (
    <div>
      <Accordion
        expanded={expandedAccordion === "details-accordion"}
        onChange={handleAccordionChange("details-accordion")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="details-accordion"
        >
          <Typography>Fertility Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FertilityDetailsComponent
            details={details}
            setDetails={setDetails}
            defaultTemplateUrl={"template/p4fertilityConsultationTemplate.json"}
            setExpandedAccordion={setExpandedAccordion}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedAccordion === "prev-treatment-accordion"}
        onChange={handleAccordionChange("prev-treatment-accordion")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="prev-treatment-accordion"
        >
          <Typography>Previous Treatment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreviousTreatment
            previousTreatments={previousTreatments}
            setPreviousTreatments={setPreviousTreatments}
            setExpandedAccordion={setExpandedAccordion}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedAccordion === "management-accordion"}
        onChange={handleAccordionChange("management-accordion")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="management-accordion"
        >
          <Typography>Management Plan</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <ManagementPlan
              tests={tests}
              setTests={setTests}
              preTreatmentPlan={preTreatmentPlan}
              setPreTreatmentPlan={setPreTreatmentPlan}
              treatments={treatments}
              setTreatments={setTreatments}
            />
            <Grid item xs={12} sm={12} md={12} marginBottom={5}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <PrimaryButton
                  label={"Save"}
                  type={"button"}
                  onClick={handleSubmit}
                  className={""}
                />
              </Grid>
            </Grid>
          </>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion
        expanded={expandedAccordion === "save-header"}
        onChange={handleAccordionChange("save-header")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="save-header">
          <Typography>Save</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

export default FirstConsultation;
