export const getContentType = (fileName: string) => {
  const fileExtension = fileName.split(".").pop();
  switch (fileExtension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "pdf":
      return "application/pdf";
    case "doc":
    case "docx":
      return "application/msword";
    default:
      return "application/octet-stream";
  }
};
type Units = "B" | "KB" | "MB" | "GB" | "TB";

export const getFileSize = (size: number, unit: Units): string => {
  let fileSize = size;

  switch (unit) {
    case "B":
      break;
    case "KB":
      fileSize /= 1024;
      break;
    case "MB":
      fileSize /= 1024 * 1024;
      break;
    case "GB":
      fileSize /= 1024 * 1024 * 1024;
      break;
    case "TB":
      fileSize /= 1024 * 1024 * 1024 * 1024;
      break;
    default:
      throw new Error("Invalid unit provided.");
  }

  return fileSize.toFixed(2) + " " + unit;
};
