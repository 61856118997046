import { AZURE_URL } from "../constants/env-constants";

export const getAllRoles = async () => {
  try {
    const response = await AZURE_URL.get("/user/allRoles");
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await AZURE_URL.get("/user/get/all");
    if (response.status === 200 || response.status === 201) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return error.response;
  }
};

export const getUserDetails = async () => {
  try {
    const response = await AZURE_URL.get("/user/get");
    return response;
  } catch (error: any) {
    return error;
  }
};

export const createUser = async (userData: CreateUser) => {
  try {
    const response = await AZURE_URL.post("/user/adminSignUp", userData);
    console.log(response);
    if (response.status === 200 || response.status === 201) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createStaff = async (staffData: CreateStaff) => {
  try {
    const response = await AZURE_URL.post("/staff/create", staffData);
    if (response.status === 200 || response.status === 201) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};
export const deleteUser = async (username: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/user/delete?usernameToDelete=${username}`
    );
    if (response.status === 200) {
      return [`User ${username} deleted successfully`, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response || error.message];
  }
};

export const reset2FA = async (req: ResetAuthRequest) => {
  try {
    const response = await AZURE_URL.put("/user/admin/resetAuth", req);

    if (response?.status === 200) {
      return [response, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response];
  }
};
