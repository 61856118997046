import { AZURE_URL } from "../constants/env-constants";

export const createReport = async (data: CreateReportRequest) => {
  try {
    const response = await AZURE_URL.post("/report/create", data);

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else {
      return [null, response || "Something went wrong"];
    }
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getAllReports = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/report/get?patientPublicId=${patientId}`
    );

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getReportsByType = async (patientId: string, type: string) => {
  try {
    const response = await AZURE_URL.get(
      `/report/get?patientPublicId=${patientId}&type=${type}`
    );

    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    console.log(error);
    return [null, error.response.data];
  }
};

export const deleteReportById = async (reportId: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/report/delete?publicId=${reportId}`
    );
    console.log(response);
    if (response.status === 200) {
      return [response.status, null];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};
