import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <Container className="mt-5">
      <Row>
        <Col xs={12} className="text-center">
          <h1 className="text-danger">401 - Unauthorised</h1>
          <p>You do not have permission to access this page.</p>
          <BackButton />
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
