import { useState, SyntheticEvent, useEffect } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getAllTDLTestRequests } from "../../../apis/labs";
import { CustomTable } from "../../table/Table";
import { formatDate } from "../../../util/data.util";
import PrimaryButton from "../../buttons/PrimaryButton";
import ActionBack from "../../actionIcons/ActionBack";
import { useDispatch, useSelector } from "react-redux";
import { doneLoading, loading } from "../../../redux/slice/loaderSlice";
import { createTestResult } from "../../../util/labs/labResultPdf";
import { testResultStatus } from "../../../util/appointment/util";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import ShowTestResults from "../testResults/ShowTestResults";
import PatientBookTest from "./PatientBookTest";
interface TestRequestsProps {
  patientId?: string;
}

const PatientRequestedTests: React.FC<TestRequestsProps> = ({ patientId }) => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const [requestedTests, setRequestedTests] = useState<
    TDLRequestedTestResponse[]
  >([]);
  const [selectedTest, setSelectedTest] = useState<TDLRequestedTestResponse>();
  const [requestData, setRequestData] = useState<TDLRequestedTestResponse>();
  const [showResult, setShowResult] = useState<boolean>(false);
  const [isBookingTest, setIsBookingTest] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { downloadFile } = useDownloadFile();

  const handleDownload = async (row: TDLRequestedTestResponse) => {
    dispatch(loading());
    await downloadFile(row.formUrl);
    dispatch(doneLoading());
  };

  const handleRowClick = (row: TDLRequestedTestResponse) => {
    setSelectedTest(row);
    setShowResult(true);
  };

  const columns = [
    {
      label: "Name",
      key: "FullName",
      sortable: true,
    },

    {
      label: "Requested Date",
      key: "whenCreated",
      sortable: true,
    },
    {
      label: "Requested Tests",
      key: "RequestedTests",
    },
    {
      label: "Result Status",
      key: "ResultStatus",
      sortable: true,
      style: testResultStatus,
    },
    {
      label: "Actions",
      key: "actions",
    },
  ];
  const actions: Action[] = [
    {
      label: "",
      onClick: handleDownload,
      tooltipLabel: "Download Form",
      icon: (
        <FileDownloadOutlinedIcon
          style={{ cursor: "pointer" }}
          color="primary"
        />
      ),
    },
    {
      label: "",
      onClick: handleRowClick,
      tooltipLabel: "View Results",
      icon: <VisibilityOutlinedIcon />,
      disableOn: ["Pending"],
      disableSelectorKey: "ResultStatus",
    },
  ];

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handlePrint = () => {
    selectedTest && createTestResult(selectedTest);
  };

  useEffect(() => {
    const getPatientTests = async (patientId: string) => {
      dispatch(loading());
      const [response, error] = await getAllTDLTestRequests({
        patientPublicId: patientId,
      });
      if (response) {
        setRequestedTests(
          response.map((test: any) => {
            return {
              ...test,
              FullName: `${capitalizeFirstLetter(
                test.patient.title ?? ""
              )} ${capitalizeFirstLetter(
                test.patient.firstName ?? ""
              )} ${capitalizeFirstLetter(test.patient.lastName ?? "")}`,
              RequestedTests: test.testCode.split(",").join(" | "),
              ResultStatus:
                (test.testResult?.Tests?.length || 0) > 0
                  ? "Results Available"
                  : "Pending",
            };
          })
        );
      } else {
        console.log(error);
      }
      dispatch(doneLoading());
    };
    if (patientObject) {
      getPatientTests(patientObject.patient.publicId);
    }
  }, [patientObject]);

  useEffect(() => {
    if (requestData) {
      setRequestedTests((prev) => {
        return [
          ...prev,
          {
            ...requestData,
            FullName: `${capitalizeFirstLetter(
              requestData.patient.title ?? ""
            )} ${capitalizeFirstLetter(
              requestData.patient.firstName ?? ""
            )} ${capitalizeFirstLetter(requestData.patient.lastName ?? "")}`,
            RequestedTests: requestData.testCode.split(",").join(" | "),
            ResultStatus:
              (requestData.testResult?.Tests?.length || 0) > 0
                ? "Results Available"
                : "Pending",
          },
        ];
      });
      setIsBookingTest(false);
    }
  }, [requestData]);

  const renderBookTest = () => {
    return (
      <>
        <ActionBack handleClick={() => setIsBookingTest((prev) => !prev)} />
        <PatientBookTest
          patient={patientObject.patient}
          setRequestData={setRequestData}
        />
      </>
    );
  };
  return (
    <>
      {!isBookingTest ? (
        <div>
          {!showResult ? (
            <Grid container xs={12} sm={12} md={12} spacing={2}>
              <Grid item xs={12} sm={12} md={12} p={2}>
                {requestedTests && (
                  <CustomTable
                    data={requestedTests}
                    columns={columns}
                    actions={actions}
                    searchable={true}
                    dateFormatter={formatDate}
                    setOptionButton={setIsBookingTest}
                    optionButtonText={"Book a Test"}
                    defaultSort={{
                      key: columns[1].key,
                      direction: "desc",
                    }}
                    height={"50vh"}
                    overflow="auto"
                    tableHeight="600px"
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            showResult &&
            selectedTest?.testResult?.Tests && (
              <>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <ActionBack handleClick={() => setShowResult(false)} />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      label={"Print Result"}
                      type={"button"}
                      onClick={handlePrint}
                      startIcon={<PrintOutlinedIcon />}
                    />
                  </Grid>
                </Grid>
                <ShowTestResults selectedTest={selectedTest} />
              </>
            )
          )}
        </div>
      ) : (
        renderBookTest()
      )}
    </>
  );
};

export default PatientRequestedTests;
