import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: PatientState = {
  patient: {
    publicId: "",
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    dateOfBirth: "",
    age: 0,
    dateOfBirthStr: "",
    address: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    ownerStaff: {
      publicId: "",
      username: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      signature: "",
      createdBy: "",
      whenCreated: "",
      type: "",
      updatedBy: "",
      whenLastUpdated: "",
      siteExternalId: "",
    },
    createdBy: "",
    whenCreated: "",
    partnerPublicId: "",
    profilePictureUrl: "",
    patientDetails: {
      gpDetails: {
        name: "",
        email: "",
        phoneNo: "",
        contact: "",
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        },
      },
      nextOfKin: {
        name: "",
        contact: "",
        relation: "",
      },
      allergies: "",
      referralSource: "",
      fundingSource: "",
      weight: 0,
      weightUnit: "",
      height: 0,
      heightUnit: "",
      occupation: "",
      smoking: false,
      tryingSinceYears: 0,
      tryingSinceMonths: 0,
      cpLiveChildren: 0,
      ppLiveChildren: 0,
      obstetricsHistory: "",
      medicalHistory: "",
      surgeryHistory: "",
      medications: "",
      significantGeneticHistory: "",
      smearsCervix: "",
      treatmentOnCervix: "",
      periods: "",
      periodsComment: "",
      rubellaImmune: false,
      primaryDiagnosis: [],
      secondaryDiagnosis: [],
      subfertility: "",
      additionalComments: "",
    },
  },
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    addPatientInformation: (
      state: PatientState,
      action: PayloadAction<PatientInformation>
    ) => {
      state.patient = action.payload;
    },
    removePatientInformation: (state: PatientState) => {
      state.patient = initialState.patient;
    },
    getPatientInformation: (state: PatientState) => {
      return state;
    },
  },
});

export default patientSlice.reducer;
export const {
  addPatientInformation,
  removePatientInformation,
  getPatientInformation,
} = patientSlice.actions;
